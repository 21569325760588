<template>
  <div class="new-wrapper min-height-100 funds pa-10">
    <div class="custom-header-text new-font-bold mb-2">Fördermöglichkeiten</div>
    <v-row>
      <v-col cols="12" md="9">
        <v-chip
          class="ma-1"
          label
          @click="onSelectFilter('Alle')"
          :color="selectedFilter === 'Alle' ? '#9C004726' : ''"
          :outlined="selectedFilter !== 'Alle'"
          :class="selectedFilter === 'Alle' ? ['filter-active'] : ['filter']"
          >Alle</v-chip
        >
        <v-chip
          v-for="thema of themen"
          :key="thema.name"
          class="ma-1"
          label
          @click="onSelectFilter(thema.name)"
          :color="selectedFilter === thema.name ? '#9C004726' : ''"
          :outlined="selectedFilter !== thema.name"
          :class="
            selectedFilter === thema.name ? ['filter-active'] : ['filter']
          "
        >
          {{ thema.name }}
          <v-avatar
            right
            size="small"
            class="px-1"
            :class="selectedFilter === thema.name ? 'count-active' : 'count'"
          >
            {{ thema.count }}
          </v-avatar>
        </v-chip>
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          dense
          background-color="#f6e4ed"
          :items="sortOptions"
          v-model="selectedSortOption"
          label="Sortierung"
          outlined
          @change="onSortBySet"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="(column, i) of columns" :key="i" :style="columnStyle">
        <template v-for="entry of column">
          <notification-hint-card :key="entry.id" v-if="entry.showSignup" class="mb-4" />
          <funding-card v-else :key="entry.id" :entry="entry" />
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FundingCard from "@/components/ui/FundingCard";
import NotificationHintCard from "@/components/ui/NotificationHintCard.vue";

export default {
  name: "Funds",
  components: {NotificationHintCard, FundingCard },
  data: () => ({
    sortOptions: ["Fristende", "Erstellungsdatum"],
    selectedSortOption: "Fristende"
  }),
  computed: {
    ...mapGetters({
      themen: "foerdermittel/getFoerderthemenWithCount",
      selectedFilter: "foerdermittel/getSelectedFilter",
      filteredFoerdermittel: "foerdermittel/getFilteredFoerdermittel"
    }),
    showNewsletterSignUp() {
      return true;
    },
    itemsWithNewsletterSignup() {
      if (!this.showNewsletterSignUp) return this.filteredFoerdermittel;
      return [
        ...this.filteredFoerdermittel.slice(0, 2),
        { id: -1, showNewsletterSignup: true },
        ...this.filteredFoerdermittel.slice(3)
      ];
    },
    columnCount() {
      if (this.$vuetify.breakpoint.xs) return 1;
      else if (this.$vuetify.breakpoint.mdAndUp) return 3;
      else return 2;
    },
    columnStyle() {
      const width = Math.trunc((1 / this.columnCount) * 100);

      return {
        width: `${width}%`
      };
    },
    columns() {
      let newsList = this.$store.getters[
        "foerdermittel/getFilteredFoerdermittel"
      ];

      const columnArray = [];

      for (let count of [...Array(this.columnCount).keys()]) {
        columnArray[count] = [];
      }

      let currentPosition = 0;
      /* eslint-disable no-unused-vars */
      for (let i of [...Array(newsList.length).keys()]) {
        columnArray[currentPosition].push(newsList.shift());
        currentPosition += 1;

        if (currentPosition === this.columnCount) {
          currentPosition = 0;
        }
      }
      // return columnArray;
      /* eslint-enable no-unused-vars */
      const entriesWithNewsletterSignup = [];

      if (this.showNewsletterSignUp) {
        entriesWithNewsletterSignup.push({
          id: -1,
          showSignup: true
        });
      }

      entriesWithNewsletterSignup.push(...columnArray[columnArray.length - 1]);
      columnArray[columnArray.length - 1] = entriesWithNewsletterSignup;
      return columnArray;
    }
  },
  methods: {
    onSelectFilter(filter) {
      this.$store.commit("foerdermittel/SET_FILTER", filter);
    },
    onSortBySet() {
      this.$store.commit("foerdermittel/SET_SORT_BY", this.selectedSortOption);
    },
    sortByCreatedDate(a, b) {
      const dateA = Date.parse(a.created_at);
      const dateB = Date.parse(b.created_at);

      if (dateA < dateB) return -1;
      else if (dateA > dateB) return 1;
      else return 0;
    },
    sortByFristDate(a, b) {
      const dateA = Date.parse(a.Frist);
      const dateB = Date.parse(b.Frist);

      if (dateA < dateB) return -1;
      else if (dateA > dateB) return 1;
      else return 0;
    }
  },
  mounted() {
    this.onSelectFilter("Alle");
  }
};
</script>

<style scoped>
.filter {
  background-color: transparent !important;
}

.filter:hover {
  border-color: #b40055;
  background: transparent !important;
  color: #b40055 !important;
}

.filter-active {
  border-style: solid !important;
  border-width: thin !important;
  border-color: #b40055 !important;
  color: #b40055 !important;
  background: rgba(156, 0, 71, 0.15) !important;
}

.count {
  border-radius: 50%;
  background: #eaecef;
}

.count-active {
  border-radius: 50%;
  background: rgba(156, 0, 71, 0.15) !important;
}
</style>
