<template>
<v-card width="100%" @click="setSelectedThemaId(themaId)" class="pa-3" :elevation="elevation || 0" :ripple="false">
    <v-row :style="rowBorder">
        <v-col cols="6" class="col-flex">
            <div class="my-auto">
            <div v-if="isNew"><v-chip x-small class="badge new-item">NEU</v-chip></div>
            <span class="font-weight-bold">{{vertrag}}</span><br>
            <vue-markdown-it class="md-text" :source="details"></vue-markdown-it>
            </div>
        </v-col>
        <v-col cols="2" class="col-flex">
            <p class="center-left">{{ communeName }}</p>
        </v-col>
        <v-col cols="2" class="col-flex">
            <p class="center-left">{{ updated}}</p>
        </v-col>
        <v-col cols="2" class="col-flex">
            <v-btn text small color="primary" :ripple="false" class="center-left">
                Verlauf
                <v-icon right>mdi-chevron-right</v-icon>
            </v-btn>
        </v-col>
    </v-row>
</v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import VueMarkdownIt from "vue-markdown-it";
export default {
    name: "KonzessionsThemaCard",
    props: ['themaId', 'isNew', 'vertrag', 'details', 'communeName', 'updated', 'borderColor', 'backgroundColor', 'elevation'],
    methods: {
        ...mapActions('konzessionsthemen', ['setSelectedThemaId']),
    },
    computed: {
        ...mapGetters('konzessionsthemen', ['getSelectedThema']),
        rowBorder() {
            return {
                'box-sizing': 'border-box',
                'border-left': `7px solid ${this.borderColor}`,
                'background-color': !this.cardIsActive ? this.backgroundColor : 'rgb(237,218,227)',
            }
        },
        cardIsActive() {
            return this.themaId === this.getSelectedThema?.id;
        }
    },
    components: {
        VueMarkdownIt
    }
}
</script>

<style scoped>
.md-text,
.md-text p {
    white-space: break-spaces !important;
    line-height: 1;
}

.new-item {
    background: #F8A807 !important;
}

.col-flex {
    display: flex !important;
}

.center-left {
    margin: auto auto auto 10px !important;
}
</style>
