<template>
<div class="pa-4">
  <span class="text-h6">Neue Lieferstelle</span>
  <v-row no-gutters>
    <v-col cols="12">
      <v-autocomplete dense filled :items="getMalosForNewLieferstelle" label="Marktlokation"
                      v-model="selectedMarktlokation" />
    </v-col>
    <v-col cols="12" class="py-3">
      <v-textarea label="Kommentar" auto-grow rows="1" hide-details v-model="comment"
                  :readonly="!selectedMarktlokation" :disabled="disableTextarea"/>
    </v-col>
    <v-col cols="6">
      <v-btn block class="mr-2">Abbrechen</v-btn>
    </v-col>
    <v-col cols="6">
      <v-btn :disabled="disableSubmitButton" block class="ml-2" color="primary" @click="onSubmit">Speichern</v-btn>
    </v-col>
  </v-row>
</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AddLieferstelleSidebar",
  data: () => ({
    selectedMarktlokation: undefined,
    comment: undefined,
  }),
  computed: {
    ...mapGetters('kommunalrabatt', ['getMalosForNewLieferstelle']),
    ...mapGetters('user', ['username', 'userCommuneName', 'isNewEmployee']),
    disableTextarea() {
      return !this.selectedMarktlokation || !this.getMalosForNewLieferstelle.includes(this.selectedMarktlokation);
    },
    disableSubmitButton() {
      return !this.comment || this.comment.length < 1;
    },
  },
  methods: {
    ...mapActions('kommunalrabatt', ['addComment', 'enableKommunalrabatt']),

    async onSubmit() {
      const addCommentsPromise = this.addComment({
        marktlokation: this.selectedMarktlokation,
        comment: {
          author: this.username,
          message: this.comment,
          timestamp: new Date().getTime(),
          commune: this.isNewEmployee ? 'NEW MA' : this.userCommuneName,
          year: new Date().getFullYear(),
        }
      });
      const enableRabattPromise = this.enableKommunalrabatt(this.selectedMarktlokation);

      await Promise.all([addCommentsPromise, enableRabattPromise]);
      this.$emit('close-sidebar');
    }
  }
}

</script>

<style scoped>

</style>
