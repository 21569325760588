import StromIcon from "./StromIcon";
import GasIcon from "./GasIcon";
import TrinkwasserIcon from "./TrinkwasserIcon";
import AbwasserIcon from "./AbwasserIcon";
import EntsorgungIcon from "./EntsorgungIcon";
import StrassenbeleuchtungIcon from "./StrassenbeleuchtungIcon";
import MobilitaetIcon from "./MobilitaetIcon";
import OepnvIcon from "./OepnvIcon";
import ErneuerbareEnergienIcon from "./ErneuerbareEnergienIcon";
import EnergienaheDienstleistungenIcon from "./EnergienaheDienstleistungenIcon";
import BaederIcon from "./BaederIcon";
import EngagementIcon from "./EngagementIcon";
import QuartiersentwicklungIcon from "./QuartiersentwicklungIcon";
import KlimaUmweltschutzIcon from "./KlimaUmweltschutzIcon";
import KontakteIcon from "./KontakteIcon";
import UeberNewIcon from "./UeberNewIcon";
import NewsIcon from "./NewsIcon";
import StoermeldungenIcon from "./StoermeldungenIcon";
import BauprojekteIcon from "./BauprojekteIcon";
import FoerdermoeglichkeitenIcon from "./FoerdermoeglichkeitenIcon";
import WissenIcon from "./WissenIcon";
import UserIcon from "./UserIcon";
import KommunalrabattIcon from "@/plugins/new-ag-icons-2021/KommunalrabattIcon.vue";
import MagazinIcon from "@/plugins/new-ag-icons-2021/MagazinIcon.vue";
import DashboardRabattprozessIcon from "@/plugins/new-ag-icons-2021/DashboardRabattprozessIcon.vue";
import DashboardHandshakeIcon from "@/plugins/new-ag-icons-2021/DashboardHandshakeIcon.vue";
import DashboardThermometerIcon from "@/plugins/new-ag-icons-2021/DashboardThermometerIcon.vue";
import DashboardTrafficConeIcon from "@/plugins/new-ag-icons-2021/DashoardTrafficConeIcon.vue";
import DashboardLadesaeuleIcon from "@/plugins/new-ag-icons-2021/DashboardLadesaeuleIcon.vue";
import DashboardEmailIcon from "@/plugins/new-ag-icons-2021/DashboardEmailIcon.vue";
import DashboardNewIcon from "@/plugins/new-ag-icons-2021/DashboardNewIcon.vue";
import DashboardContractIcon from "@/plugins/new-ag-icons-2021/DashboardContractIcon.vue";
import DashboardPressIcon from "@/plugins/new-ag-icons-2021/DashboardPressIcon.vue";
import DashboardFaqIcon from "@/plugins/new-ag-icons-2021/DashboardFaqIcon.vue";
import DashboardErlebenIcon from "@/plugins/new-ag-icons-2021/DashboardErlebenIcon.vue";
import MailIcon from "@/plugins/new-ag-icons-2021/MailIcon.vue";
import CallIcon from "@/plugins/new-ag-icons-2021/CallIcon.vue";
import ThumbsUpIcon from "@/plugins/new-ag-icons-2021/ThumbsUpIcon.vue";
import CheckmarkIcon from "@/plugins/new-ag-icons-2021/CheckmarkIcon.vue";
import FaqIcon from "@/plugins/new-ag-icons-2021/FaqIcon.vue";
import CommuneIcon from "@/plugins/new-ag-icons-2021/CommuneIcon.vue";
import WaermeIcon from "@/plugins/new-ag-icons-2021/WaermeIcon.vue";
import ArrowRightIcon from "@/plugins/new-ag-icons-2021/ArrowRightIcon.vue";
import NewsWhiteIcon from "@/plugins/new-ag-icons-2021/NewsWhiteIcon.vue";
import NachhaltigkeitIcon from "@/plugins/new-ag-icons-2021/NachhaltigkeitIcon.vue";
import NachhaltigkeitMenuIcon from "@/plugins/new-ag-icons-2021/NachhaltigkeitMenuIcon.vue";
import NotificationsIcon from "@/plugins/new-ag-icons-2021/NotificationsIcon.vue";

export default {
  strom: {
    component: StromIcon
  },
  gas: {
    component: GasIcon
  },
  trinkwasser: {
    component: TrinkwasserIcon
  },
  abwasser: {
    component: AbwasserIcon
  },
  entsorgung: {
    component: EntsorgungIcon
  },
  strassenbeleuchtung: {
    component: StrassenbeleuchtungIcon
  },
  mobilitaet: {
    component: MobilitaetIcon
  },
  oepnv: {
    component: OepnvIcon
  },
  erneuerbareEnergien: {
    component: ErneuerbareEnergienIcon
  },
  energienaheDienstleistungen: {
    component: EnergienaheDienstleistungenIcon
  },
  baeder: {
    component: BaederIcon
  },
  engagement: {
    component: EngagementIcon
  },
  quartiersentwicklung: {
    component: QuartiersentwicklungIcon
  },
  klimaUmweltschutz: {
    component: KlimaUmweltschutzIcon
  },
  kontakte: {
    component: KontakteIcon
  },
  ueberNew: {
    component: UeberNewIcon
  },
  news: {
    component: NewsIcon
  },
  stoermeldungen: {
    component: StoermeldungenIcon
  },
  bauprojekte: {
    component: BauprojekteIcon
  },
  foerdermoeglichkeiten: {
    component: FoerdermoeglichkeitenIcon
  },
  wissen: {
    component: WissenIcon
  },
  user: {
    component: UserIcon
  },
  kommunalrabatt: {
    component: KommunalrabattIcon
  },
  magazin: {
    component: MagazinIcon
  },
  dashboardRabattprozess: {
    component: DashboardRabattprozessIcon
  },
  dashboardHandshake: {
    component: DashboardHandshakeIcon
  },
  dashboardThermometer: {
    component: DashboardThermometerIcon
  },
  dashboardTrafficCone: {
    component: DashboardTrafficConeIcon
  },
  dashboardLadesaeule: {
    component: DashboardLadesaeuleIcon
  },
  dashboardEmail: {
    component: DashboardEmailIcon
  },
  dashboardNew: {
    component: DashboardNewIcon
  },
  dashboardContract: {
    component: DashboardContractIcon
  },
  dashboardPress: {
    component: DashboardPressIcon
  },
  dashboardErleben: {
    component: DashboardErlebenIcon
  },
  dashboardFaq: {
    component: DashboardFaqIcon
  },
  mailIcon: {
    component: MailIcon
  },
  callIcon: {
    component: CallIcon
  },
  thumbsUp: {
    component: ThumbsUpIcon
  },
  checkmark: {
    component: CheckmarkIcon
  },
  faq: {
    component: FaqIcon
  },
  commune: {
    component: CommuneIcon
  },
  waerme: {
    component: WaermeIcon
  },
  arrowRight: {
    component: ArrowRightIcon
  },
  newsWhite: {
    component: NewsWhiteIcon
  },
  nachhaltigkeit: {
    component: NachhaltigkeitIcon
  },
  nachhaltigkeitMenu: {
    component: NachhaltigkeitMenuIcon
  },
  notifications: {
    component: NotificationsIcon
  }
};
