export const checkIconName = {
  methods: {

    /**
     * Checks if object has an icon property (either iconNew or iconMaterialDesign) 
     * and returns its value in the appropriate string format for Vuetify <v-icon>
     * @param {object} item 
     * @returns {string} icon value
     */
    checkIconName(item) {
      if (item.iconNew) {
        return '$' + item.iconNew
      } else if (item.iconMaterialDesign) {
        return 'mdi-' + item.iconMaterialDesign
      }
    }

  }
}