import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

export default {
  namespaced: true,
  state: {
    isLoggedIn: false,
    isConfirmedAccount: false,
    id: null,
    email: "",
    firstName: String,
    lastName: String,
    imgUrl: String,
    commune: Object,
    communePartner: Object,
    communeRegionalLeader: Object,
    faculties: [],
    showDevLogin: true,
    isNewEmployee: false,
    serviceDetails: [],
    showNewsletterSignUp: true,
    faq: [],
    enableKommunalrabatt: false,
    enableKonzessionssachstaende: false,
    enableVertragsuebersicht: false,
    enableWaermeplanung: false,
    enableKommunenDashboard: false,
    enableVerbrauchsdaten: false,
    enableMoments: false,
    preferredUserData: null,
  },
  mutations,
  actions,
  getters,
};
