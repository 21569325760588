<template>
  <div class="px-6">
    <div>
      <p class="text-h6 text-uppercase mb-0">
        Details Lieferstelle | {{ selectedLieferstelle.marktlokation }}
      </p>
    </div>

    <sidebar-details />

    <div class="text-uppercase">
      <span class="text-h6">Kommentare</span>
    </div>

    <sidebar-comments />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SidebarComments from "@/components/ui/Kommunalrabatt/SidebarComments";
import SidebarDetails from "@/components/ui/Kommunalrabatt/SidebarDetails";

export default {
  name: "LieferstellenSidebar",
  computed: {
    ...mapGetters("kommunalrabatt", ["selectedLieferstelle", "getDetailsYears"])
  },
  components: { SidebarDetails, SidebarComments }
};
</script>

<style scoped></style>
