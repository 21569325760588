<script>
export default {
  name: "CommuneDashboardBadge",
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<template>
<div style="padding: 1px 0" class="d-inline px-2 white--text font-weight-bold">{{ text }}</div>
</template>

<style scoped>

</style>
