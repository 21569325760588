<script>
import { mapGetters } from "vuex";

export default {
  name: "ActionCard",
  props: {
    contactPerson: {
      type: Object
    }
  },
  data: () => ({
    showDialog: false,
    dialogType: "call",
    showDateMenu: false,
    selectedDate: undefined,
    showTimeMenu: false,
    selectedTime: undefined,
    name: undefined,
    phone: undefined,
    mail: undefined,
    message: undefined,
    sentRequest: false,
    availableTimes: [
      "08:30",
      "09:00",
      "09:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "13:00",
      "13:30",
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00"
    ],
    loading: false
  }),
  methods: {
    openCallDialog() {
      this.clearDialog();
      this.dialogType = "call";
      this.showDialog = true;
    },
    openMailDialog() {
      this.clearDialog();
      this.dialogType = "mail";
      this.showDialog = true;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    clearDialog() {
      this.selectedDate = undefined;
      this.selectedTime = undefined;
      this.mail = this.userEmail;
      this.name = this.username;
      this.phone = undefined;
      this.message = undefined;
      this.sentRequest = false;
    },
    async sendMail() {
      this.loading = true;
      await this.$store.dispatch("quartiersentwicklung/sendMail", {
        requestType: this.dialogType,
        name: this.name,
        phone: this.phone,
        mail: this.mail,
        message: this.message,
        date: this.selectedDate,
        time: this.selectedTime
      });
      this.loading = false;
      this.sentRequest = true;
    }
  },
  computed: {
    ...mapGetters("user", ["username", "userEmail"]),
    contactPersonImage() {
      const apiUrl = process.env.VUE_APP_API_BASE_URL;
      const { person } = this.contactPerson;
      if (person.image && person.image.formats.small) {
        return apiUrl + person.image.formats.small.url;
      } else if (person.image && person.image.url) {
        return apiUrl + person.image.url;
      } else {
        return undefined;
      }
    },
    minDate() {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();

      return `${yyyy}-${mm}-${dd}`;
    },
    formattedDate() {
      return this.formatDate(this.selectedDate);
    },
    disableDialogSubmitButton() {
      if (this.dialogType === "call") {
        return (
          !this.selectedDate ||
          !this.selectedTime ||
          !this.name ||
          !this.phone ||
          !this.message
        );
      } else {
        return !this.name || !this.phone || !this.message;
      }
    }
  }
};
</script>

<template>
  <div>
    <v-dialog v-model="showDialog" width="500">
      <v-card>
        <v-card-title>
          <div
            class="text-center text-h5 font-weight-bold"
            style="width: 100%"
            v-if="!sentRequest"
          >
            {{ dialogType === "call" ? "Rückruf vereinbaren" : "Ihre Anfrage" }}
          </div>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" class="text-center">
              <v-avatar class="mx-auto icon-bg" size="75">
                <v-img v-if="contactPersonImage" :src="contactPersonImage" />
                <v-icon v-else size="75" color="#ffffff80">$user</v-icon>
              </v-avatar>
              <div class="text-center font-weight-bold text-h6">
                {{ contactPerson.name }}
              </div>
            </v-col>
            <template v-if="!sentRequest">
              <v-col cols="12">
                <v-text-field
                  label="Ihr Name"
                  v-model="name"
                  outlined
                  dense
                  background-color="#B4005520"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Ihre E-Mail-Adresse"
                  outlined
                  v-model="mail"
                  dense
                  background-color="#B4005520"
                  type="mail"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Ihre Telefonnummer"
                  outlined
                  v-model="phone"
                  dense
                  background-color="#B4005520"
                ></v-text-field>
              </v-col>
              <template v-if="dialogType === 'call'">
                <v-col cols="12" md="6">
                  <v-menu
                    v-model="showDateMenu"
                    :close-on-content-click="false"
                    :nudge-top="-45"
                    transition="scale-transition"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formattedDate"
                        label="Wunschdatum"
                        outlined
                        dense
                        background-color="#B4005520"
                        :class="$vuetify.breakpoint.mdAndUp ? 'mr-2' : ''"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @input="showDateMenu = false"
                      v-model="selectedDate"
                      locale="de-de"
                      show-current
                      show-week
                      :min="minDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="selectedTime"
                    :items="availableTimes"
                    label="Wunschzeit"
                    outlined
                    dense
                    background-color="#B4005520"
                  />
                </v-col>
              </template>
              <v-col cols="12">
                <v-textarea
                  :label="dialogType === 'call' ? 'Notiz' : 'Ihre Nachricht'"
                  placeholder="Bitte beschreiben Sie kurz Ihr Projekt"
                  outlined
                  dense
                  rows="5"
                  auto-grow
                  v-model="message"
                  background-color="#B4005520"
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="text-center">
                <v-btn
                  @click="sendMail"
                  :loading="loading"
                  color="primary"
                  depressed
                  :disabled="disableDialogSubmitButton"
                  >Abschicken</v-btn
                >
              </v-col>
            </template>
            <template v-else>
              <v-col cols="12" class="text-center">
                <div class="font-weight-bold text-h4 pt-10 pb-5">
                  Vielen Dank!
                </div>
              </v-col>
              <v-col cols="12" class="text-center">
                <p class="font-weight-bold">
                  Ihre Anfrage ist bei uns eingegangen. Wir melden uns
                  schnellstmöglich bei Ihnen zurück
                </p>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card elevation="0" class="action-card">
      <v-card-title
        ><span class="text-h5 font-weight-bold"
          >Top Argumente:</span
        ></v-card-title
      >
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6" class="argument-col">
            <v-icon left>$checkmark</v-icon>
            <p class="my-auto">
              Zukunftsichere Wärmeversorgung
              <v-tooltip right color="grey lighten-5">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="white"
                    size="18px"
                    right
                    v-bind="attrs"
                    v-on="on"
                    >mdi-information-outline</v-icon
                  >
                </template>
                <span style="color: black"
                  >Unser Versorgungssystem ist krisensicher!</span
                >
              </v-tooltip>
            </p>
          </v-col>
          <v-col cols="12" md="6" class="argument-col">
            <v-icon left>$checkmark</v-icon>
            <p class="my-auto">
              Alles aus einer Hand
              <v-tooltip right color="grey lighten-5">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="white"
                    size="18px"
                    right
                    v-bind="attrs"
                    v-on="on"
                    >mdi-information-outline</v-icon
                  >
                </template>
                <span style="color: black"
                  >Wir als NEW bedienen alle notwendigen<br />
                  Sparten der Quartiersentwicklung!</span
                >
              </v-tooltip>
            </p>
          </v-col>
          <v-col cols="12" md="6" class="argument-col">
            <v-icon left>$checkmark</v-icon>
            <p class="my-auto">
              Beitrag zur regionalen Wertschöpfung
              <v-tooltip
                right
                color="grey lighten-5"
                content-class="text--black"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="white"
                    size="18px"
                    right
                    v-bind="attrs"
                    v-on="on"
                    >mdi-information-outline</v-icon
                  >
                </template>
                <span style="color: black"
                  >Unsere Wärme wird vor Ort <br />
                  produziert und verwendet!</span
                >
              </v-tooltip>
            </p>
          </v-col>
          <v-col cols="12" md="6" class="argument-col">
            <v-icon left>$checkmark</v-icon>
            <p class="my-auto">
              Individuelle und innovative Technologie
              <v-tooltip
                right
                color="grey lighten-5"
                content-class="text--black"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="white"
                    size="18px"
                    right
                    v-bind="attrs"
                    v-on="on"
                    >mdi-information-outline</v-icon
                  >
                </template>
                <span style="color: black"
                  >Wir betrachten jedes Quartier <br />individuell und
                  erarbeiten die<br />
                  technisch und wirtschaftlich beste Lösung!</span
                >
              </v-tooltip>
            </p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mt-2 action-card pa-2" elevation="0">
      <v-card-title class="px-2 py-1">
        <span class="font-weight-bold text-uppercase white--text" style="word-break: break-word">
          Fordern Sie jetzt Ihre individuelle Beratung an:
        </span>

      </v-card-title>
      <v-card-actions style="justify-content: space-between">
        <v-btn small color="white" class="primary-text" @click="openCallDialog">
          <v-icon left small>$callIcon</v-icon>
          Rückruf vereinbaren
        </v-btn>
        <v-btn small color="white" class="primary-text" @click="openMailDialog">
          <v-icon left small>$mailIcon</v-icon>
          Per E-Mail anfragen
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<style scoped>
.action-card {
  border-radius: 5px;
  color: white;
  background: transparent linear-gradient(81deg, #931880 0%, #b40055 100%) 0% 0%
    no-repeat padding-box;
}

.argument-col {
  display: flex;
  color: white;
  font-size: 16px;
}
</style>
