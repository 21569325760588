<template>
  <v-select
    background-color="#f6e4ed"
    id="sort-date-filter"
    v-model="filterProp"
    single-line
    dense
    outlined
    :items="sortDateOptions"
    item-text="label"
    item-value="val"
    hide-details
    :menu-props="{ bottom: true, offsetY: true }"
    class="text-right mt-2"
    :class="$vuetify.breakpoint.smAndDown ? '' : 'new-input-width'"
  />
</template>

<script>
export default {
  name: "SortDate",
  props: ["sortDateFilter", "sortDateOptions"],
  data: () => ({
    model: 0,
    filterProp: "desc"
    //default settings
  }),

  watch: {
    filterProp(val) {
      this.$store.dispatch("news/orderPosts", val);
    }
  }
};
</script>

<style scoped>
.new-input-width {
  width: 175px !important;
}
</style>
