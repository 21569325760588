<template>
  <v-row
    v-if="results.length > 0 || contactPersonsForQuery.length > 0"
    class="new-wrapper min-height-100 pa-10"
    no-gutters
  >
    <v-col cols="12">
      <div
        :class="
          $vuetify.breakpoint.width < 960
            ? 'mb-2 custom-header-text new-font-bold'
            : 'mb-4 custom-header-text new-font-bold'
        "
      >
        Suchergebnisse für "{{ originalQuery }}"
      </div>
      <search-filter />
      <search-list />
    </v-col>
  </v-row>
  <no-search-results-display v-else />
</template>

<script>
import { mapGetters } from "vuex";
import SearchFilter from "@/components/filter/SearchFilter";
import SearchList from "@/components/lists/SearchList";
import NoSearchResultsDisplay from "@/components/ui/NoSearchResultsDisplay";

export default {
  name: "Search",
  components: { SearchList, SearchFilter, NoSearchResultsDisplay },
  mounted() {
    this.updateQuery();
  },
  methods: {
    updateQuery() {
      let query = this.$route.query.q;

      if (!query) query = "";

      this.$store.dispatch("search/setQuery", query);
      this.$store.dispatch("search/selectFilter", "Alle");
    }
  },
  watch: {
    $route: "updateQuery"
  },
  computed: {
    ...mapGetters({
      query: "search/getQuery",
      originalQuery: "search/getOriginalQuery",
      results: "search/filteredEntries",
      contactPersonsForQuery: "search/getContactPersonsForQuery"
    })
  }
};
</script>

<style scoped></style>
