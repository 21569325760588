<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'WaermeplanungView',
  data: () => ({
    iframeUrl: undefined,
    showDialog: false
  }),
  computed: {
    ...mapGetters('user', ['getCommuneQlickAppId']),
    ...mapGetters('waerme', ['getIframeUrl', 'getAcceptedTos']),
    tosLink() {
      return `${process.env.BASE_URL}Nutzungsbedingungen Kommunale Wärmeplanung Kommunalportal.pdf`;
    }
  },
  methods: {
    ...mapActions('waerme', ['acceptTos', 'checkTosStatus', 'loadIframeUrl']),
    async onAcceptTos() {
      await this.acceptTos();
      await this.loadIframeUrl();

      this.showDialog = false;
    }
  },
  async mounted() {
    await this.checkTosStatus();
    if (!this.getAcceptedTos) {
      this.showDialog = true;
    } else {
      await this.loadIframeUrl();
    }
  }
}
</script>

<template>
  <div class="pa-10">
    <v-dialog v-model="showDialog" width="500" persistent overlay-opacity=".9">
      <v-card>
        <v-card-title class="text-h5">
          Nutzungsbedingungen
        </v-card-title>
        <v-card-text>
          Ich stimme den <a :href="tosLink">Nutzungsbedingungen</a> für die Bereitstellung der Kommunalen Wärmeplanung zu.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="onAcceptTos">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row no-gutters>
      <v-col cols="12">
        <span class="custom-header-text new-font-bold">
          Kommunale Wärmeplanung
        </span>
      </v-col>
      <v-col cols="12">
        <iframe id="qlick-frame" class="mt-5" referrerpolicy="origin" :src="getIframeUrl" width="100%" height="1500px"  v-if="!showDialog" />
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
#qlick-frame {
  border: none;
}
</style>
