<template>
  <v-row class="new-wrapper min-height-100" no-gutters>
    <!-- MAIN CONTENT -->
    <v-col :cols="colSize" class="pa-10">
      <v-row>
        <v-col align="left" cols="12">
          <span class="custom-header-text new-font-bold">
            {{ entry.title }}
          </span>
        </v-col>
      </v-row>

      <!-- TEXT SECTION 1 -->
      <v-row no-gutters class="mt-5">
        <vue-markdown-it
          v-if="entry.content1"
          :source="entry.content1"
          id="content1"
        />
      </v-row>

      <!-- CAROUSEL OR IMAGE-->
      <v-row v-if="images.length > 1" no-gutters class="mt-6">
        <Carousel :slides="images" />
      </v-row>
      <v-row v-else-if="images.length == 1" class="px-3 mt-14">
        <v-img :src="`${baseUrl}${images[0].url}`"></v-img>
        <p v-if="images[0].caption" class="text-caption">
          {{ images[0].caption }}
        </p>
      </v-row>

      <!-- TEXT SECTION 2 -->
      <v-row no-gutters class="mt-5">
        <vue-markdown-it
          v-if="entry.content2"
          :source="entry.content2"
          id="content2"
        />
      </v-row>

      <RightColumnGroup
        v-if="$vuetify.breakpoint.xs"
        :communePartner="communePartner"
        :communeRegionalLeader="communeRegionalLeader"
        :contentObject="entry"
      />
    </v-col>

    <!-- RIGHT SIDE CONTENT - ANSPRECHPARTNER, DOWNLOADS, INTERESSANTES -->
    <v-col
      v-if="$vuetify.breakpoint.smAndUp"
      cols="4"
      class="contact_container pl-4"
    >
      <RightColumnGroup
        :communePartner="communePartner"
        :communeRegionalLeader="communeRegionalLeader"
        :contentObject="entry"
      />
    </v-col>
  </v-row>
</template>

<script>
import { checkIconName } from "@/mixins/checkIconName";
import Carousel from "@/components/layout/Carousel.vue";
import RightColumnGroup from "@/components/layout/RightColumnGroup.vue";
import VueMarkdownIt from "vue-markdown-it";
import moment from "moment";

export default {
  components: {
    Carousel,
    RightColumnGroup,
    VueMarkdownIt
  },
  mixins: [checkIconName],
  name: "NewCopanyDetail",
  data: function() {
    return {
      baseUrl: process.env.VUE_APP_API_BASE_URL
    };
  },
  computed: {
    colSize() {
      if (this.$vuetify.breakpoint.smAndUp) return 8;
      else return 12;
    },
    images() {
      return this.entry.images;
    },
    updatedAt() {
      return moment(this.entry.pub_date).format("DD.MM.YYYY");
    },

    communePartner() {
      return this.$store.getters["user/userCommunePartner"];
    },

    communeRegionalLeader() {
      return this.$store.getters["user/userCommuneRegionalLeader"];
    },

    entry() {
      return this.$store.getters["generalContent/getNewCompaniesById"](
        this.$route.params.newCompanyId
      );
    }
  }
};
</script>

<style></style>
