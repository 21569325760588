import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
import { tracking_router } from "@/router/tracking";

/* modules */
import Home from "@/views/Home.vue";
import ServiceAreaDetail from "@/views/ServiceAreaDetail";
import ServiceDetail from "@/views/ServiceDetail";
import SubServiceDetail from "@/views/SubServiceDetail";
import ConstructionsMap from "@/views/ConstructionsMap.vue";
import News from "@/views/News.vue";
import NewsDeatil from "@/views/NewsDetail.vue";
import PressDetail from "@/views/PressDetail.vue";
import ApprovalPending from "@/views/ApprovalPending.vue";
import Login from "@/views/Login.vue";
import SsoRedirect from "@/views/SsoRedirect.vue";
import AboutUs from "@/views/AboutUs.vue";
import NewCompanyDetail from "@/views/NewCompanyDetail.vue";
import Search from "@/views/Search";
import Magazin from "@/views/Magazin";
import Funds from "@/views/Funds";
import FundDetails from "@/views/FundDetails";
import NotFound from "@/views/NotFound";
import FAQ from "@/views/FAQ.vue";
import Kommunalrabatt from "@/views/Kommunalrabatt";
import Lieferstellen from "@/views/Lieferstellen";
import ContractOverview from "@/views/ContractOverview.vue";
import Konzessionsthemen from "@/views/Konzessionsthemen.vue";
import Verbrauchsdaten from "@/views/Verbrauchsdaten.vue";
import Waermeplanung from "@/views/Waermeplanung.vue";
import WaermeFAQ from "@/views/WaermeFAQ.vue";
import Moments from "@/views/MomentsView.vue";
import CommuneDashboard from "@/views/CommuneDashboard.vue";
import NotificationConfig from "@/views/NotificationConfig.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: { name: "home" } },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: "/about",
    name: "about",
    component: AboutUs,
    meta: { requiresAuth: true }
  },
  {
    path: "/faq",
    name: "faq",
    component: FAQ,
    meta: { requiresAuth: true }
  },

  {
    path: "/service-area/:serviceAreaId",
    name: "service-area",
    component: ServiceAreaDetail,
    meta: { requiresAuth: true }
  },
  {
    path: "/service-area/:serviceAreaId/service/:serviceId",
    name: "service",
    component: ServiceDetail,
    meta: { requiresAuth: true }
  },
  {
    path:
      "/service-area/:serviceAreaId/service/:serviceId/sub-service/:subServiceId",
    name: "sub-service",
    component: SubServiceDetail,
    meta: { requiresAuth: true }
  },

  {
    path: "/constructions-map",
    name: "constructions-map",
    component: ConstructionsMap,
    meta: { requiresAuth: true }
  },
  {
    path: "/news",
    name: "news",
    component: News,
    meta: { requiresAuth: true }
  },
  {
    path: "/news-detail/:newsId/",
    name: "newsDetail",
    component: NewsDeatil,
    meta: { requiresAuth: true }
  },
  {
    path: "/press-detail/:pressId/",
    name: "pressDetail",
    component: PressDetail,
    meta: { requiresAuth: true }
  },
  {
    path: "/newcompany-detail/:newCompanyId/",
    name: "newCompanyDetail",
    component: NewCompanyDetail,
    meta: { requiresAuth: true }
  },
  {
    path: "/approval",
    name: "approval-pending",
    component: ApprovalPending,
    meta: { requiresAuth: true }
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/connect/login_new_ag/redirect",
    name: "oauth-redirect",
    component: SsoRedirect
  },
  {
    path: "/search",
    name: "search",
    component: Search,
    meta: { requiresAuth: true }
  },
  {
    path: "/magazin",
    name: "magazin",
    component: Magazin,
    meta: { requiresAuth: true }
  },
  {
    path: "/funds",
    name: "funds",
    component: Funds,
    meta: { requiresAuth: true }
  },
  {
    path: "/funds/:id",
    name: "fund-details",
    component: FundDetails,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/vertragsuebersicht",
    name: "vertragsuebersicht",
    component: ContractOverview,
    meta: { requiresAuth: true }
  },
  {
    path: "/konzessionsthemen",
    name: "konzessionsthemen",
    component: Konzessionsthemen,
    meta: { requiresAuth: true }
  },
  {
    path: "/verbrauchsdaten",
    name: "verbrauchsdaten",
    component: Verbrauchsdaten,
    meta: { requiresAuth: true }
  },
  {
    path: "/waermeplanung",
    name: "waermeplanung",
    component: Waermeplanung,
    meta: { requiresAuth: true }
  },
  {
    path: "/waermeplanung-faq",
    name: "waermeplanung-faq",
    component: WaermeFAQ,
    meta: { requiresAuth: true }
  },
  {
    path: "/new-erleben",
    name: "new-erleben",
    component: Moments,
    meta: { requiresAuth: true }
  },
  {
    path: "/commune-dashboard",
    name: "commune-dashboard",
    component: CommuneDashboard,
    meta: { requiresAuth: true }
  },
  {
    path: "/notification-config",
    name: "notification-config",
    component: NotificationConfig,
    meta: { requiresAuth: true }
  },
  {
    path: "/:match(.*)*",
    name: "not-found",
    component: NotFound,
    meta: { requiresAuth: true }
  },
];

if(process.env.NODE_ENV !== "production") {
  routes.push({
    path: "/kommunalrabatt",
    name: "kommunalrabatt",
    component: Kommunalrabatt,
    meta: { requiresAuth: true }
  });
  routes.push({
    path: "/lieferstellen",
    name: "lieferstellen",
    component: Lieferstellen,
    meta: { requiresAuth: true }
  });
}

routes.push({
  path: "/:match(.*)*",
  name: "not-found",
  component: NotFound,
  meta: { requiresAuth: true }
});

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

/**
 * @param {Route} toRoute
 * @param {Function} nextFn
 * @private
 */
function _handleLoggedInUser(toRoute, nextFn) {
  const approveRoute = "approval-pending";
  const isUserApproved = store.getters["user/isUserApproved"];
  const isOnApproveRoute = toRoute.name === approveRoute;

  const redirectUrl = window.sessionStorage.getItem("REDIRECT_URL");

  if (!isUserApproved && !isOnApproveRoute) {
    router.replace({ name: approveRoute });
    return;
  } else if (isOnApproveRoute && isUserApproved && !redirectUrl) {
    // when is approved, but on approval pending page
    router.replace({ name: "home" }).catch();
    return;
  } else if (redirectUrl) {
    window.sessionStorage.removeItem("REDIRECT_URL");
    router.replace(redirectUrl);
    return;
  }

  nextFn();
}

router.beforeEach((to, from, next) => {
  // google analytics tracking
  tracking_router(to, from);

  // if route requires auth, check if logged in
  if (to.matched.some(route => route.meta.requiresAuth)) {
    const isInitialPageLoad = !from.name;
    if (isInitialPageLoad) {
      store.dispatch("user/verifyAuth").then(() => {
        // if not, redirect to login page.
        if (!store.getters["user/isLoggedIn"]) {
          console.dir(to);
          window.sessionStorage.setItem("REDIRECT_URL", to.fullPath);
          next({
            name: "login"
            // query: { redirect: to.fullPath }
          });
        } else {
          // check if users account is confirmed (via strapi backend)
          _handleLoggedInUser(to, next);
        }
      });
    } else {
      // assume verified auth when user is coming from other Vue route
      _handleLoggedInUser(to, next);
    }
  } else {
    if (to.name == "login") {
      // if user is on login page and has valid session, redirect him
      store.dispatch("user/verifyAuth").then(() => {
        if (store.getters["user/isLoggedIn"]) {
          router.replace({ name: "home" });
        }
      });
    }

    next(); // make sure to always call next()!
  }
});

export default router;
