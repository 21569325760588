<template>
  <v-col id="newCompanies">
    <NewCompaniesRow  @click="showDetails(entry)" v-for="(entry,i) in allEntries" :entry="entry" :key="i" />
  </v-col>
</template>

<script>
import NewCompaniesRow from "@/components/list_rows/NewCompaniesRow";
export default {
    name: 'NewCompaniesList',
    props:['allEntries'],
    components:{
      NewCompaniesRow
    },
    methods: {
      showDetails(entry){
        this.$router.push({name:'newCompanyDetail' ,params: { newCompanyId: entry.id } })
      },
  },
}
</script>

<style scoped>
#newCompanies{
  padding: 0px 0px 0px 0px;
}
</style>
