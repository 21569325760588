<script>
import QuartiersentwicklungProcessSteps from "@/components/ui/Quartiersentwicklung/QuartiersentwicklungProcessSteps.vue";
import QuartiersloesungenSection from "@/components/ui/Quartiersentwicklung/QuartiersloesungenSection.vue";
import SektorenkopplungSection from "@/components/ui/Quartiersentwicklung/SektorenkopplungSection.vue";
import ActionCard from "@/components/ui/Quartiersentwicklung/ActionCard.vue";

export default {
  name: "QuartiersentwicklungView",
  components: {ActionCard, SektorenkopplungSection, QuartiersloesungenSection, QuartiersentwicklungProcessSteps},
  props: {
    contactPerson: {
      type: Object,
    }
  },
  methods: {
    scrollTo(id) {
      const element = document.getElementById(id);

      window.scroll({
        top: element.offsetTop,
        left: 0,
        behavior: "smooth"
      });
    }
  }
}
</script>

<template>
<v-row>
  <v-col cols="12">
    <p>
      Wir sind Ihr kompetenter Partner für zukunftsweisende Energie- und Infrastrukturlösungen.
      Unser Unternehmen hat sich darauf spezialisiert, umfassende Dienstleistungen im Bereich der Wärmeversorgung und Infrastrukturentwicklung für Immobilienprojekte anzubieten.
      Unser Leistungsspektrum umfasst die zuverlässige Bereitstellung von Wärme, Kälte und Energie, mit dem Schwerpunkt auf regenerative Energiequellen.
    </p>

  </v-col>
  <v-col cols="12" class="pb-0">
    <b>Inhalte dieser Seite</b>
  </v-col>
  <v-col cols="12" md="6"  :class="$vuetify.breakpoint.mdAndUp ? 'text-left' : 'text-center'" class="pt-0">
    <a @click="scrollTo('quartiersloesungen')">
      Quartierslösungen
      <v-icon color="primary">mdi-arrow-down</v-icon>
    </a>
  </v-col>
  <v-col cols="12" md="6" :class="$vuetify.breakpoint.mdAndUp ? 'text-right' : 'text-center'"  class="pt-0">
    <a @click="scrollTo('sektorenkopplung')">
      Sektorenkopplung
      <v-icon color="primary">mdi-arrow-down</v-icon>
    </a>
  </v-col>
  <v-col cols="12">
    <ActionCard id="action-card" :contact-person="contactPerson" />
  </v-col>
  <v-col cols="12" lg="12" xl="7">
    <div class="mb-3 font-weight-bold text-h5">Unser Weg für Ihre nachhaltige Zukunft</div>
    <QuartiersentwicklungProcessSteps />
  </v-col>
  <v-col cols="12">
    <p>Unser Vertriebsprozess stellt sicher, dass alle relevanten Aspekte des Projekts sorgfältig geplant und umgesetzt werden. In der Regel nimmt ein Projekt je nach spezifischen Anforderungen 4-8 Monate in Anspruch.</p>
  </v-col>
  <v-col cols="12">
    <QuartiersloesungenSection id="quartiersloesungen" />
  </v-col>
  <v-col cols="12">
    <SektorenkopplungSection id="sektorenkopplung" />
  </v-col>
</v-row>
</template>

<style scoped>

</style>
