<template>
  <div class="mobile-contact-person-box mt-1 pa-2">
    <v-avatar v-if="getContactImgUrl()" size="50" tile color="grey">
      <v-img :src="`${baseUrl}${getContactImgUrl()}`"></v-img>
    </v-avatar>
    <v-avatar class="icon-bg" v-else size="50" tile>
      <v-icon size="50" color="#ffffff80">
        $user
      </v-icon>
    </v-avatar>
    <div class="ml-1">
      <p class="font-weight-bold mb-0">{{ getFullName() }}</p>
      <p class="mb-0" style="font-size: 14px">{{ contact.customTitle }}</p>
    </div>
    <v-spacer />
    <v-btn
      elevation="1"
      color="primary"
      icon
      @click="onContactClick('email')"
      :href="`mailto:${contact.email}`"
      style="background-color: #e9d4e1"
    >
      <v-icon color="primary">mdi-email-outline</v-icon>
    </v-btn>
    <v-btn
      elevation="1"
      color="primary"
      icon
      @click="onContactClick('phone')"
      :href="`tel:${contact.phone}`"
      class="ml-2"
      style="background-color: #e9d4e1"
    >
      <v-icon color="primary">mdi-phone-outline</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "MobileContactPersonBox",
  props: {
    person: Object,
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_BASE_URL,
    };
  },
  computed: {
    contact() {
      if (this.person.person) {
        return this.person.person;
      } else {
        return this.person;
      }
    },
  },
  methods: {
    onContactClick(type) {
      this.$ga("event", "page_contacted", {
        contact_name: this.getFullName(),
        contact_type: type,
      });
    },
    getFullName() {
      return this.contact.firstname + " " + this.contact.lastname;
    },
    getContactImgUrl() {
      if (this.contact.image && this.contact.image.formats.small) {
        return this.contact.image.formats.small.url;
      } else if (this.contact.image && this.contact.image.url) {
        return this.contact.image.url;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.mobile-contact-person-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f7f9fc;
}
</style>
