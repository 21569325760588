<script>

export default {
  name: "CommunePartnerCard",
  props: {
    width: {
      type: String,
      default: '250px'
    },
    userCommunePartner: {
      type: Object,
    },
  },
  data: () => ({
    actionButtonSize: 17,
  }),
  computed: {
    communePartnerName() {
      return this.userCommunePartner ? this.userCommunePartner.firstname + ' ' + this.userCommunePartner.lastname : '';
    },
    callLink() {
      return this.userCommunePartner?.phone ? 'tel:' + this.userCommunePartner.phone.replaceAll(' ', '') : '#';
    },
    mailLink() {
      return this.userCommunePartner?.email ? 'mailto:' + this.userCommunePartner.email : '#';
    },
  }
}
</script>

<template>
  <v-sheet class="commune-partner-card" :style="{ minWidth: `${this.width}`}">
    <div class="commune-partner-card-text py-1 px-2">
      <span class="label-text">Ihr Kommunalpartner</span>
      <span class="font-weight-bold">{{ communePartnerName }}</span>
    </div>
    <div class="commune-partner-card-actions">
      <v-btn link icon small class="commune-partner-card-actions-btn" :ripple="false" :href="mailLink">
        <v-icon :size="actionButtonSize">$mailIcon</v-icon>
      </v-btn>
      <v-btn icon small class="commune-partner-card-actions-btn" :ripple="false" :href="callLink">
        <v-icon :size="actionButtonSize">$callIcon</v-icon>
      </v-btn>
    </div>
  </v-sheet>
</template>

<style scoped>
.commune-partner-card {
  background-color: #E7ECF0 !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 37px;
  border-radius: 3px;
}

.commune-partner-card-text {
  display: flex;
  flex-direction: column;
}

.commune-partner-card-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.commune-partner-card-actions-btn {
  margin-right: 21px !important;
}


.label-text {
  font-size: 11px;
  line-height: 11px;
}
</style>
