export default {
  SET_MONTH_YEAR_ORDER(state, monthYear) {
    state.monthYearOrder = monthYear;
  },
  SET_CATEGORYFILTER(state, category) {
    state.categoryFilter = category;
  },

  SET_DATEORDER(state, order) {
    state.dateOrder = order;
  },

  SET_NEWS(state, newsData) {
    let newsObj = {};
    newsData.forEach((newsArticle) => {
      newsArticle.wasRead = state.newsReadIdList.indexOf(newsArticle.id) != -1;
      newsObj[newsArticle.id] = newsArticle;
    });

    state.newsList = newsData;
    state.newsIdMap = newsObj;
  },

  SET_PRESS(state, pressData) {
    let pressObj = {};
    pressData.forEach((pressArticle) => {
      // add some computed attribute(s)
      pressArticle.is_press = true;
      pressArticle.published_at = pressArticle.pub_date;

      //press_article.wasRead = true; //todo: fetch form DB read list later --> done
      pressArticle.wasRead =
        state.pressReadIdList.indexOf(pressArticle.id) != -1;
      pressObj[pressArticle.id] = pressArticle; // news_id kommt in presse-post objekten vor, nicht umbenennen
    });

    state.pressList = pressData;
    state.pressIdMap = pressObj;
  },

  SET_NEWS_READS(state, news_reads) {
    let newsReadIdList = [];
    news_reads.forEach((read) => {
      newsReadIdList.push(read.news_post);
    });

    state.newsReadIdList = newsReadIdList;
  },

  SET_PRESS_READS(state, press_reads) {
    let pressReadIdList = [];
    press_reads.forEach((press) => {
      pressReadIdList.push(press.press_post);
    });

    state.pressReadIdList = pressReadIdList;
  },

  SET_NEWS_COUNT(state, new_count) {
    state.newsCount = new_count;
  },

  SET_PRESS_COUNT(state, new_count) {
    state.pressCount = new_count;
  },

  MARK_NEWS_READ(state, newsId) {
    state.newsIdMap[newsId].wasRead = true;
  },

  MARK_PRESS_READ(state, pressId) {
    state.pressIdMap[pressId].wasRead = true;
  },
};
