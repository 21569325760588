<template>
  <v-card
    class="mb-5 fund-card text-decoration-none"
    elevation="2"
    link
    :to="`/funds/${entry.id}`"
  >
    <v-img
      v-if="entry.foerderthema.Bild.length > 0"
      :src="`${baseUrl}${entry.foerderthema.Bild[0].url}`"
    />
    <div class="px-4 pt-2">
      <v-chip
        x-small
        class="mr-2 mt-1 thema-badge"
        label
        v-for="thema of entry.themen"
        :key="thema"
        :color="selectedFilter !== thema ? '' : '#9C004726'"
      >
        {{ thema }}
      </v-chip>
      <v-chip x-small class="mr-2 mt-1 frist-badge" label
        >FRIST: {{ fristDate }}</v-chip
      >
    </div>
    <v-card-title style="color: #9c0047; word-break: break-word" class="pt-1">{{
      entry.Foerdertitel
    }}</v-card-title>
    <v-card-subtitle>{{ entry.Untertitel }}</v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  name: "FundingCard",
  props: ["entry"],
  data: () => ({
    baseUrl: process.env.VUE_APP_API_BASE_URL
  }),
  computed: {
    fristDate() {
      const splits = this.entry.Frist.split("-");
      return splits[2] + "." + splits[1] + "." + splits[0];
    },
    selectedFilter() {
      return this.$store.getters["foerdermittel/getSelectedFilter"];
    }
  }
};
</script>

<style scoped></style>
