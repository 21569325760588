<template>
<div class="pb-1">
  <v-divider class="pb-1"></v-divider>
  <v-row no-gutters>
    <v-col md="7" cols="12">
  <v-list-item class="px-0" @click="onItemClick">
    <!--<v-list-item-icon class="ma-1">
        <v-icon color="#1F2B37" class="pt-2">{{ checkIconName(getServiceArea) }}</v-icon>
    </v-list-item-icon>-->

    <v-list-item-icon class="ma-0 mr-3" v-if="serviceArea.type !== 'Headline'">
      <v-img
          v-if="getNewsThumbUrl()"
          :width="iconProps.width"
          :height="iconProps.height"

          :src="getNewsThumbUrl()"
      />
      <v-img
          v-else-if="getNewsFirstImgUrl()"
          :width="iconProps.width"
          :height="iconProps.height"

          :src="getNewsFirstImgUrl()"
      />
      <div v-else align="center" class="d-inline-block icon-bg" :style="`width: ${iconProps.width}; height: ${iconProps.height}`" >
        <v-icon
            x-large

            color="#ffffff80"
            :class="`${iconProps.spacing}`"
        >
            {{ checkIconName(getServiceArea) }}
          </v-icon>
      </div>
    </v-list-item-icon>
    <v-list-item-content>

          <div>
            <v-chip x-small class="badge">{{ serviceArea.filter }}</v-chip>
          </div>
          <v-list-item-title class="primary--text font-weight-bold" v-html="title"></v-list-item-title>
          <v-list-item-subtitle>{{ getServiceArea.title }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
    </v-col>
    <v-col md="5" cols="12" style="background-color: #f7f9fc">
      <template v-for="personInCharge of serviceArea.personsInCharge">
        <v-divider :key="`divider-${personInCharge.person.id}`"/>
      <contact-person-box :key="personInCharge.person.id" :person="personInCharge" :vertical-design="false" v-if="$vuetify.breakpoint.mdAndUp" />
      <mobile-contact-person-box :key="personInCharge.person.id" :person="personInCharge" v-else />
      </template>
    </v-col>
  </v-row>
</div>
</template>

<script>
import {checkIconName} from "@/mixins/checkIconName";
import ContactPersonBox from "@/components/layout/ContactPersonBox";
import MobileContactPersonBox from "@/components/layout/MobileContactPersonBox";

export default {
  name: "SearchServiceAreaRow",
  props: ['serviceArea'],
  mixins: [checkIconName],
  components: {
    MobileContactPersonBox,
    ContactPersonBox
  },
  methods: {
    getNewsThumbUrl() {
        return this.$store.getters.getStrapiImageUrl(
            this.serviceArea.images[0],
            "thumbnail"
        );
    },
    getNewsFirstImgUrl(){
      if(this.serviceArea.images && this.serviceArea.images.length > 0){
        var str = this.baseUrl + this.serviceArea.images[0].url
        return str
      }
      return undefined;
    },
    async onItemClick() {
      switch(this.serviceArea.type) {
        case 'ServiceAreas':
          await this.$store.dispatch('analytics/commitSearchSession', {
            followUpType: 'service_area',
            followUp: this.serviceArea
          });
          break;
        case 'Services':
          await this.$store.dispatch('analytics/commitSearchSession', {
            followUpType: 'service',
            followUp: this.serviceArea
          });
          break;
        case 'SubServices':
          await this.$store.dispatch('analytics/commitSearchSession', {
            followUpType: 'sub_service',
            followUp: this.serviceArea
          });
          break;
      }

      await this.$router.push(this.to);
    }
  },
  data(){
    return {
      baseUrl: process.env.VUE_APP_API_BASE_URL,
    }
  },
  computed: {
    query() {
      return this.$store.getters['search/getOriginalQuery'];
    },
    title() {
      return this.serviceArea.title.replace(this.query, `<b>${this.query}</b>`)
    },
    to() {
      switch (this.serviceArea.type) {
        case 'ServiceAreas':
          return `/service-area/${this.serviceArea.id}`; /*{
            name: 'service-area',
            params: {
              serviceAreaId: this.serviceArea.id
            }
          }*/
        case 'Services':
          return `/service-area/${this.serviceArea.serviceArea.id}/service/${this.serviceArea.id}`; /*{
            name: 'service',
            params: {
              serviceAreaId: this.serviceArea.serviceArea.id,
              serviceId: this.serviceArea.id
            }
          }*/
        case 'SubServices': {
          return `/service-area/${this.serviceArea.service.serviceArea}/service/${this.serviceArea.service.id}/sub-service/${this.serviceArea.id}` /*{
            name: 'sub-service',
            params: {
              serviceAreaId: this.serviceArea.service.serviceArea,
              serviceId: this.serviceArea.service.id,
              subServiceId: this.serviceArea.id
            }
          }*/

        }
        default:
          return '#'
      }
    },
    getServiceArea() {
      switch (this.serviceArea.type) {
        case 'ServiceAreas':
          return this.serviceArea;
        case 'Services':
          return this.serviceArea.serviceArea;
        case 'SubServices':
          return this.$store.getters['navigation/serviceAreaByServiceAreaId'](this.serviceArea.service.serviceArea);
        default:
          return {
            iconNew: 'baeder',
          }
      }
    },
    iconProps(){
      var props = {}
      if(this.$vuetify.breakpoint.width < 960){
        props = {
          height: '90px',
          width: '90px',
          spacing: 'mt-7'
        }
      } else {
        props = {
          height: '150px',
          width: '150px',
          spacing: 'mt-13'
        }
      }
      return props
    },
  }
}
</script>

<style scoped>
.v-list-item:hover {
  cursor: pointer;
  background-color: #9C00471A;
}
/* Remove underline from router-link */
a {
  text-decoration: none !important;
}
</style>
