export default {
  SET_ABOUT_US(state, aboutUs) {
    let arr = aboutUs.NewGroupOptions;
    let companyObj = {};
    arr.forEach((newCompany) => {
      companyObj[newCompany.id] = newCompany.newCompany;
    });
    state.aboutUs = aboutUs;
    state.newCompaniesIdMap = companyObj;
  },
  SET_FUNDING_OPPORTUNITIES(state, fundingOpportunities) {
    state.fundingOpportunities = fundingOpportunities;
  },
  SET_NEW_COMPANIES(state, newCompanies) {
    state.newCompanies = newCompanies;
  },
  SET_CONTACT_PEOPLE(state, contactPeople) {
    state.contactPeople = contactPeople;
  },
  SET_COMMUNE_NAMES(state, communeNames) {
    state.communeNames = communeNames;
  },
};
