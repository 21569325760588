<script>
import {mapGetters} from "vuex";
import CommuneDashboardBadge from "@/components/ui/CommuneDashboard/CommuneDashboardBadge.vue";

export default {
  name: "KonzessionsThemenCardContent",
  components: {CommuneDashboardBadge},
  computed: {
    ...mapGetters('communeDashboard', ['latestKonzessionsThema'])
  }
}
</script>

<template>
<v-card width="100%">
  <v-card-title>Konzessionsthemen</v-card-title>
  <v-card-text class="commune-dashboard-min-height">
    <template v-if="latestKonzessionsThema">
      <!--<div class="primary d-inline px-2 white--text font-weight-bold">NEU</div>-->
      <commune-dashboard-badge text="NEU" class="primary" />
      <p class="font-weight-bold commune-dashboard-card-text mb-0">
        {{ latestKonzessionsThema.service_area}}<br>
        {{ latestKonzessionsThema.commune}}
      </p>
    </template>
  </v-card-text>
  <v-card-actions>
    <v-btn tile to="/konzessionsthemen" text color="primary" block link class="font-weight-bold commune-dashboard-dotted-border commune-dashboard-card-btn">
      Alle Konzessionsthemen
      <v-icon right>mdi-arrow-right</v-icon>
    </v-btn>
  </v-card-actions>
</v-card>
</template>

<style scoped>
.v-btn__content {
  justify-content: space-between !important;
}
</style>
