<template>
  <v-footer class="footer__container px-6 py-5" style="z-index: 9">
    <v-row>
      <v-col
        cols="12"
        md="8"
        :class="$vuetify.breakpoint.mdAndDown ? 'text-center' : ''"
      >
        <!-- copyright -->
        <v-btn class="mr-5 white--text" plain>&copy; NEW 2023</v-btn>

        <!-- footer links -->
        <v-btn
          link
          plain
          v-for="(link, i) in links"
          :key="i"
          :href="link.url"
          :target="link.target"
          class="mr-5 white--text"
        >
          {{ link.text }}
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        md="4"
        :class="$vuetify.breakpoint.mdAndDown ? 'text-center' : 'text-right'"
      >
        <!-- social links -->
        <v-btn
          v-for="(social, i) in socials"
          :key="i"
          icon
          plain
          class="mr-4 white--text"
          :href="social.url"
          :target="social.target"
        >
          <v-icon size="32" color="white">{{ social.icon }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
    name: "TheFooter",
  data() {
    return {
      socials: [
        { url: "https://www.youtube.com/NEWniederrhein", icon: "mdi-youtube", target: "_blank" },
        { url: "https://www.instagram.com/new_gruppe", icon: "mdi-instagram", target: "_blank" },
        { url: "https://www.linkedin.com/company/new-ag", icon: "mdi-linkedin", target: "_blank" },
        { url: "https://www.facebook.com/new.niederrhein", icon: "mdi-facebook", target: "_blank" }
      ],
      links: [
        {
          url: "https://www.new.de/impressum",
          text: "Impressum",
          target: "_blank"
        },
        {
          url: "https://datenschutz.new.de/kommunalportal",
          text: "Datenschutz",
          target: "_blank"
        }
      ]
    };
  }
};
</script>

<style scoped>
.footer__container {
  background-color: #b40055 !important;
  color: white !important;
}
</style>
