import mutations from "@/store/modules/verbrauchsdaten/mutations";
import actions from "@/store/modules/verbrauchsdaten/actions";
import getters from "@/store/modules/verbrauchsdaten/getters";

export default {
    namespaced: true,
    state: {
        communeNames: [],
        verbrauchsdaten: undefined,
        loaded: false,
        selectedYears: [],
        selectedEnergietraeger: [],
        selectedSektoren: [],
        contactPerson: undefined,
        communeData: undefined,
    },
    mutations,
    actions,
    getters,
}
