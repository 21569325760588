import httpClient from "@/store/httpClient";

export default {
  async loadMoments({ commit }) {
    const response = await httpClient.get("/moments");

    if (response.status !== 200) return;

    commit("SET_MOMENTS", response.data);
  },
  async loadSidebarContact({ commit }) {
    const response = await httpClient.get("/moments/getMomentsContact");

    if (response.status !== 200) return;

    commit("SET_MOMENTS_CONTACT", response.data);
  },
  async sendMomentsEmail(_, payload) {
    await httpClient.post("/moments/sendMomentsEmail", payload);
  }
};
