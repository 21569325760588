//import loginNew from "loginNew";

function getLocalData() {
  return new Promise((resolve) => {
    let intervall = setInterval(() => {
      const data = localStorage.getItem("uc_settings");

      if (data !== null) {
        clearInterval(intervall);
        resolve(data);
      }
    }, 1000);
  });
}

/*const GOOGLE_ANALYTICS = JSON.parse(
  localStorage.getItem("uc_settings")
).services.find(e => e.id === "HkocEodjb7");*/

/*const GOOGLE_ANALYTICS4 = JSON.parse(
  localStorage.getItem("uc_settings")
).services.find(e => e.id === "87JYasXPF");*/

export default {
  gtag: async function() {
    const localData = await getLocalData();
    const google_analytics4 = JSON.parse(localData).services.find(
      (s) => s.id === "87JYasXPF"
    );

    if (google_analytics4.status) {
      window.dataLayer.push(arguments);
    }
  },
  install: async function(Vue, options) {
    const localData = await getLocalData();
    const google_analytics4 = JSON.parse(localData).services.find(
      (s) => s.id === "87JYasXPF"
    );
    if (!google_analytics4.status) {
      return;
    }

    let externalScript = document.createElement("script");
    externalScript.setAttribute(
      "src",
      "https://www.googletagmanager.com/gtag/js?id=" + options.GA_MEASUREMENT_ID
    );
    document.head.appendChild(externalScript);
    window.dataLayer = window.dataLayer || [];
    await this.gtag("js", new Date());

    await this.gtag("config", options.GA_MEASUREMENT_ID, {
      custom_map: {
        commune_dimension: "commune",
        role_dimension: "role",
        contact_name_dimension: "contact_name",
        contact_type_dimension: "contact_type",
      },
    });
    Vue.prototype.$ga = this.gtag;
  },
};
