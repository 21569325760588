import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import googleAnalytics from "./plugins/google-analytics";
import VueApexCharts from "vue-apexcharts";
import VueMasonry from "vue-masonry-css";

Vue.use(VueApexCharts);
Vue.use(VueMasonry);

Vue.component("apexchart", VueApexCharts);

Vue.config.productionTip = false;
Vue.use(googleAnalytics, {
  GA_MEASUREMENT_ID: "G-CPZ9Y5RWST"
  // dev GA_MEASUREMENT_ID: "G-RXY0876P66",
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
