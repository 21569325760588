<template>
<div class="pa-5" >
  <h1>Kommunalrabatt</h1>
  <lottie-animation class="animation" :loop="true" :autoplay="true" :animationData="require('@/assets/404-animation.json')"></lottie-animation>
  <div class="text-center mt-10">
    <div class="mt-2">
      Die Inhalte sind auf kleinen Bildschirmen nicht verfügbar. <br>
      <b>Bitte besuchen Sie diese Seite über Ihren Computer.</b>
    </div>
    <v-btn class="mt-5" to="/home" color="primary">ZUR STARTSEITE</v-btn>
  </div>
  <div class="copyright">
    Source: <a href="https://lottiefiles.com/debbiediaz" target="_blank">@Debbie Diaz / Lottiefiles</a>
  </div>
</div>
</template>

<script>
import LottieAnimation from "lottie-web-vue";

export default {
  name: "MobileView",
  components: {
    LottieAnimation
  },
}
</script>

<style scoped>

</style>