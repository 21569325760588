export default {
    communeNames: state => state.communeNames,
    loaded: state => state.loaded,
    getYears: state => Object.keys(state.verbrauchsdaten ?? {}),
    getEnergietraeger(state) {
        if(!state.verbrauchsdaten) return [];

        const energietraeger = [];

        const years = Object.keys(state.verbrauchsdaten);

        for(const year of years) {
            for(const item of state.verbrauchsdaten[year]) {
                if(!energietraeger.includes(item.name)) energietraeger.push(item.name);
            }
        }

        return energietraeger;
    },
    getSektoren(state) {
        if(!state.verbrauchsdaten) return [];

        const sektoren = [];

        const years = Object.keys(state.verbrauchsdaten);

        for(const year of years) {
            for(const item of state.verbrauchsdaten[year]) {
                sektoren.push(...Object.keys(item).filter(key => key !== 'name'));
            }
        }

        return [...new Set(sektoren)].filter(s => s !== 'undefined');
    },
    verbrauchsdaten(state) {
        if(!state.verbrauchsdaten) return [];

        const verbrauchsdaten = {};

        for(const year of state.selectedYears) {
            verbrauchsdaten[year] = [];
            const verbrauchsdatenPerYear = state.verbrauchsdaten[year]
                .filter(i => state.selectedEnergietraeger.includes(i.name));

            for(const item of verbrauchsdatenPerYear) {
                const verbrauchsdatenForSektoren = {
                    name: item.name,
                    sum: 0,
                };

                for(const sektor of state.selectedSektoren) {
                    let sektorSum = 0;


                    if(item[sektor]){
                        const sektorTarife = Object.keys(item[sektor]);


                        for(const tarif of sektorTarife) {
                            sektorSum += item[sektor][tarif];
                        }
                    }

                    verbrauchsdatenForSektoren[sektor] = sektorSum
                    verbrauchsdatenForSektoren.sum += sektorSum;
                }


                verbrauchsdaten[year].push(verbrauchsdatenForSektoren);
            }
        }

        return verbrauchsdaten;
    },
    getVerbrauchPerTarif(state) {
        if(!state.verbrauchsdaten) return [];

        const verbrauchPerTarif = {};

        for(const energietraeger of state.selectedEnergietraeger) {
            verbrauchPerTarif[energietraeger] = {};

            for(const year of state.selectedYears) {
                verbrauchPerTarif[energietraeger][year] = {};
            }
        }

        for(const year of state.selectedYears) {
            const verbrauchsdatenPerYear = state.verbrauchsdaten[year]
                .filter(i => state.selectedEnergietraeger.includes(i.name));

            for(const item of verbrauchsdatenPerYear) {
                for(const sektor of state.selectedSektoren) {
                    const sektorTarife = Object.keys(item[sektor]);

                    for(const tarif of sektorTarife) {

                        if(!verbrauchPerTarif[item.name][year][tarif]) verbrauchPerTarif[item.name][year][tarif] = 0;

                        verbrauchPerTarif[item.name][year][tarif] += item[sektor][tarif];
                    }
                }
            }
        }

        return verbrauchPerTarif;
    },
    getEinspeisedaten(state) {
        return state.communeData?.einspeisedaten;
    },
    getContactPerson(state) {
        return state.contactPerson;
    },
    getEinwohnerCount(state) {
        return state.communeData?.einwohner ?? 0;
    },
    getServices(state) {
        return [state.communeData?.urbanLife, state.communeData?.eLadeinfrastruktur].filter(s => s !== undefined);
    },
    getImportDate(state) {
        return state.communeData ? new Date(state.communeData.importDate).toLocaleDateString() : '';
    }
}
