import axios from 'axios';

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
  }
});

httpClient.interceptors.request.use(
  config => {
    const jwt = localStorage.getItem('jwt');
    if (jwt) {
      config.headers.common['Authorization'] = `Bearer ${jwt}`;
    }

    return config;
  },
  error => Promise.reject(error),
);

export default httpClient;
