<template>
  <v-dialog v-model="showDialog" persistent width="420">
    <v-card>
      <v-card-text v-if="message" class="pa-5">
        {{ message }}
      </v-card-text>

      <v-divider class="mb-2"></v-divider>

      <v-card-text class="text-end">
        <v-btn color="primary" @click="close">
          <v-icon left small>mdi-close</v-icon>
          Schließen
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CommentDialog",
  props: ["message", "showDialog"],
  computed: {
    ...mapGetters("kommunalrabatt", ["getCommentInputText"])
  },
  methods: {
    close() {
      this.$emit("close-dialog", false);
    }
  }
};
</script>