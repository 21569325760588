<template>
  <div class="new-wrapper min-height-100 pa-10">
    <v-row>
      <v-col cols="12" md="9" lg="9" class="mb-0">
        <div class="custom-header-text new-font-bold mb-2">
          Hier - Ihr NEW Magazin
        </div>
        <p class="mb-0">Ihr kommunaler Informationsdienst für Ihre Region</p>
      </v-col>
      <v-col cols="12" md="3" lg="3" class="mt-0 pt-0">
        <month-year-picker></month-year-picker>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-for="(column, i) of columns" :key="i" :style="columnStyle">
        <template v-for="entry of column">
          <NewsletterSignUp
            :key="entry.id"
            v-if="entry.showSignup"
            :stacked="true"
            class="mb-4"
          />
          <magazin-card :key="entry.id" :entry="entry" v-else />
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MagazinCard from "@/components/ui/MagazinCard";
import MonthYearPicker from "@/components/date/MonthYearPicker.vue";

export default {
  name: "Magazin",
  components: { MagazinCard, MonthYearPicker },
  computed: {
    news() {
      const idMap = this.$store.getters["news/newsIdMap"];

      if (!idMap) return [];

      const news = Object.keys(idMap).map(key => idMap[key]);
      const orderMonthYear = this.$store.getters["news/getOrderMonthYear"];

      if (orderMonthYear != null) {
        const filteredNews = news.filter(
          item => item.published_at.substr(0, 7) == orderMonthYear
        );
        return filteredNews;
      } else {
        return news;
      }
    },
    columnCount() {
      if (this.$vuetify.breakpoint.xs) return 1;
      else if (this.$vuetify.breakpoint.mdAndUp) return 3;
      else return 2;
    },
    columnStyle() {
      const width = Math.trunc((1 / this.columnCount) * 100);

      return {
        width: `${width}%`
      };
    },
    columns() {
      const columnArray = [];

      let newsList = this.news;

      newsList.reverse();

      for (let count of [...Array(this.columnCount).keys()]) {
        columnArray[count] = [];
      }

      let currentPosition = 0;
      /* eslint-disable no-unused-vars */
      for (let i of [...Array(newsList.length).keys()]) {
        columnArray[currentPosition].push(newsList.shift());
        currentPosition += 1;

        if (currentPosition === this.columnCount) {
          currentPosition = 0;
        }
      }

      return columnArray;
    }
  }
};
</script>
