<template>
  <v-container fluid class="new-wrapper min-height-100 pa-0">
    <v-container fluid class="pa-10">
      <div>
        <span class="custom-header-text new-font-bold">Vertragsübersicht</span>
      </div>
    </v-container>

    <v-container fluid class="contract_filter px-10">
      <v-row>
        <v-col cols="12" md="2" sm="12" lg="2" xl="2">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Verträge durchsuchen"
            background-color="#f6e4ed"
            single-line
            hide-details
            outlined
            :clearable="false"
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2" sm="12" lg="2" xl="2">
          <v-select
            label="Status"
            v-model="filterStatus"
            background-color="#f6e4ed"
            :items="statusOptions"
            item-text="text"
            item-value="value"
            outlined
            hide-details
            dense
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
          lg="5"
          xl="3"
          :class="$vuetify.breakpoint.smAndDown ? 'mb-4' : ''"
        >
          <span class="active_inactive_container">
            <span class="active active_inactive_fix mr-2"></span>
            <b>{{ contractCounts.active }} aktive Verträge</b>
            <span class="mr-4"></span>
            <span class="inactive active_inactive_fix mr-2"></span>
            {{ contractCounts.inactive }} inaktive
          </span>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="contract_table">
      <v-data-table
        :loading="loading"
        loading-text="Verträge werden geladen ..."
        id="contract-table"
        :headers="headers"
        :items="getAllContracts"
        :items-per-page="10"
        item-class="contract-table-header"
        :search="search"
        ref="contractTable"
      >
        <template v-slot:[`item.contract_number`]="{ item }">
          {{ getAllContracts.indexOf(item) + 1 }}
        </template>

        <template v-slot:[`item.Vertragsstatus`]="{ item }">
          <span
            class="active active_inactive_fix"
            v-if="item['Vertragsstatus'] === 'aktiv'"
          ></span>
          <span class="inactive active_inactive_fix" v-else></span>
        </template>

        <template v-slot:[`item.Vertragsbeginn`]="{ item }">
          {{ formateDate(item["Vertragsbeginn"]) }}
        </template>

        <template v-slot:[`item.Vertragsende`]="{ item }">
          {{ formateDate(item["Vertragsende"]) }}
        </template>

        <template v-slot:[`item.Restlaufzeit`]="{ item }">
          {{ item.Restlaufzeit.replaceAll(".", ",") }} Jahre
        </template>

        <template v-slot:[`item.Gebiet`]="{ item }">
          {{ item.Gebiet }}<br />
          <span v-if="item.Stadtteil">{{
            item.Stadtteil.replace("-", "")
          }}</span>
        </template>

        <template v-slot:[`item.contract_documents`]="{ item }">
          <v-btn
            :href="item.link"
            target="_blank"
            :link="false"
            text
            outlined
            small
          >
            Enaio
            <v-icon small right>mdi-open-in-new</v-icon>
          </v-btn>
        </template>

        <template v-slot:[`item.Kuendigungsoption`]="{ item }">
          {{ item.Kuendigungsoption }}
        </template>
      </v-data-table>
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "ContractOverviewView",
  data() {
    return {
      loading: false,
      search: "",
      filterStatus: "Aktive und Inaktive",
      statusOptions: [
        { value: "Aktive und Inaktive", text: "Aktive und Inaktive" },
        { value: "aktiv", text: "Aktive" },
        { value: "nicht aktiv", text: "Inaktive" }
      ],
      headers: [
        {
          text: "",
          value: "contract_number",
          class: "contract-table-header",
          cellClass: "contract-table-cell",
          sortable: false,
          align: "center"
        },
        {
          text: "Status",
          value: "Vertragsstatus",
          class: "contract-table-header",
          cellClass: "contract-table-cell",
          filterable: false,
          align: "center"
        },
        {
          text: "Titel",
          value: "Vertragsgegenstand",
          divider: true,
          class: "contract-table-header",
          cellClass: "contract-table-cell-v"
        },
        {
          text: "Start",
          value: "Vertragsbeginn",
          class: "contract-table-header",
          cellClass: "contract-table-cell-v",
          filterable: false,
          align: "center"
        },
        {
          text: "Ende",
          value: "Vertragsende",
          class: "contract-table-header",
          cellClass: "contract-table-cell-v",
          filterable: false,
          align: "center"
        },
        {
          text: "Restlaufzeit",
          value: "Restlaufzeit",
          class: "contract-table-header",
          cellClass: "contract-table-cell-v",
          filterable: false,
          align: "center"
        },
        {
          text: "Gemeinde/ Stadt",
          value: "Gebiet",
          class: "contract-table-header",
          cellClass: "contract-table-cell-v"
        },
        {
          text: "Vertragspartner",
          value: "NEW_Vertragspartner_(intern)",
          class: "contract-table-header",
          cellClass: "contract-table-cell-v"
        },
        {
          text: "Vertrags-Dokumente",
          value: "contract_documents",
          class: "contract-table-header",
          cellClass: "contract-table-cell-v",
          sortable: false,
          filterable: false,
          align: "center"
        },
        {
          text: "Kündigungsoption",
          value: "Kuendigungsoption",
          class: "contract-table-header",
          cellClass: "contract-table-cell-v",
          filterable: false,
          sortable: true,
          align: "center"
        }
      ]
    };
  },
  methods: {
    formateDate(ts) {
      return moment(parseInt(ts)).format("DD.MM.YYYY");
    },
  },
  computed: {
    ...mapGetters("contractOverview", [
      "getContracts",
      "getInactiveContractsCount",
      "getActiveContractsCount"
    ]),
    ...mapGetters("user", ["enableVertragsuebersicht"]),
    getAllContracts() {
      if(this.filterStatus === "Aktive und Inaktive") return this.getContracts;
      else return this.getContracts.filter(item =>
          (this.filterStatus === "aktiv" && item.Vertragsstatus === "aktiv") ||
          (this.filterStatus !== "aktiv" && item.Vertragsstatus !== "aktiv")
      );
    },
    filteredItems() {
      return this.getAllContracts.filter(item =>
          item["Vertragsgegenstand"]
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          item["NEW_Vertragspartner_(intern)"]
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          item["Gebiet"].toLowerCase().includes(this.search.toLowerCase())
      );
    },
    contractCounts() {
      const active = this.filteredItems.filter(
        c => c.Vertragsstatus === "aktiv"
      ).length;
      const inactive = this.filteredItems.length - active;

      return { active, inactive };
    }
  },
  async beforeMount() {
    if (!this.enableVertragsuebersicht) {
      this.$router.back();
      return;
    }
    this.loading = true;
    await this.$store.dispatch("contractOverview/loadContracts");
    this.loading = false;
  }
};
</script>

<style scoped>
.contract_table {
  padding: 0 !important;
}

.active_inactive_container {
  position: relative;
  top: 7px;
  border: 1px solid #1f2b37;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  padding: 10px 15px !important;
  letter-spacing: 1px;
}

.active {
  display: inline-block;
  background-color: #6aa94f;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin: 0 auto;
}

.inactive {
  display: inline-block;
  border: 1px solid #dedfe0;
  background-color: #ffffff;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin: 0 auto;
}

.active_inactive_fix {
  position: relative;
  top: 2px;
  margin-right: 6px;
}
</style>
