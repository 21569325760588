function formatDateString(dateString) {
    return new Date(dateString)
        .toLocaleDateString('de-DE', {  month: '2-digit', day: '2-digit', year: 'numeric' })
}

function sortItems(items, key, order) {
    switch (key) {
        case 'Vertrag': {
            return items.sort((a, b) => {
                if(order === 'asc') return a.vertrag.localeCompare(b.vertrag);
                else return b.vertrag.localeCompare(a.vertrag);
            });
        }
        case 'Kommune': {
            return items.sort((a, b) => {
                if(order === 'asc') return a.communeName.localeCompare(b.communeName);
                else return b.communeName.localeCompare(a.communeName);
            });
        }
        case 'Änderungsdatum':
        default: {
            return items.sort((a, b) => {
                if(order === 'asc')
                    return a.date - b.date;
                else
                    return b.date - a.date;
            });
        }
    }
}

export default {
    getNewKonzessionsThemen(state) {
        const items = [];

        for(const thema of state.items) {
            if(thema.Archiviert || thema.Details.every(d => !d.active)) {
                continue;
            }

            const activeItems = thema.Details.filter(d => d.active);
            for(const item of activeItems) {
                items.push({
                    themaId: thema.id,
                    vertrag: thema.vertrag,
                    communeName: thema.communeName,
                    updated: formatDateString(item.Datum),
                    date: new Date(item.Datum).getTime(),
                    details: item.Details,
                });

            }
        }

        return sortItems(items, state.sortBy.key, state.sortBy.order);
    },
    getAllKonzessionsThemen(state, getters) {
        const items = [];
        const newThemenIds = getters.getNewKonzessionsThemen.map(t => t.themaId);

        for(const thema of state.items.filter(i => !i.Archiviert && !newThemenIds.includes(i.id))) {
            const latestItem = thema.Details[thema.Details.length - 1];
            items.push({
                ...thema,
                themaId: thema.id,
                details: latestItem.Details,
                active: latestItem.active,
                updated: formatDateString(latestItem.Datum),
                date: new Date(latestItem.Datum).getTime(),
            });
        }

        return sortItems(items, state.sortBy.key, state.sortBy.order);
    },
    getArchivedItems(state) {
        const items = [];

        for(const thema of state.items.filter(i => i.Archiviert)) {
            const latestItem = thema.Details[thema.Details.length - 1];
            items.push({
                ...thema,
                themaId: thema.id,
                details: latestItem.Details,
                active: latestItem.active,
                updated: formatDateString(latestItem.Datum),
                date: new Date(latestItem.Datum).getTime(),
            });
        }

        return sortItems(items, state.sortBy.key, state.sortBy.order);
    },
    getSelectedThema: (state) => state.items.find(item => item.id === state.selectedThemaId),
    getSelectedThemaDetails: (state) => {
        if(!state.selectedThemaId) return [];

        const details = state.items.find(item => item.id === state.selectedThemaId).Details;
        details.sort((a, b) => new Date(b.Datum) - new Date(a.Datum));
        return details;
    },
    getSortBy: (state) => state.sortBy,
}
