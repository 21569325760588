import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

export default {
  namespaced: true,
  state: {
    // nav items
    navigationList: [],

    mainNavObject: {
      serviceAreaItems: [
        // contains so far:
        // { name: "Strom", id: 1, services: [] }
      ],
      generalNavItems: [
        { name: "Über die NEW", iconNew: "ueberNew", route: "about" },
        { name: "Presse", iconNew: "news", route: "news" },
        { name: "NEW Magazin", iconNew: "magazin", route: "magazin" },
        //{ name: "Störmeldungen", iconNew: "stoermeldungen", route: "fault-messages" },
        {
          name: "Fördermöglichkeiten",
          iconNew: "foerdermoeglichkeiten",
          route: "funds",
        },
        {
          name: "Bauprojekte",
          iconNew: "bauprojekte",
          route: "constructions-map",
        },
        // { name: "Fördermöglichkeiten", iconNew: "foerdermoeglichkeiten", route: "funding-opportunities" },
        { name: "FAQ", iconNew: "faq", route: "faq" },
        {
          name: "Verbrauchsdaten",
          route: "verbrauchsdaten",
          iconMaterialDesign: "chart-bar",
        },
        /*
        {
          name: 'Kommunalrabatt',
          iconNew: 'kommunalrabatt',
          route: 'kommunalrabatt'
        },
        */
      ],
    },
  },
  mutations,
  actions,
  getters,
};
