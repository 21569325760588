// import RestMethods from '@/store/RestMethods';
// import { _sortBy } from '@/utils/sortings'

// const SERVICE_MODEL = RestMethods.init('service-area-navigation');
// const SERVICE_CACHE_SECS = 10 * 60; // 10 mins

export default {
  // async fetchNavItems({ commit }) {
  //     if (!SERVICE_MODEL.isCacheExpired('fetchNavItems', SERVICE_CACHE_SECS)) {
  //         return;
  //     }
  //     const serviceAreaItems = await SERVICE_MODEL.find();
  //     //todo: handle failed request(s)
  //     if (serviceAreaItems) {
  //         SERVICE_MODEL.resetCacheTimeout('fetchNavItems');
  //         commit('SET_NAVIGATION_LISTS', serviceAreaItems.data);
  //     }
  // },
};
