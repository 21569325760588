<template>
  <v-dialog v-model="dialog" persistent max-width="800">
    <v-card>
      <v-card-title id="dev-login-title">Login</v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12">
            <p>Willkommen auf dem {{ instanceName }} des NEW Kommunalportals</p>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
          <v-text-field :error="showError" :error-messages="this.errorMessage"
              label="Passwort" type="password" v-model="devPassword" id="dev-password-input"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="justify-end" no-gutters>

            <v-btn @click="onDevLoginClick" depressed color="primary" id="dev-login-btn">Login</v-btn>

        </v-row>

        <v-row>
        <v-col>
        <v-divider></v-divider>
        <p class="primary--text pt-5">
            Sollten Sie die Live-Version des Kommunalportals suchen, finden Sie diese unter:
           <a href="https://kommunalportal.new.de/">https://kommunalportal.new.de/</a></p>
        </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DevLoginDialog",
  data: () => ({
    dialog: false,
    devPassword: undefined,
    showError: false,
    errorMessage: undefined,
  }),
  mounted() {
    this.$store.dispatch('user/checkDevDialog');
    this.dialog = this.$store.getters['user/showDevLoginDialog'];
  },
  computed: {
    instanceName() {
      return location.href === 'kommunalportal-next.new.de' ? 'Redaktionssystem' : 'Entwicklungssystem';
    },

  },
  methods: {
    onDevLoginClick() {
      localStorage.setItem('DEV_PASSWORD', this.devPassword);
      if([process.env.VUE_APP_DEV_TOKEN].includes(this.devPassword)) {
        this.dialog = false;
        this.showError = false;
      } else {
        this.errorMessage = 'Ungültiges Password';
        this.showError = true;
      }
      this.$store.dispatch('user/checkDevDialog');
    },
  }
}
</script>

<style scoped>

</style>
