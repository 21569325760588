<template>
  <div v-if="$vuetify.breakpoint.mdAndUp" class="new-wrapper">
    <v-dialog v-model="editDialogIsVisible" max-width="750px" persistent>
      <edit-lieferstelle-card @close="editDialogIsVisible = false" />
    </v-dialog>

    <v-row class="mt-0" no-gutters>
      <v-col
        cols="8"
        style="border-bottom: 1px solid #edc1d3; display: flex;align-items: center"
        class="pl-10"
      >
        <span class="custom-header-text new-font-bold">Lieferstellen</span>
      </v-col>
      <v-col cols="4" style="border-bottom: 1px solid #edc1d3">
        <contact-person-horizontal></contact-person-horizontal>
      </v-col>
      <lieferstellen-filter
        @openSidebar="onOpenSidebar"
        @download-csv="downloadCsv"
      />
      <v-col :cols="sidebarIsVisible ? 9 : 12">
        <div class="filter-row pa-4">
          <span class="font-weight-bold">Status:</span>
          <span>{{ getSelectedCommuneStatus }}</span>
          <div style="width: 275px" class="ml-auto">
            <v-text-field
              append-icon="mdi-magnify"
              placeholder="Lieferstellen durchsuchen"
              single-line
              v-model="query"
              dense
              outlined
              background-color="#f6e4ed"
              clearable
              hide-details
            />
          </div>
          <v-select
            :items="filterItems"
            outlined
            dense
            hide-details
            v-model="filterBy"
            background-color="#f6e4ed"
            style="max-width: 300px"
            label="Filter"
          />

          <v-select
            :items="['Strom', 'Gas']"
            v-model="selectedSparten"
            style="max-width: 150px; margin-right: unset "
            multiple
            background-color="#f6e4ed"
            dense
            outlined
            hide-details
          ></v-select>
        </div>
        <div
          class="filter-row pa-4"
          v-if="getSelectedCommuneStatus === 'Abgeschlossen'"
        >
          <span class="text-h5"
            >Achtung, der Kommunalrabattprozess für das Jahr
            {{ getSelectedYear }} wurde bereits abgeschlossen.</span
          >
        </div>
        <v-data-table
          style="z-index: 11"
          :headers="tableHeaders"
          :items="filteredLieferstellen"
          sort-by="Marktlokation"
          :sort-desc="false"
          :header-props="headerProps"
          id="kommunalrabatt-table"
          :search="query"
          :item-class="getItemClass"
          :items-per-page="itemsPerPage"
          ref="table"
          :loading="loading"
          loading-text="Lieferstellen werden geladen"
        >
          <template v-slot:item="{ item }">
            <tr
              :style="
                selectedLieferstelle && item.id === selectedLieferstelle.id
                  ? activeRowStyle
                  : {}
              "
            >
              <td style="width: 75px">
                <v-simple-checkbox
                  v-if="isNewEmployee"
                  v-model="item.rabatt"
                  :ripple="false"
                  @input="onUpdate(item)"
                  :disabled="
                    !isNewEmployee || getSelectedCommuneStatus === 'Bei Kommune'
                  "
                  color="!isNewEmployee || getSelectedCommuneStatus === 'Bei Kommune' ? 'grey':'primary'"
                  :class="
                    !isNewEmployee || getSelectedCommuneStatus === 'Bei Kommune'
                      ? 'Kommune'
                      : 'NEW'
                  "
                />

                <v-icon v-else>
                  {{ item.rabatt ? "mdi-check" : "" }}
                </v-icon>
              </td>
              <td
                style="width: 200px; border-right: thin solid rgba(0, 0, 0, 0.12);"
              >
                <b>{{ item.marktlokation }}</b
                ><br />
                <span style="font-size: 12px; color: #9da1a6"
                  >{{ item.adresszeile1 }} {{ item.adresszeile2 }}, <br />
                  {{ item.plz }} {{ item.ort }}</span
                >
              </td>
              <td
                :class="
                  item.changes &&
                  item.changes.includes('geschaeftspartnerNummer')
                    ? ['changed-cell']
                    : []
                "
                style="width: 150px;"
              >
                <div class="kommunalrabatt-table-cell lighten-3 text-cell">
                  {{ item.geschaeftspartnerNummer }}
                </div>
              </td>
              <td
                :class="
                  item.changes && item.changes.includes('geschaeftspartnerName')
                    ? ['changed-cell']
                    : []
                "
                style="width: 250px"
              >
                <div class="kommunalrabatt-table-cell lighten-3 text-cell">
                  {{ item.geschaeftspartnerName }}
                </div>
              </td>
              <td class="text-center">
                {{ item.zuordnungMg }}
              </td>
              <td class="text-center">
                {{ item.zusatzinfo1.startsWith("#") ? "" : item.zusatzinfo1 }}
              </td>
              <td class="text-center">
                {{ item.zusatzinfo2.startsWith("#") ? "" : item.zusatzinfo2 }}
              </td>
              <td class="text-center">
                {{ item.vkSammler }}
              </td>
              <td class="text-center">
                {{ item.vkEinzel }}
              </td>
              <td
                class="text-center"
                style="border-right: thin solid rgba(0, 0, 0, 0.12);"
              >
                {{
                  (item.sparte === "Strom"
                    ? item.spannungsebene
                    : item.druckstufe
                  ).replace("#", "")
                }}
              </td>
              <td style="width: 150px" class="text-center">
                <div class="text-center">
                  <v-icon
                    left
                    color="primary"
                    small
                    v-if="getUnreadComments.includes(item.marktlokation)"
                    >mdi-message-badge
                  </v-icon>
                  <v-icon left small v-else>mdi-message</v-icon>
                  <a @click="showSidebar(item)" style="font-size: 14px"
                    >Details</a
                  >
                  <v-icon right color="primary">mdi-chevron-right</v-icon>
                </div>
                <div class="text-center">
                  <v-icon left small>mdi-text-box-edit</v-icon>
                  <a
                    @click="showEditLieferstelleModal(item)"
                    style="font-size: 14px"
                    >Bearbeiten</a
                  >
                  <v-icon right color="primary">mdi-chevron-right</v-icon>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="3" v-if="sidebarIsVisible" id="lieferstellen-sidebar">
        <v-row no-gutters style="position: sticky; top: 64px">
          <v-col cols="12" class="pt-1">
            <div style="display: flex; justify-content: end">
              <v-btn
                :disabled="getCommentInputText"
                icon
                @click="sidebarIsVisible = false"
                color="primary"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <lieferstellen-sidebar v-if="selectedLieferstelle" />
            <add-lieferstelle-sidebar v-else @close-sidebar="onSidebarClose" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- comment dialog -->
    <comment-dialog
      :show-dialog="showDialog"
      :message="message"
      @close-dialog="closeCommentDialog"
    />
  </div>
  <mobile-view v-else />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LieferstellenSidebar from "@/components/ui/Kommunalrabatt/LieferstellenSidebar";
import LieferstellenFilter from "@/components/filter/LieferstellenFilter";
import MobileView from "@/components/ui/Kommunalrabatt/MobileView";
import AddLieferstelleSidebar from "@/components/ui/Kommunalrabatt/AddLieferstelleSidebar.vue";
import CommentDialog from "@/components/ui/CommentDialog.vue";
import { filter } from "core-js/internals/array-iteration";
import ContactPersonHorizontal from "../components/layout/ContactPersonHorizontal.vue";
import EditLieferstelleCard from "@/components/ui/Kommunalrabatt/EditLieferstelleCard.vue";

export default {
  name: "Lieferstellen",
  components: {
    EditLieferstelleCard,
    AddLieferstelleSidebar,
    MobileView,
    LieferstellenFilter,
    LieferstellenSidebar,
    CommentDialog,
    ContactPersonHorizontal
  },
  data: () => ({
    showDialog: false,
    message:
      "Sie haben einen ungespeicherten Kommentar. Bitte klicken sie zuerst auf «Hinzufügen» oder «Abbrechen»",
    selectedCommune: undefined,
    selectedYear: undefined,
    headerProps: {
      "sort-icon": "mdi-chevron-up"
    },
    filterBy: "Alle",
    selectedSparten: ["Strom", "Gas"],
    query: undefined,
    sidebarIsVisible: false,
    selectedItem: undefined,
    showChangedOnly: false,
    changeableFieldNames: [
      "geschaeftspartnerNummer",
      "geschaeftspartnerName",
      "druckstufe",
      "spannungsebene"
    ],
    activeRowStyle: {
      "background-color": "#f5e6ed"
    },
    itemsPerPage: 10,
    editDialogIsVisible: false
  }),
  computed: {
    loading() {
      return this.lieferstellenByYear.length === 0;
    },
    ...mapGetters("kommunalrabatt", ["getCommentInputText"]),
    getCountAlle() {
      let total = 0;
      if (this.selectedSparten.includes("Gas")) {
        total += this.lieferstellenByYear.filter(ls => ls.sparte === "Gas")
          .length;
      }
      if (this.selectedSparten.includes("Strom")) {
        total += this.lieferstellenByYear.filter(ls => ls.sparte === "Strom")
          .length;
      }
      return total > 0 ? total : 0;
    },
    getCountAddedByCommune() {
      return this.lieferstellenByYear.filter(ls => ls.addedByCommune === true)
        .length;
    },
    getCountAddedByNEW() {
      return this.lieferstellenByYear.filter(ls => !ls.addedByCommune).length;
    },
    getCountCommentsByUserid() {
      return this.getCommentsByUserid.length;
    },
    getCountCommentsByCommune() {
      return this.getCommentsByCommune.length;
    },
    getCountCommentsByNEW() {
      return this.getCommentsByNEW.length;
    },
    getCountNewGp() {
      return this.lieferstellenByYear.filter(ls => ls.newGeschaeftspartner)
        .length;
    },
    getCountNichtRabattfaehige() {
      return this.lieferstellenByYear.filter(
        ls => this.selectedSparten.includes(ls.sparte) && !ls.rabatt
      ).length;
    },
    getCountRabattfaehige() {
      return this.lieferstellenByYear.filter(
        ls => this.selectedSparten.includes(ls.sparte) && ls.rabatt
      ).length;
    },
    filterItems() {
      const items = [
        { text: `Alle `, value: "Alle" },
        {
          text: `Neuer Geschäftspartner (${this.getCountNewGp})`,
          value: "Neue Geschäftspartner"
        }
      ];

      if (this.isNewEmployee) {
        items.push({
          text: `Von Kommune hinzugefuegt (${this.getCountAddedByCommune})`,
          value: "Von Kommune hinzugefuegt"
        });
      }

      items.push(
        {
          text: `Rabattfähige (${this.getCountRabattfaehige})`,
          value: "Rabattfähige"
        },
        {
          text: `Nicht rabattfähige (${this.getCountNichtRabattfaehige})`,
          value: "Nicht rabattfähige"
        },
        {
          text: `Von mir kommentierte (${this.getCountCommentsByUserid})`,
          value: "Von mir kommentierte"
        }
      );

      if (this.isNewEmployee) {
        items.push({
          text: `Von Kommune kommentiert (${this.getCountCommentsByCommune})`,
          value: "Von Kommune kommentiert"
        });
      } else {
        items.push(
          {
            text: `Von NEW hinzugefügt (${this.getCountAddedByNEW})`,
            value: "Von NEW hinzugefuegt"
          },
          {
            text: `Von NEW kommentiert (${this.getCountCommentsByNEW})`,
            value: "Von NEW kommentiert"
          }
        );
      }
      return items;
    },
    tableHeaders() {
      const headers = [
        {
          text: "Rabatt",
          align: "start",
          sortable: true,
          value: "rabatt",
          class: "kommunalrabatt-table-header",
          cellClass: "kommunalrabatt-table-cell",
          width: 90
        },
        {
          text: "Marktlokation",
          align: "start",
          sortable: true,
          value: "marktlokation",
          divider: true,
          class: "kommunalrabatt-table-header",
          cellClass: "kommunalrabatt-table-cell",
          width: "200px"
        },
        {
          text: "Geschäftspartner-Nr. ",
          align: "start",
          sortable: true,
          value: "geschaeftspartnerNummer",
          class: "kommunalrabatt-table-header",
          cellClass: "kommunalrabatt-table-cell px-0",
          width: "170px"
        },
        {
          text: "Geschäftspartner",
          align: "start",
          sortable: true,
          value: "geschaeftspartnerName",
          class: "kommunalrabatt-table-header",
          cellClass: "kommunalrabatt-table-cell px-0",
          width: 250
        },
        {
          text: "Zuordnung MG",
          align: "center",
          sortable: true,
          value: "zuordnungMG",
          class: "kommunalrabatt-table-header",
          cellClass: "kommunalrabatt-table-cell"
        },
        {
          text: "Zusatzinformation 1",
          align: "center",
          sortable: true,
          value: "zusatzinfo1",
          class: "kommunalrabatt-table-header",
          cellClass: "kommunalrabatt-table-cell"
        },
        {
          text: "Zusatzinformation 2",
          align: "center",
          sortable: true,
          value: "zusatzinfo2",
          class: "kommunalrabatt-table-header",
          cellClass: "kommunalrabatt-table-cell"
        },
        {
          text: "Vertragskonto Sammler",
          align: "center",
          sortable: true,
          value: "vkSammler",
          class: "kommunalrabatt-table-header",
          cellClass: "kommunalrabatt-table-cell"
        },
        {
          text: "Vertragskonto Einzel",
          align: "center",
          sortable: true,
          value: "vkEinzel",
          class: "kommunalrabatt-table-header",
          cellClass: "kommunalrabatt-table-cell"
        },
        {
          text: "Druckstufe / Spannungsebene",
          align: "center",
          sortable: true,
          value: "",
          class: "kommunalrabatt-table-header",
          cellClass: "kommunalrabatt-table-cell px-0",
          divider: true
        }
      ];

      headers.push({
        text: "",
        align: "center",
        sortable: false,
        value: "details",
        class: "kommunalrabatt-table-header",
        cellClass: "kommunalrabatt-table-cell",
        width: 200
      });

      return headers;
    },
    ...mapGetters("kommunalrabatt", [
      "getLieferstellenForCommune",
      "getCommunes",
      "getSelectedCommune",
      "getSelectedYear",
      "getCommuneStatuses",
      "getSelectedCommuneStatus",
      "getLieferstellen",
      "getUnreadComments",
      "selectedLieferstelle",
      "getCommentsByUserid",
      "getCommentsByCommune",
      "getCommentsByNEW",
      "getCommentInputText",
      "getCommentsByNEW"
    ]),
    ...mapGetters("user", ["isNewEmployee"]),
    lieferstellenByYear() {
      return this.getLieferstellen;
    },
    filteredLieferstellen() {
      let lieferstellen = this.lieferstellenByYear;
      const commentsByUserid = this.getCommentsByUserid;
      const commentsByCommune = this.getCommentsByCommune;

      switch (this.filterBy) {
        case "Nicht rabattfähige":
          lieferstellen = this.lieferstellenByYear.filter(ls => !ls.rabatt);
          break;
        case "Rabattfähige":
          lieferstellen = this.lieferstellenByYear.filter(ls => ls.rabatt);
          break;
        case "Von mir kommentierte":
          lieferstellen = this.lieferstellenByYear.filter(ls => {
            return commentsByUserid.includes(ls.marktlokation);
          });
          break;
        case "Von Kommune kommentiert":
          lieferstellen = this.lieferstellenByYear.filter(ls => {
            return commentsByCommune.includes(ls.marktlokation);
          });
          break;
        case "Von NEW kommentiert":
          lieferstellen = this.lieferstellenByYear.filter(ls => {
            return this.getCommentsByNEW.includes(ls.marktlokation);
          });
          break;
        case "Von Kommune hinzugefuegt":
          lieferstellen = this.lieferstellenByYear.filter(
            ls => ls.addedByCommune == true
          );
          break;
        case "Von NEW hinzugefuegt":
          lieferstellen = this.lieferstellenByYear.filter(
            ls => !ls.addedByCommune
          );
          break;
        case "Neue Geschäftspartner":
          lieferstellen = this.lieferstellenByYear.filter(
            ls => ls.newGeschaeftspartner
          );
          break;
        default:
          break;
      }

      lieferstellen = lieferstellen
        .filter(ls => this.selectedSparten.includes(ls.sparte))
        .sort((a, b) => parseInt(a.marktlokation) - parseInt(b.marktlokation));

      return lieferstellen;
    }
  },
  methods: {
    filter,
    ...mapActions("kommunalrabatt", [
      "updateLieferstelle",
      "updateSelectedLieferstelle",
      "loadCommuneStatuses",
      "loadCommentsByUserid",
      "loadCommentsByCommune",
      "loadCommentsByNEW",
      "clearLieferstellen",
      "loadDetails",
      "loadUnreadComments",
      "loadComments"
    ]),
    ...mapActions("msgSnackbar", ["showMessage"]),
    onUpdate(item) {
      this.updateLieferstelle(item);
    },
    getItemClass(item) {
      const itemClass =
        item.marktlokation === this.selectedLieferstelle?.marktlocation
          ? "active-row"
          : "";
      return itemClass;
    },
    async showSidebar($item) {
      if (this.getCommentInputText) {
        this.showDialog = true;
        return;
      }
      await this.updateSelectedLieferstelle($item);
      this.sidebarIsVisible = true;
      await Promise.all([this.loadDetails(), this.loadComments()]);
    },
    async showEditLieferstelleModal($item) {
      await this.updateSelectedLieferstelle($item);
      this.editDialogIsVisible = true;
    },
    closeCommentDialog() {
      this.showDialog = false;
    },
    onOpenSidebar() {
      this.updateSelectedLieferstelle(undefined);
      this.sidebarIsVisible = true;
    },
    onSidebarClose() {
      this.sidebarIsVisible = false;
      this.updateSelectedLieferstelle(undefined);
    },
    convertLieferstelleToCsvRow(lieferstelle) {
      const rabatt = lieferstelle.rabatt ? "TRUE" : "FALSE";
      const marktlokation = lieferstelle.marktlokation;
      const adresse = `${lieferstelle.adresszeile1} ${lieferstelle.adresszeile2}`.trim();
      const plz = lieferstelle.plz;
      const geschaeftspartnerNr = lieferstelle.geschaeftspartnerNummer;
      const geschaeftspartner = lieferstelle.geschaeftspartnerName;
      const notiz = [
        lieferstelle.zusatzinfo1,
        lieferstelle.zusatzinfo2,
        lieferstelle.zusatzinfo3
      ]
        .filter(i => i.length > 0)
        .join(", ");
      const druckstufeOderSpannungsebene =
        lieferstelle.sparte === "Strom"
          ? lieferstelle.spannungsebene
          : lieferstelle.druckstufe;

      return `"${rabatt}","${marktlokation}","${adresse}","${plz}","${geschaeftspartnerNr}","${geschaeftspartner}","${notiz}","${druckstufeOderSpannungsebene}"`;
    },
    downloadCsv() {
      const headerRow =
        "Rabatt,Marktlokation,Adresse,PLZ,Geschäftspartner-Nr,Geschäftspartner,Notiz,Druckstufe/Spannungsebene";

      const rows = this.filteredLieferstellen.map(ls =>
        this.convertLieferstelleToCsvRow(ls)
      );

      const csvContent = `sep=,\n${headerRow}\n${rows.join("\n")}`;

      const filename = "kommunalrabatt-export.csv";
      const blob = new Blob([csvContent], {
        type: "text/plain;charset=utf-16-le"
      });

      const url = window.URL || window.webkitURL;
      const dlElement = document.createElement("a");
      dlElement.style.display = "none";
      dlElement.setAttribute("href", url.createObjectURL(blob));
      dlElement.setAttribute("download", filename);
      dlElement.click();
    }
  },
  async mounted() {
    this.clearLieferstellen();

    await this.loadCommuneStatuses();
    await this.loadUnreadComments();
    await this.loadCommentsByUserid();
    await this.loadCommentsByCommune();
    await this.loadCommentsByNEW();
  }
};
</script>

<style scoped>
.select-row {
  display: flex;
  align-items: center;
}

.Kommune
  > .v-input--selection-controls__input
  > .v-icon.notranslate.v-icon--disabled.mdi.mdi-checkbox-marked.theme--light {
  color: grey !important;
}

.Kommune
  > .v-input--selection-controls__input
  > .v-icon.notranslate.v-icon--disabled.mdi.mdi-checkbox-blank-outline.theme--light {
  color: grey !important;
}

/*
.NEW > .v-input--selection-controls__input > .v-icon.notranslate.v-icon--disabled.mdi.mdi-checkbox-marked.theme--light {
  color: pink !important;
}
.NEW > .v-input--selection-controls__input > .v-icon.notranslate.v-icon--disabled.mdi.mdi-checkbox-blank-outline.theme--light {
  color: grey !important;
}
*/

.filter-row {
  /*background-color: #f5e6ed;*/
  border-top: 1px solid #edc1d3;
  border-bottom: 1px solid #edc1d3;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.filter-row-container {
  background-color: #faf2f6;
}

.filter-row > * {
  margin-right: 12px;
}

.theme--light.v-icon.v-icon.v-icon--disabled {
  color: #b40055 !important;
}

.text-cell {
  padding-left: 16px;
  display: flex;
  align-items: center;
}

.changed-cell {
  background-color: rgba(255, 152, 0, 0.13);
}

#lieferstellen-sidebar {
  background-color: #ffffff;
  border-top: 1px solid #edc1d3;
  border-left: #edc1d3 solid 1px;
}

.active-table-row tr {
  background-color: #f5e6ed !important;
}
</style>
