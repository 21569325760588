export default {
    SET_COMMUNE_NAMES(state, communeNames) {
        state.communeNames = communeNames;
    },
    SET_VERBRAUCHSDATEN(state, verbrauchsdaten) {
        state.verbrauchsdaten = verbrauchsdaten;
    },
    SET_LOADED(state, loaded) {
        state.loaded = loaded;
    },
    SET_SELECTED_YEARS(state, selectedYears) {
        state.selectedYears = selectedYears;
    },
    SET_SELECTED_ENERGIETRAEGER(state, selectedEnergietraeger) {
        state.selectedEnergietraeger = selectedEnergietraeger;
    },
    SET_SELECTED_SEKTOREN(state, selectedSektoren) {
        state.selectedSektoren = selectedSektoren;
    },
    SET_CONTACT_PERSON(state, contactPerson) {
        state.contactPerson = contactPerson;
    },
    SET_COMMUNE_DATA(state, communeData) {
        state.communeData = communeData;
    }
}
