import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
    namespaced: true,
    state: {
        config: undefined,
        notifications: [],
        showNotifications: false,
    },
    actions,
    getters,
    mutations,
}
