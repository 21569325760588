<template>
  <div>
    <v-app-bar
      app
      clipped-left
      class="white elevation-custom logo-gradient"
      style="display: flex; justify-content: center; align-items: center;"
      fixed
      width="300"
    >
      <router-link :to="{ name: 'home' }">
        <v-img class="logo" src="@/assets/logo/NEW-KoPo-Logo.png" width="100%" />
      </router-link>
    </v-app-bar>

    <v-app-bar
      app
      clipped-right
      class="white elevation-custom"
      :class="$vuetify.breakpoint.width < 1264 ? 'logo-gradient' : 'white'"
      flat
    >
      <!-- KOMUNNALPORTAL LOGO -->
      <router-link
        v-if="$vuetify.breakpoint.width < 1264"
        :to="{ name: 'home' }"
        id="mobile-home-logo"
        class="mr-1"
      >
        <v-img class="logo" src="@/assets/logo/NEW-KoPo-Logo.svg" width="100%" contain />
      </router-link>

      <v-col cols="4" class="pa-0" v-if="$vuetify.breakpoint.width > 1264">
        <v-autocomplete
          id="search-input"
          v-model="searchValue"
          :items="entries"
          item-text="name"
          item-value="data"
          label="Suche"
          @click:prepend-inner="onSearchSubmit"
          @update:search-input="onSearchInput"
          @keyup.enter="onSearchSubmit"
          background-color="#f6e4ed"
          :menu-props="menuProps"
          prepend-inner-icon="mdi-magnify"
          clearable
          outlined
          dense
          filled
          append-icon=""
        >
          <template v-slot:item="data" dense>
            <v-list-item
              v-if="data.item.data !== null"
              @click="onItemClick(data.item)"
            >
              <v-list-item-avatar
                v-if="data.item.data !== null && data.item.data.image"
              >
                <img
                  :src="
                    `${baseUrl}${data.item.data.image.formats.thumbnail.url}`
                  "
                  style="height: 100%; width: auto"
                />
              </v-list-item-avatar>
              <v-list-item-content class="py-0" v-if="data.item.data !== null">
                <v-list-item-title v-html="maskResult(data.item.name)"
                  >testesteste</v-list-item-title
                >
                <v-list-item-subtitle
                  v-if="data.item.data.type === 'ServiceAreas'"
                  >Allgemein</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  v-else-if="data.item.data.type === 'Services'"
                  >{{ data.item.data.serviceArea.title }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  v-else-if="data.item.data.type === 'SubServices'"
                  >{{ data.item.data.service.title }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  v-else-if="data.item.data.type === 'News und Presse'"
                  >News & Presse</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  v-else-if="data.item.data.type === 'Ansprechpartner'"
                  >Ansprechpartner</v-list-item-subtitle
                >
                <v-list-item-subtitle v-else
                  >Ihre Sucheingabe</v-list-item-subtitle
                >
              </v-list-item-content>
              <span
                v-if="data.item.data !== null && data.item.data.area"
                class="ml-2"
              >
                <v-chip
                  v-for="areaName of data.item.data.area"
                  :key="areaName"
                  color="primary"
                  class="ml-1"
                  label
                  small
                >
                  {{ areaName }}
                </v-chip>
              </span>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>

      <v-spacer></v-spacer>

      <div v-if="$vuetify.breakpoint.width >= 1264" class="d-flex">
        <commune-partner-card v-for="partner of getDashboardCommunePartners" :key="partner.id" :user-commune-partner="partner" class="ml-1" />
      </div>

      <template v-if="$vuetify.breakpoint.width >= 1264">
        <div id="loginnew-dropdown"></div>
        <v-badge v-if="getUnreadNotificationsCount > 0" :content="getUnreadNotificationsCount" overlap>
        <!--<v-btn height="40px" width="40px" fab elevation="0" class="border-btn-primary" @click="onNotificationsBtnClick">
          <v-icon>mdi-email-outline</v-icon>
        </v-btn>-->
          <v-btn icon @click="onNotificationsBtnClick">
            <v-icon size="40px">$notifications</v-icon>
          </v-btn>
        </v-badge>
        <v-btn icon @click="onNotificationsBtnClick" v-else>
          <v-icon size="40px">$notifications</v-icon>
        </v-btn>
      </template>

      <v-btn
        v-else
        icon
        small
        class="mt-1 mx-1"
        color="white"
        @click="showSearch = !showSearch"
      >
        <v-icon>{{ !showSearch ? "mdi-magnify" : "mdi-magnify-close" }}</v-icon>
      </v-btn>

      <!-- BURGER ICON -->
      <v-app-bar-nav-icon
        v-if="isLoggedInAndApproved"
        class="hidden-lg-and-up white--text mt-1"
        id="nav-burger-icon"
        @click="drawer = !drawer"
      />
    </v-app-bar>

    <!-- SIDE NAVIGATION CONTENT -->
    <v-navigation-drawer
      v-if="isLoggedInAndApproved"
      v-model="drawer"
      clipped
      app
      width="300"
      :src="image"
      class="grey lighten-3"
      :class="$vuetify.breakpoint.mdAndDown ? '' : 'new-sidebar-pb'"
      id="nav-drawer"
    >
      <v-app-bar
        v-if="$vuetify.breakpoint.mdAndDown"
        fixed
        class="white elevation-custom logo-gradient"
        style="display: flex; justify-content: center; align-items: center;"
      >
        <span>
          <router-link :to="{ name: 'home' }">
            <v-img class="logo" src="@/assets/logo/NEW-KoPo-Logo.svg" />
          </router-link>
        </span>
      </v-app-bar>

      <component v-bind:is="navDrawer" @showSubMenuDrawer="onSubMenuClick" />
    </v-navigation-drawer>

    <div
      v-if="showSearch && $vuetify.breakpoint.width < 1264"
      id="mobile-search"
      class="elevation-custom"
    >
      <v-autocomplete
        :items="entries"
        @update:search-input="onSearchInput"
        item-text="name"
        class="mx-auto"
        v-model="searchValue"
        item-value="data"
        background-color="#f6e4ed"
        @keyup.enter="onSearchSubmit"
        @click:prepend-inner="onSearchSubmit"
        :menu-props="menuProps"
        prepend-inner-icon="mdi-magnify"
        label="Suche"
        clearable
        outlined
        dense
        filled
        append-icon=""
      >
        <template v-slot:item="data" dense>
          <v-list-item-avatar v-if="data.item.data.image">
            <img
              :src="`${baseUrl}${data.item.data.image.formats.thumbnail.url}`"
              style="height: 100%; width: auto"
            />
          </v-list-item-avatar>
          <v-list-item-content class="py-0">
            <v-list-item-title
              v-html="maskResult(data.item.name)"
            ></v-list-item-title>
            <v-list-item-subtitle v-if="data.item.data.type === 'ServiceAreas'"
              >Allgemein</v-list-item-subtitle
            >
            <v-list-item-subtitle
              v-else-if="data.item.data.type === 'Services'"
              >{{ data.item.data.serviceArea.title }}</v-list-item-subtitle
            >
            <v-list-item-subtitle
              v-else-if="data.item.data.type === 'SubServices'"
              >{{ data.item.data.service.title }}</v-list-item-subtitle
            >
            <v-list-item-subtitle
              v-else-if="data.item.data.type === 'News und Presse'"
              >News & Presse</v-list-item-subtitle
            >
            <v-list-item-subtitle
              v-else-if="data.item.data.type === 'Ansprechpartner'"
              >Ansprechpartner</v-list-item-subtitle
            >
            <v-list-item-subtitle v-else>Ihre Sucheingabe</v-list-item-subtitle>
          </v-list-item-content>
          <span v-if="data.item.data.area" class="ml-2">
            <v-chip
              v-for="areaName of data.item.data.area"
              :key="areaName"
              color="primary"
              class="ml-1"
              label
              small
            >
              {{ areaName }}
            </v-chip>
          </span>
        </template>
      </v-autocomplete>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import NavigationDrawerMainRoutes from "./NavigationDrawerMainRoutes.vue";
import NavigationDrawerSubRoutes from "./NavigationDrawerSubRoutes.vue";
import NavigationDrawerCommuneRoutes from "@/components/navigation/private/NavigationDrawerCommuneRoutes.vue";
import NavigationDrawerWaermeRoutes from "@/components/navigation/private/NavigationDrawerWaermeRoutes.vue";
import loginNew from "loginNew";
import CommunePartnerCard from "@/components/ui/CommunePartnerCard.vue";

export default {
  components: {
    CommunePartnerCard,
    NavigationDrawerMainRoutes,
    NavigationDrawerSubRoutes,
    NavigationDrawerCommuneRoutes,
    NavigationDrawerWaermeRoutes
  },
  data() {
    return {
      showSearch: false,
      menuProps: {
        // maxHeight: 200,
        dense: true,
        maxHeight: 700,
        offsetY: true,
        offsetOverflow: true,
        closeOnContentClick: true
      },
      searchQuery: "",
      searchValue: null,
      userPhotoUrl: false,
      drawer: false,
      image: require("@/assets/Sidebar_bg.png"),
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      selectedSubMenu: null
    };
  },
  watch: {
    searchValue(newSearchValue) {
      if (!newSearchValue) return;
    }
  },
  computed: {
    ...mapGetters("user", ["userCommuneId", "getDashboardCommunePartners"]),
    ...mapGetters("notifications", ["getUnreadNotificationsCount"]),
    showSubRoutes() {
      return this.$route.path.includes("service-area/");
    },

    navDrawer() {
      if (this.showSubRoutes) return "NavigationDrawerSubRoutes";
      else if (this.selectedSubMenu) return this.selectedSubMenu;
      else return "NavigationDrawerMainRoutes";
    },

    isLoggedIn() {
      return this.$store.getters["user/isLoggedIn"];
    },

    isLoggedInAndApproved() {
      return this.isLoggedIn && this.$store.getters["user/isUserApproved"];
    },
    entries() {
      const query = (this.searchQuery ?? "").toLowerCase();

      const itemsPerCategory = 2;
      // const maxItems = 10;
      const foundNews = this.searchEntries.news
        .filter(news => news.name?.toLowerCase().includes(query))
        .slice(0, itemsPerCategory);
      const foundPress = this.searchEntries.press
        .filter(news => news.name.toLowerCase().includes(query))
        .slice(0, itemsPerCategory);
      const foundServiceAreas = this.searchEntries.serviceAreas
        .filter(news => news.name.toLowerCase().includes(query))
        .slice(0, itemsPerCategory);
      const foundServices = this.searchEntries.services
        .filter(news => news.name.toLowerCase().includes(query))
        .slice(0, itemsPerCategory);
      const foundSubservices = this.searchEntries.subServices
        .filter(news => news.name.toLowerCase().includes(query))
        .slice(0, itemsPerCategory);
      const foundContactPeople = this.searchEntries.distinctContactPersons
        .filter(news => news.name.toLowerCase().includes(query))
        .slice(0, itemsPerCategory);

      const foundEntries = [];

      if (foundServiceAreas.length > 0) {
        // foundEntries.push({ header: 'ServiceAreas' });
        foundEntries.push(...foundServiceAreas);
        // foundEntries.push({ divider: true});
      }

      if (foundServices.length > 0) {
        // foundEntries.push({ header: 'Services' });
        foundEntries.push(...foundServices);
        // foundEntries.push({ divider: true});
      }

      if (foundSubservices.length > 0) {
        // foundEntries.push({ header: 'Subservices' });
        foundEntries.push(...foundSubservices);
        // foundEntries.push({ divider: true});
      }

      if (foundContactPeople.length > 0) {
        //foundEntries.push({ header: 'Ansprechpartner' });
        foundEntries.push(...foundContactPeople);
        //foundEntries.push({ divider: true});
      }

      if (foundNews.length > 0) {
        //foundEntries.push({ header: 'News & Presse' });
        foundEntries.push(...foundNews);
        // foundEntries.push({ divider: true});
      }

      if (foundPress.length > 0) {
        //foundEntries.push({ header: 'News & Presse' });
        foundEntries.push(...foundPress);
        // foundEntries.push({ divider: true});
      }

      const entries = [{ name: this.searchQuery, data: this.searchQuery }];
      entries.push(...foundEntries);

      return entries;
    },
    ...mapGetters({
      searchEntries: "search/getEntries"
    })
  },

  methods: {
    ...mapActions("notifications", ["toggleShowNotifications", "loadNotifications"]),
    onNotificationsBtnClick() {
      this.toggleShowNotifications();
    },
    onSubMenuClick(menuName) {
      this.selectedSubMenu = menuName;
    },
    maskResult(result) {
      const regex = new RegExp(`(${this.searchQuery})`, "gi");
      return result.replace(regex, '<span class="masked-result">$1</span>');
    },
    // TODO: async machen, da es
    doLogout() {
      if (!process.browser) {
        return;
      }
      this.$store.dispatch("user/logout");
    },
    onSearchInput($event) {
      //if($event) {
      this.searchQuery = $event;
      //}
    },
    onSearchSubmit() {
      this.showSearch = false;
      const query = this.searchQuery ?? "";

      this.$store.dispatch("search/setQuery", query);
      this.$store.dispatch("analytics/addQuery", query);
      this.$router.push(`/search?q=${query}`);
    },
    onItemClick(searchQuery) {
      this.searchValue = searchQuery.data;
      this.searchQuery = searchQuery.name;

      this.$store.dispatch("search/setQuery", this.searchQuery);
      this.$store.dispatch("analytics/addQuery", this.searchQuery);

      if (searchQuery.data.type === "Ansprechpartner") {
        this.$store.dispatch("analytics/commitSearchSession", {
          followUpType: "contact_person",
          followUp: searchQuery.data
        });
      }

      this.$router.push(`/search?q=${this.searchQuery}`);
    }
  },

  async mounted() {
    loginNew.init(
      "kommunalportal",
      `${process.env.VUE_APP_API_BASE_URL}/connect/login_new_ag`,
      ["google_analytics"],
      "openid email profile new_cookie usercentrics"
    );

    loginNew.onBeforeLogout(() => {
      this.doLogout();
    });

    this.drawer = this.$vuetify.breakpoint.smAndUp;

    loginNew.onCookieStateChanged(() => {
      if (loginNew.serviceAllowed("eTbCyQ9d_")) {
        if (document.getElementById("usersnap-script")) return;

        const id = sessionStorage.user_id;
        const mail = sessionStorage.user_mail;

        window.onUsersnapCXLoad = function(api) {
          window.usersnapApi = api;
          api.init({
            user: {
              userId: id,
              email: mail
            }
          });
        };
        const script = document.createElement("script");
        script.id = "usersnap-script";
        script.defer = true;
        script.src =
          "https://widget.usersnap.com/global/load/5adbf085-e81e-48e0-b636-f8cdbb683fcc?onload=onUsersnapCXLoad";
        document.getElementsByTagName("head")[0].appendChild(script);
      } else {
        window.usersnapApi?.destroy();
        const scriptTag = document.getElementById("usersnap-script");

        if (scriptTag) {
          scriptTag.remove();
        }
      }
    });

    await this.loadNotifications();
  }
};
</script>

<style scoped>
header .v-toolbar {
  border-bottom: 6px solid red !important;
}

.login-new-avatar img {
  max-height: 35px;
  max-width: 35px;
}

/* #login-new-avatar-btn > span {
  display: var(--displayProfile);
}

#login-new-signin-btn > span {
  display: var(--displayProfile);
} */

#search-input {
  width: 50px !important;
}

#mobile-search {
  position: fixed;
  top: 65px;
  padding: 10px;
  background-color: #ffffff;
  z-index: 2;
  width: 100vw;
}

.v-text-field--outlined >>> fieldset {
  border-color: #22478a29;
}

.v-text-field--outlined:hover >>> fieldset {
  border-color: #b40055;
}
.new-sidebar-pb {
  padding-bottom: 70px !important;
}

.border-btn-primary {
  border: #B40055 solid 1px;
}
</style>
