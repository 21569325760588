<template>
  <v-row class="new-wrapper min-height-100" no-gutters>
    <v-col :cols="colSize" class="pa-10">
      <v-container fluid class="verbrauchsdaten_container pa-0">
        <v-container fluid class="verbrauchsdaten_header pa-0">
          <v-container fluid class="pa-0" style="position:relative">
            <span v-if="selectedCommune" class="einwohnerzahlen_container">
              <v-chip
                small
                class="vd-chip text-uppercase mr-2 px-2 new-font-medium"
              >
                Stand: {{ getImportDate }}
              </v-chip>

              <v-chip small class="vd-chip px-2 new-font-medium">
                EWZ: {{ formatNumber(getEinwohnerCount) }}
              </v-chip>
            </span>
            <span class="custom-header-text new-font-bold">
              Verbrauchs- und Einspeisedaten
              <span v-if="selectedCommune">{{ selectedCommune }}</span>
            </span>
          </v-container>
        </v-container>

        <v-container fluid class="verbrauchsdaten_filter py-10 px-0">
          <v-row dense>
            <template v-if="$vuetify.breakpoint.lgAndUp || showFilter">
              <v-col cols="12" sm="12" lg="6" xl="3">
                <v-select
                  label="Kommune"
                  background-color="#f6e4ed"
                  :items="communeNames"
                  v-model="selectedCommune"
                  @change="onCommuneChangeChange"
                  outlined
                  hide-details
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" lg="6" xl="3">
                <v-select
                  label="Zeitraum"
                  background-color="#f6e4ed"
                  :items="getYears"
                  v-model="selectedYears"
                  @change="setSelectedYears(selectedYears)"
                  multiple
                  outlined
                  hide-details
                  dense
                >
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index === 0">{{ item }}</span>
                    <span v-if="index === 1" class="grey--text caption">
                      (+{{ selectedYears.length - 1 }})
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="12" lg="6" xl="3">
                <v-select
                  label="Energieträger"
                  background-color="#f6e4ed"
                  multiple
                  :items="getEnergietraeger"
                  v-model="selectedEnergietraeger"
                  @change="changeSelectedEnergietraeger(selectedEnergietraeger)"
                  outlined
                  hide-details
                  dense
                >
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index === 0">{{ item }}</span>
                    <span v-if="index === 1" class="grey--text caption">
                      (+{{ selectedEnergietraeger.length - 1 }})
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="12" lg="6" xl="3">
                <v-select
                  label="Alle Sektoren"
                  background-color="#f6e4ed"
                  multiple
                  :items="getSektoren"
                  v-model="selectedSektoren"
                  @change="setSelectedSektoren(selectedSektoren)"
                  outlined
                  hide-details
                  dense
                >
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index === 0">{{ item }}</span>
                    <span v-if="index === 1" class="grey--text caption">
                      (+{{ selectedSektoren.length - 1 }})
                    </span>
                  </template>
                </v-select>
              </v-col>
            </template>
            <v-col cols="12" v-if="$vuetify.breakpoint.mdAndDown">
              <v-btn
                @click="showFilter = !showFilter"
                block
                outlined
                color="primary"
              >
                <v-icon color="primary"
                  >mdi-chevron-{{ showFilter ? "up" : "down" }}
                </v-icon>
                Filter {{ showFilter ? "ausblenden" : "anzeigen" }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
      <v-row v-if="loaded" no-gutters>
        <v-col cols="12" v-show="showColumnChart" id="main-chart">
          <apexchart
            ref="columnChart"
            type="bar"
            :options="columnChartOptions"
            height="450"
            :series="columnChartOptions.series"
          />
        </v-col>
        <v-col
          cols="12"
          v-if="selectedEnergietraeger.includes('Strom')"
          class="pa-0"
        >
          <div class="mt-15">
            <span class="new-font-bold custom-header-text">
              Strom
            </span>
          </div>
          <v-row no-gutters>
            <v-col cols="12" md="6" class="py-8">
              <div class="vd-card">
                <h2 class="mb-2 text-center new-font-medium">
                  Verbrauch: {{ formatNumber(stromSum) }}
                  <small class="font-weight-medium">MWh</small>
                </h2>
                <div style="position: relative">
                  <v-icon size="100" class="apex-icon-position" color="#3a357b"
                    >mdi-home-city
                  </v-icon>
                  <apexchart
                    :options="stromSektorenChartOptions"
                    type="donut"
                    width="100%"
                    :series="stromSektorenChartOptions.series"
                    ref="stromSektorenDonut"
                  />
                </div>

                <h3 class="mb-5 mt-5 new-font-medium">Sektoren (MWh)</h3>

                <v-row
                  v-for="(label, index) of stromSektorenChartOptions.labels"
                  :key="label"
                  @mouseenter="toggleDataPoint('stromSektorenDonut', index)"
                  @mouseleave="toggleDataPoint('stromSektorenDonut', index)"
                >
                  <v-col cols="8">
                    <v-chip
                      class="rounded-0 mr-2"
                      :color="stromColors[index]"
                      style="height: 21px"
                    ></v-chip>
                    {{ label }}
                  </v-col>
                  <v-col cols="4" class="text-end font-weight-bold">
                    {{ formatNumber(stromSektorenChartOptions.series[index]) }}
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="12" md="6" class="einspeisung-col py-8">
              <div class="vd-card">
                <h2 class="mb-2 text-center new-font-medium">
                  Einspeisung:
                  {{ getTotal(stromEinspeisungChartOptions.series) }}
                  <small class="font-weight-medium">MWh</small>
                </h2>
                <div style="position: relative">
                  <v-icon size="100" class="apex-icon-position" color="#3a357b"
                    >mdi-transmission-tower
                  </v-icon>
                  <apexchart
                    :options="stromEinspeisungChartOptions"
                    type="donut"
                    width="100%"
                    :series="stromEinspeisungChartOptions.series"
                    ref="stromEinspeisungDonut"
                  />
                </div>

                <h3 class="mb-5 mt-5 new-font-medium">
                  Einspeisequellen (MWh)
                </h3>

                <v-row
                  v-for="(label, index) of stromEinspeisungChartOptions.labels"
                  :key="label"
                  @mouseenter="toggleDataPoint('stromEinspeisungDonut', index)"
                  @mouseleave="toggleDataPoint('stromEinspeisungDonut', index)"
                  style="cursor: pointer"
                >
                  <v-col>
                    <v-chip
                      class="rounded-0 mr-2"
                      :color="stromColors[index]"
                      style="height: 21px"
                    ></v-chip>
                    {{ label }}
                  </v-col>
                  <v-col class="text-end font-weight-bold">
                    {{
                      formatNumber(stromEinspeisungChartOptions.series[index])
                    }}
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          v-if="selectedEnergietraeger.includes('Gas')"
          class="pa-0"
        >
          <div class="mt-10">
            <span class="new-font-bold custom-header-text">
              Gas
            </span>
          </div>
          <v-row no-gutters>
            <v-col cols="12" md="6" class="py-8">
              <h2 class="mb-2 text-center new-font-medium">
                Verbrauch:
                {{ formatNumber(gasSum) }}
                <small class="font-weight-medium">MWh</small>
              </h2>
              <div class="vd-card">
                <div style="position: relative">
                  <v-icon size="100" class="apex-icon-position" color="#3a357b"
                    >mdi-home-city
                  </v-icon>
                  <apexchart
                    :options="gasSektorenChartOptions"
                    type="donut"
                    width="100%"
                    :series="gasSektorenChartOptions.series"
                    ref="gasSektorenDonut"
                  />
                </div>

                <h3 class="mb-5 mt-5 new-font-medium">Sektoren (MWh)</h3>

                <v-row
                  v-for="(label, index) of gasSektorenChartOptions.labels"
                  :key="label"
                  @mouseenter="toggleDataPoint('gasSektorenDonut', index)"
                  @mouseleave="toggleDataPoint('gasSektorenDonut', index)"
                >
                  <v-col cols="8">
                    <v-chip
                      class="rounded-0 mr-2"
                      :color="gasColors[index]"
                      style="height: 21px"
                    ></v-chip>
                    {{ label }}
                  </v-col>
                  <v-col cols="4" class="text-end font-weight-bold">
                    {{ formatNumber(gasSektorenChartOptions.series[index]) }}
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="12" md="6"></v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      v-if="$vuetify.breakpoint.smAndUp"
      cols="3"
      class="contact_container px-4"
    >
      <div class="my-5 mb-12">
        <h5 class="mb-2 text-uppercase">
          Ihr NEW Ansprechpartner
        </h5>
        <ContactPersonBox
          v-if="userCommunePartner"
          :key="userCommunePartner.id"
          :person="userCommunePartner"
          :verticalDesign="$vuetify.breakpoint.width < 1440"
        />
      </div>
      <template v-if="selectedCommune">
        <v-sheet class="pa-3 commune-stats-sheet">
          <h5 class="text-uppercase mb-3 font-weight-medium">
            {{ selectedCommune }} auf einen Blick
          </h5>

          <h5 class="font-weight-medium">Einwohner</h5>
          <h2 class="mb-3">{{ formatNumber(getEinwohnerCount) }}</h2>

          <h5 class="font-weight-medium">Gesamteinspeisung EE</h5>
          <h2 class="mb-3">{{ formatNumber(getTotalEinspeisung) }} MWh</h2>

          <h5 class="font-weight-medium">Gesamtverbrauch</h5>
          <h2 class="mb-3">{{ formatNumber(getGesamtVerbrauch) }} MWh</h2>

          <h5 class="font-weight-medium">Anteil EE</h5>
          <h2 class="mb-3">{{ getEinspeisequote }} %</h2>
        </v-sheet>

        <h5 class="mb-2 mt-8 text-uppercase">
          Aktuelle Fördermöglichkeiten
        </h5>

        <v-card
          v-for="service of getServices"
          :key="service.id"
          :to="service.link"
          class="mt-3 rounded-lg text-decoration-none"
          elevation="1"
        >
          <v-img :src="getImageUrl(service)" class="rounded-lg" />
          <v-card-title class="mt-2" style="color: #9c0047"
            >{{ service.title }}
          </v-card-title>
          <v-card-subtitle
            >{{ getTextPreview(service.content1, 20) }}
          </v-card-subtitle>
        </v-card>
      </template>
    </v-col>
  </v-row>
</template>

<script>
// import RightColumnGroup from "@/components/layout/RightColumnGroup.vue";
import { mapActions, mapGetters } from "vuex";
import ContactPersonBox from "@/components/layout/ContactPersonBox.vue";
// import ApexCharts from 'apexcharts';

export default {
  name: "Verbrauchsdaten",
  data() {
    return {
      stromStatus: false,
      gasStatus: false,
      energieTypen: [
        "PV-Anlagen",
        "Windenergie",
        "Geothermie",
        "Wasserkraft",
        "Biomasse",
        "Deponiegas, Klärgas, Grubengas"
      ],
      showFilter: false,
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      selectedCommune: undefined,
      selectedYears: [],
      selectedEnergietraeger: [],
      selectedSektoren: [],
      legendOptions: {
        show: false,
        position: "bottom"
      },
      stromColors: [
        "#9c0047",
        "#bf618c",
        "#cf91ae",
        "#dcb5c8",
        "#e5ceda",
        "#ece1e7"
      ],
      gasColors: ["#64b6da", "#64b6da", "#94cae3", "#c2deea"],
      stromDonutTheme: {
        mode: "light",
        monochrome: {
          enabled: true,
          color: "#934261", //'#9c0047',
          shadeTo: "light",
          shadeIntensity: 0.75
        }
      },
      gasDonutTheme: {
        mode: "light",
        monochrome: {
          enabled: true,
          color: "#749abf",
          shadeTo: "light",
          shadeIntensity: 0.75
        }
      },
      donutStroke: {
        width: 0
      }
    };
  },
  components: {
    ContactPersonBox
  },
  computed: {
    ...mapGetters("verbrauchsdaten", [
      "communeNames",
      "loaded",
      "verbrauchsdaten",
      "getYears",
      "getEnergietraeger",
      "getSektoren",
      "getVerbrauchPerTarif",
      "getEinspeisedaten",
      "getContactPerson",
      "getEinwohnerCount",
      "getServices",
      "getImportDate"
    ]),
    ...mapGetters("user", [
      "isNewEmployee",
      "enableVerbrauchsdaten",
      "userCommuneName",
      "userCommunePartner"
    ]),
    getEinspeisequote() {
      const gesamtVerbraucht = parseFloat(this.getGesamtVerbrauch);
      const gesamtEingespeist = parseFloat(this.getTotalEinspeisung);
      const einspeisequote = (gesamtEingespeist / gesamtVerbraucht) * 100;
      if (isNaN(einspeisequote)) return "0,00";
      return einspeisequote.toLocaleString("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },
    getTotalEinspeisung() {
      if (!this.getEinspeisedaten) return 0;

      let total = 0;
      for (const year of this.selectedYears) {
        const einspeisung = this.getEinspeisedaten[year];
        if (einspeisung) {
          for (const key of Object.keys(einspeisung)) {
            total += einspeisung[key];
          }
        }
      }

      return total;
    },
    showColumnChart() {
      return (
        this.selectedEnergietraeger.length > 0 &&
        this.selectedSektoren.length > 0 &&
        this.selectedYears.length > 0 &&
        this.selectedCommune &&
        this.loaded
      );
    },
    colSize() {
      if (this.$vuetify.breakpoint.smAndUp) return 9;
      else return 12;
    },
    communePartner() {
      return this.$store.getters["user/userCommunePartner"];
    },
    communeRegionalLeader() {
      return this.$store.getters["user/userCommuneRegionalLeader"];
    },
    aboutUs() {
      return {
        personsInCharge: [this.getContactPerson]
      };
    },
    stromSum() {
      if (!this.selectedEnergietraeger.includes("Strom")) return 0;

      let value = 0;

      for (const year of this.selectedYears) {
        value +=
          this.verbrauchsdaten[year].find(d => d.name === "Strom")?.sum ?? 0;
      }
      return value.toFixed(3).replaceAll(".", ",");
    },
    gasSum() {
      if (!this.selectedEnergietraeger.includes("Gas")) return 0;

      let value = 0;
      for (const year of this.selectedYears) {
        value +=
          this.verbrauchsdaten[year].find(d => d.name === "Gas")?.sum ?? 0;
      }

      return value.toFixed(3).replaceAll(".", ",");
    },
    columnChartOptions() {
      const baseColors = [];
      const legendsColors = [];
      if (this.gasStatus) {
        baseColors.push("#025399");
        legendsColors.push("#b2cbe0");
      }
      if (this.stromStatus) {
        baseColors.push("#b40055");
        legendsColors.push("#b40055");
      }

      const options = {
        chart: {
          type: "bar",
          stacked: false,
          toolbar: {
            tools: {
              download: false
            }
          }
        },
        plotOptions: {
          bar: {
            columnWidth: this.$vuetify.breakpoint.lgAndDown ? "25%" : "10%"
          }
        },
        stroke: {
          show: true,
          width: 10,
          colors: ["transparent"]
        },
        tooltip: {
          followCursor: true,
          intersect: false,
          shared: true,
          marker: {
            show: true,
            fillColors: legendsColors
          },
          y: {
            formatter: function(value) {
              return Math.ceil(value).toLocaleString("de-DE") + " MWh";
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              return Math.ceil(value).toLocaleString("de-DE") + " MWh";
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          markers: {
            fillColors: legendsColors
          },
          horizontalAlign: "left",
          fontSize: "16px",
          offsetX: 40
        },
        series: [],
        fill: {
          colors: baseColors,
          type: "gradient",
          gradient: {
            shade: "white",
            type: "vertical",
            shadeIntensity: 0,
            opacityFrom: 0.95,
            opacityTo: 0.45,
            stops: [0, 97]
          }
        }
      };

      for (const energietraeger of this.selectedEnergietraeger) {
        const data = [];
        for (const year of this.selectedYears) {
          const yearValue =
            this.verbrauchsdaten[year].find(d => d.name === energietraeger)
              ?.sum ?? 0;
          data.push({
            x: year,
            y: yearValue
          });
        }

        options.series.push({ name: energietraeger, data });
      }

      options.series.sort((a, b) => {
        if (a.name === "Gas") return -1;
        if (b.name === "Gas") return 1;
        return a.name.localeCompare(b.name);
      });

      return options;
    },
    stromSektorenChartOptions() {
      const sektorItems = [];
      for (const sektor of this.selectedSektoren) {
        const sektorItem = {
          name: sektor,
          value: 0
        };
        for (const year of this.selectedYears) {
          const stromItem = this.verbrauchsdaten[year].find(
            d => d.name === "Strom"
          );
          if (stromItem) sektorItem.value += stromItem[sektor];
        }

        sektorItems.push(sektorItem);
      }

      sektorItems.sort((a, b) => b.value - a.value);

      return {
        chart: {
          type: "donut"
        },
        stroke: this.donutStroke,
        series: sektorItems.map(i => i.value),
        labels: sektorItems.map(i => i.name),
        legend: this.legendOptions,
        dataLabels: {
          enabled: false
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              return Math.ceil(value).toLocaleString("de-DE") + " MWh";
            }
          }
        },
        theme: this.stromDonutTheme
      };
    },
    stromAnwendungenChartOptions() {
      const keys = [];

      for (const year of this.selectedYears) {
        keys.push(
          ...Object.keys(this.getVerbrauchPerTarif["Strom"][year] ?? {})
        );
      }
      const anwendungItems = [];

      const visibleKeys = [
        "Allgemeiner Bedarf",
        "Mittelspannung",
        "Mittelspannung Z 2,3%",
        "Umspannung MSP/NSP 12",
        "Niederspannung",
        "Wärmepumpe"
      ];

      const sonstige = {
        name: "Sonstige",
        value: 0
      };

      for (const key of [...new Set(keys)]) {
        if (!visibleKeys.includes(key)) {
          let value = 0;

          for (const year of this.selectedYears) {
            value += this.getVerbrauchPerTarif["Strom"][year][key] ?? 0;
          }

          sonstige.value = value;
        } else {
          const item = {
            name: key,
            value: 0
          };

          for (const year of this.selectedYears) {
            item.value += this.getVerbrauchPerTarif["Strom"][year][key] ?? 0;
          }
          anwendungItems.push(item);
        }
      }
      anwendungItems.sort((a, b) => b.value - a.value);
      anwendungItems.push(sonstige);

      return {
        chart: {
          type: "donut"
        },
        labels: anwendungItems.map(i => i.name),
        legend: this.legendOptions,
        dataLabels: {
          enabled: false
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              return Math.ceil(value).toLocaleString("de-DE") + " MWh";
            }
          }
        },
        stroke: this.donutStroke,
        theme: this.stromDonutTheme,
        series: anwendungItems.map(i => parseFloat(i.value.toFixed(3)))
      };
    },
    stromEinspeisungChartOptions() {
      let keys = [];
      const series = [];

      for (const year of this.selectedYears) {
        keys.push(...Object.keys(this.getEinspeisedaten[year] ?? {}));
      }

      // add missing keys
      keys = [...keys, ...this.energieTypen];

      const labels = [...new Set(keys)];
      for (const key of labels) {
        let value = 0;

        for (const year of this.selectedYears) {
          if (this.getEinspeisedaten[year])
            value += this.getEinspeisedaten[year][key] ?? 0;
        }
        series.push({ name: key, value });
      }

      series.sort((a, b) => b.value - a.value);

      return {
        chart: {
          type: "donut"
        },
        series: series.map(s => s.value),
        labels: series.map(s => {
          return s.name;
        }), //['Windenergie', 'Geothermie', 'Wasserkraft', 'PV-Anlagen', 'Deponiegas, Klärgas, Grubengas','Biomasse'],
        legend: this.legendOptions,
        dataLabels: {
          enabled: false
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              return Math.ceil(value).toLocaleString("de-DE") + " MWh";
            }
          }
        },
        stroke: this.donutStroke,
        theme: this.stromDonutTheme
      };
    },
    gasSektorenChartOptions() {
      const sektorItems = [];

      for (const sektor of this.selectedSektoren) {
        const sektorItem = {
          name: sektor,
          value: 0
        };

        for (const year of this.selectedYears) {
          const gasItem = this.verbrauchsdaten[year].find(
            d => d.name === "Gas"
          );
          if (gasItem) sektorItem.value += gasItem[sektor];
        }

        sektorItems.push(sektorItem);
      }

      sektorItems.sort((a, b) => b.value - a.value);

      return {
        chart: {
          type: "donut"
        },
        stroke: this.donutStroke,
        series: sektorItems.map(i => i.value),
        labels: sektorItems.map(i => i.name),
        legend: this.legendOptions,
        dataLabels: {
          enabled: false
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              return Math.ceil(value).toLocaleString("de-DE") + " MWh";
            }
          }
        },
        theme: this.gasDonutTheme
      };
    },
    gasDurchleitungenChartOptions() {
      const keys = [];

      for (const year of this.selectedYears) {
        keys.push(...Object.keys(this.getVerbrauchPerTarif["Gas"][year] ?? {}));
      }
      const durchleitungenItems = [];

      for (const key of [...new Set(keys)]) {
        const item = {
          name: key,
          value: 0
        };

        for (const year of this.selectedYears) {
          item.value += this.getVerbrauchPerTarif["Gas"][year][key] ?? 0;
        }

        durchleitungenItems.push(item);
      }

      durchleitungenItems.sort((a, b) => b.value - a.value);

      return {
        chart: {
          type: "donut"
        },
        stroke: this.donutStroke,
        series: durchleitungenItems.map(i => i.value),
        labels: durchleitungenItems.map(i => i.name),
        legend: this.legendOptions,
        dataLabels: {
          enabled: false
        },
        title: {
          text: "Durchleitungen",
          align: "center",
          style: {
            fontWeight: "bold",
            fontsize: "25px"
          }
        },
        theme: this.gasDonutTheme
      };
    },
    fallbackImg() {
      const image = require("@/assets/headerbild_image_1920x900.jpg");

      return image;
    },
    getGesamtVerbrauch() {
      return parseFloat(this.stromSum);
    },
    getVerbrauchProEinwohner() {
      if (this.getEinwohnerCount === 0) return 0;
      return this.formatNumber(
        this.getGesamtVerbrauch / this.getEinwohnerCount
      );
    }
  },
  methods: {
    ...mapActions("verbrauchsdaten", [
      "loadCommuneNames",
      "loadVerbrauchsdaten",
      "setSelectedYears",
      "setSelectedSektoren",
      "setSelectedEnergietraeger",
      "loadContactPerson",
      "loadCommuneData",
      "setCommuneNames"
    ]),
    getTotal(item) {
      const total = item.reduce((accumulator, value) => {
        return accumulator + value;
      }, 0);
      return this.formatNumber(total);
    },
    toggleDataPoint(refName, datapointIndex) {
      this.$refs[refName].toggleDataPointSelection(datapointIndex);
    },
    getTextPreview(text, wordCount) {
      const splits = text.split(" ");

      if (splits.length <= wordCount) return text;
      else return splits.slice(0, wordCount).join(" ") + "...";
    },
    async onCommuneChangeChange() {
      this.selectedYears = [];
      this.selectedEnergietraeger = [];
      this.selectedSektoren = [];

      await this.loadVerbrauchsdaten(this.selectedCommune);
      await this.loadCommuneData(this.selectedCommune);

      if (this.selectedCommune) {
        this.selectedYears = [...this.getYears];
        this.setSelectedYears(this.selectedYears);

        this.selectedEnergietraeger = this.getEnergietraeger;
        this.changeSelectedEnergietraeger(this.selectedEnergietraeger);

        this.selectedSektoren = this.getSektoren;
        this.setSelectedSektoren(this.selectedSektoren);

        this.$refs.columnChart.updateSeries(this.columnChartOptions.series);
      }
    },
    changeSelectedEnergietraeger(energietraeger) {
      this.setSelectedEnergietraeger(energietraeger);
      this.stromStatus = energietraeger.includes("Strom");
      this.gasStatus = energietraeger.includes("Gas");
    },
    getServiceAreaForServiceId(serviceId) {
      const serviceArea = this.$store.getters[
        "navigation/serviceAreas"
      ].find(servieArea =>
        servieArea.services.map(service => service.id).includes(serviceId)
      );

      return serviceArea;
    },
    getImageUrl(item) {
      if (item.images.length === 0) {
        const serviceId = item.service ?? item.id;
        const serviceArea = this.getServiceAreaForServiceId(serviceId);

        if (serviceArea.headerImage)
          return `${this.baseUrl}${serviceArea.headerImage.url}`;
        else return this.fallbackImg;
      }
      return "";
    },
    formatNumber(value) {
      const number = typeof value === "string" ? parseInt(value) : value;
      return Math.round(number).toLocaleString("de-DE");
    },
    async downloadFile(format) {
      const dataObject = await this.$refs.columnChart.dataURI();
      this.downloadBase64File(dataObject.imgURI, format);
    },
    downloadBase64File(img, format) {
      const ts = Date.now();
      const fileFormat = format.toLowerCase();
      const fileName = `verbrauchsdaten_${this.selectedCommune}_${ts}.${fileFormat}`;
      if (format === "PNG") {
        // download png file
        const link = document.createElement("a");
        link.href = img;
        link.download = fileName;
        link.click();
        link.remove();
      } else if (format === "CSV") {
        // download csv file
        let dataMap = {};
        const years = [
          ...new Set(
            this.columnChartOptions.series.flatMap(item =>
              item.data.map(d => d.x)
            )
          )
        ].sort();
        this.columnChartOptions.series.forEach(item => {
          item.data.forEach(d => {
            if (!dataMap[d.x]) {
              dataMap[d.x] = {};
            }
            dataMap[d.x][item.name] = d.y;
          });
        });

        let csv = `Verbrauchsdaten ${this.selectedCommune}\n\nGas (MWh);Strom (MWh);Jahr\n`;
        years.forEach(year => {
          const stromValue = dataMap[year]["Strom"] || 0;
          const gasValue = dataMap[year]["Gas"] || 0;
          csv += `${gasValue};${stromValue};${year}\n`;
        });

        const blob = new Blob([csv], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        link.remove();
      } else if (format === "SVG") {
        const mainChart = document.getElementById("main-chart");
        const { offsetWidth, offsetHeight } = mainChart;
        // download svg file
        const svgContent = `
            <svg xmlns="http://www.w3.org/2000/svg" width="${offsetWidth -
              80}" height="${offsetHeight - 80}">
               <image href="${img}" x="0" y="0" width="${offsetWidth -
          80}" height="${offsetHeight - 80}"/>
            </svg>
        `;
        const blob = new Blob([svgContent], { type: "text/svg" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        link.remove();
      }
    }
  },
  mounted() {
    if(!this.enableVerbrauchsdaten) {
      this.$router.back();
      return;
    }

    if (this.isNewEmployee) {
      this.loadCommuneNames();
    } else {
      this.setCommuneNames([this.userCommuneName]);

      this.selectedCommune = this.userCommuneName;
      this.onCommuneChangeChange();
    }

    this.loadContactPerson();
  }
};
</script>

<style scoped>
.verbrauchsdaten_container {
  padding: 0px !important;
}

.verbrauchsdaten_header {
  background-color: #faf2f6;
}

.icon-text {
  position: relative;
  top: 2px;
  left: 4px;
  font-weight: 400;
}

.back-commune {
  color: #b40055;
  cursor: pointer;
}

th,
td {
  padding: unset !important;
}

.commune-stats-sheet {
  background: #b40055;
  background: linear-gradient(255deg, #b40055 0%, rgba(56, 103, 161, 1) 100%);
  color: white;
}

.einspeisung-col {
  /*background: rgb(249, 242, 246);*/
}

.vd-card {
  max-width: 400px;
  margin: 0 auto;
}

.vd-chip {
  border-radius: 0px !important;
}

.einwohnerzahlen_container {
  position: absolute;
  top: -25px;
}

.apex-icon-position {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
