<script>
import { mapGetters } from "vuex";

export default {
  name: "NavigationDrawerWaermeRoutes",
  methods: {
    goToDashboard() {
      this.$emit("showSubMenuDrawer", null);
      if (this.$route.path !== "/home") this.$router.push({ name: "home" });
    }
  },
  computed: {
    ...mapGetters("user", ["enableWaermeMenu", "enableWaermeplanung"])
  }
};
</script>

<template>
  <v-list
    dense
    v-if="enableWaermeMenu"
    :class="$vuetify.breakpoint.mdAndDown ? 'mt-16' : ''"
  >
    <!-- 2ND ITEM (ZUM DASHBOARD NAVIGIEREN) -->
    <v-list-item class="py-1">
      <v-list-item-content>
        <v-btn @click.stop="goToDashboard" color="primary" small outlined block>
          <v-icon left small>mdi-arrow-left</v-icon>
          Zurück zum Dashboard
        </v-btn>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link class="text-decoration-none py-1">
      <v-list-item-icon>
        <v-icon class="new-icon-color">mdi-thermometer-lines</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="new-font-size-14">
        Wärme
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      to="/waermeplanung"
      active-class="bg-active"
      color="primary"
      link
      class="text-decoration-none py-1"
    >
      <v-list-item-icon />
      <v-list-item-content class="new-font-size-14">
        Kommunale Wärmeplanung
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      color="primary"
      to="/waermeplanung-faq"
      active-class="bg-active"
      link
      class="text-decoration-none py-1"
    >
      <v-list-item-icon />
      <v-list-item-content class="new-font-size-14">
        FAQs Wärmeplanung
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<style scoped></style>
