<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        background-color="#f6e4ed"
        dense
        v-if="date == null"
        v-model="date"
        :label="filterLabel"
        append-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        outlined
        clearable
        class="filter__input"
        @click:clear="clearMonthYear"
      ></v-text-field>

      <v-text-field
        background-color="#f6e4ed"
        dense
        v-else
        :label="filterLabel"
        :value="formatDate"
        append-icon="mdi-calendar"
        v-bind="attrs"
        v-on="on"
        clearable
        class="filter__input"
        @click:clear="clearMonthYear"
        outlined
      ></v-text-field>
    </template>

    <v-date-picker
      v-model="date"
      type="month"
      no-title
      @change="changeMonthYear"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "MonthYearPicker",
  data() {
    return {
      filterLabel: "Beiträge filtern",
      date: null,
      formatDate: null,
      menu: false,
      filterLabelButton: "Filtern"
    };
  },
  methods: {
    clearMonthYear() {
      this.date = null;
      this.$store.dispatch("news/orderMonthYear", null);
    },
    changeMonthYear(monthYear) {
      this.$refs.menu.save(monthYear);
      this.$store.dispatch("news/orderMonthYear", monthYear);
      this.menu = false;
      this.formatDate = this.createDateFormat(monthYear);
    },
    createDateFormat(monthYear) {
      const d = monthYear.split("-").reverse();
      const month = d[0].replace(/^0+/, "");
      const months = [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember"
      ];

      return `${months[month - 1]} ${d[1]}`;
    }
  },
  computed: {
    getResponsiveWidth() {
      let width;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          width = "100%";
          break;
        case "sm":
          width = "100%";
          break;
        case "md":
          width = "250px";
          break;
        case "lg":
          width = "250px";
          break;
        case "xl":
          width = "250px";
          break;
      }
      return width;
    }
  },
  mounted() {
    this.$store.dispatch("news/orderMonthYear", null);
  }
};
</script>

<style scoped>
.filter__input {
  position: relative;
  top: 25px;
}
</style>
