<script>
import {mapGetters} from "vuex";
import CommuneDashboardBadge from "@/components/ui/CommuneDashboard/CommuneDashboardBadge.vue";

export default {
  name: "QuartiersentwicklungCardContent",
  components: {CommuneDashboardBadge},
  computed: {
    ...mapGetters('communeDashboard', ['latestRequest']),
    ...mapGetters('navigation', ['getServiceByName']),
    quartiersentwicklungLink() {
      const service = this.getServiceByName('Quartiersentwicklung');

      return `/service-area/${service.serviceArea.id}/service/${service.id}`
    },
    requestDate() {
      if(!this.latestRequest) return '';

      return new Date(this.latestRequest.created_at).toLocaleDateString();
    }
  }
}
</script>

<template>
<v-card width="100%">
  <v-card-title>Quartiersentwicklung</v-card-title>
  <v-card-text class="commune-dashboard-min-height">
    <template v-if="latestRequest">
      <commune-dashboard-badge text="ANGEFRAGT" class="green" />
      <p class="font-weight-bold commune-dashboard-card-text mb-0">
        {{ requestDate }}<br>
        Neue Anfrage eingegangen
      </p>
    </template>
    <p v-else class="font-weight-bold">
      Es liegen keine Anfragen über das Kommunalportal vor
    </p>
  </v-card-text>
  <v-card-actions>
    <v-btn tile text color="primary" block :to="quartiersentwicklungLink" link class="font-weight-bold commune-dashboard-dotted-border commune-dashboard-card-btn">
      Zur Quartiersentwicklung
      <v-icon right>mdi-arrow-right</v-icon>
    </v-btn>
  </v-card-actions>
</v-card>
</template>

<style scoped>

</style>
