import RestMethods from "@/store/RestMethods";
// import { _sortBy } from '@/utils/sortings'

const SUB_SERVICES_MODEL = RestMethods.init("sub-services");
const SUB_SERVICE_CACHE_SECS = 10 * 60; // 10 mins

export default {
  async fetchSubServices({ commit }) {
    if (
      !SUB_SERVICES_MODEL.isCacheExpired(
        "fetchSubServices",
        SUB_SERVICE_CACHE_SECS
      )
    ) {
      return;
    }

    const subServices = await SUB_SERVICES_MODEL.find();

    //todo: handle failed request(s)

    if (subServices) {
      SUB_SERVICES_MODEL.resetCacheTimeout("fetchSubServices");
      commit("SET_SUB_SERVICES", subServices.data);
    }
  },
};
