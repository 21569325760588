<script>
import CommuneMap from "@/components/ui/CommuneDashboard/CommuneMap.vue";
import OverviewCard from "@/components/ui/CommuneDashboard/OverviewCard.vue";
import MayorCard from "@/components/ui/CommuneDashboard/MayorCard.vue";
import KonzessionsThemenCard from "@/components/ui/CommuneDashboard/KonzessionsThemenCard.vue";
import QuartiersentwicklungCard from "@/components/ui/CommuneDashboard/QuartiersentwicklungCard.vue";
import ContractCard from "@/components/ui/CommuneDashboard/ContractCard.vue";
import {mapActions, mapGetters} from "vuex";
import SelectCommuneCard from "@/components/ui/CommuneDashboard/SelectCommuneCard.vue";

export default {
  name: "CommuneDashboard",
  components: {
    SelectCommuneCard,
    ContractCard,
    QuartiersentwicklungCard,
    KonzessionsThemenCard,
    MayorCard,
    OverviewCard,
    CommuneMap
  },
  data() {
    return {
      maxHeight: 250,
      cardSize: {
        maxWidth: 450,
        height: 250,
        width: 300
      },
      selectedCommuneModel: undefined,
    }
  },
  computed: {
    ...mapGetters('communeDashboard', ['communeNames', 'showCards', 'selectedCommune']),
  },
  methods: {
    ...mapActions('communeDashboard', ['setSelectedCommune']),
    onCommuneChange() {
      this.setSelectedCommune(this.selectedCommuneModel)
    }
  },
  watch: {
    selectedCommune: {
      handler: function (newValue) {
        this.selectedCommuneModel = newValue;
      }
    }
  }
}
</script>

<template>
  <div class="pa-4 commune-dashboard-view">
    <v-row style="max-width: 1200px">
      <v-col cols="12" md="8" style="display: flex; flex-direction: column; justify-content: center">
        <div class="custom-header-text new-font-bold">Kommunenübersicht</div>
      </v-col>
      <v-col cols="12" md="4">
        <v-select :items="communeNames" v-model="selectedCommuneModel" filled label="Kommune" single-line hide-details @input="onCommuneChange" />
      </v-col>
      <v-col cols="12" md="8" style="">
        <commune-map />
      </v-col>
      <v-col cols="12" md="4">
          <overview-card v-if="showCards" />
          <mayor-card v-if="showCards" style="margin-top: 10px" />
          <select-commune-card v-else />
      </v-col>
      <v-col cols="12" md="4">
          <contract-card v-if="showCards" />
      </v-col>
      <v-col cols="12" md="4">
        <quartiersentwicklung-card v-if="showCards" />
      </v-col>
      <v-col cols="12" md="4">
          <konzessions-themen-card v-if="showCards" />
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.commune-dashboard-view {
  background-color: #f2f2f3 !important;
  min-height: calc(100vh - 64px);
}
</style>
