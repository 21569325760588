<template>
  <v-list  dense :class="$vuetify.breakpoint.mdAndDown ? 'mt-16' : ''">

    <v-list-item v-if="$vuetify.breakpoint.width < 1264">
      <div id="loginnew-dropdown"></div>
    </v-list-item>
    <!-- OBERSTES ITEM (LOGO) -->
    <template v-if="$vuetify.breakpoint.width < 1264">
    <v-list-item class="py-2" v-for="partner of getDashboardCommunePartners" :key="partner.id">
      <commune-partner-card width="100%" :user-commune-partner="partner" />
    </v-list-item>
    </template>
    <v-list-item
      v-for="item in items"
      :key="item.route"
      :to="{ name: 'service-area', params: { serviceAreaId: `${item.id}` } }"
      link
      color="primary"
      class="text-decoration-none py-1"
    >
      <v-list-item-icon>
        <v-icon class="new-icon-color">{{ checkIconName(item) }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content class="new-font-size-14">
        {{ item.title }}
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="menuItems.serviceAreaItems.length > 9"
      @click.stop="areItemsHidden = !areItemsHidden"
      class="py-1"
      style="color: #9c0047 !important;"
    >
      <v-list-item-icon>
        <v-icon color="primary">{{ chevronIconName }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content class="new-font-size-14">
        {{ areItemsHidden ? "Alle zeigen" : "Weniger zeigen" }}
      </v-list-item-content>
    </v-list-item>

    <v-subheader class="new-icon-color ml-3 mt-5 new-font-bold">
      Fachbereiche
    </v-subheader>

    <v-list-item
      active-class="bg-active"
      link
      @click="() => onSubMenuClick('NavigationDrawerCommuneRoutes')"
      class="text-decoration-none py-1"
      v-if="isNewEmployee"
    >
      <v-list-item-icon class="icon">
        <v-icon class="new-icon-color">$commune</v-icon>
      </v-list-item-icon>

      <v-list-item-content class="new-font-size-14">
        Kommunen
      </v-list-item-content>
    </v-list-item>

    <v-list-item link class="text-decoration-none pz-1" color="primary" @click="showNotificationsList" to="" v-if="$vuetify.breakpoint.width < 1264">
      <v-list-item-icon>
        <v-icon>mdi-email-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="new-font-size-14">
        Benachrichtigungen
      </v-list-item-content>
    </v-list-item>

    <template v-for="item in mainMenuItems">
      <v-list-item
        active-class="bg-active"
        :key="item.route"
        :to="{ name: item.route }"
        link
        class="text-decoration-none py-1"
        v-if="showFaqItem(item)"
        color="primary"
      >
        <v-list-item-icon class="icon">
          <v-icon class="new-icon-color">{{ checkIconName(item) }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content class="new-font-size-14">
          {{ item.name }}
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import { checkIconName } from '@/mixins/checkIconName'
import {mapActions, mapGetters} from "vuex";
import CommunePartnerCard from "@/components/ui/CommunePartnerCard.vue";
export default {
  name: 'NavigationDrawerMainRoutes',
  components: {CommunePartnerCard},
  mixins: [checkIconName],
  data() {
    return {
      areItemsHidden: true
    };
  },

  computed: {
    ...mapGetters("user", ["isNewEmployee", "enableWaermeMenu", "getDashboardCommunePartners"]),
    menuItems () {
      return this.$store.getters['navigation/getMainNavObject']
    },
    mainMenuItems() {
      return this.$store.getters["navigation/getGeneralNavItems"];
    },
    initialServiceAreaItems() {
      return this.menuItems.serviceAreaItems.slice(0, 9);
    },
    allServiceAreaItems() {
      return this.menuItems.serviceAreaItems;
    },
    items() {
      if (this.areItemsHidden) {
        return this.initialServiceAreaItems;
      } else {
        return this.allServiceAreaItems;
      }
    },
    chevronIconName() {
      return this.areItemsHidden ? "mdi-chevron-down" : "mdi-chevron-up";
    }
  },
  methods: {
    ...mapActions("notifications", ["showNotificationsList"]),
    showFaqItem(item) {
      if (item.route !== "faq") return true;

      return this.$store.getters["user/getFAQ"].length > 0;
    },
    onCommuneItemClick() {
      this.$emit("showCommuneDrawer");
    },
    onSubMenuClick(menuName) {
      this.$emit("showSubMenuDrawer", menuName);
    }
  }
};
</script>

<style>
.icon:hover {
  text-decoration-color: transparent !important;
  text-decoration-thickness: unset !important;
}
</style>
