<template>
  <v-row no-gutters class="min-height-100 new-wrapper" v-if="subService">
    <!-- MAIN CONTENT -->
    <v-col cols="12" v-if="subService.title === 'NEW Erleben'">
      <MomentsView />
    </v-col>
    <template v-else>
    <v-col :cols="colSize" class="pa-10">
      <v-row>
        <v-col align="left" cols="12">
          <span class="custom-header-text new-font-bold">
            {{ subService.title }}
          </span>
        </v-col>
      </v-row>

      <!-- TEXT SECTION 1 -->
      <v-row no-gutters class="mt-5">
        <vue-markdown-it
          v-if="subService.content1"
          :source="subService.content1"
          id="content1"
        />
      </v-row>

      <template v-if="subService.title === 'NEW-Vereinsförderung'">
        <v-expansion-panels accordion>
          <v-expansion-panel v-for="(item, i) of faqs" :key="i">
            <v-expansion-panel-header class="expansion-panel-header-custom">
              {{ item.title }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p v-html="item.content"></p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>

      <!-- CAROUSEL OR IMAGE-->
      <template v-if="images">
        <v-row v-if="images.length > 1" no-gutters class="mt-6 new-iframe">
          <Carousel :slides="images" />
        </v-row>
        <v-row v-else-if="images.length == 1" class="px-3 mt-6">
          <v-img class="new-iframe" :src="`${baseUrl}${images[0].url}`"></v-img>
          <p v-if="images[0].caption" class="text-caption">
            {{ images[0].caption }}
          </p>
        </v-row>
      </template>

      <!-- TEXT SECTION 2 -->
      <v-row no-gutters class="mt-5">
        <vue-markdown-it
          v-if="subService.content2"
          :source="subService.content2"
          id="content2"
        />
      </v-row>



      <RightColumnGroup
        v-if="$vuetify.breakpoint.xs"
        :communePartner="communePartner"
        :communeRegionalLeader="communeRegionalLeader"
        :contentObject="subService"
      />
    </v-col>

    <!-- RIGHT SIDE CONTENT - ANSPRECHPARTNER, DOWNLOADS, INTERESSANTES -->
    <v-col
      v-if="$vuetify.breakpoint.smAndUp"
      cols="4"
      class="contact_container pl-4"
    >
      <RightColumnGroup
        :communePartner="communePartner"
        :communeRegionalLeader="communeRegionalLeader"
        :contentObject="subService"
      />
    </v-col>
    </template>
  </v-row>
</template>

<script>
import { checkIconName } from "@/mixins/checkIconName";
import Carousel from "@/components/layout/Carousel.vue";
import RightColumnGroup from "@/components/layout/RightColumnGroup.vue";
import VueMarkdownIt from "vue-markdown-it";
import { mapGetters } from "vuex";
import faqs from "@/utils/foerderungFaqs";
import MomentsView from "@/views/MomentsView.vue";

export default {
  name: "SubServiceDetail",
  components: {
    MomentsView,
    Carousel,
    RightColumnGroup,
    VueMarkdownIt
  },
  mixins: [checkIconName],

  data: () => ({
    baseUrl: process.env.VUE_APP_API_BASE_URL,
    faqs,
  }),
  methods: {
    getCommuneSubServiceUrl(id) {
      return `/service-area/${this.subService.service.serviceArea}/service/${this.subService.service.id}/sub-service/${id}`;
    }
  },
  computed: {
    ...mapGetters("navigation", ["getMainNavObject"]),
    colSize() {
      if (this.$vuetify.breakpoint.smAndUp) return 8;
      else return 12;
    },
    images() {
      return this.subService.images;
    },
    serviceAreaId() {
      return Number(this.$route.params.serviceAreaId);
    },
    serviceId() {
      return Number(this.$route.params.serviceId);
    },
    subServiceId() {
      return Number(this.$route.params.subServiceId);
    },

    subService() {
      const serviceArea = this.getMainNavObject.serviceAreaItems.find(
        s => s.id === this.serviceAreaId
      );
      const service = serviceArea.services.find(s => s.id === this.serviceId);
      return service.subServices.find(s => s.id === this.subServiceId);
    },
    communePartner() {
      return this.$store.getters["user/userCommunePartner"];
    },
    communeRegionalLeader() {
      return this.$store.getters["user/userCommuneRegionalLeader"];
    },
    isNewEmployee() {
      return this.$store.getters["user/isNewEmployee"];
    },
    communeSubServices() {
      return this.isNewEmployee
        ? this.$store.getters["serviceOverview/getCommunesForSubService"](
            this.subService.title
          )
        : [];
    }
  }
};
</script>
