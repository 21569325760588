<template>
  <!-- Slider main container -->
  <div class="swiper-container">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
      <!-- Slides -->
      <div class="swiper-slide" v-for="(slide, index) in slides" :key="index">
        <v-dialog v-model="dialog[index]" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-avatar
              tile="tile"
              size="85%"
              class="mt-3 ml-6"
              v-bind="attrs"
              v-on="on"
            >
              <v-img :src="`${baseUrl}${slide.url}`"></v-img>
            </v-avatar>
          </template>

          <v-card>
            <v-img :src="`${baseUrl}${slide.url}`"></v-img>
          </v-card>
        </v-dialog>
      </div>
    </div>

    <!-- Additional Option: pagination -->
    <div class="swiper-pagination"></div>

    <!-- Additional Option: navigation buttons -->
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/swiper-bundle.css";

// core version + navigation, pagination modules:
import SwiperCore, { Navigation, Pagination } from "swiper/core";

// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination]);

export default {
  name: "Carousel",
  props: ["slides"],
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      dialog: []
    };
  },
  mounted() {
    /* eslint-disable no-unused-vars */
    const swiper = new Swiper(".swiper-container", {
      // Optional parameters
      direction: "horizontal",
      loop: false,
      // Responsive breakpoints
      // using "ratio" endpoints
      breakpoints: {
        "@0.75": {
          slidesPerView: 2,
          spaceBetween: 10
        },
        "@1.00": {
          slidesPerView: this.slides.length > 2 ? 3 : 2, //3,
          spaceBetween: 10
        },
        "@1.50": {
          slidesPerView: this.slides.length > 2 ? 3 : 2, //3,
          spaceBetween: 10
        },
        "@2.00": {
          slidesPerView:
            this.slides.length > 3 ? 4 : this.slides.length > 2 ? 3 : 2, //4,
          spaceBetween: 10
        }
      },
      // pagination
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true
      },

      // navigation buttons
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    });
  }
};
</script>

<style>
:root {
  --swiper-navigation-size: 24px;
  --swiper-navigation-color: #b40055;
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background: #b40055;
}
.swiper-container {
  background-color: #f4f6f9;
  width: 100%;
  height: 35vh;
}
.swiper-button-prev {
  position: absolute;
  width: 30vw;
  top: 93%;
}
.swiper-button-next {
  position: absolute;
  width: 30vw;
  top: 93%;
}
</style>
