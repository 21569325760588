<template>
  <v-row class="new-wrapper min-height-100" no-gutters>
    <v-col :cols="colSize" class="pa-10">
      <div class="caption mb-3">
        <span>veröffentlicht am: {{ updatedAt }} </span>
      </div>
      <div class="custom-header-text new-font-bold mb-10">
        {{ entry.title }}
      </div>
      <p class="font-weight-bold">{{ entry.subtitle }}</p>
      <div v-html="htmlDoc.body.innerHTML"></div>
      <div class="mt-10">
        <v-img class="new-iframe" :src="entry.img_url" />
      </div>
      <RightColumnGroup
        v-if="$vuetify.breakpoint.xs"
        :communePartner="communePartner"
        :communeRegionalLeader="communeRegionalLeader"
        :contentObject="entry"
      />
    </v-col>

    <!-- RIGHT SIDE CONTENT - ANSPRECHPARTNER, DOWNLOADS, INTERESSANTES -->
    <v-col
      v-if="$vuetify.breakpoint.smAndUp"
      cols="4"
      class="contact_container pl-4"
    >
      <RightColumnGroup
        :communePartner="communePartner"
        :communeRegionalLeader="communeRegionalLeader"
        :contentObject="entry"
      />
    </v-col>
  </v-row>
</template>

<script>
import RightColumnGroup from "@/components/layout/RightColumnGroup.vue";
import moment from "moment";

export default {
  components: {
    RightColumnGroup
  },
  data() {
    return {};
  },
  methods: {
    getNewsPostById(pressId) {
      return this.$store.getters["news/pressById"](pressId);
    }
  },
  computed: {
    colSize() {
      if (this.$vuetify.breakpoint.smAndUp) return 8;
      else return 12;
    },
    updatedAt() {
      return moment(this.entry.pub_date).format("DD.MM.YYYY");
    },
    communePartner() {
      return this.$store.getters["user/userCommunePartner"];
    },
    entry() {
      var pressId = this.$route.params.pressId;
      return this.$store.getters["news/pressById"](pressId);
    },
    htmlDoc() {
      var parser = new DOMParser();

      var htmlstr = this.entry.content.replace(/<p>&nbsp;/g, "");
      return parser.parseFromString(htmlstr, "text/html");
    },
    communeRegionalLeader() {
      return this.$store.getters["user/userCommuneRegionalLeader"];
    }
  }
};
</script>
