<template>
  <v-list
    dense
    v-if="services"
    :class="$vuetify.breakpoint.mdAndDown ? 'mt-16' : ''"
  >
    <v-list-item v-if="$vuetify.breakpoint.width < 1264">
      <commune-partner-card width="100%"/>
    </v-list-item>

    <!-- 2ND ITEM (ZUM DASHBOARD NAVIGIEREN) -->
    <v-list-item class="py-1 px-5">
      <v-list-item-content class="new-font-size-14">
        <v-btn @click.stop="goToDashboard" block outlined small color="primary">
          <v-icon left small>mdi-arrow-left</v-icon>
          Zurück zum Dashboard
        </v-btn>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      :to="{
        name: 'service-area',
        params: { serviceAreaId: `${serviceAreaId}` }
      }"
      link
      color="primary"
      class="text-decoration-none py-1"
    >
      <v-list-item-icon>
        <v-icon class="new-icon-color">{{
          checkIconName(serviceAreaByServiceAreaId)
        }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <span class="pl-2">
          {{ serviceAreaByServiceAreaId.title }}
        </span>
      </v-list-item-content>
    </v-list-item>

    <template v-for="service of services">
      <v-list-item
        :key="'service-' + service.id"
        :to="{ name: 'service', params: { serviceId: `${service.id}` } }"
        link
        color="primary"
        class="text-decoration-none py-1 pl-14"
        v-if="!isWaermeplanungService(service) || enableWaermeplanung"
      >
        <v-list-item-content class="new-font-size-14">
          {{ service.title }}
        </v-list-item-content>
      </v-list-item>

      <template v-if="service.length !== 0">
        <v-list-item
          v-for="subService of getSubServicesForRole(service)"
          :key="subService.id"
          :to="{
            name: 'sub-service',
            params: {
              serviceId: `${service.id}`,
              subServiceId: `${subService.id}`
            }
          }"
          link
          color="primary"
          class="text-decoration-none py-1 pl-14"
        >
          <v-list-item-content
            style="padding-left:24px"
            class="new-font-size-14"
          >
            {{ subService.title }}
          </v-list-item-content>
        </v-list-item>
      </template>
    </template>
  </v-list>
</template>

<script>
import { checkIconName } from '@/mixins/checkIconName'
import {mapGetters} from "vuex";
import CommunePartnerCard from "@/components/ui/CommunePartnerCard.vue";
export default {
  name: 'NavigationDrawerSubRoutes',
  components: {CommunePartnerCard},
  mixins: [checkIconName],
  data: function() {
    return {};
  },

  methods: {
    goToDashboard() {
      this.$router.push({ name: "home" });
    },
    sortAlphabetical(item1, item2) {
      return item1.title.localeCompare(item2.title);
    },
    isWaermeplanungService(service) {
      const waermeplanungServices = ['Kommunale Wärmeplanung', 'FAQs Wärmeplanung'];
      return waermeplanungServices.includes(service.title);
    },
    getSubServicesForRole(service) {
      if(this.enableMoments) return service.subServices;
      return service.subServices.filter(s => s.title !== "NEW Erleben");
    }
  },

  computed: {
    ...mapGetters('user', ['enableWaermeplanung', 'enableMoments']),
    serviceAreaId() {
      return Number(this.$route.params.serviceAreaId);
    },

    menuItems() {
      return this.$store.getters["navigation/getMainNavObject"];
    },

    serviceAreaByServiceAreaId() {
      return this.menuItems.serviceAreaItems.filter(
        s => s.id == this.serviceAreaId
      )[0];
    },

    services() {
      const sortedServices = this.serviceAreaByServiceAreaId.services;
      sortedServices.sort(this.sortAlphabetical);
      sortedServices.forEach(service =>

        service.subServices.sort(this.sortAlphabetical)
      );

      if (!this.enableMoments) {
        return sortedServices.filter(service => service.title !== 'NEW Erleben');
      }
      return sortedServices;
    }
  },
};
</script>

<style></style>
