<template>
<v-list dense v-if="isNewEmployee" :class="$vuetify.breakpoint.mdAndDown ? 'mt-16' : ''">

  <!-- 2ND ITEM (ZUM DASHBOARD NAVIGIEREN) -->
  <v-list-item>
    <v-list-item-content>
      <v-btn
          @click.stop="goToDashboard"
          color="primary"
          small
          outlined>
        <v-icon class="mr-2">mdi-arrow-left</v-icon>
        Zurück zum Dashboard
      </v-btn>
    </v-list-item-content>
  </v-list-item>
  <template v-if="$vuetify.breakpoint.width < 1264">
    <v-list-item v-for="partner of getDashboardCommunePartners" :key="partner.id">
      <commune-partner-card width="100%" :user-commune-partner="partner" />
    </v-list-item>
  </template>
    <v-list-item link class="text-decoration-none py-1">
      <v-list-item-icon>
        <v-icon class="new-icon-color">$commune</v-icon>
      </v-list-item-icon>

      <v-list-item-content class="new-font-size-14">
        Kommunen
      </v-list-item-content>
    </v-list-item>
  <v-list-item to="/commune-dashboard" color="primary" link class="text-decoration-none py-1" v-if="enableKommunenDashboard">
    <v-list-item-icon />
    <v-list-item-content class="new-font-size-14">
      Dashboard
    </v-list-item-content>
  </v-list-item>
    <!--<v-list-item
      to="/kommunalrabatt"
      color="primary"
      link
      v-if="enableKommunalrabatt && isDevSystem"
      class="text-decoration-none py-1"
    >
      <v-list-item-icon />
      <v-list-item-content class="new-font-size-14">
        Kommunalrabatt
      </v-list-item-content>
    </v-list-item>-->
    <v-list-item
      to="/vertragsuebersicht"
      color="primary"
      link
      class="text-decoration-none py-1"
      v-if="enableVertragsuebersicht"
    >
      <v-list-item-icon />
      <v-list-item-content class="new-font-size-14">
        Vertragsübersicht
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      to="/konzessionsthemen"
      color="primary"
      link
      v-if="enableKonzessionssachstaende"
      class="text-decoration-none py-1"
    >
      <v-list-item-icon />
      <v-list-item-content class="new-font-size-14">
        Konzessionsthemen
      </v-list-item-content>
    </v-list-item>
    <v-list-item
        v-if="enableVerbrauchsdaten"
      to="/verbrauchsdaten"
      color="primary"
      link
      class="text-decoration-none py-1"
    >
      <v-list-item-icon />
      <v-list-item-content class="new-font-size-14">
        Verbrauchsdaten
      </v-list-item-content>
    </v-list-item>
  </v-list>
  <v-list v-else></v-list>
</template>

<script>
import {mapGetters} from "vuex";
import CommunePartnerCard from "@/components/ui/CommunePartnerCard.vue";

export default {
  name: "NavigationDrawerCommuneRoutes",
  components: {CommunePartnerCard},
  computed: {
    ...mapGetters("user", [
      "isNewEmployee",
      "enableKommunalrabatt",
      "enableKonzessionssachstaende",
      "enableVertragsuebersicht",
      "enableVerbrauchsdaten",
      "enableKommunenDashboard",
        "enableTestate",
        "getDashboardCommunePartners"
    ]),
    isDevSystem: () => process.env.NODE_ENV !== "production",
  },
  methods: {
    goToDashboard() {
      this.$emit("showSubMenuDrawer", null);
      if (this.$route.path !== "/home") this.$router.push({ name: "home" });
    }
  }
};
</script>
