<script>
export default {
  name: "ThumbsUpIcon"
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="31" height="29.994" viewBox="0 0 31 29.994">
    <g id="Impuls-icon-rgb-gefaellt-mir-3" transform="translate(-2 -2.049)">
      <path id="Pfad_3100" data-name="Pfad 3100" d="M10.757,32.773H2V11.506h8.757ZM3.251,31.522H9.506V12.757H3.251ZM13.259,15.259h-2.5V14.008h2.5c1.977,0,3.522-1.783,3.522-4.066V4H18.9c3.7,0,4.278,3.54,4.848,6.968a28.714,28.714,0,0,0,.782,3.715l-1.195.375a28.553,28.553,0,0,1-.826-3.884C21.9,7.5,21.4,5.257,18.9,5.257h-.869V9.949c0,2.984-2.1,5.317-4.773,5.317ZM30.5,23.391H25.131a2.5,2.5,0,0,1-2.5-2.5v-.626a2.5,2.5,0,0,1,2.5-2.5H30.5a2.5,2.5,0,0,1,2.5,2.5v.626a2.5,2.5,0,0,1-2.5,2.5Zm-5.367-4.379a1.255,1.255,0,0,0-1.251,1.251v.626a1.255,1.255,0,0,0,1.251,1.251H30.5a1.255,1.255,0,0,0,1.251-1.251v-.626A1.255,1.255,0,0,0,30.5,19.012Z" transform="translate(0 -0.731)" fill="#fff"/>
      <path id="Pfad_3101" data-name="Pfad 3101" d="M42.849,24.63H37.482a2.5,2.5,0,0,1-2.5-2.5V21.5a2.5,2.5,0,0,1,2.5-2.5h5.367a2.5,2.5,0,0,1,2.5,2.5v.626A2.5,2.5,0,0,1,42.849,24.63Zm-5.367-4.379A1.255,1.255,0,0,0,36.231,21.5v.626a1.255,1.255,0,0,0,1.251,1.251h5.367A1.255,1.255,0,0,0,44.1,22.128V21.5a1.255,1.255,0,0,0-1.251-1.251Zm5.367,13.136H37.482a2.5,2.5,0,0,1-2.5-2.5v-.625a2.5,2.5,0,0,1,2.5-2.5h5.367a2.5,2.5,0,0,1,2.5,2.5v.625A2.5,2.5,0,0,1,42.849,33.387Zm-5.367-4.379a1.255,1.255,0,0,0-1.251,1.251v.625a1.255,1.255,0,0,0,1.251,1.251h5.367A1.255,1.255,0,0,0,44.1,30.885v-.625a1.255,1.255,0,0,0-1.251-1.251Z" transform="translate(-12.351 -6.348)" fill="#fff"/>
      <path id="Pfad_3102" data-name="Pfad 3102" d="M41.06,45.63H37.482a2.5,2.5,0,0,1-2.5-2.5V42.5a2.5,2.5,0,0,1,2.5-2.5H41.06a2.5,2.5,0,0,1,2.5,2.5v.626A2.5,2.5,0,0,1,41.06,45.63Zm-3.578-4.379A1.255,1.255,0,0,0,36.231,42.5v.626a1.255,1.255,0,0,0,1.251,1.251H41.06a1.255,1.255,0,0,0,1.251-1.251V42.5a1.255,1.255,0,0,0-1.251-1.251Z" transform="translate(-12.351 -14.213)" fill="#fff"/>
      <path id="Pfad_3103" data-name="Pfad 3103" d="M23.026,31.417c-1.032,0-5.673-.125-7.856-2.258A3.722,3.722,0,0,1,14,26.407h1.251a2.458,2.458,0,0,0,.794,1.851c2.045,2,7.112,1.9,7.168,1.9h6.43V31.4H23.032Zm-2.571-7.694a3.857,3.857,0,0,1-2.027-.575.628.628,0,1,1,.657-1.07,2.615,2.615,0,0,0,2.145.269,2.6,2.6,0,0,0,1.7-3.259.623.623,0,1,1,1.188-.375,3.847,3.847,0,0,1-3.678,5ZM29.638,7.029a.781.781,0,0,1-.2-.031.625.625,0,0,1-.394-.794l1.251-3.759a.625.625,0,0,1,1.182.4L30.226,6.6a.623.623,0,0,1-.594.425Zm1.251,2.5a.643.643,0,0,1-.375-.125.627.627,0,0,1-.125-.876l1.877-2.508a.626.626,0,1,1,1,.751L31.389,9.28a.634.634,0,0,1-.5.25Z" transform="translate(-4.494)" fill="#fff"/>
    </g>
  </svg>
</template>
