<template>
  <v-card class="rounded-lg mb-4 elevation-3">
    <v-card-title class="pa-0 mb-3" v-if="item.Bild">
      <v-img :src="getImageUrl(item)" class="image__custom"></v-img>
    </v-card-title>
    <v-card-text style="color: #000000 !important">
      <v-chip :class="getStatusClass(getItemStatus(item).status)" small>
        {{ getItemStatus(item).text }}
      </v-chip>
      <div class="new-font-bold mt-2">
        {{ item.Titel }}
      </div>
      <div class="mb-3">
        <vue-markdown-it v-if="expanded" :source="item.Beschreibung"></vue-markdown-it>
        <vue-markdown-it v-else :source="previewText"></vue-markdown-it>
        <v-btn text small block color="primary" @click="expanded = !expanded" v-if="previewText != item.Beschreibung">
          {{ expanded ? "Weniger" : "Mehr" }}
        </v-btn>
      </div>

      <MomentsModal
        :title="item.Titel"
        :id="item.id"
        :status="getItemStatus(item).status"
      ></MomentsModal>
    </v-card-text>
  </v-card>
</template>

<script>
import MomentsModal from "@/components/ui/Moments/MomentsModal.vue";
import VueMarkdownIt from "vue-markdown-it";
import moment from "moment";
export default {
  name: "MomentsCard",
  components: { MomentsModal, VueMarkdownIt },
  props: ["item"],
  data() {
    return {
      expanded: false,
      baseUrl: process.env.VUE_APP_API_BASE_URL
    };
  },
  methods: {
    getImageUrl(item) {
      return `${this.baseUrl}${item.Bild.url}`;
    },
    onShowMoreClick(id) {
      if (this.expandedCards.includes(id)) {
        this.expandedCards = this.expandedCards.filter((item) => item !== id);
      } else {
        this.expandedCards.push(id)
      }
    },
    getStatusClass(status) {
      switch (status) {
        case "available_at":
          return "rounded-0 text-uppercase new-font-bold primary-badge pa-2";
        case "expired":
          return "rounded-0 text-uppercase new-font-bold pa-2";
        case "available":
          return "rounded-0 text-uppercase new-font-bold primary-badge pa-2";
        default:
          return "";
      }
    },
    getItemStatus(item) {
      const currentDate = moment();
      const startDate = moment(item.Startdatum);
      const endDate = moment(item.Enddatum);

      if (
        startDate.isSameOrBefore(currentDate) &&
        endDate.isSameOrAfter(currentDate)
      ) {
        return {
          status: "available",
          text: "Verfügbar"
        };
      } else if (startDate.isAfter(currentDate)) {
        return {
          status: "available_at",
          text: `Verfügbar ab ${startDate.format("DD.MM.YYYY")}`
        };
      } else {
        return {
          status: "expired",
          text: "Abgelaufen"
        };
      }
    }
  },
  computed: {
    previewText() {
      const words = this.item.Beschreibung.split(" ");
      if (words.length <= 15) return words.join(" ");
      return  words.slice(0, 15).join(" ") + "..."
    }
  }
};
</script>

<style scoped>
.image__custom {
  height: 180px;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}
.available__custom {
  background-color: #cce7db !important;
  color: #02884d;
}
.expired__custom {
  background-color: #faccd4 !important;
  color: #eb3a59;
}

.primary-badge {
  background-color: rgb(246,225,235) !important;
  color: rgb(187,44,107) !important;
}
</style>
