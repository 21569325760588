<template>
  <v-row justify="center">
    <v-col cols="12" md="8" class="text-center">
      <v-card class="pa-9">
        <h2 class="text--secondary font-weight-light custom-header-text">
          <v-icon style="font-size: 1em; vertical-align: top">
            mdi-account-clock
          </v-icon>
          {{ !showForm ? 'Konto-Freischaltung läuft...' : 'Für Ihr Kommunalportal-Konto fehlen uns noch einige Informationen...' }}
        </h2>

        <v-divider class="mb-8 mt-3" />
        <template v-if="!showForm">
          <h4 class="text-h6 mb-2">
            Hallo <b>{{ getUsername() }}</b>!
          </h4>
          <p class="body-1 mb-6">
            Ihr Kommunalportal-Konto wird derzeit von uns zur Freischaltung geprüft -
            Sie erhalten eine E-Mail an <b>{{ getEmail() }}</b>
            sobald ihr Profil von uns bestätigt wurde.
          </p>
        </template>
        <v-form @submit.prevent="onFormSubmit" v-else>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="preferredRole"
                :items="getRoles"
                label="Rolle"
                outlined
                dense
                :rules="roleRules"
                @change="onRoleChange"
                required />
            </v-col>
            <v-col cols="12" v-if="preferredRole !== null && preferredRole !== 'Gremienmitglieder'">
              <v-text-field
                  v-model="preferredCommune"
                label="Kommune"
                outlined
                dense
                required
                  :rules="communeRules"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="telefon"
                label="Telefonnummer (optional)"
                outlined
                dense
                required />
            </v-col>
            <v-col col="12">
              <v-btn color="primary" :disabled="disableSubmitButton" type="submit">Absenden</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ApprovalPending",
  data: () => ({
    preferredRole: null,
    preferredCommune: null,
    roleRules: [
      v => !!v || 'Bitte wählen Sie eine Rolle aus',
    ],
    communeRules: [
      v => !!v || 'Bitte geben Sie eine Kommune an',
    ],
    telefon: undefined,
  }),
  mounted() {
    this.loadRoles();
  },
  computed: {
    ...mapGetters('user', ['showRegisterForm']),
    ...mapGetters('userDetails', ['getRoles']),
    disableSubmitButton() {
      return !this.preferredRole || !this.preferredCommune;
    },
    showForm() {
      return this.showRegisterForm;
    },
  },
  methods: {
    ...mapActions('userDetails', ['loadRoles', 'updatePreferredData']),
    onRoleChange() {
      if(this.preferredRole === 'Gremienmitglieder') {
        this.preferredCommune = 'Gremienmitglieder';
      } else {
        this.preferredCommune = null;
      }
    },
    async onFormSubmit() {
      const data = {
        preferredData: {
          preferredFaculty: this.preferredRole,
          preferredCommune: this.preferredCommune,
        },
        telefon: this.telefon,
      };
      await this.updatePreferredData(data);

      if(!this.showRegisterForm) {
        this.preferredRole = null;
        this.preferredCommune = null;
      }
    },
    getUsername() {
      return String(this.$store.getters["user/username"]).trim();
    },
    getEmail() {
      return this.$store.getters["user/userEmail"];
    },
  },
};
</script>
