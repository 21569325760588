import mutations from "@/store/modules/moments/mutations";
import actions from "@/store/modules/moments/actions";
import getters from "@/store/modules/moments/getters";

export default {
  namespaced: true,
  state: {
    moments: [],
    contact: null
  },
  mutations,
  actions,
  getters
};
