import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

export default {
    namespaced: true,
    state: {
        foerdermittel: [],
        activeFilter: 'Alle',
        sortBy: 'Fristende',
    },
    mutations,
    actions,
    getters,
};
