import Vue from "vue";
import Vuex from "vuex";

// store modules
import user from "./modules/user";
import news from "./modules/news";
import serviceArea from "./modules/serviceAreas";
import services from "./modules/services";
import subServices from "./modules/subServices";
import navigation from "./modules/navigation";
import generalContent from "./modules/generalContent";
import search from "./modules/search";
import analytics from "./modules/analytics";
import serviceOverview from "@/store/modules/serviceOverview";
import foerdermittel from "@/store/modules/foerdermittel";
import kommunalrabatt from "@/store/modules/kommunalrabatt";
import msgSnackbar from "@/store/modules/msg-snackbar";
import contractOverview from "@/store/modules/contractOverview";
import konzessionsthemen from "@/store/modules/konzessionsthemen";
import verbrauchsdaten from "@/store/modules/verbrauchsdaten";
import userDetails from "@/store/modules/userDetails";
import waerme from "@/store/modules/waerme";
import quartiersentwicklung from "@/store/modules/quartiersentwicklung";
import moments from "@/store/modules/moments";
import communeDashboard from "@/store/modules/communeDashboard";
import notifications from "@/store/modules/notifications";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    SERVER_BASE_URL: process.env.VUE_APP_API_BASE_URL,
    appVersion: process.env.VUE_APP_VERSION || "0"
  },

  getters: {
    getAppVersion: state => {
      return state.appVersion;
    },

    getAbsUrl: state => path => {
      return state.SERVER_BASE_URL + path;
    },

    getStrapiDocumentUrl: (state, getters) => doc => {
      return getters.getAbsUrl(doc.url);
    },

    getStrapiImageUrl: (state, getters) => (image, imgFormat) => {
      let imgData = image;
      if (!imgData) return null;

      if (imgFormat && imgData.formats && imgData.formats[imgFormat]) {
        imgData = imgData.formats[imgFormat];
      }

      return getters.getAbsUrl(imgData.url);
    }
  },

  mutations: {},

  actions: {},

  modules: {
    user,
    news,
    navigation,
    serviceArea,
    services,
    subServices,
    generalContent,
    search,
    analytics,
    serviceOverview,
    foerdermittel,
    kommunalrabatt,
    msgSnackbar,
    contractOverview,
    konzessionsthemen,
    verbrauchsdaten,
    userDetails,
    waerme,
    quartiersentwicklung,
    moments,
    communeDashboard,
    notifications
  }
});
