export default {
    getFAQ(state) {
        const allgemein = state.faq?.Allgemein?.Eintraege ?? [];
        const kommune = state.faq?.Kommune?.Eintraege ?? [];
        const endverbraucher = state.faq?.Endverbraucher?.Eintraege ?? [];

        return {
            allgemein,
            kommune,
            endverbraucher,
        }
    },
    getIframeUrl(state) {
        return state.iframeUrl;
    },
    getAcceptedTos(state) {
        return state.acceptedTos;
    }
}
