<template>
  <v-select
    background-color="#f6e4ed"
    id="sort-category-filter"
    v-model="filterProp"
    single-line
    dense
    outlined
    :items="sortCategoryOptions"
    item-text="label"
    item-value="val"
    :menu-props="{ bottom: true, offsetY: true }"
    hide-details
    class="text-right mt-2"
    :class="$vuetify.breakpoint.smAndDown ? '' : 'new-input-width'"
  />
</template>

<script>
export default {
  name: "SortCategory",
  props: ["sortCategoryFilter", "sortCategoryOptions"],
  data: () => ({
    model: 0,
    filterProp: "all"
    //default settings
  }),
  watch: {
    filterProp(val) {
      this.$store.dispatch("news/filterPosts", val);
    }
  }
};
</script>

<style scoped>
.new-input-width {
  width: 175px !important;
}
</style>
