<template>
  <div>
    <v-snackbar
      :timeout="timeout"
      :value="showMessageSnackbar"
      bottom
      left
      :color="getMessageSnackbarColor"
      app
    >
      <v-icon small left>
        {{ getMessageSnackbarIcon }}
      </v-icon>
      {{ getMessageSnackbarText }}
      <v-progress-linear
        absolute
        bottom
        color="white"
        :value="Math.floor(100 * (currentTime / timeout))"
      />
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MsgSnackbar',
  data() {
    return {
      currentTime: 200,
      timeout: 10 * 1000,
      timeoutID: 0,
    };
  },
  computed: {
    ...mapGetters('msgSnackbar', [
      'getMessageSnackbarDisplayBoolean',
      'getMessageSnackbarText',
      'getMessageSnackbarColor',
      'getMessageSnackbarIcon',
    ]),

    showMessageSnackbar() {
      const isDisplayOn = this.getMessageSnackbarDisplayBoolean;
      if (isDisplayOn) {
        clearTimeout(this.timeoutID);
        this.syncProgressbar();
      } else {
        this.resetTime();
        clearTimeout(this.timeoutID);
      }

      return isDisplayOn;
    },
  },

  methods: {
    ...mapActions('msgSnackbar', [
      'closeMessage',
    ]),

    syncProgressbar() {
      this.timeoutID = setTimeout(() => {
        this.currentTime += 100;
        if (this.timeout <= this.currentTime) {
          this.currentTime = 200;
          if (this.getMessageSnackbarDisplayBoolean) {
            this.resetTime();
            this.closeMessage();
            clearTimeout(this.timeoutID);
          }
        } else if (!this.getMessageSnackbarDisplayBoolean) {
          this.resetTime();
          clearTimeout(this.timeoutID);
        } else {
          this.syncProgressbar();
        }
      }, 100);
    },

    resetTime() {
      this.currentTime = 200;
      this.timeout = 10 * 1000;
    },

    maximumTime() {
      this.currentTime = 12000;
    },
  },
};
</script>
