import { _sortBy } from "@/utils/sortings";
import { getNewsDate } from "./functions";

export default {
  getOrderMonthYear(state) {
    return state.monthYearOrder;
  },
  categoryFilter(state) {
    return state.categoryFilter;
  },
  dateOrder(state) {
    return state.dateOrder;
  },
  newsCount(state) {
    //TODO: refractor via newsList.length ?
    return state.newsCount;
  },

  newsCountUnread(state, getters) {
    return getters.unreadNewsList.length;
  },

  pressCount(state) {
    return state.pressCount;
  },

  newsList(state) {
    return state.newsList || [];
  },

  newsListOrderByDate(state) {
    return (state.newsList || []).sort(function(a, b) {
      const dateA = a.pub_date ?? a.created_at;
      const dateB = b.pub_date ?? b.created_at;
      return new Date(dateA) - new Date(dateB);
    });
  },

  newsListByUnreadFirst(state, getters) {
    const newsList = getters.newsList || [];
    _sortBy(newsList, "wasRead");
    return newsList;
  },

  unreadNewsList(state, getters) {
    return getters.newsList
      ? getters.newsList.filter((n) => n.wasRead !== true)
      : [];
  },

  pressList(state) {
    return state.pressList || [];
  },

  newsById: (state) => (newsId) => {
    if (state.newsIdMap === null) return undefined;
    return state.newsIdMap[newsId] || [];
  },

  pressById: (state) => (pressId) => {
    if (state.pressIdMap === null) return undefined;
    return state.pressIdMap[pressId] || [];
  },
  isUnreadNews: (state) => (newsId) => {
    return !state.newsReadIdList.includes(newsId);
  },
  allPosts(state, getters) {
    var allPosts = [];
    var newsPosts = getters.newsList;
    var pressPosts = getters.pressList;
    for (let i = 0; i < newsPosts.length; i++) {
      var post = newsPosts[i];
      post.category = "News";
      allPosts.push(post);
    }
    for (let j = 0; j < pressPosts.length; j++) {
      post = pressPosts[j];
      post.category = "Presse";
      allPosts.push(post);
    }

    return allPosts.sort(function(a, b) {
      const dateA = a.pub_date ?? a.created_at;
      const dateB = b.pub_date ?? b.created_at;
      return new Date(dateA) - new Date(dateB);
    });
  },
  filtertedByCategoryPosts(state, getters) {
    var array = getters.allPosts;
    var category = getters.categoryFilter;
    var filtertedByCategoryPosts = [];
    if (category == "all") {
      filtertedByCategoryPosts = array;
    } else {
      filtertedByCategoryPosts = array.filter(function(arr) {
        return arr.category == category;
      });
    }
    return filtertedByCategoryPosts;
  },
  orderdByDatePosts(state, getters) {
    var array = getters.filtertedByCategoryPosts;

    var orderdByDatePosts = [];
    if (getters.dateOrder === "asc") {
      orderdByDatePosts = array.sort(
        (a, b) => new Date(getNewsDate(a)) - new Date(getNewsDate(b))
      );
    } else {
      orderdByDatePosts = array.sort(
        (a, b) => new Date(getNewsDate(b)) - new Date(getNewsDate(a))
      );
    }
    return orderdByDatePosts;
  },
  newsIdMap(state) {
    return state.newsIdMap;
  },
  newsIdMapAsList(state) {
    const idMap = state.newsIdMap;
    const items = Object.keys(idMap).map((key) => idMap[key]);
    return items;
  },
};
