import httpClient from "@/store/httpClient";

export default {
    async loadNotificationsConfig ({ commit }) {
        const response = await httpClient.get("/notification-configs/for-user");
        if(response.status !== 200) {
            return;
        }

        commit("SET_CONFIG", response.data);
    },
    async updateNotificationsConfig({ commit }, { key, value }) {
        try {
            const response = await httpClient.put(`/notification-configs/set-for-user?key=${key}&value=${value}`);
            if(response.status === 200) {
                commit("UPDATE_CONFIG_VALUE", {key, value })
            }
        } catch (e) {
            console.log("Failed to update notification config");
        }
    },
    async loadNotifications({ commit }) {
        try {
            const response = await httpClient.get("/notifications/for-user");
            if (response.status === 200) {
                commit("SET_NOTIFICATIONS", response.data);
            }
        } catch (e) {
            console.log("Failed to load notifications");
        }
    },
    toggleShowNotifications({ commit }) {
        commit("TOGGLE_SHOW_NOTIFICATIONS");
    },
    hideNotificationsList({ commit, dispatch, state }) {
        if(state.config?.promoteNotifications !== false) {
            dispatch("updateNotificationsConfig", { key: "promoteNotifications", value: false });
        }
        commit("SET_SHOW_NOTIFICATIONS", false);
    },
    showNotificationsList({ commit }) {
        commit("SET_SHOW_NOTIFICATIONS", true);
    },
    async markNotificationAsRead({ dispatch }, notificationId) {
        try {
            const response = await httpClient.put(`/notifications/read?id=${notificationId}`);
            if(response.status === 200) {
                await dispatch("loadNotifications");
            }
        } catch (e) {
            console.log("Failed to mark notification as read");
        }
    }
}
