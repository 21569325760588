<script>
import {mapGetters} from "vuex";

export default {
  name: "ContractCard",
  computed: {
    ...mapGetters('communeDashboard', ['contracts']),
    contractsSample() {
      return this.contracts.slice(0, 3);
    }
  }
}
</script>

<template>
  <v-card width="100%">
    <v-card-title>{{ contracts.length }} laufende Verträge</v-card-title>
    <v-card-text class="commune-dashboard-min-height">
      <table style="width: 100%">
        <tbody>
          <tr v-for="(contract, index) in contractsSample" :key="index">
            <th class="text-start commune-dashboard-card-text">{{ contract.name }}</th>
            <td class="text-start commune-dashboard-card-text">bis {{ contract.date.getFullYear() }}</td>
          </tr>
        </tbody>
      </table>
    </v-card-text>
    <v-card-actions>
      <v-btn text color="primary" tile block link to="/vertragsuebersicht" class="font-weight-bold commune-dashboard-dotted-border commune-dashboard-card-btn">
        Vertragsdaten ansehen
        <v-icon right>mdi-arrow-right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped>

</style>
