import httpClient from "@/store/httpClient";
export default {
    async loadKonzessionsThemen({ commit }) {
        const response = await httpClient.get("/users/me?type=konzessionsthemen");

        if(response.status === 200) {
            commit('SET_ITEMS', response.data);
        }
    },
    setSelectedThemaId({ commit }, themaId) {
        commit('SET_SELECTED_THEMA_ID', themaId);
    },
    setSortByKeyOrToggleOrder({ commit }, key) {
        commit('SET_SORT_BY_KEY_OR_TOGGLE_ORDER', key);
    },
    setSortBy({ commit }, sortBy) {
        commit('SET_SORT_BY', sortBy);
    },
}
