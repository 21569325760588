import actions from "@/store/modules/kommunalrabatt/actions";
import getters from "@/store/modules/kommunalrabatt/getters";
import mutations from "@/store/modules/kommunalrabatt/mutations";

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state: {
    commentInputText: false,
    lieferstellenContact: null,
    comments: [],
    commentsByUserid: [],
    commentsByCommune: [],
    commentsByNEW: [],
    lieferstellen: [],
    communeStatuses: [],
    selectedCommune: undefined,
    selectedYear: undefined,
    selectedLieferstelle: undefined,
    selectedLieferstellenMalo: undefined,
    details: [],
    chartColors: [
      "#9c0048",
      "#af336d",
      "#c36691",
      "#d799b6",
      "#22478c",
      "#4e6ba3",
      "#7a90ba",
      "#a6b5d1",
      "#f88800",
      "#f99f2e",
      "#fbb765",
      "#fccf98",
    ],
    unreadComments: [],
    responsiblePersons: [],
    autoCompleteMalos: [],
  },
};
