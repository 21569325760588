export default {
  showMessage({ commit, getters }, { text, color, icon }) {
    let myTimeout;
    if (getters.getMessageSnackbarDisplayBoolean) {
      commit('SET_MESSAGE_SNACKBAR_DISPLAY_BOOLEAN');
      clearTimeout(myTimeout);
    }
    myTimeout = setTimeout(() => {
      commit('SET_MESSAGE_SNACKBAR_TEXT', text);
      commit('SET_MESSAGE_SNACKBAR_COLOR', color);
      commit('SET_MESSAGE_SNACKBAR_ICON', icon);
      commit('SET_MESSAGE_SNACKBAR_DISPLAY_BOOLEAN');
    }, 500);
  },

  closeMessage({ commit }) {
    commit('SET_MESSAGE_SNACKBAR_DISPLAY_BOOLEAN');
  },
};
