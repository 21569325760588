import moment from "moment/moment";

export default {
  getContracts(state) {
    const now = moment();
    const contracts = state.contracts.map((item) => {
      Object.keys(item.simpleFields).forEach((key) => {
        let newKey = key.replaceAll(" ", "_").replaceAll(".", "");
        if (key !== newKey) {
          item.simpleFields[newKey] = item.simpleFields[key];
          delete item.simpleFields[key];
        }
      });
      const end = moment(parseInt(item.simpleFields.Vertragsende));
      const Restlaufzeit = end.diff(now, "years", true).toFixed(1);
      const Kuendigungsoption = moment(parseInt(item.simpleFields.Vertragsende))
        .subtract(5, "years")
        .format("DD.MM.YYYY");

      return {
        osId: item.osId,
        link: item.link,
        ...item.simpleFields,
        Restlaufzeit: Restlaufzeit < "0" ? "0" : Restlaufzeit,
        Kuendigungsoption,
      };
    });

    return contracts.filter(c => c.Vertragsstatus === 'aktiv' || c.Vertragsstatus === 'nicht aktiv');
  },
  getActiveContractsCount(state) {
    return state.contracts.filter(
      (contract) => contract.simpleFields["Vertragsstatus"] === "aktiv"
    ).length;
  },
  getInactiveContractsCount(state) {
    return state.contracts.filter(
      (contract) => contract.simpleFields["Vertragsstatus"] !== "aktiv"
    ).length;
  },
  getContractStates(state) {
    return [
      ...new Set(state.contracts.map((c) => c.simpleFields["Vertragsstatus"])),
    ];
  },
};
