<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "EditLieferstelleCard",
  data: () => ({
    adresszeile1: undefined,
    adresszeile2: undefined,
    notiz: undefined,
  }),
  mounted() {
    this.adresszeile1 = this.selectedLieferstelle.adresszeile1;
    this.adresszeile2 = this.selectedLieferstelle.adresszeile2;
    this.notiz = this.selectedLieferstelle.notiz;
  },
  watch: {
    selectedLieferstelle: {
      handler: function (val) {
        this.adresszeile1 = val.adresszeile1;
        this.adresszeile2 = val.adresszeile2;
        this.notiz = val.notiz;
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('kommunalrabatt', ['updateLieferstellenData']),
    async update() {
      await this.updateLieferstellenData({
        adresszeile1: this.adresszeile1,
        adresszeile2: this.adresszeile2,
        notiz: this.notiz,
        marktlokation: this.selectedLieferstelle.marktlokation,
        commune: this.getSelectedCommune,
      })
      this.$emit('close');
    },
  },
  computed: {
    ...mapGetters('kommunalrabatt', ['selectedLieferstelle', 'getSelectedCommune']),
    getDruckstufeOrSpannungsebene() {
      if (this.selectedLieferstelle.sparte === 'Gas') {
        return {
          label: 'Druckstufe',
          value: this.selectedLieferstelle.druckstufe
        };
      } else {
        return {
          label: 'Spannungsebene',
          value: this.selectedLieferstelle.spannungsebene
        };
      }
    }
  },
}
</script>

<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">Lieferstelle bearbeiten</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field label="Marktlokation" :value="selectedLieferstelle.marktlokation" disabled hide-details />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field label="Geschäftspartner Name" :value="selectedLieferstelle.geschaeftspartnerName" disabled hide-details />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field label="Geschäftspartner Nummer" :value="selectedLieferstelle.geschaeftspartnerNummer" disabled hide-details />
          </v-col>
          <v-col cols="9">
            <v-text-field label="Straße" v-model="adresszeile1" hide-details />
          </v-col>
          <v-col cols="3">
            <v-text-field label="Hausnummer" v-model="adresszeile2" hide-details />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field label="PLZ" :value="selectedLieferstelle.plz" disabled hide-details />
          </v-col>
          <v-col>
            <v-text-field label="Ort" :value="selectedLieferstelle.ort" disabled hide-details />
          </v-col>
          <v-col cols="12">
            <v-text-field :label="getDruckstufeOrSpannungsebene.label" :value="getDruckstufeOrSpannungsebene.value" disabled hide-details />
          </v-col>
          <v-col cols="12">
            <v-textarea label="Notiz" v-model="notiz" hide-details rows="1" auto-grow />
          </v-col>

        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text @click="$emit('close')" >Abbrechen</v-btn>
      <v-btn color="primary" text @click="update">Speichern</v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped>

</style>
