export default {
    SET_ITEMS(state, items) {
        state.items = items;
    },
    SET_SELECTED_THEMA_ID(state, themaId) {
        if(state.selectedThemaId === themaId) {
            return;
        }

        state.selectedThemaId = themaId;
    },
    SET_SORT_BY_KEY_OR_TOGGLE_ORDER(state, key) {
        if(state.sortBy.key === key) {
            state.sortBy.order = state.sortBy.order === 'asc' ? 'desc' : 'asc';
            return;
        }

        state.sortBy.key = key;
        state.sortBy.order = 'asc';
    },
    SET_SORT_BY(state, sortBy) {
        state.sortBy = sortBy;
    }
}
