export default {
  SET_SERVICE_AREAS(state, data) {
    state.serviceAreaList = data;

    // let serviceAreaObj = {};
    // servicesData.forEach(serviceArea => {
    //   serviceAreaObj[serviceArea.id] = serviceArea
    // });
    // state.servicesAreaIdMap = serviceAreaObj;
  },
};
