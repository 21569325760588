<script>
export default {
  name: "SelectCommuneCard"
}
</script>

<template>
  <v-card width="100%" class="select-commune-card" elevation="0">
    <v-card-text class="px-10 py-15">
      <p class="info-text">Bitte wählen Sie eine Kommune, um Details zu sehen.</p>
    </v-card-text>
    <svg xmlns="http://www.w3.org/2000/svg" width="67.453" height="76.086" viewBox="0 0 67.453 76.086" id="arrow-top">
      <g id="Gruppe_160849" data-name="Gruppe 160849" transform="matrix(0.946, 0.326, -0.326, 0.946, 18.875, -10.359)">
        <path id="Pfad_2807" data-name="Pfad 2807" d="M0,44.559C21.086,5.732,46.237,0,46.237,0" transform="matrix(-0.545, 0.839, -0.839, -0.545, 62.553, 38.003)" fill="none" stroke="#b40055" stroke-linecap="round" stroke-width="2"/>
        <path id="Pfad_2808" data-name="Pfad 2808" d="M0,22.521,9.009,0,25.346,16.337" transform="matrix(0.951, -0.309, 0.309, 0.951, 12.212, 7.832)" fill="none" stroke="#b40055" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      </g>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="76.032" height="67.566" viewBox="0 0 76.032 67.566" id="arrow-left" v-if="$vuetify.breakpoint.mdAndUp">
      <g id="Gruppe_160848" data-name="Gruppe 160848" transform="translate(80.608 -34.033) rotate(72)">
        <path id="Pfad_2807" data-name="Pfad 2807" d="M0,0C21.086,38.828,46.237,44.56,46.237,44.56" transform="matrix(-0.545, -0.839, 0.839, -0.545, 46.61, 88.733)" fill="none" stroke="#b40055" stroke-linecap="round" stroke-width="2"/>
        <path id="Pfad_2808" data-name="Pfad 2808" d="M0,0,9.009,22.521,25.346,6.184" transform="matrix(0.951, 0.309, -0.309, 0.951, 40.6, 73.217)" fill="none" stroke="#b40055" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      </g>
    </svg>
  </v-card>
</template>

<style scoped>
.select-commune-card {
  background: rgb(238, 197, 216);
  aspect-ratio: 1;
}

.info-text {
  font-size: 24px;
  color: #B40055;
}

#arrow-top {
  position: absolute;
  top: 15px;
  right: 0;
}

#arrow-left {
  position: absolute;
  bottom: 0;
  left: 15px;
}
</style>
