<template>
  <v-row class="px-7 py-4" no-gutters>
    <v-dialog v-model="showDialog" max-width="700px" overlay-opacity=".9">
      <v-card
        v-if="getSelectedCommuneStatus !== 'Gegenprüfung durch NEW'"
        class="py-10 px-7"
      >
        <v-card-title>
          <span class="text-h5">{{ getPopupHeader }}</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            style="position: absolute; top: 10px; right: 10px"
            @click="showDialog = false"
            color="primary"
            plain
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-html="getPopupBody"></v-card-text>
        <v-row style="padding-right: 24px; padding-left: 24px">
          <v-col cols="6">
            <v-btn outlined color="primary" block @click="showDialog = false">
              Zurück zur Bearbeitung
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              depressed
              color="primary"
              block
              @click="onSendClick"
              :disabled="sendButtonClicked"
              :loading="sendButtonClicked"
            >
              <v-icon left>
                mdi-send
              </v-icon>
              {{ goBack ? "Zurück an NEW" : getSendButtonText }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-card v-else class=" py-0 px-7">
        <v-card-title>
          <span class="text-h5">Finale Bestätigung anfragen</span>
        </v-card-title>
        <v-card-text>
          Sind alle Daten final arbeitet?<br />
          <br />
          Wollen Sie die Gemeinde zur abschließenden Bestätigung der
          Vollständigkeit der Liste aller Lieferstellen auffordern?
          <div style="display: flex" class="pa-5">
            <div class="text-center">
              <v-avatar color="primary" size="48">
                <span class="white--text text-h5">{{ userInitials }}</span>
              </v-avatar>
              <br />
              <span>
                {{ username }}<br />
                {{ userCommuneName }}<br />
                <a :href="`mailto://${userEmail}`">{{ userEmail }}</a>
              </span>
            </div>
            <v-spacer />
            <div class="text-center" v-if="getResponsiblePerson">
              <v-avatar color="secondary" size="48">
                <span class="white--text text-h5">{{
                  responsableInitials
                }}</span>
              </v-avatar>
              <br />
              <span>
                {{ getResponsiblePerson.firstName }}
                {{ getResponsiblePerson.lastName }}<br />
                {{ communeName }}<br />
                <a :href="`mailto://${getResponsiblePerson.email}`">{{
                  getResponsiblePerson.email
                }}</a>
              </span>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            outlined
            color="primary"
            @click="showDialog = false"
            style="width: 50%"
          >
            Zurück zur Bearbeitung
          </v-btn>
          <v-btn
            depressed
            color="primary"
            @click="onSendClick"
            :disabled="sendButtonClicked"
            :loading="sendButtonClicked"
            style="width: 50%"
          >
            <v-icon left>
              mdi-send
            </v-icon>
            An {{ isNewEmployee ? getSelectedCommuneName : "NEW" }} senden
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="flex-filter mr-0 ml-auto">
      <v-btn
        color="primary"
        outlined
        height="40"
        v-if="!isNewEmployee"
        @click="onNewLieferstelleClick"
      >
        <v-icon left>mdi-plus-circle</v-icon>
        Neue Lieferstelle
      </v-btn>
      <v-select
        v-if="isNewEmployee"
        dense
        outlined
        hide-details
        :items="communes"
        label="Kommune"
        item-text="name"
        item-value="id"
        v-model.number="selectedCommune"
        background-color="#f6e4ed"
        @change="onCommuneChange"
        style="width: 225px"
      />
      <v-text-field
        v-else
        dense
        outlined
        hide-details
        :value="userCommuneName"
        label="Kommune"
        background-color="#f6e4ed"
        readonly
        style="width: 225px"
      />
      <v-select
        class="ml-2"
        dense
        outlined
        hide-details
        :items="years"
        label="Jahr"
        v-model="selectedYear"
        background-color="#f6e4ed"
        @change="onYearChange"
        ref="yearSelect"
        style="width: 100px"
      />
      <v-btn
        v-if="
          getCommuneStatuses[0].data[0].status === 'Freizugeben' && !isNewEmployee
        "
        height="40"
        class="ml-1 new-slide-button"
        depressed
        block
        @click="onStepBack"
      >
        <v-icon left small>mdi-send</v-icon>
        Zurück an NEW
      </v-btn>
      <v-btn
        depressed
        class="ml-2 new-slide-button"
        height="40px"
        @click="onButtonClick"
        :disabled="disableSendButton"
      >
        <v-icon left small>mdi-send</v-icon>
        {{ getSendButtonText }}
      </v-btn>
      <v-btn
        @click="download"
        color="primary"
        outlined
        class="ml-3"
        height="40px"
      >
        <v-icon left small>mdi-download-box</v-icon>
        xlsx
      </v-btn>
    </div>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LieferstellenFilter",
  data: () => ({
    selectedCommune: undefined,
    selectedYear: undefined,
    showDialog: false,
    sendButtonClicked: false,
    goBack: false
  }),
  computed: {
    ...mapGetters("kommunalrabatt", [
      "getCommunes",
      "getLieferstellenForCommune",
      "getSelectedCommune",
      "getYearsForCommune",
      "getCommuneStatuses",
      "getSelectedCommuneStatus",
      "getResponsiblePerson"
    ]),
    ...mapGetters("user", [
      "isNewEmployee",
      "userCommuneId",
      "userCommuneName",
      "userInitials",
      "username",
      "userEmail"
    ]),
    ...mapGetters("generalContent", ["getCommuneIdByName"]),
    communes() {
      if (this.isNewEmployee) {
        return this.getCommuneStatuses.map(status =>
          this.getCommuneIdByName(status.commune)
        );
      } else {
        return [
          {
            id: this.userCommuneId,
            name: this.userCommuneName
          }
        ];
      }
    },
    responsableInitials() {
      if (!this.getResponsiblePerson) return "";
      return (
        this.getResponsiblePerson?.firstName[0] +
        this.getResponsiblePerson?.lastName[0]
      );
    },
    getPopupHeader() {
      if (this.goBack) {
        return "Zur Gegenprüfung zurück an die NEW";
      }
      switch (this.getSelectedCommuneStatus) {
        case "Neu":
          return `Liste ${this.selectedYear} an ${this.getSelectedCommuneName} senden`;
        case "Bei Kommune":
          return `Liste ${this.selectedYear} an NEW übergeben`;
        case "Freizugeben":
          return `Lieferstellenliste ${this.selectedYear} akzeptieren`;
        default:
          return "";
      }
    },
    getPopupBody() {
      if (this.goBack) {
        return "Möchten Sie die Bearbeitung an die NEW zurück geben?";
      }
      switch (this.getSelectedCommuneStatus) {
        case "Neu":
          return `Alle Daten geprüft?<br>
            <br>
            Wollen Sie den aktuellen Stand an ${this.getSelectedCommuneName} zur Prüfung geben
            und somit die Liste für die Bearbeitung durch die Gemeinde freigeben?`;
        case "Bei Kommune":
          return `Alle Daten geprüft und ergänzt?<br>
                    <br>
                    Wollen Sie den aktuellen Stand zur gegenprüfung an die NEW zurücksenden und freigeben?`;
        case "Gegenprüfung durch NEW":
          return `Sind alle Daten final bearbeitet?<br>
                    <br>
                    Wollen Sie die Gemeinde zur Bestätigung der Vollständigkeit der Liste aller Lieferstellen auffordern?`;
        case "Freizugeben":
          return "Möchten Sie die finale Lieferstellenliste herunterladen und schriftlich bestätigen?";
        default:
          return "";
      }
    },
    communeName() {
      return this.communes.find(c => c.id === this.selectedCommune).name;
    },
    years() {
      if (!this.getSelectedCommune) return [];
      return this.getYearsForCommune(this.selectedCommune);
    },
    getSelectedCommuneName() {
      if (!this.selectedCommune) return "";
      return this.communes.find(c => c.id === this.selectedCommune)?.name;
    },
    disableSendButton() {
      const allowedStatuses = this.isNewEmployee
        ? ["Neu", "Gegenprüfung durch NEW"]
        : ["Bei Kommune", "Freizugeben"];
      return (
        !this.selectedCommune ||
        !this.selectedYear ||
        !allowedStatuses.includes(this.getSelectedCommuneStatus)
      );
    },
    getSendButtonText() {
      if (
        this.getSelectedCommuneStatus === "Freizugeben" ||
        this.getSelectedCommuneStatus === "Abgeschlossen"
      ) {
        return this.isNewEmployee
          ? "Wartet auf Bestätigung"
          : "Liste final akzeptieren";
      }

      if (!this.isNewEmployee) return "An New";
      else return `An Gemeinde`;
    },
    getNextStatus() {
      const statuses = [
        "Neu",
        "Bei Kommune",
        "Gegenprüfung durch NEW",
        "Freizugeben",
        "Abgeschlossen"
      ];

      const currentStatusIndex = statuses.indexOf(
        this.getSelectedCommuneStatus
      );
      return statuses[currentStatusIndex + 1];
    }
  },
  methods: {
    ...mapActions("kommunalrabatt", [
      "updateSelectedCommune",
      "updateSelectedYear",
      "updateCommuneStatus",
      "loadCommuneStatuses",
      "downloadPdf",
      "loadResponsiblePersons"
    ]),
    async onCommuneChange() {
      await this.updateSelectedCommune(this.selectedCommune);
    },
    async onYearChange() {
      await this.updateSelectedYear(this.selectedYear);
    },
    async onSendClick() {
      if (this.disableSendButton) return;
      this.sendButtonClicked = true;

      if (this.getSelectedCommuneStatus === "Freizugeben" && !this.goBack) {
        await this.downloadPdf();
      }
      let newStatus;
      if (this.goBack) {
        newStatus = "Gegenprüfung durch NEW";
      } else {
        newStatus = this.getNextStatus;
      }

      await this.updateCommuneStatus({
        commune: this.getSelectedCommuneName,
        year: this.selectedYear,
        status: newStatus
      });

      await this.$router.push("/kommunalrabatt");
    },
    async onStepBack() {
      this.showDialog = true;
      this.goBack = true;
    },
    async onButtonClick() {
      this.goBack = false;
      if (this.getSelectedCommuneStatus !== "Abgeschlossen") {
        this.showDialog = true;
      } else {
        await this.downloadPdf();
      }
    },
    onNewLieferstelleClick() {
      this.$emit("openSidebar");
    },
    download() {
      this.$emit("download-csv");
    }
  },
  async mounted() {
    this.sendButtonClicked = false;
    if (!this.isNewEmployee) {
      await this.loadCommuneStatuses();
      this.selectedCommune = this.getCommuneStatuses[0].commune;
      await this.updateSelectedCommune(this.userCommuneId);
    }

    const { commune, year } = this.$route.query;

    if (commune && year) {
      this.selectedCommune = this.isNewEmployee
        ? this.getCommuneIdByName(commune).id
        : this.userCommuneId;
      await this.onCommuneChange();

      this.selectedYear = parseInt(year, 10);
      await this.onYearChange();
      if (this.isNewEmployee) {
        await this.loadResponsiblePersons(commune);
      }
    }
  }
};
</script>

<style scoped>
.flex-filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
</style>
