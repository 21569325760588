<template>
  <v-col cols="12" class="px-0 py-3">
    <template v-for="filterName of filter">
      <v-chip
        label
        @click="onSelectFilter(filterName)"
        :key="filterName"
        :color="selectedFilter === filterName ? '#9C004726' : ''"
        :outlined="selectedFilter !== filterName"
        class="mr-1 mb-1 search-filter"
        :class="
          selectedFilter === filterName
            ? ['search-filter-active']
            : ['search-filter']
        "
      >
        {{ filterName }}
        <v-avatar
          right
          size="small"
          class="px-1"
          :class="
            selectedFilter === filterName
              ? 'search-result-count-active'
              : 'search-result-count'
          "
          >{{ getEntryCount(filterName) }}</v-avatar
        >
      </v-chip>
    </template>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SearchFilter",
  computed: {
    ...mapGetters({
      filter: "search/getFilter",
      selectedFilter: "search/getSelectedFilter",
      getEntryCount: "search/getResultCountForCategory"
    })
  },
  methods: {
    onSelectFilter(filterName) {
      this.$store.dispatch("search/selectFilter", filterName);
    }
  }
};
</script>

<style scoped>
.search-filter:hover {
  border-color: #b40055;
  background: transparent !important;
  color: #b40055 !important;
}

.search-filter {
  background-color: transparent !important;
}

.search-filter-active {
  border-style: solid !important;
  border-width: thin !important;
  border-color: #b40055 !important;
  color: #b40055 !important;
  background: rgba(156, 0, 71, 0.15) !important;
}

.search-result-count {
  border-radius: 50%;
  background: #eaecef;
}

.search-result-count-active {
  border-radius: 50%;
  background: rgba(156, 0, 71, 0.15) !important;
}
</style>
