export default {
    SET_FAQ(state, faq) {
        state.faq = faq;
    },
    SET_TOS_STATUS(state, status) {
        state.acceptedTos = status;
    },
    SET_IFRAME_URL(state, url) {
        state.iframeUrl = url;
    }
}
