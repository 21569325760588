import httpClient from "@/store/httpClient";

export default {
    async setSelectedCommune({ commit, state }, commune) {
        if(state.selectedCommune === commune) {
            commit('SET_SELECTED_COMMUNE', '');
            commit('SET_COMMUNE_DATA', undefined);
            return;
        }

        commit('SET_SELECTED_COMMUNE', commune);

        const response = await httpClient.get(`/communes/dashboard-data?commune=${commune}`);

        if(response.status === 200) {
            commit('SET_COMMUNE_DATA', response.data);
        }
    },
}
