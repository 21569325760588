<script>
import {mapActions, mapGetters} from "vuex";
import NotificationRow from "@/components/list_rows/NotificationRow.vue";
import NotificationHintCard from "@/components/ui/NotificationHintCard.vue";

export default {
  name: "NotificationsList",
  components: {NotificationHintCard, NotificationRow},
  methods: {
    ...mapActions("notifications", ["hideNotificationsList"]),
  },
  computed: {
    ...mapGetters("notifications", ["getShowNotifications", "getNotifications", "getUnreadNotificationsCount"]),
    listTitle() {
      if(this.getUnreadNotificationsCount === 0) return "Keine Neuigkeiten seit Ihrem letzten Besuch";
      else if (this.getUnreadNotificationsCount === 1) return "Eine Neuigkeit seit Ihrem letzten Besuch";
      else return `${this.getUnreadNotificationsCount} Neuigkeiten seit Ihrem letzten Besuch`;
    },
    newNotifications() { return this.getNotifications.filter(n => !n.read)},
    oldNotifications() { return this.getNotifications.filter(n => n.read)},
    isMobile() {
      return this.$vuetify.breakpoint.width < 1264;
    },
    width() {
      return !this.isMobile ? "350px" : "100%";
    },
  }
}
</script>

<template>
<div>
  <div v-if="getShowNotifications">
    <div class="notifications-backdrop" @click="hideNotificationsList" />
  <v-sheet class="notifications-list" elevation="2" :width="width">
    <div class="notifications-list-container">
      <div class="notifications-list-view">
        <v-btn small text @click="hideNotificationsList" color="primary" class="pt-5" v-if="isMobile">
          <v-icon>mdi-arrow-left</v-icon>
          übersicht
        </v-btn>
        <div class="font-weight-bold px-3 pt-5">{{ listTitle }}</div>
        <notification-hint-card class="mx-3" />
        <notification-row v-for="notification in newNotifications" :key="notification.id" :notification="notification" />
        <notification-row v-for="notification in oldNotifications" :key="notification.id" :notification="notification" />
    </div>
      <div class="py-2">
        <v-divider />
        <v-btn @click="hideNotificationsList" to="/notification-config" text color="primary" block>
          Einstellungen
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
      </div>
    </div>
  </v-sheet>
  </div>
</div>
</template>

<style scoped>

.notifications-list {
  height: calc(100vh - 66px);
  position: fixed;
  top: 66px !important;
  right: 0;
  z-index: 10;
}

.notifications-list-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.notifications-backdrop {
  position: fixed;
  top: 66px;
  left: 0;
  width: 100vw;
  opacity: 0.25;
  z-index: 10;
  height: calc(100vh - 66px);
  backdrop-filter: blur(10px);
}

.notifications-list-view {
  height: calc(100vh - 119px);
  overflow-y: scroll;
}
</style>
