import httpClient from '@/store/httpClient';

/**
 * Abstraction util for strapi REST models
 * that wraps around Axios requests
 */

export default {
    /**
     * @param {string} resourceName
     */
    init(resourceName) {
        const RESOURCE_URL = '/' + resourceName;

        const LAST_FETCH_TIMES = {}; // for caching helper

        function count() {
            return httpClient.get(RESOURCE_URL + '/count')
                .then(_sanitizeResponseData)
                .catch(_onRequestFail);
        }

        function find() {
            return httpClient.get(RESOURCE_URL)
                .then(_sanitizeResponseData)
                .catch(_onRequestFail);
        }

        /**
         * @param {number} resourceId
         * @returns {Promise<* | {data: *, status: *} | boolean>}
         */
        function findOne(resourceId) {
            return httpClient.get(RESOURCE_URL + `/${resourceId}`)
                .then(_sanitizeResponseData)
                .catch(_onRequestFail);
        }

        function create(data) {
            return httpClient.post(RESOURCE_URL, data)
                .then(_sanitizeResponseData)
                .catch(_onRequestFail);
        }

        function _onRequestFail() {
            return false;
        }

        function _sanitizeResponseData(responseObj) {
            if (!responseObj) {
                return responseObj;
            }
            const { data, status } = responseObj;

            return { data, status };
        }

        //----- Helper -----

        /**
         * @param {string} cacheId
         * @param {number} cacheTimeoutSecs
         * @returns {boolean}
         */
        function isCacheExpired(cacheId, cacheTimeoutSecs) {
            if (!cacheTimeoutSecs) {
                // no cache timeout is set (=always re-fetch data)
                return true;
            }

            // get last fetch time for cache key (if key exists)
            const lastFetchTime = (cacheId in LAST_FETCH_TIMES) ? LAST_FETCH_TIMES[cacheId] : null;

            if (!lastFetchTime) {
                return true;
            } else {
                // compare last fetch time with given cache timeout duration
                const now = new Date();
                const secsPast = Math.abs(now - lastFetchTime) / 1000;

                return (secsPast >= cacheTimeoutSecs)
            }
        }

        /**
         * @param {string} cacheId
         */
        function resetCacheTimeout(cacheId) {
            LAST_FETCH_TIMES[cacheId] = new Date();
        }

        return {
            count,
            find,
            findOne,
            create,

            // helper
            isCacheExpired,
            resetCacheTimeout
        };
    }
}
