<template>
<v-sheet :elevation="elevation" width="100%" :color="backgroundColor">
    <v-progress-linear :value="100" height="6" :color="borderColor"></v-progress-linear>
    <v-row class="py-3" no-gutters>
        <v-col cols="12" class="px-3">
            <div v-if="isNew"><v-chip x-small class="badge new-item">NEU</v-chip></div>
            <span :class="isNew ? ['primary-text'] : []" class="font-weight-bold">{{ vertrag }}</span>
            <vue-markdown-it class="md-text mt-2" :source="details"></vue-markdown-it>
        </v-col>
        <v-col cols="6" class="subtitle-col px-3 py-2" :style="subtitleColStyle">
            <span>
                Kommune:<br>
                {{ communeName }}
            </span>
        </v-col>
        <v-col cols="6" class="subtitle-col px-3 py-2" :style="subtitleColStyle">
            <span>
                Letzte Änderung:<br>
                {{ updated }}
            </span>
        </v-col>
        <v-col cols="12" v-if="verlaufIsVisible" class="px-0 py-2">
            <v-timeline dense>
                <v-timeline-item v-for="item of getSelectedThemaDetails" :key="item.id" :color="borderColor">
                    <div class="subtitle-2">{{ formatDateString(item.Datum) }}</div>
                    <vue-markdown-it :source="item.Details" style="white-space: break-spaces; font-size: 15px; line-height: 1.25"  class="mb-0"/>
                </v-timeline-item>
            </v-timeline>
        </v-col>
        <v-col cols="12" style="display: flex">
            <v-btn text small color="primary" :ripple="false" class="mx-auto" @click="onShowVerlaufClick">
                <v-icon>{{ verlaufIsVisible ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                Verlauf
            </v-btn>
        </v-col>
    </v-row>
</v-sheet>
</template>

<script>
import VueMarkdownIt from "vue-markdown-it";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "KonzessionsThemaMobileCard",
    props: ['themaId', 'isNew', 'vertrag', 'details', 'communeName', 'updated', 'borderColor', 'backgroundColor', 'elevation', 'secondaryColor'],
    components: {
        VueMarkdownIt
    },
    computed: {
        ...mapGetters('konzessionsthemen', ['getSelectedThema', 'getSelectedThemaDetails']),
        verlaufIsVisible() {
            return this.themaId === this.getSelectedThema?.id;
        },
        subtitleColStyle() {
            return {
                backgroundColor: this.secondaryColor
            }
        }
    },
    methods: {
        ...mapActions('konzessionsthemen', ['setSelectedThemaId']),
        onShowVerlaufClick() {
            this.setSelectedThemaId(this.verlaufIsVisible ? undefined : this.themaId);
        },
        formatDateString(dateString) {
            return new Date(dateString)
                .toLocaleDateString('de-DE', {  month: '2-digit', day: '2-digit', year: 'numeric' })
        },
    }
}
</script>

<style scoped>
.new-item {
    background: #F8A807 !important;
}

.md-text,
.md-text p {
    white-space: break-spaces !important;
    line-height: 1;
}

.subtitle-col {
    line-height: 1.1;
    font-size: 14px;
    /*background-color: rgb(249,250,252); */
}
</style>
