<template>
  <v-col v-if="showInput" class="pt-0">
    <v-textarea
      v-model="newComment"
      label="Einen Kommentar hinzufügen"
      auto-grow
      rows="1"
      @keyup.enter.exact="addNewComment"
      hide-details
      class="mb-3"
    />
    <v-alert
      color="primary"
      colored-border
      v-if="newComment"
      border="left"
      :icon="false"
      dense
      text
    >
      <span class="comment-warning">
        Achtung! Kommentare können nicht gelöscht werden
      </span>
    </v-alert>
    <v-row no-gutters class="mt-1 pa-0">
      <v-btn
        @click="
          newComment = null;
          showInput = false;
        "
        color="primary"
        text
        >Abbrechen</v-btn
      >
      <v-btn
        class="ml-auto"
        :disabled="disableButtons"
        @click="addNewComment"
        color="primary"
        >Hinzufügen</v-btn
      >
    </v-row>
  </v-col>
  <v-col v-else class="text-right pt-0">
    <v-btn class="new-slide-button" outlined @click="showInput = true"
      >Kommentieren</v-btn
    >
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CommentInput",
  data: () => ({
    newComment: null,
    showInput: false
  }),
  computed: {
    ...mapGetters("user", ["username", "userCommuneName", "isNewEmployee"]),
    ...mapGetters("kommunalrabatt", ["selectedLieferstelle"]),
    disableButtons() {
      return this.newComment === null;
    }
  },
  watch: {
    newComment(ov) {
      if (ov) {
        this.setCommentInputText(true);
      } else {
        this.setCommentInputText(false);
      }
    }
  },
  methods: {
    ...mapActions("kommunalrabatt", ["addComment", "setCommentInputText"]),
    async addNewComment() {
      if (!this.newComment) return;

      const comment = {
        author: this.username,
        message: this.newComment,
        timestamp: new Date().getTime(),
        commune: this.isNewEmployee ? "NEW MA" : this.userCommuneName,
        year: new Date().getFullYear()
      };
      this.newComment = null;

      await this.addComment({
        marktlokation: this.selectedLieferstelle.marktlokation,
        comment
      });
      this.showInput = false;
    }
  }
};
</script>

<style scoped>
.comment-warning {
  font-size: 14px !important;
  line-height: 1.25 !important;
}

.v-alert {
  background-color: #f5e6ed !important;
}

.v-alert--text:before {
  background-color: unset !important;
}
</style>
