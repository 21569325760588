import mutations from "@/store/modules/communeDashboard/mutations";
import actions from "@/store/modules/communeDashboard/actions";
import getters from "@/store/modules/communeDashboard/getters";

export default {
    namespaced: true,
    state: {
        selectedCommune: '',
        communeData: undefined,
        communeNames: ['Brüggen', 'Schwalmtal','Tönisvorst', 'Viersen', 'Mönchengladbach', 'Korschenbroich', 'Jüchen', 'Grevenbroich', 'Erkelenz', 'Wegberg', 'Niederkrüchten', 'Wassenberg', 'Hückelhoven', 'Geilenkirchen', 'Übach-Palenberg', 'Waldfeucht', 'Gangelt', 'Selfkant']
    },
    mutations,
    actions,
    getters
}
