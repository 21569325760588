<template>
  <div class="min-height-100">
    <v-row no-gutters class="new-font-bold mt-5 mb-4">
      Ansprechpartner
    </v-row>

    <!-- KOMMUNALPARTNER & REGIONALLEITER-->
    <template
      v-if="
        contentObject.personsInCharge &&
          contentObject.personsInCharge.length == 0
      "
    >
      <ContactPersonBox
        v-if="contentObject.showRegionalLeiter == true"
        :person="communeRegionalLeader"
        :verticalDesign="$vuetify.breakpoint.width < 1440"
      />
      <ContactPersonBox
        v-else
        :person="communePartner"
        :verticalDesign="$vuetify.breakpoint.width < 1440"
      />
    </template>

    <!-- SONSTIGE ANSPRECHPARTNER -->
    <template v-for="person in contentObject.personsInCharge">
      <ContactPersonBox
        :key="person.id"
        :person="person"
        :verticalDesign="$vuetify.breakpoint.width < 1440"
      />
    </template>

    <!-- DOWNLOADS -->
    <template
      v-if="
        contentObject.fileDownloads && contentObject.fileDownloads.length != 0
      "
    >
      <v-row no-gutters class="mt-12">
        <span class="text-overline">Downloads</span>
      </v-row>
      <v-row
        no-gutters
        v-for="file in contentObject.fileDownloads"
        :key="file.id"
      >
        <span>
          <v-icon color="primary">mdi-download</v-icon>
          <a
            v-if="file.caption"
            :href="`${baseUrl}${file.url}`"
            class="primary--text"
          >
            {{ file.caption }}
          </a>
          <a v-else :href="`${baseUrl}${file.url}`" class="primary--text">
            {{ file.name }}
          </a>
        </span>
      </v-row>
    </template>

    <!-- EBENFALLS INTERESSANT -->
    <template
      v-if="
        contentObject.interestingLinks &&
          contentObject.interestingLinks.length != 0
      "
    >
      <v-row no-gutters class="new-font-bold mt-12 mb-3">
        Ebenfalls Interessant
      </v-row>
      <v-row
        no-gutters
        v-for="link in contentObject.interestingLinks"
        :key="link.id"
      >
        <a :href="`${link.link}`" target="_blank" class="primary--text">
          {{ link.title }}
        </a>
      </v-row>
    </template>
  </div>
</template>

<script>
import ContactPersonBox from "@/components/layout/ContactPersonBox.vue";

export default {
  name: "RightColumnGroup",
  components: {
    ContactPersonBox
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_BASE_URL
    };
  },
  props: {
    communePartner: Object,
    communeRegionalLeader: Object,
    contentObject: Object
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
