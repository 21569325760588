export default {
  servicesCount(state) {
    return state.servicesList.length;
  },

  // eslint-disable-next-line no-unused-vars
  serviceByServiceId: (state, getters, rootState, rootGetters) => (serviceId) => {
    return state.servicesList.find((service) => service.id == serviceId);
  },
};
