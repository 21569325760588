<template>
  <v-container fluid v-if="getLieferstellenContact">
    <v-row dense>
      <v-col cols="12">
        <h5 class="text-uppercase">Ihr NEW Ansprechpartner</h5>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.lgAndDown ? 3 : 2">
        <v-img
          class="rounded-circle"
          :src="`${baseUrl}${getContactImgUrl()}`"
        ></v-img>
      </v-col>
      <v-col>
        <b>{{ getFullname() }}</b
        ><br />
        <span v-if="getCustomTitle() && getCustomTitle() != ''">{{
          getCustomTitle()
        }}</span
        ><br />

        <!-- email -->
        <v-tooltip bottom v-if="getLieferstellenContact.email !== null">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="contact__container mb-1">
              <v-icon small color="primary">mdi-email-outline</v-icon>
              <a
                :href="`mailto:${getLieferstellenContact.email}`"
                class="primary--text text-decoration-none"
              >
                {{ getLieferstellenContact.email }}
              </a></span
            >
          </template>
          <span>{{ getFullname() }} per Email kontaktieren</span> </v-tooltip
        ><br />

        <!-- phone -->
        <v-tooltip bottom v-if="getLieferstellenContact.phone !== null">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="contact__container"
              ><v-icon small color="primary">mdi-phone-outline</v-icon>
              <a
                style="text-decoration:none"
                :href="`tel:${getLieferstellenContact.phone}`"
                class="primary--text text-decoration-none"
              >
                {{ getLieferstellenContact.phone }}
              </a></span
            >
          </template>
          <span>{{ getFullname() }} anrufen</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ContactPersonHorizontal",
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_BASE_URL
    };
  },
  computed: {
    ...mapGetters("kommunalrabatt", ["getLieferstellenContact"])
  },
  methods: {
    ...mapActions("kommunalrabatt", ["loadLieferstellenContact"]),
    getFullname() {
      const f = this.getLieferstellenContact.firstname;
      const l = this.getLieferstellenContact.lastname;
      return `${f} ${l}`;
    },
    getCustomTitle() {
      return this.getLieferstellenContact.customTitle;
    },
    getContactImgUrl() {
      if (
        this.getLieferstellenContact.image &&
        this.getLieferstellenContact.image.formats.small
      ) {
        return this.getLieferstellenContact.image.formats.small.url;
      } else if (
        this.getLieferstellenContact.image &&
        this.getLieferstellenContact.image.url
      ) {
        return this.getLieferstellenContact.image.url;
      } else {
        return false;
      }
    }
  },
  async created() {
    await this.loadLieferstellenContact();
  }
};
</script>

<style scoped>
.contact__container {
  display: inline-block;
  background-color: #e9cfdc !important;
  padding: 0px 6px;
  border-radius: 5px;
}
</style>
