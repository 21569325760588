var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('v-row',{staticClass:"mt-5",attrs:{"no-gutters":""}},[_c('v-col',{class:{ 'text-center': _vm.verticalDesign },attrs:{"cols":"2"}},[(_vm.getContactImgUrl())?_c('v-avatar',{style:(!_vm.verticalDesign ? _vm.auto : 'margin:auto'),attrs:{"size":!_vm.verticalDesign ? _vm.auto : 150,"color":"grey"}},[_c('v-img',{attrs:{"src":("" + _vm.baseUrl + (_vm.getContactImgUrl()))}})],1):_c('v-avatar',{staticClass:"icon-bg",style:(!_vm.verticalDesign ? 38 : 'margin:auto'),attrs:{"size":!_vm.verticalDesign ? 38 : 150}},[_c('v-icon',{attrs:{"size":!_vm.verticalDesign ? 100 : 150,"color":"#ffffff80"}},[_vm._v(" $user ")])],1)],1),(!_vm.verticalDesign)?_c('v-col',[_c('v-row',{staticClass:"new-font-bold",attrs:{"no-gutters":""}},[_vm._v(" "+_vm._s(_vm.getFullName())+" ")]),_c('v-row',{attrs:{"no-gutters":""}},[_vm._v(_vm._s(_vm.contact.customTitle))]),_c('span',{staticClass:"font-italic"},[_vm._v(_vm._s(_vm.person.info))])],1):_vm._e(),(!_vm.verticalDesign)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"label-bg mb-1"},'span',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("$dashboardEmail")]),_c('a',{staticClass:"primary--text text-decoration-none",attrs:{"href":("mailto:" + (_vm.contact.email))},on:{"click":function($event){return _vm.onContactClick('email')}}},[_vm._v(" "+_vm._s(_vm.contact.email)+" ")])],1)]}}],null,false,809849288)},[_c('span',[_vm._v(_vm._s(_vm.getFullName())+" per Email kontaktieren")])])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"label-bg"},'span',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("$callIcon")]),_c('a',{staticClass:"primary--text text-decoration-none",staticStyle:{"text-decoration":"none"},attrs:{"href":("tel:" + (_vm.contact.phone))},on:{"click":function($event){return _vm.onContactClick('phone')}}},[_vm._v(" "+_vm._s(_vm.contact.phone)+" ")])],1)]}}],null,false,1695248568)},[_c('span',[_vm._v(_vm._s(_vm.getFullName())+" anrufen")])])],1)],1):_vm._e()],1),(_vm.verticalDesign)?[_c('v-row',{staticClass:"font-weight-bold",attrs:{"no-gutters":""}},[_vm._v(" "+_vm._s(_vm.getFullName())+" ")]),_c('v-row',{attrs:{"no-gutters":""}},[_vm._v(" "+_vm._s(_vm.contact.customTitle)+" ")]),_c('v-row',{attrs:{"no-gutters":""}},[_c('span',{staticClass:"font-italic"},[_vm._v(_vm._s(_vm.person.info))])])]:_vm._e(),(_vm.verticalDesign)?_c('v-row',{staticClass:"mt-1",attrs:{"no-gutters":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"label-bg mb-1"},'span',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("$dashboardEmail")]),_c('a',{staticClass:"primary--text",attrs:{"href":("mailto:" + (_vm.contact.email))},on:{"click":function($event){return _vm.onContactClick('email')}}},[_vm._v(" "+_vm._s(_vm.contact.email)+" ")])],1)]}}],null,false,2827032093)},[_c('span',[_vm._v(_vm._s(_vm.getFullName())+" per Email kontaktieren")])])],1):_vm._e(),(_vm.verticalDesign)?_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"label-bg"},'span',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("$callIcon")]),_c('a',{staticClass:"primary--text",staticStyle:{"text-decoration":"none"},attrs:{"href":("tel:" + (_vm.contact.phone))},on:{"click":function($event){return _vm.onContactClick('phone')}}},[_vm._v(" "+_vm._s(_vm.contact.phone)+" ")])],1)]}}],null,false,546547469)},[_c('span',[_vm._v(_vm._s(_vm.getFullName())+" anrufen")])])],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }