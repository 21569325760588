<script>
import {mapGetters} from "vuex";

export default {
  name: "MayorCardContent",
  computed: {
    ...mapGetters('communeDashboard', ['communeMayor']),
  }
}
</script>

<template>
<v-card width="100%" class="quadrat-card flex-card">
  <v-card-title>Bürgermeister</v-card-title>
  <v-card-text class="commune-dashboard-min-height">
    <div style="display: flex; flex-direction: row; justify-content: space-evenly" class="pb-0">
    <v-avatar color="primary" size="75" class="white--text">
      <v-img :src="communeMayor.picture"></v-img>
    </v-avatar>
    <p style="font-weight: bold" class="mb-0 commune-dashboard-card-text">
      {{ communeMayor.name }}<br>
      {{ communeMayor.position }}<br>
      {{ communeMayor.party }}<br>
      Geb. {{ communeMayor.dob }}
    </p>
    </div>
  </v-card-text>
  <v-spacer />
  <v-card-actions>
    <v-btn tile text color="primary" block link :href="communeMayor.url" target="_blank" class="font-weight-bold commune-dashboard-dotted-border commune-dashboard-card-btn">
      {{ communeMayor.url.split('//')[1]}}
      <v-icon right>mdi-arrow-top-right</v-icon>
    </v-btn>
  </v-card-actions>
</v-card>
</template>

<style scoped>

</style>
