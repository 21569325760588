<template>
  <div class="white">
    <v-divider></v-divider>
    <v-row no-gutters>
      <v-col :md="contentColumnWidth" :cols="12">
        <v-list-item three-line @click="$emit('click')">
          <v-list-item-icon class="ma-0 mr-3 news-icon-container">
            <v-img
              v-if="getNewsThumbUrl()"
              :width="iconProps.width"
              :height="iconProps.height"
              :src="getNewsThumbUrl()"
            />
            <v-img
              v-else-if="getNewsFirstImgUrl()"
              :width="iconProps.width"
              :height="iconProps.height"
              :src="getNewsFirstImgUrl()"
            />
            <div
              v-else
              align="center"
              class="d-inline-block icon-bg"
              :style="`width: ${iconProps.width}; height: ${iconProps.height}`"
            >
              <v-icon x-large :class="`${iconProps.spacing}`">
                $newsWhite
              </v-icon>
            </div>
            <div class="news-icon-overlap text-center">
              {{ entry.category }}
            </div>
          </v-list-item-icon>

          <v-list-item-content class="pt-0">
            <div class="">
              <v-chip
                v-show="showNeuBadge"
                x-small
                color="rgba(248, 168, 7, 0.3)"
                class="newBadge ml-1 mr-2"
                label
                >Neu</v-chip
              >
              <v-chip x-small class="dateBadge">{{ getNewsDate() }} </v-chip>
              <v-chip x-small class="dateBadge ml-2" v-if="showFilterBadge">{{
                entry.filter
              }}</v-chip>
            </div>
            <v-list-item-title
              v-if="$vuetify.breakpoint.width < 960"
              class="subtitle-1 news-mobile"
              :class="{ 'font-weight-bold primary--text': !entry.wasRead }"
              v-text="entry.title"
            >
            </v-list-item-title>
            <v-list-item-title
              v-else
              class="subtitle-1 "
              :class="{ 'font-weight-bold primary--text': !entry.wasRead }"
              v-text="entry.title"
            >
            </v-list-item-title>
            <v-list-item-subtitle v-if="this.$vuetify.breakpoint.width > 960">
              <span :class="{ 'font-weight-bold ': !entry.wasRead }">
                {{ entry.subtitle }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              :class="{ 'font-weight-bold': !entry.wasRead }"
            >
              <RichText
                v-if="!isPressNews()"
                :markdown-text="entry.content"
                show-plain-text
              />
              <div v-else v-text="entry.content_plaintext" />
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col
        md="5"
        cols="12"
        class="px-4"
        v-if="showContactPerson"
        style="background-color: #f7f9fc"
      >
        <contact-person-box
          :person="personInCharge"
          :vertical-design="false"
          v-if="$vuetify.breakpoint.mdAndUp"
        />
        <mobile-contact-person-box :person="personInCharge" v-else />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RichText from "@/components/ui/RichText";
import moment from "moment";
import ContactPersonBox from "@/components/layout/ContactPersonBox";
import MobileContactPersonBox from "@/components/layout/MobileContactPersonBox";

export default {
  name: "NewsListRow",
  components: {
    MobileContactPersonBox,
    ContactPersonBox,
    RichText
  },

  props: {
    entry: Object,
    settings: Object,
    iconProps: Object,
    showContactPerson: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_BASE_URL
    };
  },
  computed: {
    showFilterBadge() {
      return this.$route.name === "search";
    },
    contentColumnWidth() {
      return this.showContactPerson ? 7 : 12;
    },
    personInCharge() {
      return this.entry.personsInCharge.length > 0
        ? this.entry.personsInCharge[0]
        : this.$store.getters["user/userCommunePartner"];
    },
    showNeuBadge() {
      const newsDate = moment(this.getNewsDate(), "DD.MM.YYYY");
      const currentDate = moment();
      const daysBetween = moment.duration(currentDate.diff(newsDate)).asDays();

      return !this.entry.wasRead && daysBetween < 21;
    }
  },
  methods: {
    isPressNews() {
      return this.entry.is_press == true;
    },
    getNewsThumbUrl() {
      if (this.isPressNews()) {
        return this.entry.img_url;
      } else {
        return this.$store.getters.getStrapiImageUrl(
          this.entry.image,
          "thumbnail"
        );
      }
    },

    getNewsFirstImgUrl() {
      if (this.entry.images && this.entry.images.length > 0) {
        var str = this.baseUrl + this.entry.images[0].url;
        return str;
      } else {
        return this.$store.getters.getStrapiImageUrl(
          this.entry.image,
          "thumbnail"
        );
      }
    },

    getNewsDate() {
      // Generell benutzerdefinierte Datum vor automatisch generiertem Timestamp anzeigen
      // updated_at vor published_at anzeigen

      let date;

      // Wenn Presse (kein benutzerdefiniertes veröffentlichungsdatum vorhanden)
      if (this.isPressNews()) {
        if (this.entry.custom_updated_at) {
          date = this.entry.custom_updated_at;
        } else {
          date = this.entry.published_at;
        }
        // Wenn News (Reihenfolge: 1. custom_updated_at, 2. custom_published_at, 3. updated_at, 4. published_at)
      } else {
        if (this.entry.custom_updated_at) {
          date = this.entry.custom_updated_at;
        } else if (this.entry.custom_published_at) {
          date = this.entry.custom_published_at;
        } else if (this.entry.updated_at) {
          date = this.entry.updated_at;
        } else if (this.entry.published_at) {
          date = this.entry.published_at;
        }
      }

      return moment(date).format("DD.MM.YYYY");
    }
  }
};
</script>

<style scoped>
/* Make mouse cursor pointer, when hovering over this element */
.v-list-item:hover {
  cursor: pointer;
  background-color: #9c00471a;
}
/* Remove underline from router-link */
a {
  text-decoration: none;
}

/* Background gradient of icon */
.icon-bg {
  background: transparent linear-gradient(226deg, #b40055 0%, #025399 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
}
.dateBadge {
  color: #1f2b37;
  background: #dddddd 0% 0% no-repeat padding-box;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
}
.newBadge {
  color: #f88807;
  border-radius: 4px;
  font-size: 12px;
}
.v-list-item {
  padding: 0 0 !important;
}
.news-mobile {
  max-height: 58px;
  white-space: pre-line;
  word-break: normal;
  overflow: hidden;
  text-overflow: clip;
  text-overflow: ellipsis;
}
.news-icon-container {
  position: relative;
}
.news-icon-overlap {
  position: absolute;
  color: #dddddd;
  font-size: 12px;
  height: 16px;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #1f2b37cc;
  /* color: green; */
}
</style>
