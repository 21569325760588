<template>
  <div class="not-found-view">
    <lottie-animation
      class="animation"
      :loop="true"
      :autoplay="true"
      :animationData="require('@/assets/404-animation.json')"
    ></lottie-animation>
    <div class="text-center mt-10">
      <span class="custom-header-text new-font-bold">Es tut uns leid!</span>
      <div class="mt-2">
        Leider konnten wir die aufgerufene Seite nicht finden.
      </div>
      <v-btn class="mt-5 text-decoration-none" to="/home" color="primary">
        <v-icon left small>mdi-home</v-icon>
        ZURÜCK STARTSEITE
      </v-btn>
    </div>
    <div class="copyright">
      Source:
      <a href="https://lottiefiles.com/debbiediaz" target="_blank"
        >@Debbie Diaz / Lottiefiles</a
      >
    </div>
  </div>
</template>

<script>
import LottieAnimation from "lottie-web-vue";

export default {
  name: "NotFound",
  components: {
    LottieAnimation
  }
};
</script>

<style scoped></style>
