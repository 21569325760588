export default {
  getLieferstellen(state) {
    return state.lieferstellen;
  },
  getYearsForCommune: (state) => (communeId) => {
    const communeStatus = state.communeStatuses.find(
      (status) => status.communeId === communeId
    );

    if (!communeStatus) return [];
    return communeStatus.data.map((d) => d.year);
  },
  getLieferstellenForCommune: (state, getters) => () =>
    state.lieferstellen
      .map((l) => l.data)
      .flat()
      .filter((d) => d.year === getters.getSelectedYear)
      .map((d) => d.info),

  getCommunes(state) {
    return [...new Set(state.lieferstellen.map((l) => l.commune))];
  },
  getSelectedLieferstellenMalo(state) {
    return state.selectedLieferstellenMalo;
  },
  selectedLieferstelle(state) {
    return state.selectedLieferstelle;
  },
  getDetailsYears(state) {
    if (!state.selectedLieferstelle) return [];

    return [
      ...new Set(
        (state.selectedLieferstelle.comments ?? []).map((c) => c.year)
      ),
    ]
      .sort()
      .map((year) => {
        return {
          text: `Kalenderjahr ${year}`,
          value: year,
        };
      });
  },
  getSelectedCommune: (state) => state.selectedCommune,
  getSelectedYear: (state) => state.selectedYear,

  getCommuneStatuses: (state) => state.communeStatuses,
  getChartColors: (state) => state.chartColors,
  getSelectedCommuneStatus: (state, getters, rootState, rootGetters) => {
    if (!state.selectedCommune || !state.selectedYear) return "";

    if (rootGetters["user/isNewEmployee"]) {
      const communeName = rootGetters["generalContent/getCommuneNameById"](
        state.selectedCommune
      ).name;
      return state.communeStatuses
        .find((c) => c.commune === communeName)
        ?.data.find((d) => d.year === state.selectedYear)?.status;
    } else {
      return state.communeStatuses[0]?.data.find(
        (d) => d.year === state.selectedYear
      ).status;
    }
  },
  getComments: (state) => state.comments,
  getCommentsByUserid: (state) => state.commentsByUserid,
  getCommentsByCommune: (state) => state.commentsByCommune,
  getCommentsByNEW: (state) => state.commentsByNEW,
  getDetails: (state) => state.details,
  getUnreadComments: (state) => state.unreadComments,
  getMalosForNewLieferstelle(state, getters) {
    const rabattfaehigeLieferstellen = getters.getLieferstellen
      .filter((ls) => ls.rabatt)
      .map((ls) => ls.marktlokation);

    return state.autoCompleteMalos.filter((malo) => !rabattfaehigeLieferstellen.includes(malo));
  },
  getResponsiblePerson: (state) => state.responsiblePersons[0],
  getLieferstellenContact: (state) => state.lieferstellenContact,
  getCommentInputText: (state) => state.commentInputText,
};
