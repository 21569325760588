import httpClient from "@/store/httpClient";

export default {
    async loadRoles({ commit }) {
        const response = await httpClient.get("user-details/availableRoles");

        if(response.status === 200) {
            commit("SET_ROLES", response.data);
        }
    },
    async updatePreferredData({ commit }, data) {
        const response = await httpClient.post("user-details/updateUserPreferredData", data);

        if(response.status === 200) {
            commit("user/SET_PREFERRED_USER_DATA", data.preferredData, { root: true });
        }
    }
}
