import httpClient from "@/store/httpClient";

export default {
    setSelectedYears({ commit }, years) {
        commit('SET_SELECTED_YEARS', years);
    },
    setSelectedEnergietraeger({ commit }, energietraeger) {
        commit('SET_SELECTED_ENERGIETRAEGER', energietraeger);
    },
    setSelectedSektoren({ commit }, sektoren) {
        commit('SET_SELECTED_SEKTOREN', sektoren);
    },
    async loadCommuneNames({ commit }) {
        const response = await httpClient.get('verbrauchsdaten/getVerbrauchsdatenCommuneNames');

        if (response.status === 200) commit('SET_COMMUNE_NAMES', response.data);
    },
    setCommuneNames({ commit }, communeNames) {
        commit('SET_COMMUNE_NAMES', communeNames);
    },
    async loadVerbrauchsdaten({ commit }, communeName) {
        try {
            commit('SET_LOADED', false);
            let url = 'verbrauchsdaten';

            if(communeName) {
                url += `/${communeName}`;
            }

            const response = await httpClient.get(url);

            if(response.status === 200) commit('SET_VERBRAUCHSDATEN', response.data);
        } finally {
            commit('SET_LOADED', true);
        }
    },
    async loadContactPerson({ commit }) {
        const response = await httpClient.get('verbrauchsdaten/getContact');

        if (response.status === 200) commit('SET_CONTACT_PERSON', response.data);
    },
    async loadCommuneData({ commit }, communeName) {
        const response = await httpClient(`verbrauchsdaten/getCommuneStats?communeName=${communeName}`);

        if (response.status === 200) commit('SET_COMMUNE_DATA', response.data);
    }
}
