<template>
  <v-row class="py-0" no-gutters>
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <v-col :cols="mainColumnWidth">
        <div
          class="py-5 pt-10 px-10"
          style="background-color: rgb(248, 243, 245);"
        >
          <span class="text-h2">Konzessionsthemen</span>
          <v-divider class="my-10" />
          <span class="text-h4">Statusbericht</span>
          <v-row>
            <v-col cols="12">
              <konzessions-thema-header />
            </v-col>
            <v-col
              cols="12"
              v-for="item of getNewKonzessionsThemen"
              :key="item.themaId"
            >
              <konzessions-thema-card
                :thema-id="item.themaId"
                :vertrag="item.vertrag"
                :details="item.details"
                :commune-name="item.communeName"
                :updated="item.updated"
                :isNew="true"
                border-color="rgb(142, 27, 71)"
                elevation="2"
              />
            </v-col>
          </v-row>
        </div>
        <div class="py-5 pt-10 px-10">
          <v-row>
            <v-col cols="12" class="mb-5">
              <span class="text-h4">Alle Konzessionsthemen im Überblick</span>
            </v-col>
            <v-col cols="12">
              <konzessions-thema-header />
            </v-col>
            <v-col
              cols="12"
              v-for="item of getAllKonzessionsThemen"
              :key="item.themaId"
            >
              <konzessions-thema-card
                :thema-id="item.themaId"
                :vertrag="item.vertrag"
                :details="item.details"
                :commune-name="item.communeName"
                :updated="item.updated"
                :isNew="item.active"
                border-color="rgb(43,70,133)"
                background-color="rgb(244,246,249)"
              />
            </v-col>
            <v-col
              cols="12"
              style="display: flex"
              v-if="getArchivedItems.length > 0"
            >
              <v-btn
                @click="showArchiv = !showArchiv"
                class="mx-auto"
                color="primary"
                text
              >
                Archiv {{ showArchiv ? "ausblenden" : "einblenden" }}
                <v-icon right>{{
                  showArchiv ? "mdi-arrow-up" : "mdi-arrow-down"
                }}</v-icon>
              </v-btn>
            </v-col>
            <template v-if="showArchiv">
              <v-col cols="12">
                <span class="text-h4">Archiv</span>
              </v-col>
              <v-col cols="12">
                <konzessions-thema-header />
              </v-col>
              <v-col
                cols="12"
                v-for="item of getArchivedItems"
                :key="item.themaId"
              >
                <konzessions-thema-card
                  :thema-id="item.themaId"
                  :vertrag="item.vertrag"
                  :details="item.details"
                  :commune-name="item.communeName"
                  :updated="item.updated"
                  :isNew="item.active"
                  border-color="rgb(43,70,133)"
                  background-color="rgb(245,245,245)"
                />
              </v-col>
            </template>
          </v-row>
        </div>
      </v-col>
      <v-col
        v-if="getSelectedThema"
        :cols="12 - mainColumnWidth"
        class="sidebar px-5 pt-1"
      >
        <div style="position: sticky; top: 64px">
          <div style="display: flex; flex-direction: row-reverse">
            <v-btn icon color="primary" @click="updateSelectedThema(undefined)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="subtitle-1">Verlauf</div>
          <div class="text-h6">{{ getSelectedThema.vertrag }}</div>
          <v-timeline dense>
            <v-timeline-item
              v-for="item of getSelectedThemaDetails"
              :key="item.id"
            >
              <div class="subtitle-2">{{ formatDateString(item.Datum) }}</div>
              <vue-markdown-it
                :source="item.Details"
                style="white-space: break-spaces; font-size: 15px; line-height: 1.25"
                class="mb-0"
              />
            </v-timeline-item>
          </v-timeline>
        </div>
      </v-col>
    </template>
    <template v-else>
      <div
        class="py-10 px-5"
        style="background-color: rgb(248, 243, 245); width: 100%;"
      >
        <v-row class="mt-2">
          <v-col cols="12">
            <span class="text-h4">Konzessionsthemen</span>
            <v-select
              label="Sortierung"
              :items="mobileSortOptions"
              v-model="selectedMobileSortOption"
              hide-details
              dense
              class="mt-4"
              @change="onMobileSortOptionChanged"
            />
            <v-divider class="my-6" />
            <span class="text-h5">Statusbericht</span>
          </v-col>
          <v-col
            cols="12"
            v-for="item of getNewKonzessionsThemen"
            :key="item.themaId"
          >
            <konzessions-thema-mobile-card
              :thema-id="item.themaId"
              :vertrag="item.vertrag"
              :details="item.details"
              :commune-name="item.communeName"
              :updated="item.updated"
              :isNew="true"
              border-color="rgb(142, 27, 71)"
              elevation="2"
              secondary-color="rgb(249,250,252)"
            />
          </v-col>
        </v-row>
      </div>
      <v-row class="py-5 px-5">
        <v-col cols="12">
          <span class="text-h5">Alle Konzessionsthemen im Überblick</span>
        </v-col>
        <v-col
          cols="12"
          v-for="item of getAllKonzessionsThemen"
          :key="item.themaId"
        >
          <konzessions-thema-mobile-card
            :thema-id="item.themaId"
            :vertrag="item.vertrag"
            :details="item.details"
            :commune-name="item.communeName"
            :updated="item.updated"
            :isNew="item.active"
            border-color="rgb(43,70,133)"
            background-color="rgb(244,246,249)"
            elevation="1"
            secondary-color="rgb(216,223,235)"
          />
        </v-col>
        <v-col
          cols="12"
          style="display: flex"
          v-if="getArchivedItems.length > 0"
        >
          <v-btn
            @click="showArchiv = !showArchiv"
            class="mx-auto"
            color="primary"
            text
          >
            Archiv {{ showArchiv ? "ausblenden" : "einblenden" }}
            <v-icon right>{{
              showArchiv ? "mdi-arrow-up" : "mdi-arrow-down"
            }}</v-icon>
          </v-btn>
        </v-col>
        <template v-if="showArchiv">
          <v-col cols="12">
            <span class="text-h5">Archiv</span>
          </v-col>
          <v-col cols="12" v-for="item of getArchivedItems" :key="item.themaId">
            <konzessions-thema-mobile-card
              :thema-id="item.themaId"
              :vertrag="item.vertrag"
              :details="item.details"
              :commune-name="item.communeName"
              :updated="item.updated"
              :isNew="item.active"
              border-color="rgb(43,70,133)"
              background-color="rgb(244,246,249)"
              elevation="1"
              secondary-color="rgb(216,223,235)"
            />
          </v-col>
        </template>
      </v-row>
    </template>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueMarkdownIt from "vue-markdown-it";
import KonzessionsThemaCard from "@/components/ui/KonzessionsThemaCard.vue";
import KonzessionsThemaHeader from "@/components/ui/KonzessionsThemaHeader.vue";
import KonzessionsThemaMobileCard from "@/components/ui/KonzessionsThemaMobileCard.vue";

export default {
  name: "Konzessionsthemen",
  components: {
    KonzessionsThemaHeader,
    VueMarkdownIt,
    KonzessionsThemaCard,
    KonzessionsThemaMobileCard
  },
  data: () => ({
    loading: false,
    showArchiv: false,
    mobileSortOptions: [
      {
        text: "Vertrag aufsteigend",
        value: {
          key: "Vertrag",
          order: "asc"
        }
      },
      {
        text: "Vertrag absteigend",
        value: {
          key: "Vertrag",
          order: "desc"
        }
      },
      {
        text: "Kommune aufsteigend",
        value: {
          key: "Kommune",
          order: "asc"
        }
      },
      {
        text: "Kommune absteigend",
        value: {
          key: "Kommune",
          order: "desc"
        }
      },
      {
        text: "Änderungsdatum aufsteigend",
        value: {
          key: "Änderungsdatum",
          order: "asc"
        }
      },
      {
        text: "Änderungsdatum absteigend",
        value: {
          key: "Änderungsdatum",
          order: "desc"
        }
      }
    ],
    selectedMobileSortOption: {
      key: "Vertrag",
      order: "asc"
    }
  }),
  methods: {
    ...mapActions("konzessionsthemen", [
      "loadKonzessionsThemen",
      "setSelectedThemaId",
      "setSortBy"
    ]),
    updateSelectedThema(id) {
      this.setSelectedThemaId(id);
    },
    formatDateString(dateString) {
      return new Date(dateString).toLocaleDateString("de-DE", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric"
      });
    },
    onMobileSortOptionChanged() {
      this.setSortBy(this.selectedMobileSortOption);
    }
  },
  computed: {
    ...mapGetters("user", ["enableKonzessionssachstaende"]),
    ...mapGetters("konzessionsthemen", [
      "getSelectedThema",
      "getNewKonzessionsThemen",
      "getAllKonzessionsThemen",
      "getArchivedItems",
      "getSelectedThemaDetails"
    ]),
    mainColumnWidth() {
      return this.getSelectedThema ? 9 : 12;
    }
  },
  async mounted() {
    if (!this.enableKonzessionssachstaende) {
      this.$router.back();
      return;
    }

    try {
      this.loading = true;
      await this.loadKonzessionsThemen();
    } finally {
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.sidebar {
  border-left: #b40055 solid 1px;
}

vue-markdown-it {
  white-space: pre;
  font-size: 14px !important;
}

p {
  margin-bottom: 4px !important;
}
</style>
