export default {
  SET_SERVICES(state, servicesData) {
    state.servicesList = servicesData;

    let servicesObj = {};
    servicesData.forEach((service) => {
      servicesObj[service.id] = service;
    });

    state.servicesIdMap = servicesObj;
  },

  // SET_SERVICE_POSTS(state, servicePostsData) {
  //     // default sorting is newest first
  //     _sortBy(servicePostsData, 'published_at', true);

  //     let postsByServiceMap = {};

  //     // append dynamic / computed attributes to each service post
  //     servicePostsData.forEach((servicePost, index) => {
  //         _enrichServicePost(servicePost, state.servicesIdMap);

  //         const postServiceIds = servicePost.services;

  //         if (postServiceIds && postServiceIds.length > 0) {
  //             // add post to list of each service it's assigned to
  //             postServiceIds.forEach(serviceId => {
  //                 if (!postsByServiceMap[serviceId]) {
  //                     // create post list if none exists yet
  //                     postsByServiceMap[serviceId] = [];
  //                 }

  //                 postsByServiceMap[serviceId].push(servicePost)
  //             });

  //         } else {
  //             // remove service posts that are not assigned to any service
  //             servicePostsData.splice(index, 1);
  //         }

  //     });

  //     state.servicePosts = servicePostsData;
  //     state.servicePostsMap = postsByServiceMap;
  // },
};
