<template>
  <v-row no-gutters class="min-height-100 new-wrapper" v-if="serviceByIds">
    <v-col cols="12" v-if="serviceByIds.title === 'Kommunale Wärmeplanung'">
      <WaermeplanungView />
    </v-col>
    <v-col cols="12" v-else-if="serviceByIds.title === 'FAQ Kommunale Wärmeplanung'">
        <WaermeFAQ />
    </v-col>

    <template v-else>
    <!-- MAIN CONTENT -->
      <v-col :cols="colSize" class="pa-10">
      <v-row>
        <v-col align="left" cols="12">
          <span class="custom-header-text new-font-bold">
            {{ serviceByIds.title }}
          </span>
        </v-col>
      </v-row>

      <!-- TEXT SECTION 1 -->
      <v-row no-gutters class="mt-5">
        <vue-markdown-it
          v-if="serviceByIds.content1"
          :source="serviceByIds.content1"
          id="content1"
        />
      </v-row>

      <!-- SERVICE/SUBSERVICE ANGEBOT -->
      <template v-if="subServices.length != 0">
        <v-row no-gutters class="mt-6 text-h5" id="service-overview-title">
          {{
            isNewEmployee && hasKonzession
              ? "Unsere Kommunen:"
              : "Unser Angebot:"
          }}
        </v-row>
        <v-row class="mt-3">
          <v-col
            v-for="(subService, index) in subServices"
            :key="subService.id"
            :cols="$vuetify.breakpoint.smAndDown ? 12 : 6"
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'pa-1'
                : index % 2
                ? 'pl-2'
                : 'pr-2'
            "
          >
            <ButtonCard :object="subService" routeName="sub-service" />
          </v-col>
        </v-row>
      </template>

      <!-- CAROUSEL OR IMAGE-->
      <template v-if="images">
        <v-row v-if="images.length > 1" no-gutters class="mt-6 new-iframe">
          <Carousel :slides="images" />
        </v-row>
        <v-row v-else-if="images.length == 1" class="px-3 mt-6">
          <v-img class="new-iframe" :src="`${baseUrl}${images[0].url}`"></v-img>
          <p v-if="images[0].caption" class="text-caption">
            {{ images[0].caption }}
          </p>
        </v-row>
      </template>

      <!-- TEXT SECTION 2 -->
      <v-row no-gutters class="mt-5">
        <vue-markdown-it
          v-if="serviceByIds.content2"
          :source="serviceByIds.content2"
          id="content2"
        />
      </v-row>

      <QuartiersentwicklungView v-if="serviceByIds.title === 'Quartiersentwicklung'"
                                :contact-person="serviceByIds.personsInCharge[0]" />

      <RightColumnGroup
        v-if="$vuetify.breakpoint.xs"
        :communePartner="communePartner"
        :communeRegionalLeader="communeRegionalLeader"
        :contentObject="serviceByIds"
      />
    </v-col>

    <!-- RIGHT SIDE CONTENT - ANSPRECHPARTNER, DOWNLOADS, INTERESSANTES -->
    <v-col
      v-if="$vuetify.breakpoint.smAndUp"
      cols="4"
      class="contact_container pl-4"
    >
      <RightColumnGroup
        :communePartner="communePartner"
        :contentObject="serviceByIds"
        :communeRegionalLeader="communeRegionalLeader"
      />
    </v-col>
    </template>
  </v-row>
</template>

<script>
import { checkIconName } from "@/mixins/checkIconName";
import Carousel from "@/components/layout/Carousel.vue";
import ButtonCard from "@/components/layout/ButtonCard.vue";
import RightColumnGroup from "@/components/layout/RightColumnGroup.vue";
import VueMarkdownIt from "vue-markdown-it";
import httpClient from "@/store/httpClient";
import WaermeplanungView from "@/views/Waermeplanung.vue";
import WaermeFAQ from "@/views/WaermeFAQ.vue";
import QuartiersentwicklungView from "@/views/QuartiersentwicklungView.vue";
import {mapGetters} from "vuex";

export default {
  name: "ServiceDetail",
  components: {
    QuartiersentwicklungView,
    WaermeFAQ,
    WaermeplanungView,
    Carousel,
    ButtonCard,
    RightColumnGroup,
    VueMarkdownIt
  },
  mixins: [checkIconName],

  data: () => ({
    baseUrl: process.env.VUE_APP_API_BASE_URL,
    iframeUrl: undefined
  }),
  computed: {
    ...mapGetters('user', ['enableMoments']),
    colSize() {
      if (this.$vuetify.breakpoint.smAndUp) return 8;
      else return 12;
    },
    images() {
      return this.serviceByIds.images;
    },

    menuItems() {
      return this.$store.getters["navigation/getMainNavObject"];
    },

    serviceId() {
      return Number(this.$route.params.serviceId);
    },

    serviceAreaId() {
      return Number(this.$route.params.serviceAreaId);
    },

    serviceAreaByServiceAreaId() {
      return this.menuItems.serviceAreaItems.filter(
        s => s.id == this.serviceAreaId
      )[0];
    },

    serviceByIds() {
      let serviceArea = this.menuItems.serviceAreaItems.filter(
        s => s.id == this.serviceAreaId
      )[0];
      return serviceArea.services.find(s => s.id == this.serviceId);
    },

    communePartner() {
      return this.$store.getters["user/userCommunePartner"];
    },

    communeRegionalLeader() {
      return this.$store.getters["user/userCommuneRegionalLeader"];
    },
    isNewEmployee() {
      return this.$store.getters["user/isNewEmployee"];
    },
    communeServices() {
      return this.$store.getters["serviceOverview/getCommunesForService"](
        this.serviceByIds.title
      );
    },
    communeSubServices() {
      return this.$store.getters["serviceOverview/getCommunesForSubService"](
        this.serviceByIds.title
      );
    },
    hasKonzession() {
      return [
        "Stromlieferung",
        "Stromversorgung",
        "Erdgaslieferung",
        "Erdgasversorgung"
      ].includes(this.serviceByIds.title);
    },
    subServices() {
      if(this.enableMoments) return this.serviceByIds.subServices;
      else return this.serviceByIds.subServices.filter(s => s.title !== 'NEW Erleben');
    }
  },
  async mounted() {
    // TODO: Move to store
    if (this.isNewEmployee && this.serviceByIds.title === "Energieberatung") {
      const { data } = await httpClient.get(
        "/waermeplanung/getUrl?commune=Brüggen"
      );
      const url = `${location.protocol}//${location.host}${data.url}`;

      this.iframeUrl = url;

      if (location.host === "localhost:8080") {
        this.iframeUrl = this.iframeUrl.replace(
          "http://localhost:8080",
          "https://kommunalportal-dev.new.de"
        );
      }
    }
  },
  methods: {
    getCommuneServiceUrl(id) {
      return `/service-area/${this.serviceAreaId}/service/${id}`;
    }
  }
};
</script>
