<template>
  <router-link :to="to" class="text-decoration-none">
    <v-card elevation="1" class="rounded">
      <v-card-text>
        <v-row>
          <v-col cols="10" class="primary-text new-font-bold">{{
            title ? title : object.title
          }}</v-col>
          <v-col class="text-center">
            <v-icon color="primary">
              mdi-arrow-right
            </v-icon>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </router-link>
</template>

<script>
export default {
  props: ["object", "routeName", "title", "link"],

  data() {
    return {
      moveRight: false
    };
  },

  computed: {
    to() {
      return this.link
        ? this.link
        : {
            name: this.routeName,
            params: this.routeParam
          };
    },
    routeParam() {
      let returnObj = {};
      let key = "";
      switch (this.routeName) {
        case "service":
          key = "serviceId";
          break;
        case "service-area":
          key = "serviceAreaId";
          break;
        case "sub-service":
          key = "subServiceId";
          break;
      }
      returnObj[key] = this.object.id;
      return returnObj;
    },

    isLineBreak() {
      let characters = this.object.title.split("");
      if (characters.length > 16) return true;
      else return false;
    }
  }
};
</script>

<style scoped>
/* Make mouse cursor pointer, when hovering over this element */
.v-card:hover {
  cursor: pointer;
  background-color: #9c00471a;
  transition: all 0.2s ease-in-out;
}
/* Remove underline from router-link */
a {
  text-decoration: none;
}

/* Background gradient of icon */
.icon-bg {
  background-image: linear-gradient(to top right, #025399, #b40055);
}

.handle-overflow {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
