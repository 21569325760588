
/**
 * @param {Array} list
 * @param {String} attr
 * @param {Boolean|undefined} desc - sort order descending
 * @private
 */
export function _sortBy(list, attr, desc) {
    list.sort((s1, s2) => {
        let returnVal = 0;

        if (s1[attr] < s2[attr]) { returnVal = -1; }
        if (s1[attr] > s2[attr]) { returnVal = 1; }

        if (desc == true) {
            returnVal *= -1; // invert
        }

        return returnVal;
    });
}