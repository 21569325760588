<template>
  <div>
    <iframe
      style="
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      "
      class="new-wrapper"
      width="400"
      height="650"
      marginheight="0"
      marginwidth="0"
      scrolling="yes"
      frameborder="0"
      id="baumapframe"
      name="baumapframe"
      src="https://www.google.com/maps/d/u/0/embed?mid=13DFnofib0CTodJs1pK_pb6RC2oO_RMUU"
    ></iframe>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Bauprojekte",
  components: {}
};
</script>
