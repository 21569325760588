<script>
import moment from "moment";
import {mapActions} from "vuex";

export default {
  name: "NotificationRow",
  props: ["notification"],
  computed: {
    previewText() {
      const maxLength = 10;
      const words = this.notification.Content?.split(" ")

      if(!words) return '';
      if (words.length < maxLength) return this.notification.Content;
      return words.slice(0, maxLength).join(" ") + " ...";
    },
    date() {
      return moment(new Date(this.notification.created_at)).format("DD.MM.YYYY");
    }
  },
  methods: {
    ...mapActions("notifications", ["hideNotificationsList", "markNotificationAsRead"]),
    async onNotificationClick() {
      await this.markNotificationAsRead(this.notification.id);
      await this.hideNotificationsList();
    }
  }
}
</script>

<template>
  <v-card class="ma-3 pa-2" :elevation="notification.read ? 1 : 4" :to="notification.url" @click="onNotificationClick">
    <v-card-subtitle class="pa-0">
      <span v-if="!notification.read" class="badge px-2 mr-1 new-item">NEU</span>
      <span class="thema-badge badge px-2">
              {{ date }}
            </span>
    </v-card-subtitle>
    <v-card-title class="pa-0 primary-text font-weight-bold" style="font-size: 15px; word-break: break-word; line-height: 1.5rem">
      {{  notification.Titel }}
    </v-card-title>
    <v-card-text class="pa-0" v-if="previewText.length > 0">
      {{ previewText }}
    </v-card-text>
  </v-card>
</template>

<style scoped>

</style>
