import httpClient from "@/store/httpClient";
import router from "@/router/index";
import RestMethods from "@/store/RestMethods";

const SUBSCRIBED_TO_NEWSLETTER_MODEL = RestMethods.init(
  "subscribed-to-newsletters"
);

export default {
  /**
   * @param getters
   * @param commit
   * @param {string|null} authToken (optional)
   * @returns {Promise<void>}
   */
  async verifyAuth({ getters, commit, dispatch }, authToken) {
    if (authToken || getters.authToken) {
      if (authToken) {
        commit("SET_AUTH_TOKEN", authToken);
      }

      // check if auth token is still valid for API
      const res = await httpClient.get("/users/me").catch(() => false);

      if (!res) {
        // remove expired / invalid auth token
        commit("REMOVE_AUTH_TOKEN");
      } else {
        // // map users serviceAreas and use them to fetch services
        // let serviceAreas = res.data.commune.ServiceConfiguration.serviceAreas
        // let serviceAreasTitles = serviceAreas.map(s => s.title)
        // // create url query string like 'serviceArea.name=baeder&serviceArea.name=versogrung'
        // let params = new URLSearchParams();
        // for (let serviceAreasTitle of serviceAreasTitles) {
        //     params.append('serviceArea.title', serviceAreasTitle)
        // }
        // let queryString = params.toString()
        // // fetch services
        // const servicesResponse = await httpClient.get(`/services?${queryString}`).catch(() => false);
        // console.log('servicesResponse', servicesResponse)

        // // add user data to store of authenticated user
        // commit('SET_SERVICES', servicesResponse.data);  // new

        // commit('SET_SERVICES', res.data.commune.services);  // old

        const { data } = res;

        const newsRes = await httpClient.get("/users/me?type=news");
        data.news = newsRes.data;

        const navigationRes = await httpClient.get("/users/me?type=navigation");
        data.navigation = navigationRes.data;

        const foerdermittelRes = await httpClient.get(
          "/users/me?type=foerdermittel"
        );
        data.foerdermittel = foerdermittelRes.data;

        const faqRes = await httpClient.get("/users/me?type=faq")
        data.faq = faqRes.data;

        commit("SET_USER", data);

        dispatch("news/fetchNews", data.news, { root: true });
        const myServices = [];
        // console.log(res.data.commune.myServices);
        /*
                res.data.commune.myServices.forEach(myService => {
                    const service = myService.serviceArea;
                    service.title = myService.serviceAreaName;
                    myServices.push(service);
                })
*/



        if (data.isNewEmployee) {
          dispatch("serviceOverview/setDetails", data.serviceDetails, {
            root: true,
          });

          const communeNamesRes = await httpClient.get('/users/me?type=commune-names');
          commit('generalContent/SET_COMMUNE_NAMES', communeNamesRes.data, { root: true });
        }
        commit(
          "navigation/SET_NAVIGATION_LISTS",
          myServices.length > 0 ? myServices : data.navigation,
          { root: true }
        );
        dispatch("generalContent/fetchGeneralContent", null, { root: true });
        commit("foerdermittel/SET_FOERDERMITTEL", data.foerdermittel, {
          root: true,
        });

        dispatch("notifications/loadNotificationsConfig", null, { root: true });
      }
    }

    // redirect after logging in with given 'authToken' param
    if (authToken && getters.isLoggedIn) {
      const targetRoute = !getters.isUserApproved ? "approval-pending" : "home";
      router.push({ name: targetRoute });
    }
  },

  checkDevDialog({ commit }) {
    commit("SET_SHOW_DEV_DIALOG");
  },
  // todo: local auth (email + pw) for strapi is not longer needed (using SSO instead)
  /*async login({dispatch}, {user, password}) {
        const URL_LOGIN = '/auth/local';
        const res = await httpClient.post(URL_LOGIN, {
            // map to API param names
            identifier: user,
            password: password
        }).catch(err => {
            console.log('auth failed ', err);
        });

        //todo: check if login was successful

        // will fetch full user data
        dispatch('verifyAuth', res.data.jwt);

        // redirect to platform landing page
        router.push({name: 'home'});
    },*/

  logout({ state, commit }) {
    commit("REMOVE_AUTH_TOKEN");

    //todo: trigger API logout endpoint (SSO?)

    // to support logging out from all windows
    // localStorage.setItem("logout", Date.now());

    state.isLoggedIn = false;
    router.push({ name: "login" });
  },
  hideNewsletterSignUp({ state, commit }) {
    SUBSCRIBED_TO_NEWSLETTER_MODEL.create({
      UserId: state.id,
    });
    commit("SET_SHOW_NEWSLETTER_SIGNUP", false);
  },
};
