<script>
import { mapGetters } from "vuex";

export default {
  name: "NewsCarousel",
  data: () => ({
    currentSlide: 0
  }),
  props: {
    slideCount: {
      type: Number,
      default: 5
    }
  },
  computed: {
    ...mapGetters(["getStrapiImageUrl"]),
    ...mapGetters("news", ["allPosts", "newsListOrderByDate"]),
    posts() {
      return this.newsListOrderByDate.slice(this.slideCount * -1).reverse();
    },
    descriptionPosition() {
      return this.$vuetify.breakpoint.mdAndDown
        ? {
            display: "flex",
            flexDirection: "column-reverse",
            justifyContent: "flex-start"
          }
        : {
            display: "flex"
          };
    },
    titleLength() {
      return this.$vuetify.breakpoint.smAndDown
        ? "two-line-clamp"
        : "five-line-clamp";
    }
  },
  methods: {
    getPostImgUrl(post) {
      return post.img_url ?? this.getStrapiImageUrl(post.images[0]);
    },
    getPostDate(post) {
      const postDate = new Date(post.pub_date ?? post.published_at);
      const date = postDate
        .getDate()
        .toString()
        .padStart(2, "0");
      const month = (postDate.getMonth() + 1).toString().padStart(2, "0");
      const year = postDate.getFullYear();
      return `${date}.${month}.${year}`;
    },
    navigateToPost(post) {
      if (post.category === "Presse") {
        this.$router.push({
          name: "pressDetail",
          params: { pressId: post.id }
        });
      } else {
        this.$router.push({ name: "newsDetail", params: { newsId: post.id } });
      }
    },
    hasPostImage(post) {
      const imageURL = post.img_url ?? post.images[0];
      if (imageURL) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<template>
  <v-carousel
    v-model="currentSlide"
    :height="$vuetify.breakpoint.mdAndDown ? '450px' : '317px'"
    hide-delimiter-background
    show-arrows-on-hover
    cycle
  >
    <v-carousel-item v-for="(post, i) in posts" :key="post.id">
      <v-sheet
        height="100%"
        tile
        :style="descriptionPosition"
        class="news-card"
      >
        <!-- title content -->
        <v-sheet
          @click="navigateToPost(post)"
          class="title-sheet post-content-position"
          :color="i % 2 === 0 ? 'rgb(119, 0, 101)' : '#b40055'"
          dark
          width="100%"
          height="100%"
        >
          <div class="mb-2">
            <span class="tag-pill">
              {{ post.category }}: {{ getPostDate(post) }}
            </span>
          </div>
          <span class="white--text text-h5 post-title" :class="titleLength">
            <template>
              {{ post.title }}
            </template>
          </span>
          <v-icon class="post-icon-position" large>mdi-arrow-right-thin</v-icon>
        </v-sheet>

        <!-- image content -->
        <!--<v-img :src="getPostImgUrl(post)" height="317px" style="width: calc(100% - 296px)" />-->
        <v-img
          v-if="hasPostImage(post)"
          :src="getPostImgUrl(post)"
          position="center center"
          :style="
            $vuetify.breakpoint.mdAndDown
              ? 'width: 100%; height: 250px'
              : 'width: calc(66% + 8px);'
          "
        />
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>

<style scoped>
.title-sheet {
  padding: 41px 36px;
  cursor: pointer;
}

.tag-pill {
  background: white;
  color: rgb(119, 0, 101);
  padding: 1px 6px;
  font-size: 14px;
}

.post-title {
  font-size: 2rem !important;
}
.post-content-position {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.post-icon-position {
  position: absolute;
  bottom: 12px;
  right: 12px;
}
.five-line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 5;
  line-height: 1em;
  max-height: 5em;
}
.two-line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  line-height: 1em;
  max-height: 2em;
}
</style>
