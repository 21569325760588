export default {
    getFilteredFoerdermittel(state) {
        const sortByFristDate = function sortByFristDate(a, b) {
            const dateA = Date.parse(a.Frist);
            const dateB = Date.parse(b.Frist);

            if(dateA < dateB) return -1;
            else if(dateA > dateB) return 1;
            else return 0;
        };

        const sortByCreatedDate = function sortByCreatedDate(a, b) {
            const dateA = Date.parse(a.created_at);
            const dateB = Date.parse(b.created_at);

            if(dateA < dateB) return -1;
            else if(dateA > dateB) return 1;
            else return 0;
        }

        const filteredFoerdermittel = state.foerdermittel.filter(foerdermittel => state.activeFilter === 'Alle' || foerdermittel.themen.includes(state.activeFilter));

        if(state.sortBy === 'Fristende') {
            filteredFoerdermittel.sort(sortByFristDate)
        } else {
            filteredFoerdermittel.sort(sortByCreatedDate)
        }
        return filteredFoerdermittel;
    },
    getFoerderthemenWithCount(state) {
        const themen = [...new Set(state.foerdermittel.map(foerdermittel => foerdermittel.themen).flat())];
        const themenWithCount = [];

        for(let thema of themen) {
            themenWithCount.push({
                name: thema,
                count: state.foerdermittel.filter(foerdermittel => foerdermittel.themen.includes(thema)).length
            })
        }
        return themenWithCount;
    },
    getSelectedFilter(state) {
        return state.activeFilter;
    },
    getFoerdermittelById: (state) => (id) => {
        return state.foerdermittel.find(entry => entry.id === id);
    },
    getLatestFoerdermittel(state) {
        const ids = state.foerdermittel.map(entry => entry.id).sort((a,b) => a - b).reverse();
        
        return state.foerdermittel.find(entry => entry.id === ids[0]);
    },
}
