import httpClient from "@/store/httpClient";

export default {
  async loadContracts({ commit }) {
    const response = await httpClient("/enaio");

    if (response.status !== 200) return;

    await commit("SET_CONTRACTS", response.data);
  },
};
