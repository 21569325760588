export default {
    SET_CONFIG(state, config) {
        state.config = config
    },
    UPDATE_CONFIG_VALUE(state, {key, value}) {
        state.config[key] = value;
    },
    SET_NOTIFICATIONS(state, notifications) {
        state.notifications = notifications;
    },
    TOGGLE_SHOW_NOTIFICATIONS(state) {
        state.showNotifications = !state.showNotifications;
    },
    SET_SHOW_NOTIFICATIONS(state, showNotifications) {
        state.showNotifications = showNotifications;
    }
}
