import httpClient from "@/store/httpClient";
import { saveAs } from "file-saver";

export default {
  async loadLieferstellen({ commit }, { commune, year }) {
    const response = await httpClient.get(
      commune
        ? `lieferstellen?commune=${commune}&year=${year}`
        : `lieferstellen?year=${year}`
    );

    if (response.status !== 200) return;

    commit("SET_LIEFERSTELLEN", response.data);
  },
  clearLieferstellen({ commit }) {
    commit("SET_LIEFERSTELLEN", []);
  },
  async updateLieferstellenData({ commit }, { commune, adresszeile1, adresszeile2, notiz, marktlokation }) {
    let url = '/lieferstellen/updateAdditionalData';

    if(commune) {
      url = `${url}?commune=${commune}`;
    }

    const response = await httpClient.put(url, {
      adresszeile1,
      adresszeile2,
      notiz,
      marktlokation
    });

    if(response.status === 200) {
      commit("UPDATE_ADDITIONAL_LIEFERSTELLEN_DATA", { adresszeile1, adresszeile2, notiz, marktlokation })
    }
  },
  async updateLieferstelle({ dispatch, commit, getters }, lieferstelle) {
    const response = await httpClient.put(
      `lieferstellen/${lieferstelle.id}`,
      lieferstelle
    );

    if (response.status === 200) {
      commit("UPDATE_LIEFERSTELLE", lieferstelle);
      if (
        getters.selectedLieferstelle?.marktlokation ===
        lieferstelle.marktlokation
      ) {
        commit("UPDATE_SELECTED_LIEFERSTELLE", lieferstelle);
      }
      dispatch(
        "msgSnackbar/showMessage",
        {
          text:
            "Rabatt für Marktlokation: " +
            lieferstelle.marktlokation +
            " erfolgreich bearbeitet",
          color: "#1F2B37CC",
          icon: "mdi-check"
        },
        { root: true }
      );
    } else {
      dispatch(
        "msgSnackbar/showMessage",
        {
          text:
            "Datenbank antwortet nicht. Bearbeitung von Marktlokation: " +
            lieferstelle.marktlokation +
            " fehlgeschlagen. Fehlercode: " +
            response.status,
          color: "red",
          icon: "mdi-alert"
        },
        { root: true }
      );
    }
  },
  async loadComments({ commit, getters }) {
    const response = await httpClient.get(
      `lieferstellen/${getters.selectedLieferstelle.marktlokation}/comment`
    );

    if (response.status === 200) {
      commit("SET_COMMENTS", response.data);
      commit(
        "SET_UNREAD_COMMENTS",
        getters.getUnreadComments.filter(
          c => c !== getters.selectedLieferstelle.marktlokation
        )
      );
    }
  },
  async updateSelectedLieferstellenMalo({ commit }, selectedLieferstellenMalo) {
    commit("UPDATE_SELECTED_LIEFERSTELLEN_MALO", selectedLieferstellenMalo);
  },
  async updateSelectedLieferstelle({ commit }, selectedLieferstelle) {
    commit("UPDATE_SELECTED_LIEFERSTELLE", selectedLieferstelle);
  },
  async addComment(
    { dispatch, rootGetters, getters },
    { marktlokation, comment }
  ) {
    let url = `lieferstellen/${marktlokation}/comment`;

    if (rootGetters["user/isNewEmployee"]) {
      url += `?commune=${getters.selectedLieferstelle.commune}`;
    }
    const response = await httpClient.post(url, comment);

    if (response.status === 200 && getters.selectedLieferstelle) {
      await dispatch("loadComments");
    }
  },
  async updateSelectedCommune({ commit }, selectedCommune) {
    commit("UPDATE_SELECTED_COMMUNE", selectedCommune);
  },
  updateSelectedYear({ commit, dispatch, getters }, selectedYear) {
    commit("UPDATE_SELECTED_YEAR", selectedYear);
    dispatch("loadLieferstellen", {
      commune: getters.getSelectedCommune,
      year: getters.getSelectedYear
    });
    dispatch("loadAutoCompleteMalos", {
        commune: getters.getSelectedCommune,
        year: getters.getSelectedYear
      });
  },
  async loadCommuneStatuses({ commit }) {
    const response = await httpClient.get("lieferstellen/dashboard");

    if (response.status !== 200) return;

    commit("SET_COMMUNE_STATUSES", response.data);
  },
  async updateCommuneStatus({ dispatch }, { commune, year, status }) {
    const response = await httpClient.put(
      `lieferstellen/update-status?commune=${commune}&year=${year}&status=${status}`
    );

    if (response.status !== 200) return;

    dispatch("loadCommuneStatuses");
  },
  async loadDetails({ commit, getters }) {
    const response = await httpClient.get(
      `lieferstellen/${getters.selectedLieferstelle.marktlokation}/${getters.selectedLieferstelle.geschaeftspartnerNummer}/details`
    );

    if (response.status === 200) {
      commit("SET_DETAILS", response.data);
    } else {
      commit("SET_DETAILS", []);
    }
  },
  async loadUnreadComments({ commit }) {
    const response = await httpClient.get("lieferstellen/comments/unread");

    commit("SET_UNREAD_COMMENTS", response.status === 200 ? response.data : []);
  },
  async downloadPdf({ getters }) {
    const response = await httpClient.get(
      `lieferstellen/export/${getters.getSelectedCommune}/${getters.getSelectedYear}`,
      {
        responseType: "blob"
      }
    );

    if (response.status === 200) {
      saveAs(response.data, "kommunalrabatt-export.pdf");
    }
  },
  async enableKommunalrabatt({ commit, getters }, marktlokation) {
    const response = await httpClient.post(
      `lieferstellen/enablerabattstatus?malo=${marktlokation}&year=${getters.getSelectedYear}`
    );

    if (response.status === 200) {
      commit("ENABLE_RABATT_FOR_MALO", marktlokation);
    }
  },
    async loadAutoCompleteMalos({ commit }, {commune, year}) {
      const response = await httpClient.get(
                commune
                    ? `lieferstellen/getCachedMalos?commune=${commune}&year=${year}`
                    : `lieferstellen/getCachedMalos?year=${year}`
            );

            if (response.status === 200) {
                commit("SET_AUTO_COMPLETE_MALOS", response.data);
            }
    },
  async loadResponsiblePersons({ commit }, commune) {
    const response = await httpClient.get(
      `lieferstellen/getCommuneResponsible?commune=${commune}`
    );

    if (response.status === 200) {
      commit("SET_RESPONSIBLE_PERSONS", response.data);
    }
  },
  async loadCommentsByUserid({ commit }) {
    const response = await httpClient.get("lieferstellen/getCommentsByUserid");
    commit("SET_COMMENTS_BY_USERID", response.data);
  },
  async loadCommentsByCommune({ commit, getters }) {
    const selectedCommune = getters.getSelectedCommune;
    const response = await httpClient.get(
      `lieferstellen/getCommentsByCommune/${selectedCommune}`
    );
    commit("SET_COMMENTS_BY_COMMUNE", response.data);
  },
  async loadCommentsByNEW({ commit }) {
    const responseComments = await httpClient.get(
      "/lieferstellen/getCommentsByMa"
    );

    if (responseComments) {
      commit("SET_COMMENTS_BY_NEW", responseComments.data);
    } else {
      commit("SET_COMMENTS_BY_NEW", []);
    }
  },
  async loadLieferstellenContact({ commit }) {
    const responseContact = await httpClient.get(
      "lieferstellen/getlieferstellencontact"
    );

    if (responseContact) {
      commit("SET_LIEFERSTELLEN_CONTACT", responseContact.data);
    } else {
      commit("SET_LIEFERSTELLEN_CONTACT", null);
    }
  },
  setCommentInputText({ commit }, data) {
    commit("SET_COMMENT_TEXT", data);
  }
};
