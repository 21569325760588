export default {
  getMessageSnackbarDisplayBoolean(state) {
    return state.messageSnackbarDisplayBoolean;
  },

  getMessageSnackbarText(state) {
    return state.messageSnackbarText;
  },

  getMessageSnackbarColor(state) {
    return state.messageSnackbarColor;
  },

  getMessageSnackbarIcon(state) {
    return state.messageSnackbarIcon;
  },
};
