import actions from "@/store/modules/contractOverview/actions";
import mutations from "@/store/modules/contractOverview/mutations";
import getters from "@/store/modules/contractOverview/getters";

export default {
    namespaced: true,
    state: {
        contracts: []
    },
    mutations,
    actions,
    getters
}