export default {
  SET_LIEFERSTELLEN(state, lieferstellen) {
    state.lieferstellen = lieferstellen;
  },
  UPDATE_LIEFERSTELLE(state, lieferstelle) {
    const index = state.lieferstellen.findIndex(
      ls => ls.marktlokation === lieferstelle.marktlokation
    );
    state.lieferstellen[index] = lieferstelle;
  },
  UPDATE_ADDITIONAL_LIEFERSTELLEN_DATA(state, { adresszeile1, adresszeile2, notiz, marktlokation }) {
    for(let index = 0; index < state.lieferstellen.length; index++) {
        if(state.lieferstellen[index].marktlokation === marktlokation) {
            state.lieferstellen[index].adresszeile1 = adresszeile1;
            state.lieferstellen[index].adresszeile2 = adresszeile2;
            state.lieferstellen[index].notiz = notiz;
        }
    }
  },
  UPDATE_SELECTED_LIEFERSTELLE(state, selectedLieferstelle) {
    state.selectedLieferstelle = selectedLieferstelle;
  },
  UPDATE_SELECTED_LIEFERSTELLEN_MALO(state, selectedLieferstellenMalo) {
    state.selectedLieferstellenMalo = selectedLieferstellenMalo;
  },
  UPDATE_SELECTED_COMMUNE(state, selectedCommune) {
    state.selectedCommune = selectedCommune;
  },
  UPDATE_SELECTED_YEAR(state, selectedYear) {
    state.selectedYear = selectedYear;
  },
  SET_COMMUNE_STATUSES(state, communeStatuses) {
    state.communeStatuses = communeStatuses;
  },
  SET_COMMENTS(state, comments) {
    state.comments = comments;
  },
  SET_DETAILS(state, details) {
    state.details = details;
  },
  SET_UNREAD_COMMENTS(state, unreadComments) {
    state.unreadComments = unreadComments;
  },
  ENABLE_RABATT_FOR_MALO(state, malo) {
    const lieferstellen = state.lieferstellen.filter(ls => ls.marktlokation === malo);
    const indices = lieferstellen.map(ls => state.lieferstellen.findIndex(l => l.id === ls.id));
    for(const index of indices) {
      state.lieferstellen[index].rabatt = true;
    }
  },
  SET_RESPONSIBLE_PERSONS(state, responsiblePersons) {
    state.responsiblePersons = responsiblePersons;
  },
  SET_COMMENTS_BY_USERID(state, commentsByUserid) {
    state.commentsByUserid = commentsByUserid;
  },
  SET_COMMENTS_BY_COMMUNE(state, commentsByCommune) {
    state.commentsByCommune = commentsByCommune;
  },
  SET_COMMENTS_BY_NEW(state, comments) {
    state.commentsByNEW = comments;
  },
  SET_LIEFERSTELLEN_CONTACT(state, contact) {
    state.lieferstellenContact = contact;
  },
  SET_COMMENT_TEXT(state, data) {
    state.commentInputText = data;
  },
  SET_AUTO_COMPLETE_MALOS(state, malos) {
    state.autoCompleteMalos = malos;
  },
};
