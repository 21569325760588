import httpClient from "@/store/httpClient";

export default {
  SET_SHOW_DEV_DIALOG(state) {
    const savedToken = localStorage.getItem("DEV_PASSWORD");
    const devServerUrls = [
      "kommunalportal-next.new.de",
      "kommunalportal-dev.new.de",
      "localhost:8080",
    ];

    state.showDevLogin = ![process.env.VUE_APP_DEV_TOKEN].includes(savedToken) &&
      devServerUrls.includes(location.host);
  },
  SET_USER(state, user) {
    state.enableMoments = user.EnableMoments;
    state.enableKommunalrabatt = user.EnableKommunalrabatt
    state.enableKonzessionssachstaende = user.EnableKonzessionssachstaende;
    state.enableWaermeplanung = user.EnableWaermeplanung;
    state.enableVertragsuebersicht = user.EnableVertragsuebersicht;
    state.enableVerbrauchsdaten = user.EnableVerbrauchsdaten;
    state.enableKommunenDashboard = user.EnableKommunenDashboard;
    state.id = user.id;
    state.email = user.email;
    state.firstName = user.firstName;
    state.lastName = user.lastName;
    state.imgUrl = user.img_url;

    state.commune = user.commune;
    state.communePartner = user.commune.kommunalPartner;
    state.communeRegionalLeader = user.commune.regionalLeiter;

    state.faculties = user.faculties;

    // has to be last
    state.isConfirmedAccount = user.confirmed == true;
    state.isLoggedIn = true;

    state.isNewEmployee = user.isNewEmployee;
    state.serviceDetails = user.serviceDetails;
    state.showNewsletterSignUp = user.showNewsletterSignUp;
    state.faq = user.faq;
    state.preferredUserData = user.preferredUserData;
  },

  SET_PREFERRED_USER_DATA(state, preferredUserData) {
    state.preferredUserData = preferredUserData;
  },

  SET_AUTH_TOKEN(state, newAuthToken) {
    localStorage.setItem("jwt", newAuthToken);
    httpClient.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${newAuthToken}`;
  },

  REMOVE_AUTH_TOKEN() {
    localStorage.removeItem("jwt");
    localStorage.removeItem("new_openid_token");
    delete httpClient.defaults.headers.common["Authorization"];
  },
  // SET_COMMUNE_CONTACTS(state, contacts) {
  //     state.communePartner = contacts.communePartner
  //     state.communeRegionalLeader = contacts.communeRegionalLeader
  // }
  SET_SHOW_NEWSLETTER_SIGNUP(state, showSignUp) {
    state.showNewsletterSignUp = showSignUp;
  },
};
