<template>
  <v-app>
    <MsgSnackbar />
    <NavigationLoggedIn LoggedIn v-if="isLoggedIn" />
    <notifications-list />
    <v-main>
      <router-view v-if="showMainView" />
      <LoadingSpinner v-else />
    </v-main>
    <the-footer v-if="isLoggedIn"></the-footer>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import MsgSnackbar from "@/components/ui/MsgSnackbar.vue";
import NavigationLoggedIn from "./components/navigation/private/NavigationLoggedIn.vue";
import TheFooter from "@/components/layout/TheFooter.vue";
import LoadingSpinner from "@/components/layout/LoadingSpinner";
import NotificationsList from "@/components/lists/NotificationsList.vue";

export default {
  name: "App",
  components: {
    NotificationsList,
    MsgSnackbar,
    NavigationLoggedIn,
    TheFooter,
    LoadingSpinner
  },
  data: () => ({
    showNewsletterSignUp: false
  }),
  watch: {
    userId(newUserId) {
      if (!newUserId) return;

      const mail = this.userEmail;

      sessionStorage.user_mail = mail;
      sessionStorage.user_id = newUserId.toString();
    }
  },
  computed: {
    ...mapGetters({
      userEmail: "user/userEmail",
      userId: "user/userId"
    }),
    isLoggedIn() {
      return this.$store.getters["user/isLoggedIn"];
    },

    showMainView() {
      const PUBLIC_ROUTES = ["login", "oauth-redirect"];
      const CURR_ROUTE = String(this.$route.name).toLowerCase();
      return this.isLoggedIn || PUBLIC_ROUTES.indexOf(CURR_ROUTE) != -1;
    }
  }
};
</script>

<style>
@import "./styles/app.css";
</style>
