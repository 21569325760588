import RestMethods from "@/store/RestMethods";
// import { _sortBy } from '@/utils/sortings'

const ABOUT_MODEL = RestMethods.init("about-us");
// const FUNDING_MODEL = RestMethods.init("funding-opportunities");
//const FAQ_MODEL = RestMethods.init("faq");
const NEW_COMPANIES_MODEL = RestMethods.init("new-companies");
// const SERVICE_CACHE_SECS = 10 * 60; // 10 mins
const CONTACT_PEOPLE = RestMethods.init("contact-people");

export default {
  async fetchGeneralContent({ commit }) {
    const content = await Promise.all([
      ABOUT_MODEL.find(),
      // FUNDING_MODEL.find(),
      NEW_COMPANIES_MODEL.find(),
      CONTACT_PEOPLE.find(),
    ]).then((val) => val);
    commit("SET_ABOUT_US", content[0].data);
    // commit("SET_FUNDING_OPPORTUNITIES", content[1].data);
    commit("SET_NEW_COMPANIES", content[1].data);
    commit("SET_CONTACT_PEOPLE", content[2].data);
  },
};
