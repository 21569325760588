<template>
  <div class="new-wrapper min-height-100">
    <v-row no-gutters>
      <v-col :cols="colSize" class="pa-10">
        <v-row no-gutters>
          <v-col cols="12">
            <div
              :class="
                $vuetify.breakpoint.width < 960
                  ? 'mb-2 custom-header-text new-font-bold'
                  : 'mb-4 custom-header-text new-font-bold'
              "
            >
              NEW Erleben
            </div>
          </v-col>

          <v-col cols="12">
            Als Partner regionaler Sportvereine, Kunst-und Kultureinrichtungen unterstützen wir vielfältige Angebote, die das Miteinander fördern und die Lebensqualität bei uns vor Ort weiter steigern. Diese Vorteile möchten wir weitergeben.
            Ob Sportereignis oder Musik-Event – besondere Momente erzeugen Glücksgefühle mit Langzeitwirkung. <br><br>
            Überraschen Sie Ihre Bürger mit Hilfe von <span class="font-weight-bold">NEW Erleben</span>. Auf dieser Plattform stellen wir verschiedene Highlights zur Verfügung, die Sie an Menschen in Ihrer Stadt oder Gemeinde weitergeben können. Freuen Sie sich auf Tickets, Gutscheine, attraktive Aktionen, spannende Gewinnspiele und tolle Geschenke, mit denen Sie anderen eine Freude machen – und so das Wir-Gefühl stärken.
            <br><br>
            Unser Tipp: Schauen Sie regelmäßig auf <span class="font-weight-bold">NEW Erleben</span> vorbei und informieren Sie sich über die aktuellen Angebote. So können wir gemeinsam für unvergessliche Erlebnisse sorgen.
          </v-col>

          <v-col cols="12" class="mt-12">
            <moments-card-grid :items="getMoments"></moments-card-grid>
          </v-col>
        </v-row>
      </v-col>

      <!-- RIGHT SIDE CONTENT - ANSPRECHPARTNER, DOWNLOADS, INTERESSANTES -->
      <v-col
        v-if="$vuetify.breakpoint.smAndUp"
        cols="4"
        class="contact_container pl-4"
      >
        <RightColumnGroup
          :content-object="{
            personsInCharge: [getMomentsContact],
            fileDownloads: [],
            showRegionalLeiter: false,
            interestingLinks: []
          }"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import RightColumnGroup from "@/components/layout/RightColumnGroup.vue";
import MomentsCardGrid from "@/components/ui/Moments/MomentsCardGrid.vue";
export default {
  name: "MomentsView",
  components: {MomentsCardGrid, RightColumnGroup },
  methods: {
    ...mapActions("moments", ["loadMoments", "loadSidebarContact"])
  },
  computed: {
    ...mapGetters("moments", ["getMoments", "getMomentsContact"]),
    colSize() {
      if (this.$vuetify.breakpoint.smAndUp) return 8;
      else return 12;
    }
  },
  mounted() {
    this.loadMoments();
    this.loadSidebarContact();
  }
};
</script>
