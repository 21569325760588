// import RestMethods from '@/store/RestMethods';
import fb from "@/fb";

// const SEARCH_QUERY_MODEL = RestMethods.init('search-data');
// const SEARCH_RESULT_MODEL = RestMethods.init('search-results');

export default {
  async commitSearchSession(
    { commit, getters, rootGetters },
    { followUpType, followUp }
  ) {
    const session = {
      //search_queries: getters.getQueries.map(query => query.id),
      //users_permissions_user: rootGetters['user/userId'],
      userId: rootGetters["user/userId"],
      queries: getters.getQueries,
      origin: document.location.origin,
      followUpType,
      followUp,
      date: new Date(),
    };

    if (process.env.VUE_APP_LOG_SEARCH === "true") {
      await fb.db.collection("search").add(session);
    }
    commit("CLEAR_QUERIES");
  },
  async addQuery({ commit }, query) {
    commit("ADD_QUERY", {
      query,
      date: new Date(),
    });
  },
};
