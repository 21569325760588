<template>
  <v-hover v-slot="{ hover }">
<v-sheet class="commune-card mb-2"  width="100%" height="75px" @click="onCardClick"
         :elevation="hover ? 4 : 1"
         :class="{ 'on-hover': hover }">
  <div class="commune-card-border mr-1" :class="[color]"></div>
  <div class="my-auto px-4">
    <b>{{ communeName }}</b><br>
    {{ lieferstellenCount }} Lieferstellen
  </div>
  <v-icon class="my-auto ml-auto" color="primary">mdi-chevron-right</v-icon>
</v-sheet>
  </v-hover>
</template>

<script>
export default {
  name: "CommuneCard",
  props: ['communeName', 'lieferstellenCount', 'color', 'selectedYear'],
  methods: {
    onCardClick() {
      this.$router.push(`/lieferstellen?commune=${this.communeName}&year=${this.selectedYear}`)
    }
  }
}
</script>

<style scoped>
.commune-card {
  display: flex;
  flex-flow: row;
  cursor: pointer;
}

.commune-card-border {
  height: 100%;
  width: 7px;
}
</style>
