export default {
  SET_SUB_SERVICES(state, subServicesData) {
    state.subServicesList = subServicesData;

    let servicesObj = {};
    subServicesData.forEach((service) => {
      servicesObj[service.id] = service;
    });

    state.servicesIdMap = servicesObj;
  },
};
