<template>
  <v-row no-gutters v-if="aboutUs" class="new-wrapper min-height-100">
    <!-- MAIN CONTENT -->
    <v-col :cols="colSize" class="pa-10">
      <v-row>
        <v-col cols="12">
          <span class="custom-header-text new-font-bold">
            {{ aboutUs.title }}
          </span>
        </v-col>
      </v-row>

      <!-- TEXT SECTION 1 -->
      <v-row no-gutters class="mt-5">
        <vue-markdown-it
          v-if="aboutUs.content1"
          :source="aboutUs.content1"
          id="content1"
        />
      </v-row>

      <!-- CAROUSEL OR IMAGE-->
      <v-row v-if="images && images.length > 1" no-gutters class="mt-6">
        <Carousel :slides="images" />
      </v-row>
      <v-row v-else-if="images && images.length == 1" class="px-3 mt-14">
        <v-img :src="`${baseUrl}${images[0].url}`"></v-img>
        <p v-if="images[0].caption" class="text-caption">
          {{ images[0].caption }}
        </p>
      </v-row>

      <!-- TEXT SECTION 2 -->
      <v-row no-gutters class="mt-5">
        <vue-markdown-it
          v-if="aboutUs.content2"
          :source="aboutUs.content2"
          id="content2"
        />
      </v-row>

      <!-- Youtube Content -->
      <v-row>
        <iframe
          width="100%"
          :height="$vuetify.breakpoint.width < 960 ? 200 : 400"
          src="https://www.youtube.com/embed/_PxpEHJ8r6Q?rel=0?version=3?loop=1&playlist=_PxpEHJ8r6Q&autoplay=1&mute=1&enablejsapi=1"
          title="YouTube video player"
          style="border:0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </v-row>
      <v-row no-gutters class="mt-5">
        <v-col class="py-10" align="left" cols="12">
          <span class="custom-header-text new-font-bold">
            Die Unternehmen der NEW-Gruppe
          </span>
        </v-col>
        <NewCompaniesList :allEntries="allEntries" />
      </v-row>
      <RightColumnGroup
        v-if="$vuetify.breakpoint.xs"
        :communePartner="communePartner"
        :communeRegionalLeader="communeRegionalLeader"
        :contentObject="aboutUs"
      />
    </v-col>

    <!-- RIGHT SIDE CONTENT - ANSPRECHPARTNER, DOWNLOADS, INTERESSANTES -->
    <v-col
      v-if="$vuetify.breakpoint.smAndUp"
      cols="4"
      class="contact_container pl-4"
    >
      <RightColumnGroup
        :communePartner="communePartner"
        :communeRegionalLeader="communeRegionalLeader"
        :contentObject="aboutUs"
      />
    </v-col>
  </v-row>
</template>

<script>
import { checkIconName } from "@/mixins/checkIconName";
import Carousel from "@/components/layout/Carousel.vue";
import RightColumnGroup from "@/components/layout/RightColumnGroup.vue";
import VueMarkdownIt from "vue-markdown-it";
import NewCompaniesList from "@/components/lists/NewCompaniesList.vue";

export default {
  components: {
    Carousel,
    RightColumnGroup,
    VueMarkdownIt,
    NewCompaniesList
  },
  mixins: [checkIconName],
  name: "AboutUs",
  data: function() {
    return {
      baseUrl: process.env.VUE_APP_API_BASE_URL
    };
  },
  computed: {
    colSize() {
      if (this.$vuetify.breakpoint.smAndUp) return 8;
      else return 12;
    },
    images() {
      return this.aboutUs.images;
    },

    aboutUs() {
      return this.$store.getters["generalContent/getAboutUs"];
    },

    allEntries() {
      return this.aboutUs.NewGroupOptions;
    },

    communePartner() {
      return this.$store.getters["user/userCommunePartner"];
    },

    communeRegionalLeader() {
      return this.$store.getters["user/userCommuneRegionalLeader"];
    }
  },
  methods: {},
  created() {},
  mounted() {}
};
</script>

<style></style>
