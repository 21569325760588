import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAiP0tMYInxGhfKyKM27RCfHWFZX2C2YFs",
    authDomain: "kommunalportal-dev.firebaseapp.com",
    databaseURL: "https://kommunalportal-dev.firebaseio.com",
    projectId: "kommunalportal-dev",
    storageBucket: "kommunalportal-dev.appspot.com",
    messagingSenderId: "307649185332",
    appId: "1:307649185332:web:3efd29529805d4d705ec61"
};

const app = firebase.initializeApp(firebaseConfig);

const db = firebase.firestore(app);

export default {
    db
}
