<template>
  <div
    class="new-wrapper min-height-100"
    :class="$vuetify.breakpoint.width > 960 ? 'pa-10' : 'pa-8'"
  >
    <v-row dense>
      <v-col cols="12" class="mb-2">
        <span class="custom-header-text new-font-bold">
          Hallo {{ username }}!
        </span>
      </v-col>
      <v-col cols="12">
        <news-carousel :slide-count="6" />
      </v-col>
      <v-col col="6" md="4" v-for="(link, i) in dashboardLinks" :key="i">
        <link-card
          v-bind="link"
          :color="i % 2 === 0 ? '#b40055' : 'rgb(119, 0, 101)'"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NewsCarousel from "@/components/dashboard/NewsCarousel.vue";
import LinkCard from "@/components/dashboard/LinkCard.vue";
import loginNew from "loginNew";

export default {
  name: "Home",
  data: () => ({
    links: [
      {
        title: "Kommunen und Gemeinden",
        link: "/#",
        icon: "$dashboardHandshake"
      },
      {
        title: "Wärmeplanung",
        link: "/#",
        icon: "$dashboardThermometer"
      },
      {
        title: "Baumap",
        link: "/constructions-map",
        icon: "$dashboardTrafficCone"
      },
      {
        title: "Wunschladesäulen",
        link: "/service-area/6",
        icon: "$dashboardLadesaeule"
      },
      {
        title: "Kommunen auf einen Blick",
        link: "/#",
        icon: ""
      }
    ]
  }),
  components: {
    NewsCarousel,
    LinkCard
  },
  mounted() {
    setTimeout(() => {
      if (!loginNew.serviceAllowed("eTbCyQ9d_")) {
        loginNew.showCookieDialog();
      }
    }, 10000);

    /* set user properties in google analytics */
    const commune = this.userCommuneName;
    const role = this.userFaculties[0];
    this.$ga("set", "user_properties", { commune: commune, role: role });
  },
  computed: {
    ...mapGetters("user", [
      "userCommuneName",
      "userFaculties",
      "username",
      "enableKommunalrabatt","enableMoments"
    ]),
    ...mapGetters("navigation", ["getSubServiceByName", "getServiceAreaByName"]),
    dashboardLinks() {
      const collection = [
        {
          title: "Fördermöglichkeiten",
          link: "/funds",
          icon: "$dashboardRabattprozess"
        },
        {
          title: "Über die NEW",
          link: "/about",
          icon: "$dashboardNew"
        }
      ];

      if (this.isNewEmployee) {
        collection.push({
          title: "Vertragsübersicht",
          link: "/vertragsuebersicht",
          icon: "$dashboardContract"
        });
      } else {
        collection.push({
          title: "Presse",
          link: "/news",
          icon: "$dashboardPress"
        });
      }

      collection.push(
        {
          title: "Baumap",
          link: "/constructions-map",
          icon: "$dashboardTrafficCone"
        })

      const momentsSubService = this.getSubServiceByName("NEW Erleben");

      if(momentsSubService && this.enableMoments) {
        collection.push({
          title: "NEW Erleben",
          link: `/service-area/${momentsSubService.service.serviceArea}/service/${momentsSubService.service.id}/sub-service/${momentsSubService.id}`,
          icon: "$dashboardErleben"
        });
      }
        collection.push({
          title: "Wunschladesäulen",
          link: "/service-area/6",
          icon: "$dashboardLadesaeule"
        },
        {
          title: "FAQs",
          link: "/faq",
          icon: "$dashboardFaq"
        }
      );

      const nachhaltigkeitServiceArea = this.getServiceAreaByName("Nachhaltigkeit");

      if(nachhaltigkeitServiceArea) {
        collection.push({
          title: "Nachhaltigkeit",
          link: `/service-area/${nachhaltigkeitServiceArea.id}`,
          icon: "$nachhaltigkeit"
        });
      }
      /*
      if (this.enableKommunalrabatt)
        collection.push({
          title: "Rabattprozess",
          link: "/kommunalrabatt",
          icon: "$dashboardRabattprozess",
        });
      collection.push(...this.links);
*/
      return collection;
    },
    communePartner() {
      return this.$store.getters["user/userCommunePartner"];
    },
    communeLogoUrl() {
      return this.$store.getters["user/userCommuneLogoUrl"];
    },
    isNewEmployee() {
      return this.$store.getters["user/isNewEmployee"];
    },
  }
};
</script>

<style scoped>
#homeBg {
  background-color: #f2f2f3;
}
</style>
