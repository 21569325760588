export default {
  subServicesCount(state) {
    return state.subServicesList.length;
  },

  subServiceBySubServiceId: state => subServiceId => {
    return state.subServicesList.find(
      subService => subService.id == subServiceId
    );
  }

  // subServiceBySubServiceId: (state) => (serviceId, subServiceId) => {
  //   let service = state.subServicesList.find(service => service.id == serviceId)
  //   let subService = service.find(subService => subService.id == subServiceId)
  //   return subService
  // }
};
