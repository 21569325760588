import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

export default {
  namespaced: true,
  state: {
    newsCount: 0,
    newsList: null,
    newsIdMap: null,

    newsReadIdList: null,
    pressReadIdList: null,

    pressCount: 0,
    pressList: null,
    pressIdMap: null,

    categoryFilter: "all",
    dateOrder: "desc",
    //pressReadIdList: null //todo: implement 'read' for press-posts

    monthYearOrder: null,
  },
  mutations,
  actions,
  getters,
};
