import RestMethods from "@/store/RestMethods";
// import { _sortBy } from '@/utils/sortings'

const SERVICES_MODEL = RestMethods.init("services");
const SERVICE_CACHE_SECS = 10 * 60; // 10 mins

export default {
  async fetchServices({ commit }) {
    if (!SERVICES_MODEL.isCacheExpired("fetchServices", SERVICE_CACHE_SECS)) {
      return;
    }

    const servicesData = await SERVICES_MODEL.find();

    //todo: handle failed request(s)

    if (servicesData) {
      SERVICES_MODEL.resetCacheTimeout("fetchServices");
      commit("SET_SERVICES", servicesData.data);
    }
  },
};
