export default {
  getMoments: state => {
    return state.moments.sort((a, b) => {
      let dateA = new Date(a.created_at);
      let dateB = new Date(b.created_at);

      return dateB - dateA;
    });
  },
  getMomentsContact: state => state.contact
};
