<script>
export default {
  name: "NachhaltigkeitsView",
  computed: {
    images() {
      const baseUrl = process.env.BASE_URL + "img/"
      return {
        ziele: baseUrl + 'ziele.png',
        scope1: baseUrl + 'scope1.png',
        scope2: baseUrl + 'scope2.png',
        scope3: baseUrl + 'scope3.png',
        trinkwasser: baseUrl + 'trinkwasser.png',
        seestadt: baseUrl + 'seestadt.png'
      };
    }
  },
  methods: {
    scrollTo(id) {
      const element = document.getElementById(id);

      window.scroll({
        top: element.offsetTop,
        left: 0,
        behavior: "smooth"
      });
    }
  }
}
</script>

<template>
<v-row>
  <v-col cols="12">
    <span class="custom-header-text new-font-bold">Nachhaltigkeit</span>
  </v-col>
  <v-col cols="12">
    <p class="font-weight-bold">
      Für die NEW bedeutet nachhaltiges Handeln: Ökonomische, ökologische und soziale Ziele
      in Einklang bringen. Dabei leitet uns die Vision, dass auch unsere Enkel noch von der NEW als Unternehmen
      begeistert sein werden.
    </p>
    <p>
      Ob Klima- undUmweltschutz, Aus- und Weiterbildung, Biodiversität oder der lokale und regionale Zusammenhalt in
      Sport, Brauchtum und Kultur: Als NEW sind wir uns der Verantworrtung für unser Versorgungsgebiet seit jeher bewusst,
      Vor Ort können wir mit Leidenschaft, Innovation und Einsatz einen Unterschied für eine bessere und nachhaltigere
      Zukunft machen. Dabei arbeiten wir insbesondere an der Dekarbonisierung unserer Dienstleistungen und unterstützen
      unsere Kund:innen auf dem Weg zur Klimaneutralität.
    </p>
    <v-btn text color="primary" @click="scrollTo('ziele')">
      Ziele
      <v-icon right>mdi-arrow-down</v-icon>
    </v-btn>
    <v-btn text color="primary" @click="scrollTo('vision')">
      Vision
      <v-icon right>mdi-arrow-down</v-icon>
    </v-btn>
    <v-btn text color="primary" @click="scrollTo('leuchtturm-projekt')">
      Leuchtturmprojekt
      <v-icon right>mdi-arrow-down</v-icon>
    </v-btn>
  </v-col>
  <v-col cols="12">
    <div id="ziele" class="custom-header-text new-font-bold">Unsere Ziele im Überblick:</div>
    <p class="new-font-bold">Klimaschutzsstrategie: CO2-neutrale NEW 20230</p>
    <v-img :src="images.ziele" alt="Ziele" />
  </v-col>
  <v-col cols="12">
    <div id="vision" class="custom-header-text new-font-bold">Unsere Vision</div>
    <p class="new-font-bold">CO2-neutrales Produktportfolio</p>
    <p>
      Bei unserem Ziel, Treibhausgasneutralität in den Scopes 1 und 2bis zum Jahr 2030 gemäß GHG-Protokoll zu erreichen,
      ist für uns der Dreiklang maßgeblich:<b>Vermeiden, Verringern, Kompensieren</b>.</p>
  </v-col>
  <v-col cols="12" md="4">
    <b>Scope 1</b>
    <v-img :src="images.scope1" alt="Scope 1" />
    <p>
      Scope-1-Emissionen sind Emissionen aus Quellen, die direkt vom Unternehmen verantwortet und kontrolliert werden
      (z.B. im Fuhrpark oder aus Energieträgern wie Erdgas an den Standorten des Unternehmens).
    </p>
  </v-col>
  <v-col cols="12" md="4">
    <b>Scope 2</b>
    <v-img :src="images.scope2" alt="Scope 2" />
    <p>
      CO&#8322;-neutraler Strom: Bei Erreichung der EEG-Zielmarke 80 Prozent erneuerbare Energie am Bruttostromverbrauch
      in Deutschland, werden wir im Jahr 2030 ausschließlich grüne Stromtarife anbieten.
    </p>
  </v-col>
  <v-col cols="12" md="4">
    <b>Scope 3</b>
    <v-img :src="images.scope3" alt="Scope 3" />
    <p>
      Sind indirekte Emissionen, die entlang der Wertschöpfungskette des Unternehmens entstehen, aber nicht direkt aus
      dem Unternehmen stammen.
    </p>
  </v-col>
  <v-col cols="12">
    <p class="font-weight-bold">
      Wir arbeiten bereits heute mit Hochdruck an der Dekarbonisierung unsereres Produktportfolios.
    </p>
    <v-row>
      <v-col cols="4">
        <v-img :src="images.trinkwasser" alt="Trinkwasser" />
      </v-col>
      <v-col cols="8">
        <p>
          <span class="font-weight-bold">Klimaneutralität der Trinkwasserförderung</span><br>
          So haben wir unsere Trinkwasserförderung  und -aufbereitung bereits im Jahr 2021 klimaneutral gestellt.
        </p>
        <a target="_blank"
           href="https://www.niederrheinwasser.de/klimaneutralitaet#:~:text=Die%20Trinkwassergewinnung%20und%20%2D%20aufbereitung%20bei,acht%20Wasserwerke%20sowie%20der%20Verwaltung"
        >
          <v-icon left small color="primary">mdi-open-in-new</v-icon>
          Klimaneutralität
        </a>
      </v-col>
    </v-row>
  </v-col>
  <v-col cols="12" style="display: flex">
    <v-icon size="50">$nachhaltigkeit</v-icon>
    <p class="ml-2">Die größte Herausforderung stellt für uns die Wärmewende dar.</p>
  </v-col>
  <v-col cols="12" style="display: flex">
    <v-icon size="50">$nachhaltigkeit</v-icon>
    <p class="ml-2">
      Als Verteilnetzbetreiber können wir über die vorhandene Gasnetzinfrastruktur nur die Energie verteilen,
      die uns zuvor geliefert wird.
    </p>
  </v-col>
  <v-col cols="12" style="display: flex">
    <v-icon size="50">$nachhaltigkeit</v-icon>
    <p class="ml-2">
      Derzeit erhalten wir von unseren Vorlieferanten immer noch keine verlässlichen Informationen darüber, ab wann
      und in welchen Mengen grüner Wasserstoff für unsere Kund:innen in unserem Versorgungsgebiet zur Verfügung
      stehen wird.
    </p>
  </v-col>
  <v-col cols="12" style="display: flex">
    <v-icon size="50">$nachhaltigkeit</v-icon>
    <p class="ml-2">
      Dennoch zählen wir mit der Gründung unserer Tochter Stadtentfalter, der Entwicklung des Energiekonzepts der
      größten Klimaschutzsiedlung NRWs und der Erstellung von kommunalen Wärmeplänen zu den „First Movern" in
      Deutschland.
    </p>
  </v-col>
  <v-col cols="12" id="leuchtturm-projekt">
    <span class="subtitle-1 font-weight-bold">Leuchtturmprojekt</span>
    <div class="custom-header-text new-font-bold">Seestadt mg+, Mönchengladbach</div>
  </v-col>
  <v-col cols="12" md="6">
    <v-img :src="images.seestadt" alt="Seestadt" />
  </v-col>
  <v-col cols="12" md="6">
    <p>
      Größte Klimaschutzsiedlung NRW und Reallabor der Energiewende, mitten in der Innenstadt von Mönchengladbach:
    </p>

    <ul>
      <li>210.00 m&#178; Bruttogrundfläche</li>
      <li>ca. 2.000 Wohnungen</li>
      <li>Büros, Hotel und diverse Serviceeinrichtungen</li>
      <li>20.000 m&#179; großer See</li>
    </ul>
    <div  class="mt-8">
    <v-row>
      <v-col cols="6">
        <div class="custom-header-text new-font-bold">3 km</div>
        <div class="subtitle-1">langes LowEx-Netz im Quartier</div>
      </v-col>
      <v-col cols="6">
        <div class="custom-header-text new-font-bold">71%</div>
        <div class="subtitle-1">Bedarfsdeckung aus EE und Abwärmenutzung</div>
      </v-col>
    </v-row>
    </div>
  </v-col>
</v-row>
</template>

<style scoped>

</style>
