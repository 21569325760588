export default {
    getConfig: (state) => state.config,
    getOptions: (state, getters, rootState, rootGetters) => {
        if(!state.config) return [];

        const options = [];

        options.push({
            keyName: 'portal-updates',
            name: 'Neue Dokumente (verpflichtende Information) und Kommunalportal Updates',
            subtitle: 'Wir informieren Sie über neue Dokumente (z.B. Testate) und über neue Features im Kommunalportal.',
            initialValue: true,
            readonly: true
        });

        if(rootGetters["user/enableMoments"]) {
            options.push({
                keyName: "moments",
                name: "NEW Erleben",
                subtitle: "Wir informieren Sie exklusiv über die aktuellen Angebote der NEW. So können wir gemeinsam für unvergessliche Erlebnisse sorgen.",
                initialValue: state.config.moments,
                readonly: false
            });
        }
        options.push({
            keyName: "news",
            name: "NEW News",
            subtitle: "Wir informieren Sie regelmäßig über aktuelle Termine, Neuigkeiten aus dem Bereich NEW Erleben sowie wichtige Informationen aus unseren Geschäftsbereichen.",
            initialValue: state.config.news,
            readonly: false
        });

        options.push({
            keyName: "foerdermittel",
            name: "Fördermöglichkeiten",
            subtitle: "Wir informieren Sie über neue Förderoptionen aus den verschiedenen Bereichen, z.B. Digitalisierung, Elektromobilität, Wärmeversorgung etc.",
            initialValue: state.config.foerdermittel,
            readonly: false
        });



        return options;
    },
    getShowNotifications: (state) => state.showNotifications || (state.config && state.config.promoteNotifications !== false),
    getUnreadNotificationsCount: (state) => state.notifications.filter(s => !s.read).length,
    getNotifications: (state) => state.notifications,
}
