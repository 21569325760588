<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="550px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on" elevation="0" block :small="$vuetify.breakpoint.lgAndDown" :disabled="status !== 'available'">
        {{ status !== "expired" ? "Beanspruchen" : "Abgelaufen" }}
      </v-btn>
    </template>
    <v-card class="text-center">
      <v-card-title class="d-block">
        <h2 class="mt-6 mb-6">
          {{ title }}<br />
          beanspruchen
        </h2>

        <v-btn icon class="close_icon__custom" @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid" lazy-validation ref="momentRef">
          <v-text-field
            v-model="form.name"
            label="Ihr Name"
            :rules="rules.name"
            background-color="#f6e4ed"
            class="custom-input"
            dense
            outlined
          ></v-text-field>

          <v-text-field
            v-model="form.commune"
            label="Kommune"
            :rules="rules.commune"
            background-color="#f6e4ed"
            class="custom-input"
            dense
            outlined
          ></v-text-field>

          <v-text-field
            v-model="form.task"
            label="Funktion"
            :rules="rules.task"
            background-color="#f6e4ed"
            class="custom-input"
            dense
            outlined
          ></v-text-field>

          <v-text-field
            v-model="form.phone_number"
            label="Ihre Telefonnummer"
            :rules="rules.phone_number"
            background-color="#f6e4ed"
            class="custom-input"
            dense
            outlined
          ></v-text-field>

          <v-text-field
            v-model="form.email"
            label="Ihre Email-Adresse"
            :rules="rules.email"
            background-color="#f6e4ed"
            class="custom-input"
            dense
            outlined
          ></v-text-field>

          <v-btn
            color="primary"
            @click="claim"
            elevation="0"
            :disabled="!valid"
            class="mt-6 mb-6"
            >Beanspruchen</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "MomentsModal",
  props: ["title", "id", "status"],
  data() {
    return {
      valid: false,
      dialog: false,
      form: {
        name: "",
        commune: "",
        task: "",
        phone_number: "",
        email: ""
      },
      rules: {
        name: [v => !!v || "Name wird benötigt"],
        commune: [v => !!v || "Kommune wird benötigt"],
        task: [v => !!v || "Funktion wird benötigt"],
        phone_number: [v => !!v || "Telefonnummer wird benötigt"],
        email: [
          v => !!v || "Email-Adresse wird benötigt",
          v => /.+@.+/.test(v) || "Email-Adresse nicht gültig"
        ]
      }
    };
  },
  methods: {
    ...mapActions("moments", ["sendMomentsEmail"]),
    async claim() {
      if (this.$refs.momentRef.validate()) {
        const sendData = {
          subject: `${this.title} wird beansprucht`,
          ...this.form
        };
        await this.sendMomentsEmail(sendData);
        this.$refs.momentRef.reset();
        this.dialog = false;
      }
    }
  }
};
</script>

<style scoped>
.custom-input {
  width: 350px;
  margin: 0 auto;
}
.close_icon__custom {
  position: absolute;
  top: 20px;
  right: 20px;
}

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  color: white !important;
  background-color: rgb(232,169,202) !important;
}
</style>
