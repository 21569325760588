<script>
export default {
  name: "LinkCard",
  methods: {
    onClick() {
      this.$router.push(this.link);
    }
  },
  props: {
    title: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    }
  },
  computed: {
    isNotCommuneCard() {
      return this.title !== "Kommunen auf einen Blick";
    },
    communeHeaderSize() {
      return this.$vuetify.breakpoint.smAndDown
        ? "commune-header-mobile"
        : "commune-header";
    }
  }
};
</script>

<template>
  <v-sheet
    width="100%"
    :color="color"
    height="185px"
    class="dashboard-link-card pb-2"
    :class="!isNotCommuneCard ? 'commune-card px-3' : 'pt-5 px-3'"
    @click="onClick"
  >
    <template v-if="isNotCommuneCard">
      <v-icon
        v-if="icon"
        :size="$vuetify.breakpoint.mdAndDown ? '55px' : '85px'"
        class="mx-auto"
        >{{ icon }}</v-icon
      >
      <v-spacer></v-spacer>
      <div
        :class="
          $vuetify.breakpoint.mdAndDown
            ? 'link-card-text-mobile'
            : 'link-card-text'
        "
      >
        <span class="text-h6 text-md-h5 white--text">
          {{ title }}
        </span>
        <v-icon class="" small color="white">$arrowRight</v-icon>
      </div>
    </template>

    <template v-else>
      <div class="white--text">
        <span :class="communeHeaderSize">
          Alle Kommunen auf einen Blick
        </span>

        <div style="display: flex">
          <span class="link-card-number">PH_DATA</span>
          <div class="link-card-description">Einwohner<br />gesamt</div>
        </div>

        <div style="display: flex">
          <span class="link-card-number">PH_DATA</span>
          <div class="link-card-description">
            kWh Verbrauch pro<br />Einwohner
          </div>
        </div>

        <div style="display: flex">
          <span class="link-card-number">PH_DATA</span>
          <div class="link-card-description">kWh<br />TOTAL</div>
        </div>
      </div>
    </template>
  </v-sheet>
</template>

<style scoped>
.dashboard-link-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.commune-card {
  background: transparent linear-gradient(64deg, #281e78 0%, #b40055 100%) 0% 0%
    no-repeat padding-box;
}

.commune-header {
  font-size: 16px;
}

.commune-header-mobile {
  font-size: 16px;
}

.dashboard-link-card:hover {
  filter: brightness(90%);
}

.link-card-text {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.link-card-text-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.link-card-number {
  display: flex;
  align-items: center;
  font-size: 28px;
}
.link-card-number.sm {
  font-size: 24px;
}
.link-card-description {
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 12px;
  line-height: 13px;
}
</style>
