import RestMethods from "@/store/RestMethods";

const SERVICE_AREA_MODEL = RestMethods.init("service-areas");
const SERVICE_AREA_CACHE_SECS = 10 * 60; // 10 mins

export default {
  async fetchServiceAreas({ commit }) {
    if (
      !SERVICE_AREA_MODEL.isCacheExpired(
        "fetchServiceAreas",
        SERVICE_AREA_CACHE_SECS
      )
    ) {
      return;
    }

    const serviceArea = await SERVICE_AREA_MODEL.find();

    //todo: handle failed request(s)

    if (serviceArea) {
      SERVICE_AREA_MODEL.resetCacheTimeout("fetchServiceAreas");
      commit("SET_SERVICE_AREAS", serviceArea.data);
    }
  },
};
