export default {
  getOriginalQuery(state) {
    return state.originalQuery;
  },
  getEntries(state, getters, rootState, rootGetters) {
    const communePartner = rootGetters["user/userCommunePartner"];
    const regionalLeader = rootGetters["user/userCommuneRegionalLeader"];

    const newCompanies = rootGetters["generalContent/getNewCompanies"].map(
      (company) => {
        if (company.personsInCharge.length === 0) {
          company.personsInCharge.push({
            person: communePartner,
          });
        }

        return {
          name: company.title,
          data: {
            filter: "NEW-Gruppe",
            type: "NEW-Gruppe",
            newCompany: company,
          },
        };
      }
    );

    const news = [];
    const press = [];

    for (let post of rootGetters["news/allPosts"]) {
      if (post.personsInCharge.length === 0) {
        post.personsInCharge.push({
          person: communePartner,
        });
      }

      post.type = post.category;
      post.filter = post.category;

      if (post.category === "Presse") {
        press.push({
          name: post.title,
          data: post,
        });
      } else {
        news.push({
          name: post.title,
          data: post,
        });
      }
    }
    /*const newsAndPress = rootGetters['news/allPosts'].map((post) => {
            post.type = 'News und Presse';
            post.filter = 'News und Presse';

            if(post.personsInCharge.length === 0) {
                post.personsInCharge.push({
                    person: communePartner
                });
            }
            return {
                name: post.title,
                data: post
            }
        })*/
    const serviceAreas = rootGetters["navigation/serviceAreas"].map(
      (serviceArea) => {
        serviceArea.type = "ServiceAreas";
        serviceArea.filter = serviceArea.title;

        if (serviceArea.personsInCharge.length === 0) {
          serviceArea.personsInCharge.push({
            person: serviceArea.showRegionalLeiter
              ? regionalLeader
              : communePartner,
          });
        }
        return {
          name: serviceArea.title,
          data: serviceArea,
        };
      }
    );
    const services = serviceAreas
      .map((serviceArea) => serviceArea.data.services)
      .flat()
      .map((service) => {
        service.type = "Services";
        service.filter = service.serviceArea.title;

        if (service.personsInCharge.length === 0) {
          service.personsInCharge.push({
            person: service.showRegionalLeiter
              ? regionalLeader
              : communePartner,
          });
        }
        return {
          name: service.title,
          data: service,
        };
      });
    const subServices = services
      .map((service) => service.data.subServices)
      .flat()
      .map((subService) => {
        subService.type = "SubServices";
        subService.filter = rootGetters[
          "navigation/serviceAreaByServiceAreaId"
        ](subService.service.serviceArea).title;

        if (subService.personsInCharge.length === 0) {
          subService.personsInCharge.push({
            person: subService.showRegionalLeiter
              ? regionalLeader
              : communePartner,
          });
        }

        return {
          name: subService.title,
          data: subService,
        };
      });

    const personsInCharge = [];

    const newsContactPersons = news.map((post) => {
      return {
        areaName: "News",
        personsInCharge: post.data.personsInCharge,
      };
    });

    const pressContactPersons = press.map((post) => {
      return {
        areaName: "Presse",
        personsInCharge: post.data.personsInCharge,
      };
    });

    const subServiceContactPersons = subServices.map((subService) => {
      const serviceAreaName = rootGetters[
        "navigation/serviceAreaByServiceAreaId"
      ](subService.data.service.serviceArea).title;
      return {
        areaName: serviceAreaName,
        personsInCharge: subService.data.personsInCharge,
      };
    });

    const serviceContactPersons = services.map((service) => {
      return {
        areaName: service.data.serviceArea.title,
        personsInCharge: service.data.personsInCharge,
      };
    });

    const serviceAreaContactPersons = serviceAreas.map((serviceArea) => {
      return {
        areaName: serviceArea.data.title,
        personsInCharge: serviceArea.data.personsInCharge,
      };
    });

    for (let area of subServiceContactPersons) {
      for (let personInCharge of area.personsInCharge) {
        personInCharge.person.area = area.areaName;
        personsInCharge.push(personInCharge.person);
      }
    }

    for (let area of serviceContactPersons) {
      for (let personInCharge of area.personsInCharge) {
        personInCharge.person.area = area.areaName;
        personsInCharge.push(personInCharge.person);
      }
    }

    for (let area of serviceAreaContactPersons) {
      for (let personInCharge of area.personsInCharge) {
        personInCharge.person.area = area.areaName;
        personsInCharge.push(personInCharge.person);
      }
    }

    for (let post of newsContactPersons) {
      for (let personInCharge of post.personsInCharge) {
        personInCharge.person.area = post.areaName;
        personsInCharge.push(personInCharge.person);
      }
    }

    for (let post of pressContactPersons) {
      for (let personInCharge of post.personsInCharge) {
        personInCharge.person.area = post.areaName;
        personsInCharge.push(personInCharge.person);
      }
    }

    const contactPersonNames = [
      ...new Set(
        personsInCharge.map(
          (person) => person.firstname + " " + person.lastname
        )
      ),
    ];

    const distinctContactPersons = [];

    for (let name of contactPersonNames) {
      const personsWithName = personsInCharge.filter(
        (person) => person.firstname + " " + person.lastname === name
      );
      const combinedAreas = personsWithName.map((person) => person.area);
      const personWithName = personsWithName[0];
      personWithName.area = [...new Set(combinedAreas)];
      personWithName.type = "Ansprechpartner";

      distinctContactPersons.push({
        name,
        data: personWithName,
      });
    }

    return {
      news,
      press,
      serviceAreas,
      services,
      subServices,
      distinctContactPersons,
      newCompanies,
    };
  },
  entriesAsList(state, getters) {
    const query = state.query.toLowerCase();
    const entries = getters["getEntries"];

    const news = entries.news
      .filter(
        (item) =>
          item.name.toLowerCase().includes(query) ||
          item.data.personsInCharge.some((personInCharge) =>
            (
              personInCharge.person.firstname +
              " " +
              personInCharge.person.lastname
            )
              .toLowerCase()
              .includes(query)
          )
      )
      .map((item) => item.data);
    const press = entries.press
      .filter(
        (item) =>
          item.name.toLowerCase().includes(query) ||
          item.data.personsInCharge.some((personInCharge) =>
            (
              personInCharge.person.firstname +
              " " +
              personInCharge.person.lastname
            )
              .toLowerCase()
              .includes(query)
          )
      )
      .map((item) => item.data);
    const serviceAreas = entries.serviceAreas
      .filter(
        (item) =>
          item.name.toLowerCase().includes(query) ||
          item.data.personsInCharge.some((personInCharge) =>
            (
              personInCharge.person.firstname +
              " " +
              personInCharge.person.lastname
            )
              .toLowerCase()
              .includes(query)
          )
      )
      .map((item) => item.data);
    const services = entries.services
      .filter(
        (item) =>
          item.name.toLowerCase().includes(query) ||
          item.data.personsInCharge.some((personInCharge) =>
            (
              personInCharge.person.firstname +
              " " +
              personInCharge.person.lastname
            )
              .toLowerCase()
              .includes(query)
          )
      )
      .map((item) => item.data);
    const subServices = entries.subServices
      .filter(
        (item) =>
          item.name.toLowerCase().includes(query) ||
          item.data.personsInCharge.some((personInCharge) =>
            (
              personInCharge.person.firstname +
              " " +
              personInCharge.person.lastname
            )
              .toLowerCase()
              .includes(query)
          )
      )
      .map((item) => item.data);
    const companies = entries.newCompanies
      .filter(
        (item) =>
          item.name.toLowerCase().includes(query) ||
          item.data.newCompany.personsInCharge.some((personInCharge) =>
            (
              personInCharge.person.firstname +
              " " +
              personInCharge.person.lastname
            )
              .toLowerCase()
              .includes(query)
          )
      )
      .map((item) => item.data);
    //const contactPersons = entries.distinctContactPersons.filter(item => item.name.toLowerCase().includes(query)).map(item => item.data);

    return [
      ...news,
      ...press,
      ...serviceAreas,
      ...services,
      ...subServices,
      ...companies /*...contactPersons*/,
    ];
  },
  filteredEntries(state, getters) {
    const filteredEntries = getters["entriesAsList"];
    const filter = getters["getSelectedFilter"];

    if (filter === "Alle") return filteredEntries;
    return filteredEntries.filter((entry) => entry.filter === filter);
  },
  getContactPersonsForQuery(state, getters) {
    const query = state.query.toLowerCase();
    const entries = getters["getEntries"];

    const contactPersonsForEntries = [];

    for (let entry of getters["filteredEntries"]) {
      if (!entry.personsInCharge) continue;

      for (let personInCharge of entry.personsInCharge) {
        if (
          contactPersonsForEntries.find(
            (person) => person.id === personInCharge.person.id
          )
        )
          continue;

        personInCharge.person.area =
          entry.type === "ServiceAreas" ? [entry.title] : [];
        contactPersonsForEntries.push(personInCharge.person);
      }
    }

    const contactPersons = entries.distinctContactPersons
      .filter((item) => item.name.toLowerCase().includes(query))
      .map((item) => item.data);

    contactPersons.push(...contactPersonsForEntries);

    const distinctPersons = [];

    for (let contactPerson of contactPersons) {
      if (distinctPersons.find((person) => person.id === contactPerson.id))
        continue;

      distinctPersons.push(contactPerson);
    }

    return distinctPersons;
  },
  filteredContactPersons(state, getters) {
    const entries = getters["getContactPersonsForQuery"];

    if (state.selectedFilter === "Alle") return entries;
    else
      return entries.filter((entry) =>
        entry.area.includes(state.selectedFilter)
      );
  },
  getResultCountForCategory: (state, getters) => (category) => {
    const entriesAsList = getters["entriesAsList"];
    //const contactPersons = getters ['getContactPersonsForQuery'];
    if (category === "Alle") return entriesAsList.length; // + contactPersons.length;
    return entriesAsList.filter((item) => item.filter === category).length; //+ contactPersons.filter(person => person.area.includes(category)).length;
  },
  getQuery(state) {
    return state.query;
  },
  getSelectedFilter(state) {
    return state.selectedFilter;
  },
  getFilter(state, getters) {
    const filter = ["Alle"];
    const serviceAreas = getters["entriesAsList"].map((entry) => entry.filter);
    filter.push(...serviceAreas);
    const serviceAreasForContactPersons = getters["getContactPersonsForQuery"]
      .map((contactPerson) => contactPerson.area)
      .flat();
    filter.push(...serviceAreasForContactPersons);
    return [...new Set(filter)];
  },
};
