<template>
  <v-list class="transparent">
    <template v-for="(entry, index) of searchResults">
      <search-service-area-row
        v-if="serviceTypes.includes(entry.type)"
        :service-area="entry"
        :key="index"
      />
      <news-list-row
        v-else-if="entry.type === 'News' || entry.type === 'Presse'"
        :key="index"
        :entry="entry"
        :icon-props="iconProps"
        @click="showDetails(entry)"
        :show-contact-person="true"
      />
      <new-companies-list-row
        :show-image="true"
        v-else-if="entry.type === 'NEW-Gruppe'"
        :key="index"
        :entry="entry"
        @click="showCompanyDetails(entry)"
        :show-contact-person="true"
      />
    </template>
  </v-list>
</template>

<script>
import SearchServiceAreaRow from "@/components/list_rows/search/SearchServiceAreaRow";
import NewsListRow from "@/components/list_rows/NewsListRow";
import NewCompaniesListRow from "@/components/list_rows/NewCompaniesRow";
export default {
  name: "SearchList",
  components: { NewCompaniesListRow, NewsListRow, SearchServiceAreaRow },
  data() {
    return {
      serviceTypes: ["ServiceAreas", "Services", "SubServices"]
    };
  },
  methods: {
    showCompanyDetails(entry) {
      this.$store.dispatch("analytics/commitSearchSession", {
        followUpType: "new_company",
        followUp: entry.newCompany
      });
      this.$router.push({
        name: "newCompanyDetail",
        params: { newCompanyId: entry.newCompany.id }
      });
    },
    showDetails(entry) {
      this.checkReads(entry);
      if (entry.category == "Presse") {
        this.$store.dispatch("analytics/commitSearchSession", {
          followUpType: "press_post",
          followUp: entry
        });
        this.$router.push({
          name: "pressDetail",
          params: { pressId: entry.id }
        });
      } else {
        this.$store.dispatch("analytics/commitSearchSession", {
          followUpType: "news_post",
          followUp: entry
        });
        this.$router.push({ name: "newsDetail", params: { newsId: entry.id } });
      }
    },
    checkReads(entry) {
      if (entry.category == "Presse" && entry.wasRead == 0) {
        this.$store.dispatch("news/createPressRead", entry.id);
      } else if (entry.category == "News" && entry.wasRead == 0) {
        this.$store.dispatch("news/createNewsRead", entry.id);
      }
    }
  },
  computed: {
    searchResults() {
      const entries = this.$store.getters["search/filteredEntries"];
      const existingCategories = [
        ...new Set(entries.map(entry => entry.filter))
      ];

      const resultsWithHeadlines = [];

      for (let category of existingCategories) {
        if (category === "Ansprechpartner") continue;
        resultsWithHeadlines.push({
          title: category,
          type: "Headline"
        });

        resultsWithHeadlines.push(
          ...entries.filter(entry => entry.filter === category)
        );
      }
      return resultsWithHeadlines;
    },
    iconProps() {
      if (this.$vuetify.breakpoint.width < 960) {
        return {
          height: "90px",
          width: "90px",
          spacing: "mt-7"
        };
      } else {
        return {
          height: "150px",
          width: "150px",
          spacing: "mt-13"
        };
      }
    }
  }
};
</script>
