export default {
  servicesAreaCount(state) {
    return state.serviceAreaList.length;
  },

  allServiceAreas(state) {
    return state.serviceAreaList;
  },

  serviceAreaByServiceAreaId: (state) => (serviceId) => {
    return state.serviceAreaList.find((el) => el.id == serviceId);
  },
};
