<template>
  <div class="pb-1">
    <v-divider class="pb-1"></v-divider>
    <v-row no-gutters>
      <v-col :md="showContactPerson ? 7 : 12" cols="12">
        <v-list-item three-line @click="$emit('click')">
          <v-list-item-icon class="ma-0 mr-3" v-if="showImage">
            <v-img
              v-if="getNewsThumbUrl()"
              :width="iconProps.width"
              :height="iconProps.height"
              :src="getNewsThumbUrl()"
            />
            <v-img
              v-else-if="getNewsFirstImgUrl()"
              :width="iconProps.width"
              :height="iconProps.height"
              :src="getNewsFirstImgUrl()"
            />
            <div
              v-else
              align="center"
              class="d-inline-block icon-bg"
              :style="`width: ${iconProps.width}; height: ${iconProps.height}`"
            >
              <v-icon x-large color="#ffffff80" :class="`${iconProps.spacing}`">
                mdi-domain
              </v-icon>
            </div>
          </v-list-item-icon>
          <v-list-item-content class="pt-0">
            <v-list-item-title
              class="subtitle-1"
              v-text="entry.newCompany.title"
            >
            </v-list-item-title>
            <v-list-item-subtitle>
              <span class="">
                {{ entry.newCompany.content1.substring(0, 200) }}...
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col
        md="5"
        cols="12"
        v-if="showContactPerson"
        style="background-color: #f7f9fc"
      >
        <contact-person-box
          :person="personInCharge"
          :vertical-design="false"
          v-if="$vuetify.breakpoint.mdAndUp"
        />
        <mobile-contact-person-box :person="personInCharge" v-else />
      </v-col>
    </v-row>
  </div>
</template>

<script>
//import RichText from "@/components/RichText";
import moment from "moment";
import { checkIconName } from "@/mixins/checkIconName";
import ContactPersonBox from "@/components/layout/ContactPersonBox";
import MobileContactPersonBox from "@/components/layout/MobileContactPersonBox";

export default {
  name: "NewCompaniesListRow",
  components: {
    MobileContactPersonBox,
    ContactPersonBox
  },
  mixins: [checkIconName],
  props: {
    entry: Object,
    settings: Object,
    showImage: Boolean,
    //iconProps: Object
    showContactPerson: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_BASE_URL
    };
  },
  methods: {
    isPressNews() {
      return this.entry.is_press == true;
    },
    getNewsThumbUrl() {
      if (this.isPressNews()) {
        return this.entry.img_url;
      } else {
        return this.$store.getters.getStrapiImageUrl(
          this.entry.image,
          "thumbnail"
        );
      }
    },

    getNewsFirstImgUrl() {
      if (this.entry.images && this.entry.images.length > 0) {
        var str = this.baseUrl + this.entry.images[0].url;
        return str;
      } else {
        return this.$store.getters.getStrapiImageUrl(
          this.entry.image,
          "thumbnail"
        );
      }
    },
    getNewsIcon() {
      return (
        "mdi-" +
        (this.entry.category == "pressemeldung"
          ? "newspaper-variant-outline"
          : "bell-ring-outline")
      );
    },

    getNewsType() {
      return this.entry.category == "pressemeldung"
        ? "Pressemeldung"
        : "Ankündigung";
    },

    getNewsDate() {
      // Generell benutzerdefinierte Datum vor automatisch generiertem Timestamp anzeigen
      // updated_at vor published_at anzeigen

      let date;

      // Wenn Presse (kein benutzerdefiniertes veröffentlichungsdatum vorhanden)
      if (this.isPressNews()) {
        if (this.entry.custom_updated_at) {
          date = this.entry.custom_updated_at;
        } else {
          date = this.entry.published_at;
        }
        // Wenn News (Reihenfolge: 1. custom_updated_at, 2. custom_published_at, 3. updated_at, 4. published_at)
      } else {
        if (this.entry.custom_updated_at) {
          date = this.entry.custom_updated_at;
        } else if (this.entry.custom_published_at) {
          date = this.entry.custom_published_at;
        } else if (this.entry.updated_at) {
          date = this.entry.updated_at;
        } else if (this.entry.published_at) {
          date = this.entry.published_at;
        }
      }

      return moment(date).format("DD.MM.YY");
    }
  },
  computed: {
    iconProps() {
      var props = {};
      if (this.$vuetify.breakpoint.width < 960) {
        props = {
          height: "90px",
          width: "90px",
          spacing: "mt-7"
        };
      } else {
        props = {
          height: "150px",
          width: "150px",
          spacing: "mt-13"
        };
      }
      return props;
    },
    personInCharge() {
      return this.entry.newCompany.personsInCharge.length > 0
        ? this.entry.newCompany.personsInCharge[0]
        : this.$store.getters["user/userCommunePartner"];
    }
  }
};
</script>

<style scoped>
/* Make mouse cursor pointer, when hovering over this element */
.v-list-item:hover {
  cursor: pointer;
  background-color: #9c00471a;
}

/* Remove underline from router-link */
a {
  text-decoration: none;
}

/* Background gradient of icon */
.icon-bg {
  background: transparent linear-gradient(226deg, #b40055 0%, #025399 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
}
.dateBadge {
  color: #1f2b37;
  background: #dddddd 0% 0% no-repeat padding-box;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
}
.newBadge {
  color: #f88807;
  border-radius: 4px;
  font-size: 12px;
}
.v-list-item {
  padding: 0 0 !important;
}

.news-icon-container {
  position: relative;
}
.news-icon-overlap {
  position: absolute;
  color: #dddddd;
  font-size: 12px;
  height: 16px;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #1f2b37cc;
  /* color: green; */
}
</style>
