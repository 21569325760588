//Priorisieren der NewsPresse Datenfelder
export function getNewsDate(entry) {
  // Generell benutzerdefinierte Datum vor automatisch generiertem Timestamp anzeigen
  // updated_at vor published_at anzeigen

  let date;

  // Wenn Presse (kein benutzerdefiniertes veröffentlichungsdatum vorhanden)
  if (entry.is_press == true) {
    if (entry.custom_updated_at) {
      date = entry.custom_updated_at;
    } else {
      date = entry.published_at;
    }
    // Wenn News (Reihenfolge: 1. custom_updated_at, 2. custom_published_at, 3. updated_at, 4. published_at)
  } else {
    if (entry.custom_updated_at) {
      date = entry.custom_updated_at;
    } else if (entry.custom_published_at) {
      date = entry.custom_published_at;
    } else if (entry.updated_at) {
      date = entry.updated_at;
    } else if (entry.published_at) {
      date = entry.published_at;
    }
  }

  return date;
}
