var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-wrapper min-height-100"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-10",attrs:{"cols":_vm.colSize}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{class:_vm.$vuetify.breakpoint.width < 960
                ? 'mb-2 custom-header-text new-font-bold'
                : 'mb-4 custom-header-text new-font-bold'},[_vm._v(" NEW Erleben ")])]),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Als Partner regionaler Sportvereine, Kunst-und Kultureinrichtungen unterstützen wir vielfältige Angebote, die das Miteinander fördern und die Lebensqualität bei uns vor Ort weiter steigern. Diese Vorteile möchten wir weitergeben. Ob Sportereignis oder Musik-Event – besondere Momente erzeugen Glücksgefühle mit Langzeitwirkung. "),_c('br'),_c('br'),_vm._v(" Überraschen Sie Ihre Bürger mit Hilfe von "),_c('span',{staticClass:"font-weight-bold"},[_vm._v("NEW Erleben")]),_vm._v(". Auf dieser Plattform stellen wir verschiedene Highlights zur Verfügung, die Sie an Menschen in Ihrer Stadt oder Gemeinde weitergeben können. Freuen Sie sich auf Tickets, Gutscheine, attraktive Aktionen, spannende Gewinnspiele und tolle Geschenke, mit denen Sie anderen eine Freude machen – und so das Wir-Gefühl stärken. "),_c('br'),_c('br'),_vm._v(" Unser Tipp: Schauen Sie regelmäßig auf "),_c('span',{staticClass:"font-weight-bold"},[_vm._v("NEW Erleben")]),_vm._v(" vorbei und informieren Sie sich über die aktuellen Angebote. So können wir gemeinsam für unvergessliche Erlebnisse sorgen. ")]),_c('v-col',{staticClass:"mt-12",attrs:{"cols":"12"}},[_c('moments-card-grid',{attrs:{"items":_vm.getMoments}})],1)],1)],1),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-col',{staticClass:"contact_container pl-4",attrs:{"cols":"4"}},[_c('RightColumnGroup',{attrs:{"content-object":{
          personsInCharge: [_vm.getMomentsContact],
          fileDownloads: [],
          showRegionalLeiter: false,
          interestingLinks: []
        }}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }