<script>
export default {
  name: "QuartiersentwicklungProcessSteps",
  data: () => ({
    selectedStep: 1,
    steps: [
      {
        title: "Gemeinsames Erstgespräch mit folgenden Schwerpunkten:",
        lines: [
          "Gegenseitiges Kennenlernen",
          "Diskussion über mögliche Zusammenarbeit",
          "Klärung der Vertragsbasis",
          "Informationen zu verfügbaren Fördermöglichkeiten",
          "Überblick über den geplanten Projektverlauf",
          "Besprechung technischer Details",
          "Vorstellung der Möglichkeiten der Energieversorgung"
        ]
      },
      {
        title: "Sie übermitteln uns die notwendigen technischen Unterlagen",
        lines: [
          "Nach dem Erstgespräch erhalten Sie von uns eine Checkliste mit einer Auflistung erforderlicher technischer Unterlagen, die wir von Ihnen für die weitere Bearbeitung benötigen.",
        ]
      },
      {
        title: "Wir analysieren die Quellen und erstellen ein Grobkonzept",
        lines: [
          "Wir beginnen mit der groben Konzeptionierung des Projekts, wobei die im Erstgespräch erhaltenen Informationen und die technischen Unterlagen berücksichtigt werden."
        ]
      },
      {
        title: "Sie geben Feedback",
        lines: [
          "Wir stellen Ihnen die Ergebnisse der Quellenanalyse sowie der Grobkonzeptionierung zur Verfügung und Sie geben uns Feedback, um sicherzustellen, dass die Planung den Anforderungen entspricht."
        ]
      },
      {
        title:
          "Mit Ihrer Zustimmung stellen wir den Antrag zur Machbarkeitsstudie",
        lines: [
          "Sobald Sie mit dem Grobkonzept zufrieden und an einer Zusammenarbeit interessiert sind, werden die erforderlichen Vertragswerke abgeschlossen und wir stellen den Antrag zur Machbarkeitsstudie."
        ]
      },
      {
        title: "Gemeinsame Bewertung der Machbarkeitsstudie",
        lines: [
          "Auf Grundlage der Machbarkeitsstudie treffen wir gemeinsam die Entscheidung darüber, ob das Wärmekonzept umgesetzt werden soll."
        ]
      },
      {
        title: "Projektumsetzung des Wärmekonzepts",
        lines: [
          "Bei positiver Entscheidung über das Wärmekonzept erfolgt die Umsetzung des Projekts gemäß den vereinbarten Plänen und Zeitrahmen."
        ]
      }
    ]
  }),
  computed: {
    stepData() {
      return this.steps[this.selectedStep - 1];
    }
  }
};
</script>

<template>
  <div>
    <v-sheet
      elevation="1"
      class="pa-2"
      style="display: flex; justify-content: space-evenly"
      rounded
    >
      <v-btn
        @click="selectedStep = index"
        :color="selectedStep !== index ? 'primary' : ''"
        small
        fab
        rounded
        outlined
        v-for="index in 7"
        :key="index"
        >{{ index }}</v-btn
      >
    </v-sheet>
      <div class="font-weight-bold my-2">{{ stepData.title }}</div>
      <ul>
      <li v-for="(line, index) of stepData.lines" :key="index">
        {{ line }}
      </li>
    </ul>
  </div>
</template>

<style scoped>
</style>
