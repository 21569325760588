<template>
  <div class="new-wrapper min-height-100">
    <v-row no-gutters>
      <v-col :cols="colSize" class="pa-10">
        <v-row no-gutters>
          <v-col cols="12">
            <div
              :class="
                $vuetify.breakpoint.width < 960
                  ? 'mb-2 custom-header-text new-font-bold'
                  : 'mb-4 custom-header-text new-font-bold'
              "
            >
              Nachrichten und Presse
            </div>
          </v-col>

          <v-col id="filterBar" align="right" cols="12">
            <v-row no-gutters justify="end" align="end">
              <v-col
                cols="12"
                sm="12"
                v-if="this.$vuetify.breakpoint.mdAndDown"
              >
                <SortCategory :sortCategoryOptions="sortCategoryOptions" />
              </v-col>
              <v-col cols="12" sm="12">
                <SortDate :sortDateOptions="sortDateOptions" />
              </v-col>
            </v-row>
          </v-col>
          <v-col id="newsList" class="mt-2">
            <NewsList :iconProps="iconProps" />
          </v-col>
        </v-row>
      </v-col>

      <!-- RIGHT SIDE CONTENT - ANSPRECHPARTNER, DOWNLOADS, INTERESSANTES -->
      <v-col
        v-if="$vuetify.breakpoint.smAndUp"
        cols="4"
        class="contact_container pl-4"
      >
        <RightColumnGroup
          :communePartner="communePartner"
          :communeRegionalLeader="communeRegionalLeader"
          :content-object="{
            personsInCharge: [],
            fileDownloads: [],
            showRegionalLeiter: false,
            interestingLinks: []
          }"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { _sortBy } from "@/utils/sortings";
import moment from "moment";
import NewsList from "@/components/lists/NewsList";
import SortDate from "@/components/filter/SortDate";
import SortCategory from "@/components/filter/SortCategory";
import RightColumnGroup from "@/components/layout/RightColumnGroup.vue";

export default {
  name: "News",
  components: {
    SortDate,
    SortCategory,
    NewsList,
    RightColumnGroup
  },

  data: () => ({
    // filter settings
    sortDateOptions: [
      { label: "neueste zuerst", val: "desc" },
      { label: "älteste zuerst", val: "asc" }
    ],
    sortCategoryOptions: [
      { label: "Alle", val: "all" },
      { label: "nur News", val: "News" },
      { label: "nur Presse", val: "Presse" }
    ]
  }),
  computed: {
    colSize() {
      if (this.$vuetify.breakpoint.smAndUp) return 8;
      else return 12;
    },
    communePartner() {
      return this.$store.getters["user/userCommunePartner"];
    },
    communeRegionalLeader() {
      return this.$store.getters["user/userCommuneRegionalLeader"];
    },
    iconProps() {
      var props = {};
      if (this.$vuetify.breakpoint.width < 960) {
        props = {
          height: "90px",
          width: "90px",
          spacing: "mt-7"
        };
      } else {
        props = {
          height: "150px",
          width: "150px",
          spacing: "mt-13"
        };
      }
      return props;
    },
    newsEntries() {
      return this.$store.getters["news/newsList"];
    },
    pressEntries() {
      return this.$store.getters["news/pressList"];
    },
    isLoadingEntries() {
      return !Array.isArray(this.listEntries);
    },

    hasListEntries() {
      return !this.isLoadingEntries && this.listEntries.length > 0;
    },

    filteredEntries_old() {
      if (this.filtersEnabled) {
        const fDateRangeSettings = this.getDateRangeFilterSettings();
        const fSearchSettings = this.getSearchFilterSettings();
        const fCategorySettings = this.getCategoryFilterSettings();

        const useDateRangeFilter =
          fDateRangeSettings && this.dateRangeFilter.length > 0;
        const useSearchFilter = fSearchSettings && this.searchFilter;
        const useCategoryFilter =
          fCategorySettings && this.categoryFilter.length > 0;

        // date range dates
        let dateRangeStart, dateRangeEnd;
        if (useDateRangeFilter) {
          dateRangeStart = moment(this.dateRangeFilter[0]);

          if (this.dateRangeFilter.length == 2) {
            dateRangeEnd = moment(this.dateRangeFilter[1]);

            // invert user range selection 'direction' if needed
            if (dateRangeEnd < dateRangeStart) {
              let startDateTemp = dateRangeStart;
              dateRangeStart = dateRangeEnd;
              dateRangeEnd = startDateTemp;
            }
          }
        }

        // apply configured filters
        const filteredEntries = this.listEntries.filter(entry => {
          if (useDateRangeFilter) {
            const entryDate = moment(entry[fDateRangeSettings.attr]);

            if (!dateRangeEnd && !entryDate.isSame(dateRangeStart, "day")) {
              return false;
            } else if (
              !entryDate.isBetween(dateRangeStart, dateRangeEnd, "day", "[]")
            ) {
              return false;
            }
          }

          if (useSearchFilter) {
            const searchTerm = this.searchFilter.trim();

            if (searchTerm != "") {
              let searchTxt = "";
              // concat searchable fields into one string
              fSearchSettings.attr.forEach(attr => {
                searchTxt += " " + entry[attr];
              });

              const searchRegEx = new RegExp(searchTerm, "gi");

              if (!searchTxt.match(searchRegEx)) {
                return false;
              }
            }
          }

          if (useCategoryFilter) {
            const attrKey = fCategorySettings.attr;
            let categoryVal = entry[attrKey];

            if (Array.isArray(categoryVal)) {
              if (categoryVal.length == 0) return false;

              categoryVal = categoryVal[0];
            }

            // try to find entry's category value in category filter list
            if (this.categoryFilter.indexOf(categoryVal) == -1) return false;
          }

          return true;
        });

        // apply entry sorting
        if (this.contentType == "news") {
          // if news-post content, sort by date

          if (this.getSortDateFilterSettings()) {
            const dateAttr = this.getSortDateFilterSettings().attr;

            filteredEntries.sort((e1, e2) => {
              const d1 = moment(e1[dateAttr]);
              const d2 = moment(e2[dateAttr]);
              let res = d1.isSame(d2, "day") ? 0 : d1.isAfter(d2) ? -1 : 1;

              if (this.sortDateFilter == "oldest") {
                res *= -1; // oldest dates first
              }

              return res;
            });
          }
        } else {
          // if service-post content, sort by title (alphabetically)
          _sortBy(filteredEntries, "title", false);
        }

        return filteredEntries;
      } else {
        // no filters enabled (inside each service-post category)
        let entries = this.listEntries;
        _sortBy(entries, "title");
        return entries;
      }
    },

    filtersEnabled() {
      return !this.filterSettings ? false : true;
    },

    // contentSettings is NaN if no serviceId is passed
    noServiceNumber() {
      return (
        this.contentSettings && Number.isNaN(this.contentSettings.serviceId)
      );
    }
  }
};
</script>
