var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-wrapper min-height-100"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-10",attrs:{"cols":_vm.colSize}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{class:_vm.$vuetify.breakpoint.width < 960
                ? 'mb-2 custom-header-text new-font-bold'
                : 'mb-4 custom-header-text new-font-bold'},[_vm._v(" Nachrichten und Presse ")])]),_c('v-col',{attrs:{"id":"filterBar","align":"right","cols":"12"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"end","align":"end"}},[(this.$vuetify.breakpoint.mdAndDown)?_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('SortCategory',{attrs:{"sortCategoryOptions":_vm.sortCategoryOptions}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('SortDate',{attrs:{"sortDateOptions":_vm.sortDateOptions}})],1)],1)],1),_c('v-col',{staticClass:"mt-2",attrs:{"id":"newsList"}},[_c('NewsList',{attrs:{"iconProps":_vm.iconProps}})],1)],1)],1),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-col',{staticClass:"contact_container pl-4",attrs:{"cols":"4"}},[_c('RightColumnGroup',{attrs:{"communePartner":_vm.communePartner,"communeRegionalLeader":_vm.communeRegionalLeader,"content-object":{
          personsInCharge: [],
          fileDownloads: [],
          showRegionalLeiter: false,
          interestingLinks: []
        }}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }