import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

export default {
  namespaced: true,
  state: {
    query: "",
    originalQuery: "",
    selectedFilter: "Alle",
  },
  mutations,
  actions,
  getters,
};
