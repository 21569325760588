import mutations from './mutations';
import getters from './getters';
import actions from './actions';

export default {
    namespaced: true,
    state: {
        items: [],
        selectedThemaId: undefined,
        sortBy: {
            key: 'Änderungsdatum',
            order: 'asc'
        }
    },
    mutations,
    getters,
    actions,

}
