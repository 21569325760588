export default {
    communeNames(state) {
        return state.communeNames.toSorted();
    },
    selectedCommune(state) {
        return state.selectedCommune;
    },
    showCards(state) {
        return state.selectedCommune.length > 0 && state.communeData !== undefined;
    },
    communeStyles(state) {
        const activeCommuneColor = '#8c0f78';
        const inactiveCommuneColor = 'rgb(238,197,216)' //'#281e78';
        const inactiveCommuneOpacity = 1;

        const communes = {};

        for(const name of state.communeNames) {
            communes[name] = {
                fill: state.selectedCommune === name ? activeCommuneColor : inactiveCommuneColor,
                opacity:  state.selectedCommune === name ? 1 : inactiveCommuneOpacity,
            };
        }

        return communes;
    },
    communeOverview(state) {
        return state.communeData?.overview;
    },
    communeMayor(state) {
        let img = '';
        const thumbnail = state.communeData?.mayor?.Profilbild?.formats?.thumbnail ?? state.communeData?.mayor?.Profilbild;

        if(thumbnail) {
            img = `${process.env.VUE_APP_API_BASE_URL}${thumbnail.url}`;
        }

        let url = state.communeData?.mayor?.Website;

        if(url && !url.startsWith('http')) {
            url = `https://${url}`;
        }

        return {
            name: state.communeData?.mayor?.Name ?? '',
            position: state.communeData?.mayor?.Position ?? '',
            party: state.communeData?.mayor?.Partei ?? '',
            dob: state.communeData?.mayor?.Geburtstag ?? '',
            picture: img,
            url: url ?? '',
        }
    },
    latestKonzessionsThema(state) {
        const thema = state.communeData?.konzessionsthemen[0];

        if(!thema) {
            return undefined;
        }

        thema.date = new Date(thema.updated_at);

        return thema;
    },
    latestRequest(state) {
        const requests = state.communeData?.requests;
        if(!requests) {
            return undefined;
        }

        return requests[requests.length - 1];
    },
    contracts(state) {
        return state.communeData?.contracts.map(c => {
            c.date = new Date(c.ende);
            c.name = c.name.replaceAll(`Gemeindegebiet`, '')
                .replaceAll('konzessionsvertrag', '')
                .replaceAll('Gemeinde', '')
                .replaceAll(state.selectedCommune, '')
                .trim()
                .split(' ')[0];
            return c;
        }) ?? [];
    }
}
