<template>
  <v-row>
    <v-col cols="12" md="8" class="px-10 py-5">
      <p class="custom-header-text">Keine Treffer</p>
      <h3 class="mb-16">
        Zu Ihrer Anfrage konnten wir leider nichts finden.<br />
        Sie können ihre Treffer erhöhen, indem Sie nach einem ganzen Bereich,
        wie zum Beispiel "<router-link to="/search?q=Strom">Strom</router-link
        >", suchen.
      </h3>
      <span class="custom-header-text py-5">Aktuelle Neuigkeiten</span>
      <NewsList :icon-props="iconProps" />
    </v-col>
    <v-col cols="12" md="4" class="contact_container">
      <right-column-group
        :commune-partner="communePartner"
        :content-object="rightColumnOptions"
      />
    </v-col>
  </v-row>
</template>

<script>
import RightColumnGroup from "@/components/layout/RightColumnGroup";
import { mapGetters } from "vuex";
import NewsList from "@/components/lists/NewsList";
export default {
  name: "NoSearchResultsDisplay",
  components: { NewsList, RightColumnGroup },
  computed: {
    ...mapGetters({
      communePartner: "user/userCommunePartner"
    }),
    iconProps() {
      if (this.$vuetify.breakpoint.width < 960) {
        return {
          height: "90px",
          width: "90px",
          spacing: "mt-7"
        };
      } else {
        return {
          height: "150px",
          width: "150px",
          spacing: "mt-13"
        };
      }
    }
  },
  methods: {
    onLinkClick() {
      this.$router.push("/search?q=Strom");
    }
  },
  data() {
    return {
      rightColumnOptions: {
        personsInCharge: [],
        showRegionalLeiter: false,
        fileDownloads: [],
        interestingLinks: []
      }
    };
  }
};
</script>

<style scoped></style>
