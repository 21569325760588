<template>
  <v-row no-gutters class="min-height-100" v-if="serviceAreaByServiceAreaId">
    <template v-if=" serviceAreaByServiceAreaId.title !== 'Nachhaltigkeit'">
    <v-col cols="12">
      <v-img
        v-if="serviceAreaByServiceAreaId.headerImage"
        aspect-ratio="2.1333"
        max-height="450"
        :src="`${baseUrl}${serviceAreaByServiceAreaId.headerImage.url}`"
      >
        <span
          class="custom-header-text new-font-bold white--text"
          style="position: absolute; bottom: 20px; left: 35px;"
        >
          {{ serviceAreaByServiceAreaId.title }}
        </span>
      </v-img>
      <!-- Fallback img -->
      <v-img
        v-else
        aspect-ratio="2.1333"
        max-height="450"
        :src="fallbackImg"
      ></v-img>
    </v-col>
    <!-- MAIN CONTENT -->
    <v-col :cols="colSize" class="new-wrapper px-10 py-4">
      <!-- TEXT SECTION 1 -->
      <v-row no-gutters class="mt-5">
        <vue-markdown-it
          v-if="serviceAreaByServiceAreaId.content1"
          :source="serviceAreaByServiceAreaId.content1"
          id="content1"
        />
      </v-row>

      <!-- SERVICE/SUBSERVICE ANGEBOT -->
      <template v-if="serviceAreaByServiceAreaId.services.length != 0">
        <v-row no-gutters class="mt-6 text-h5">
          {{
            isNewEmployee && hasKonzession
              ? "Unsere Kommunen:"
              : "Unser Angebot:"
          }}
        </v-row>
        <v-row class="mt-1">
          <v-col
            v-for="(service, index) in serviceAreaByServiceAreaId.services"
            :key="service.id"
            :cols="$vuetify.breakpoint.smAndDown ? 12 : 6"
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'pa-1'
                : index % 2
                ? 'pl-2'
                : 'pr-2'
            "
          >
            <ButtonCard :object="service" routeName="service" />
          </v-col>
        </v-row>
      </template>

      <!-- CAROUSEL OR IMAGE-->
      <v-row v-if="images.length > 1" no-gutters class="mt-6 new-iframe">
        <Carousel :slides="images" />
      </v-row>
      <v-row v-else-if="images.length == 1" class="px-3 mt-14">
        <v-img class="new-iframe" :src="`${baseUrl}${images[0].url}`"></v-img>
        <p v-if="images[0].caption" class="text-caption">
          {{ images[0].caption }}
        </p>
      </v-row>

      <!-- TEXT SECTION 2 -->
      <v-row no-gutters class="mt-5">
        <vue-markdown-it
          v-if="serviceAreaByServiceAreaId.content2"
          :source="serviceAreaByServiceAreaId.content2"
          id="content2"
        />
      </v-row>

      <v-row v-if="serviceAreaByServiceAreaId.title === 'Mobilität'">
        <v-col id="wunschladesaeulen">
          <h2>Wunschladesäule</h2>
          <p>
            <b>Wünschen Sie sich einen Platz für eine Ladesäule</b><br />
            Wie nehmen Ihren Vorschlag im Rahmen der Bürgerbeteiligung für neue
            Standorte von Ladesäulen entgegen!
          </p>

          <ol>
            <li>
              Wunschstandort per Klick in der Karte auswählen und festlegen
            </li>
            <li>Formular ausfüllen und abschicken</li>
            <li>E-Mail-Adresse verifizieren</li>
          </ol>
          <p>
            <br />
            FERTIG
            <br />Wir prüfen Ihren Standortvorschlag und schalten ihn zum
            Bürger:innenvoting frei.
          </p>
          <div style="-webkit-overflow-scrolling: touch; overflow-y: scroll;">
            <iframe
              class="new-iframe"
              height="740"
              id="iframe"
              marginheight="0px"
              marginwidth="0px"
              name="iframe"
              scrolling="yes"
              :src="getLadesaeulenUrl"
              width="100%"
            ></iframe>
          </div>
        </v-col>
      </v-row>
      <RightColumnGroup
        v-if="$vuetify.breakpoint.xs"
        :communePartner="communePartner"
        :communeRegionalLeader="communeRegionalLeader"
        :contentObject="serviceAreaByServiceAreaId"
      />
    </v-col>
    </template>
    <v-col :cols="colSize" v-else class="new-wrapper px-10 py-4">
      <nachhaltigkeits-view />
    </v-col>
    <!-- RIGHT SIDE CONTENT - ANSPRECHPARTNER, DOWNLOADS, INTERESSANTES -->
    <v-col
      v-if="$vuetify.breakpoint.smAndUp"
      cols="4"
      class="contact_container pl-4"
    >
      <RightColumnGroup
        :communePartner="communePartner"
        :communeRegionalLeader="communeRegionalLeader"
        :contentObject="serviceAreaByServiceAreaId"
      />
    </v-col>
  </v-row>
</template>

<script>
import { checkIconName } from "@/mixins/checkIconName";
import Carousel from "@/components/layout/Carousel.vue";
import ButtonCard from "@/components/layout/ButtonCard.vue";
import RightColumnGroup from "@/components/layout/RightColumnGroup.vue";
import VueMarkdownIt from "vue-markdown-it";
import {mapGetters} from "vuex";
import NachhaltigkeitsView from "@/views/NachhaltigkeitsView.vue";

export default {
  components: {
    NachhaltigkeitsView,
    Carousel,
    ButtonCard,
    RightColumnGroup,
    VueMarkdownIt
  },
  mixins: [checkIconName],
  name: "ServiceAreaDetail",
  data: function() {
    return {
      baseUrl: process.env.VUE_APP_API_BASE_URL
    };
  },
  computed: {
    ...mapGetters('user', ['getLadesaeulenUrl']),
    colSize() {
      if (this.$vuetify.breakpoint.smAndUp) return 8;
      else return 12;
    },
    images() {
      return this.serviceAreaByServiceAreaId.images;
    },

    menuItems() {
      return this.$store.getters["navigation/getMainNavObject"];
    },

    serviceAreaId() {
      return Number(this.$route.params.serviceAreaId);
    },

    serviceAreaByServiceAreaId() {
      return this.menuItems.serviceAreaItems.filter(
        s => s.id == this.serviceAreaId
      )[0];
    },

    communePartner() {
      return this.$store.getters["user/userCommunePartner"];
    },

    communeRegionalLeader() {
      return this.$store.getters["user/userCommuneRegionalLeader"];
    },
    fallbackImg() {
      var image = require("@/assets/headerbild_image_1920x900.jpg");

      return image;
    },
    hasKonzession() {
      return ["Trinkwasser", "Abwasser", "Straßenbeleuchtung"].includes(
        this.serviceAreaByServiceAreaId.title
      );
    },
    isNewEmployee() {
      return this.$store.getters["user/isNewEmployee"];
    }
  },
  methods: {}
};
</script>

<style scoped></style>
