<template>
  <span>
    <v-row no-gutters class="mt-5">
      <v-col :class="{ 'text-center': verticalDesign }" cols="2">
        <v-avatar
          v-if="getContactImgUrl()"
          :size="!verticalDesign ? auto : 150"
          :style="!verticalDesign ? auto : 'margin:auto'"
          color="grey"
        >
          <v-img :src="`${baseUrl}${getContactImgUrl()}`"></v-img>
        </v-avatar>
        <v-avatar
          class="icon-bg"
          v-else
          :size="!verticalDesign ? 38 : 150"
          :style="!verticalDesign ? 38 : 'margin:auto'"
        >
          <v-icon :size="!verticalDesign ? 100 : 150" color="#ffffff80">
            $user
          </v-icon>
        </v-avatar>
      </v-col>
      <v-col v-if="!verticalDesign">
        <v-row no-gutters class="new-font-bold">
          {{ getFullName() }}
        </v-row>
        <v-row no-gutters>{{ contact.customTitle }}</v-row>
        <span class="font-italic">{{ person.info }}</span>
      </v-col>
      <v-col cols="12" v-if="!verticalDesign">
        <v-row class="mt-2" no-gutters>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span class="label-bg mb-1" v-bind="attrs" v-on="on">
                <v-icon small color="primary">$dashboardEmail</v-icon>
                <a
                  @click="onContactClick('email')"
                  :href="`mailto:${contact.email}`"
                  class="primary--text text-decoration-none"
                >
                  {{ contact.email }}
                </a></span
              >
            </template>
            <span>{{ getFullName() }} per Email kontaktieren</span>
          </v-tooltip>
        </v-row>
        <v-row no-gutters>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span class="label-bg" v-bind="attrs" v-on="on"
                ><v-icon small color="primary">$callIcon</v-icon>
                <a
                  style="text-decoration:none"
                  @click="onContactClick('phone')"
                  :href="`tel:${contact.phone}`"
                  class="primary--text text-decoration-none"
                >
                  {{ contact.phone }}
                </a></span
              >
            </template>
            <span>{{ getFullName() }} anrufen</span>
          </v-tooltip>
        </v-row>
      </v-col>
    </v-row>

    <template v-if="verticalDesign">
      <v-row no-gutters class="font-weight-bold">
        {{ getFullName() }}
      </v-row>
      <v-row no-gutters>
        {{ contact.customTitle }}
      </v-row>
      <v-row no-gutters>
        <span class="font-italic">{{ person.info }}</span>
      </v-row>
    </template>

    <v-row no-gutters class="mt-1" v-if="verticalDesign">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span class="label-bg mb-1" v-bind="attrs" v-on="on">
            <v-icon small color="primary">$dashboardEmail</v-icon>
            <a
              @click="onContactClick('email')"
              :href="`mailto:${contact.email}`"
              class="primary--text"
            >
              {{ contact.email }}
            </a></span
          >
        </template>
        <span>{{ getFullName() }} per Email kontaktieren</span>
      </v-tooltip>
    </v-row>
    <v-row no-gutters class="mb-4" v-if="verticalDesign">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span class="label-bg" v-bind="attrs" v-on="on"
            ><v-icon small color="primary">$callIcon</v-icon>
            <a
              style="text-decoration:none"
              @click="onContactClick('phone')"
              :href="`tel:${contact.phone}`"
              class="primary--text"
            >
              {{ contact.phone }}
            </a></span
          >
        </template>
        <span>{{ getFullName() }} anrufen</span>
      </v-tooltip>
    </v-row>
  </span>
</template>

<script>
export default {
  name: "ContactPersonBox",
  props: {
    person: Object,
    verticalDesign: Boolean
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_BASE_URL
    };
  },
  computed: {
    contact() {
      if (this.person.person) {
        return this.person.person;
      } else {
        return this.person;
      }
    }
  },
  methods: {
    onContactClick(type) {
      const data = {
        contact_name: this.getFullName(),
        contact_type: type
      };

      this.$ga("event", "page_contacted", data);
    },
    getFullName() {
      return this.contact.firstname + " " + this.contact.lastname;
    },
    getContactImgUrl() {
      if (this.contact.image && this.contact.image.formats.small) {
        return this.contact.image.formats.small.url;
      } else if (this.contact.image && this.contact.image.url) {
        return this.contact.image.url;
      } else {
        return false;
      }
    }
  }
};
</script>

<style>
.label-bg {
  background-color: #e9cfdc;
  color: #b40055;
  border-radius: 5px;
  padding: 0px 5px;
}
</style>
