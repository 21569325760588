<template>
<v-card width="100%" class="mb-5" elevation="2" link @click="onNewsClick">
  <v-img v-if="entry.images.length > 0" :src="`${baseUrl}${entry.images[0].url}`" />
  <div class="px-4 pt-2">
    <v-chip v-show="showNeuBadge" x-small color="rgba(248, 168, 7, 0.3)" class="newBadge mr-2 mt-1" label>Neu</v-chip>
    <v-chip x-small class="dateBadge mr-2 mt-1" label>{{ getDate() }}</v-chip>
  </div>
  <v-card-title style="color: #9c0047; word-break: break-word" class="pt-1">{{ entry.title }}</v-card-title>
  <v-card-subtitle>{{ entry.subtitle }}</v-card-subtitle>
</v-card>
</template>

<script>
import moment from "moment";

export default {
  name: 'MagazinCard',
  props: ['entry'],
  data: () => ({
    baseUrl: process.env.VUE_APP_API_BASE_URL,
  }),
  computed: {
    isUnreadNews() {
      return this.$store.getters['news/isUnreadNews'](this.entry.id);
    },
    url() {
      return `/news-detail/${this.entry.id}`;
    },
    showNeuBadge() {
      const date = this.getDate();
      const newsDate = moment(date, 'DD.MM.YYYY');
      const currentDate = moment();
      const daysBetween = moment.duration(currentDate.diff(newsDate)).asDays();

      return !this.entry.wasRead && daysBetween < 21;
    },
  },
  methods: {
    onNewsClick() {
      this.$store.dispatch('news/createNewsRead', this.entry.id);
      this.$router.push(this.url);
    },
    getDate() {
      if(this.entry.pub_date) {
        const splits = this.entry.pub_date.split('-')

        return splits[2] + '.' + splits[1] + '.' + splits[0]
      } else
        return moment(this.entry.published_at).format("DD.MM.YYYY");
    }
  }
}
</script>

<style scoped>
.dateBadge {
  color: #1F2B37;
  background: #DDDDDD 0% 0% no-repeat padding-box;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
}

.newBadge {
  color: #F88807;
  border-radius: 4px;
  font-size: 12px;
}
</style>
