var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.services)?_c('v-list',{class:_vm.$vuetify.breakpoint.mdAndDown ? 'mt-16' : '',attrs:{"dense":""}},[(_vm.$vuetify.breakpoint.width < 1264)?_c('v-list-item',[_c('commune-partner-card',{attrs:{"width":"100%"}})],1):_vm._e(),_c('v-list-item',{staticClass:"py-1 px-5"},[_c('v-list-item-content',{staticClass:"new-font-size-14"},[_c('v-btn',{attrs:{"block":"","outlined":"","small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.goToDashboard($event)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" Zurück zum Dashboard ")],1)],1)],1),_c('v-list-item',{staticClass:"text-decoration-none py-1",attrs:{"to":{
      name: 'service-area',
      params: { serviceAreaId: ("" + _vm.serviceAreaId) }
    },"link":"","color":"primary"}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"new-icon-color"},[_vm._v(_vm._s(_vm.checkIconName(_vm.serviceAreaByServiceAreaId)))])],1),_c('v-list-item-content',[_c('span',{staticClass:"pl-2"},[_vm._v(" "+_vm._s(_vm.serviceAreaByServiceAreaId.title)+" ")])])],1),_vm._l((_vm.services),function(service){return [(!_vm.isWaermeplanungService(service) || _vm.enableWaermeplanung)?_c('v-list-item',{key:'service-' + service.id,staticClass:"text-decoration-none py-1 pl-14",attrs:{"to":{ name: 'service', params: { serviceId: ("" + (service.id)) } },"link":"","color":"primary"}},[_c('v-list-item-content',{staticClass:"new-font-size-14"},[_vm._v(" "+_vm._s(service.title)+" ")])],1):_vm._e(),(service.length !== 0)?_vm._l((_vm.getSubServicesForRole(service)),function(subService){return _c('v-list-item',{key:subService.id,staticClass:"text-decoration-none py-1 pl-14",attrs:{"to":{
          name: 'sub-service',
          params: {
            serviceId: ("" + (service.id)),
            subServiceId: ("" + (subService.id))
          }
        },"link":"","color":"primary"}},[_c('v-list-item-content',{staticClass:"new-font-size-14",staticStyle:{"padding-left":"24px"}},[_vm._v(" "+_vm._s(subService.title)+" ")])],1)}):_vm._e()]})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }