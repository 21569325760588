var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"min-height-100"},[_c('v-row',{staticClass:"new-font-bold mt-5 mb-4",attrs:{"no-gutters":""}},[_vm._v(" Ansprechpartner ")]),(
      _vm.contentObject.personsInCharge &&
        _vm.contentObject.personsInCharge.length == 0
    )?[(_vm.contentObject.showRegionalLeiter == true)?_c('ContactPersonBox',{attrs:{"person":_vm.communeRegionalLeader,"verticalDesign":_vm.$vuetify.breakpoint.width < 1440}}):_c('ContactPersonBox',{attrs:{"person":_vm.communePartner,"verticalDesign":_vm.$vuetify.breakpoint.width < 1440}})]:_vm._e(),_vm._l((_vm.contentObject.personsInCharge),function(person){return [_c('ContactPersonBox',{key:person.id,attrs:{"person":person,"verticalDesign":_vm.$vuetify.breakpoint.width < 1440}})]}),(
      _vm.contentObject.fileDownloads && _vm.contentObject.fileDownloads.length != 0
    )?[_c('v-row',{staticClass:"mt-12",attrs:{"no-gutters":""}},[_c('span',{staticClass:"text-overline"},[_vm._v("Downloads")])]),_vm._l((_vm.contentObject.fileDownloads),function(file){return _c('v-row',{key:file.id,attrs:{"no-gutters":""}},[_c('span',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-download")]),(file.caption)?_c('a',{staticClass:"primary--text",attrs:{"href":("" + _vm.baseUrl + (file.url))}},[_vm._v(" "+_vm._s(file.caption)+" ")]):_c('a',{staticClass:"primary--text",attrs:{"href":("" + _vm.baseUrl + (file.url))}},[_vm._v(" "+_vm._s(file.name)+" ")])],1)])})]:_vm._e(),(
      _vm.contentObject.interestingLinks &&
        _vm.contentObject.interestingLinks.length != 0
    )?[_c('v-row',{staticClass:"new-font-bold mt-12 mb-3",attrs:{"no-gutters":""}},[_vm._v(" Ebenfalls Interessant ")]),_vm._l((_vm.contentObject.interestingLinks),function(link){return _c('v-row',{key:link.id,attrs:{"no-gutters":""}},[_c('a',{staticClass:"primary--text",attrs:{"href":("" + (link.link)),"target":"_blank"}},[_vm._v(" "+_vm._s(link.title)+" ")])])})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }