import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

export default {
  namespaced: true,
  state: {
    subServicesList: [],
    subServicesIdMap: {},
    // // actual service post
    // servicePosts: null,
    // servicePostsMap: {}, // key = service ID
  },
  mutations,
  actions,
  getters
};
