import Vue from "vue";
import Vuetify from "vuetify/lib";
import de from "vuetify/es5/locale/de";
// import newIcons from './new-ag-icons'
import newIcons2021 from "./new-ag-icons-2021";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { de },
    current: "de"
  },
  theme: {
    themes: {
      light: {
        primary: "#b40055",
        secondary: "#2b6ea9",
        accent: "#275c9b",
        error: "#d32f2f"
      },
      dark: {
        primary: "#b40055",
        secondary: "#9c0047",
        accent: "#275c9b",
        error: "#f34848",
        primaryLight: "#fff"
      }
    }
  },
  icons: {
    values: newIcons2021
  }
});
