<script>
import MomentsCard from "@/components/ui/Moments/MomentsCard.vue";

export default {
  name: "MomentsCardGrid",
  components: {MomentsCard},
  props: ["items"],
}
</script>

<template>
  <masonry :cols="$vuetify.breakpoint.lgAndDown ? 2 : 3" :gutter="15">
    <moments-card v-for="item of items" :key="item.id" :item="item" />
  </masonry>
</template>

<style scoped>

</style>
