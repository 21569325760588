<script>
import { mapActions, mapGetters } from "vuex";
import VueMarkdownIt from "vue-markdown-it";
import RightColumnGroup from "@/components/layout/RightColumnGroup.vue";

export default {
  name: "WaermeFAQ",
  components: {
    RightColumnGroup,
    VueMarkdownIt
  },
  props: {
    hideSidebar: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("waerme", ["getFAQ"]),
    ...mapGetters("generalContent", ["getAboutUs"]),
    ...mapGetters("user", ["userCommunePartner", "userCommuneRegionalLeader"])
  },
  methods: {
    ...mapActions("waerme", ["loadFAQ"])
  },
  mounted() {
    this.loadFAQ();
  }
};
</script>

<template>
  <v-row no-gutters class="min-height-100 new-wrapper" >
      <v-col cols="12" :sm="hideSidebar ? 12 : 8">
        <v-row :class="hideSidebar ? 'pt-10' : 'pa-10'">
          <v-col cols="12">
          <span class="custom-header-text new-font-bold">
            FAQ Wärmeplanung
          </span>
          </v-col>
          <v-col cols="12">
            <div class="text-h5 mt-4 mb-2">Allgemein</div>
            <v-card class="mx-auto" elevation="0">
              <v-expansion-panels accordion>
                <v-expansion-panel v-for="item of getFAQ.allgemein" :key="item.id">
                  <v-expansion-panel-header class="expansion-panel-header-custom">
                    {{ item.Titel }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <vue-markdown-it :source="item.Text"/>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </v-col>
          <v-col cols="12">
            <div class="text-h5 mt-4 mb-2">Endverbraucher:innen</div>
            <v-card class="mx-auto" elevation="0">
              <v-expansion-panels accordion>
                <v-expansion-panel v-for="item of getFAQ.endverbraucher" :key="item.id">
                  <v-expansion-panel-header class="expansion-panel-header-custom">
                    {{ item.Titel }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <vue-markdown-it :source="item.Text"/>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </v-col>
          <v-col cols="12">
            <div class="text-h5 mt-4 mb-2">Kommunen</div>
            <v-card class="mx-auto" elevation="0">
              <v-expansion-panels accordion>
                <v-expansion-panel v-for="item of getFAQ.kommune" :key="item.id">
                  <v-expansion-panel-header class="expansion-panel-header-custom">
                    {{ item.Titel }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <vue-markdown-it :source="item.Text"/>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="!hideSidebar" cols="4" sm="0" class="contact_container pl-4">
        <RightColumnGroup
            v-if="$vuetify.breakpoint.smAndUp"
            :communePartner="userCommunePartner"
            :communeRegionalLeader="userCommuneRegionalLeader"
            :contentObject="getAboutUs"
        />
      </v-col>
  </v-row>
</template>

<style scoped></style>
