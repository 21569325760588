export default {
    SET_FOERDERMITTEL(state, foerdermittel) {
        state.foerdermittel = foerdermittel;
    },
    SET_FILTER(state, filter) {
        state.activeFilter = filter;
    },
    SET_SORT_BY(state, sortBy) {
        state.sortBy = sortBy;
    },
}
