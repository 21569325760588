<template>
  <div>
    <v-row>
      <v-col :cols="colSize" class="px-0">
        <v-row no-gutters>
          <v-col cols="12" class="py-3 px-10 frist-row">
            <b>FRIST: {{ fristDate }}</b>
            <br v-if="$vuetify.breakpoint.smAndDown" />
            <span
              v-if="entry.Aufruf"
              :class="{ 'ml-5': $vuetify.breakpoint.mdAndUp }"
              >+++ {{ entry.Aufruf }} +++</span
            >
          </v-col>
          <v-col cols="12" class="px-10">
            <div class="pt-2">
              <v-chip
                x-small
                class="mr-2 mt-1 thema-badge"
                label
                v-for="thema of entry.themen"
                :key="thema"
                >{{ thema }}</v-chip
              >
              <v-chip x-small class="mr-2 mt-1 frist-badge" label
                >FRIST: {{ fristDate }}</v-chip
              >
            </div>
            <div class="custom-header-text new-font-bold">
              {{ entry.Foerdertitel }}
            </div>
            <div class="text-h6 my-4" style="line-height: 1.5rem">
              {{ entry.Untertitel }}
            </div>
          </v-col>
          <v-col cols="12" class="info-row px-10 py-4">
            <v-row>
              <v-col cols="6" md="4">
                <span class="font-weight-bold">Antragsberechtigt:</span>
                <div
                  v-for="berechtigter of entry.Antragsberechtigt"
                  :key="berechtigter.id"
                >
                  {{ berechtigter.Beschreibung }}
                </div>
                <!--<br>
              {{ entry.Antragsberechtigter }}-->
              </v-col>
              <v-col cols="6" md="4">
                <span class="font-weight-bold">Beihilfekategorie:</span>
                <br />
                {{ entry.Beihilfekategorie }}
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-bold">Zuständige Stellen:</span>
                <br />
                <v-btn @click="scrollToAnsprechpartner" color="primary"
                  >Ansprechpartner</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pa-10">
            <span class="font-weight-bold">Fördergegenstände:</span>
            <ul class="pt-5">
              <li v-for="item of entry.Foerdergegenstaende" :key="item.id">
                <vue-markdown-it :source="item.Wert" class="markdown-entry" />
              </li>
            </ul>
          </v-col>
          <v-col cols="12" md="8" class="px-10">
            <span class="font-weight-bold">Förderquote:</span>
            <ul class="pt-5">
              <li v-for="item of entry.Foerderhoehe" :key="item.id">
                <vue-markdown-it :source="item.Wert" class="markdown-entry" />
              </li>
            </ul>
          </v-col>
          <v-col cols="12" md="4" class="pa-10 hinweis-col">
            <span class="font-weight-bold">Hinweis:</span>
            <vue-markdown-it :source="entry.Hinweis" />
          </v-col>
          <v-col
            cols="12"
            class="pa-5"
            v-if="$vuetify.breakpoint.mdAndUp"
          ></v-col>
          <v-col cols="12" class="pa-10 ansprechpartner-col">
            <span class="font-weight-bold" id="ansprechpartner"
              >Ansprechpartner:</span
            >
            <v-row>
              <v-col
                cols="12"
                :md="ansprechpartner.length > 1 ? 6 : 12"
                class="text-wrap"
                style="word-break: break-all;"
                v-for="(partner, index) of ansprechpartner"
                :key="index"
              >
                <vue-markdown-it :source="partner" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pa-10">
            <span class="font-weight-bold">Weiterführende Links:</span>
            <div v-for="link of entry.Links" :key="link.id">
              <a :href="link.Url" target="_blank">
                <v-icon left color="primary">mdi-open-in-new</v-icon>
                {{ link.Titel }}
              </a>
            </div>
          </v-col>
          <v-col cols="12" class="px-10">
            <span class="font-weight-bold">Fördermittelgeber:</span>
            <div style="display: flex">
              <a
                v-for="item of entry.Foerdermittelgeber"
                :key="item.id"
                :href="item.Link"
                target="_blank"
              >
                <v-img
                  v-if="item.Logo.length > 0"
                  :alt="item.Titel"
                  :src="`${baseUrl}${item.Logo[0].url}`"
                  :width="imgSize"
                />
                <span v-else>{{ item.Titel }}</span>
              </a>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4" class="contact_container pl-2">
        <div :class="sidebarClasses" v-if="entry.AngebotDerNew">
          <v-row no-gutters class="my-4" v-if="showAnpsrechpartner">
            <span class="font-weight-bold" style="width: 100%"
              >IHR NEW ANSPRECHPARTNER</span
            >
          </v-row>
          <div class="mx-auto" style="max-width: 360px">
            <ContactPersonBox
              class="mx-auto"
              v-if="showAnpsrechpartner"
              :person="entry.AngebotDerNew.Ansprechpartner[0]"
              :verticalDesign="$vuetify.breakpoint.width < 1520"
            />
          </div>
          <v-row no-gutters class="my-4" v-if="showServiceAndSubserviceTitle">
            <span class="font-weight-bold"
              >WIR KÖNNEN SIE BEI DIESER FÖRDERMÖGLICHKEIT UNTERSTÜTZEN</span
            >
          </v-row>
          <v-card
            v-for="service of entry.AngebotDerNew.Services"
            :key="service.id"
            :to="`/service-area/${service.serviceArea}/service/${service.id}`"
            link
            class="angebot-der-new-card mb-4"
          >
            <v-img :src="getImageUrl(service)" />
            <v-card-title style="color: #9c0047">{{
              service.title
            }}</v-card-title>
            <v-card-subtitle>
              {{ getTextPreview(service.content1, 20) }}</v-card-subtitle
            >
          </v-card>
          <v-card
            v-for="service of entry.AngebotDerNew.SubServices"
            :key="service.id"
            :to="
              `/service-area/${
                getServiceAreaForServiceId(service.service).id
              }/service/${service.service}/sub-service/${service.id}`
            "
            link
            class="angebot-der-new-card"
          >
            <v-img :src="getImageUrl(service)" />
            <v-card-title style="color: #9c0047">{{
              service.title
            }}</v-card-title>
            <v-card-subtitle>
              {{ getTextPreview(service.content1, 20) }}</v-card-subtitle
            >
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VueMarkdownIt from "vue-markdown-it";
import ContactPersonBox from "@/components/layout/ContactPersonBox";

export default {
  name: "FundDetails",
  props: ["id"],
  data: () => ({
    baseUrl: process.env.VUE_APP_API_BASE_URL
  }),
  components: {
    ContactPersonBox,
    VueMarkdownIt
  },
  methods: {
    scrollToAnsprechpartner() {
      const element = document.getElementById("ansprechpartner");

      window.scroll({
        top: element.offsetTop,
        left: 0,
        behavior: "smooth"
      });
    },
    getTextPreview(text, wordCount) {
      const splits = text.split(" ");

      if (splits.length <= wordCount) return text;
      else return splits.slice(0, wordCount).join(" ") + "...";
    },
    getServiceAreaForServiceId(serviceId) {
      const serviceArea = this.$store.getters[
        "navigation/serviceAreas"
      ].find(servieArea =>
        servieArea.services.map(service => service.id).includes(serviceId)
      );

      return serviceArea;
    },
    getImageUrl(item) {
      if (item.images.length === 0) {
        const serviceId = item.service ?? item.id;
        const serviceArea = this.getServiceAreaForServiceId(serviceId);

        if (serviceArea.headerImage)
          return `${this.baseUrl}${serviceArea.headerImage.url}`;
        else return this.fallbackImg;
      }

      return `${this.baseUrl}${item.images[0].url}`;
    }
  },
  computed: {
    imgSize() {
      return this.$vuetify.breakpoint.mdAndUp ? "200px" : "150px";
    },
    fallbackImg() {
      const image = require("@/assets/headerbild_image_1920x900.jpg");

      return image;
    },
    entry() {
      const idAsNumber = Number.parseInt(this.id, 10);
      return this.$store.getters["foerdermittel/getFoerdermittelById"](
        idAsNumber
      );
    },
    fristDate() {
      const splits = this.entry.Frist.split("-");
      return splits[2] + "." + splits[1] + "." + splits[0];
    },
    ansprechpartner() {
      return this.entry.Ansprechpartner.split("\n\n\n").map(item =>
        item.replace("\n", "  \n")
      );
    },
    colSize() {
      if (this.$vuetify.breakpoint.smAndUp) return 8;
      else return 12;
    },
    showAnpsrechpartner() {
      return this.entry.AngebotDerNew.Ansprechpartner.length > 0;
    },
    showServiceAndSubserviceTitle() {
      return (
        this.entry.AngebotDerNew.Services.length > 0 ||
        this.entry.AngebotDerNew.SubServices.length > 0
      );
    },
    sidebarClasses() {
      return this.$vuetify.breakpoint.mdAndUp
        ? ["ml-8", "sidebar-desktop"]
        : [];
    }
  }
};
</script>

<style scoped lang="scss">
.frist-row {
  background-color: #fee7cd;
}

.info-row {
  background-color: #f4f5f7;
}

.hinweis-col {
  background-color: #feeecd;
}

.ansprechpartner-col {
  background-color: #f0d9e4;
}

.angebot-der-new-card:hover {
  text-decoration: none !important;
}

.sidebar-desktop {
  max-width: 360px;
}
</style>
