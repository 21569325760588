export default {
  getAboutUs(state) {
    return state.aboutUs;
  },
  getFundingOpportunities(state) {
    return state.fundingOpportunities;
  },
  getNewCompaniesById: (state) => (newCompanyId) => {
    return state.newCompaniesIdMap[newCompanyId] || [];
  },
  getNewCompanies(state) {
    return state.newCompanies;
  },
  getContactPeople(state) {
    return state.contactPeople;
  },
  getCommuneIdByName: (state) => (name) => {
    return state.communeNames.find(commune => commune.name === name);
  },
  getCommuneNameById: (state) => (id) => {
    return state.communeNames.find(commune => commune.id === id);
  },
};
