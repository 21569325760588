import store from "@/store";
import googleAnalytics from "@/plugins/google-analytics";

export const tracking_router = (to, from) => {
  try {
    const analytics = {
      page_type: to.name,
      navigated_dashboard: from.name !== "home" ? true : false,
    };

    switch (to.name) {
      case "home":
        analytics.page_name = "Dashboard";
        break;
      case "about":
        analytics.page_name = "About";
        break;
      case "faq":
        analytics.page_name = "FAQ";
        break;
      case "service-area": {
        analytics.page_name = store.getters["navigation/serviceAreas"].find(
          (s) => s.id === parseInt(to.params.serviceAreaId)
        ).title;
        break;
      }
      case "service": {
        const service = store.getters["navigation/serviceAreas"]
          .find((s) => s.id === parseInt(to.params.serviceAreaId))
          .services.find((s) => s.id === parseInt(to.params.serviceId));
        analytics.page_name = service.title;
        break;
      }
      case "sub-service": {
        const subService = store.getters["navigation/subServiceBySubServiceId"](
          parseInt(to.params.subServiceId)
        );
        analytics.page_name = subService.title;
        break;
      }
      case "constructions-map": {
        analytics.page_name = "Bauprojekte";
        break;
      }
      case "news":
        analytics.page_name = "News";
        break;
      case "pressDetail": {
        const post = store.getters["news/pressById"](to.params.pressId);
        analytics.page_name = post.title;
        break;
      }
      case "newCompanyDetail": {
        const entry = store.getters["generalContent/getNewCompaniesById"](
          to.params.newCompanyId
        );
        analytics.page_name = entry.title;
        break;
      }
      case "approval-pending": {
        analytics.page_name = "Warten auf Freischaltung";
        break;
      }
      case "login": {
        analytics.page_name = "Login";
        break;
      }
      case "search": {
        analytics.page_name = "Suche";
        break;
      }
      case "magazin": {
        analytics.page_name = "NEW Magazin";
        break;
      }
      case "newsDetail": {
        const entry = store.getters["news/newsById"](to.params.newsId);
        analytics.page_name = entry.title;
        break;
      }
      case "funds": {
        analytics.page_name = "Fördermittel";
        break;
      }
      case "fund-details": {
        const entry = store.getters["foerdermittel/getFoerdermittelById"](
          parseInt(to.params.id)
        );
        analytics.page_name = entry.Foerdertitel;

        break;
      }
    }

    googleAnalytics.gtag("event", "page_visited", {
      ...analytics,
    });
    // eslint-disable-next-line no-empty
  } catch (e) {
  }
};
