export default {
  showDevLoginDialog(state) {
    return state.showDevLogin;
  },
  authToken() {
    return window.localStorage.getItem("jwt");
  },

  ssoLoginUrl(state, getters, rootState, rootGetters) {
    return rootGetters.getAbsUrl("/connect/login_new_ag");
  },

  ssoCallbackUrl: (state, getters, rootState, rootGetters) => ({
    id_token,
    access_token,
  }) => {
    return rootGetters.getAbsUrl(
      `/auth/login_new_ag/callback?access_token=${access_token}&id_token=${id_token}`
    );
  },

  isLoggedIn(state) {
    return state.isLoggedIn;
  },

  isUserApproved(state) {
    return state.isConfirmedAccount;
  },

  username(state) {
    return state.firstName + " " + state.lastName;
  },

  userEmail(state) {
    return state.email;
  },

  userImgAbsUrl(state) {
    return state.imgUrl;
  },

  userCommuneName(state) {
    if (!state.isLoggedIn) return "";
    return state.commune.name;
  },

  userCommuneId: (state) => state.commune.id,

  userCommunePartner(state) {
    return state.communePartner;
  },

  userCommunePartnerQuote(state) {
    return state.commune.partner_quote;
  },

  userCommuneRegionalLeader(state) {
    return state.communeRegionalLeader;
  },

  userFaculties(state) {
    const faculties = state.faculties;
    const ST_faculties = [];

    faculties.map((f) => {
      ST_faculties.push(f.name);
    });

    return ST_faculties;
  },

  userCommuneLogoUrl(state, getters, rootState, rootGetters) {
    if (!state.isLoggedIn) return "";
    return rootGetters.getStrapiImageUrl(state.commune.logo, "thumbnail");
  },

  userCommuneLocation(state) {
    let latLong = {};
    latLong.latitude = state.commune.latitude;
    latLong.longitude = state.commune.longitude;
    return latLong;
  },

  userBgImgUrl(state, rootGetters) {
    if (!state.isLoggedIn) return "";
    return rootGetters.getAbsUrl(state.commune.bg_image.url);
  },
  userCommuneServices(state, getters, rootState, rootGetters) {
    const visibleAreaIds = rootGetters["navigation/serviceAreas"].map(
      (area) => area.id
    );
    return state.commune.myServiceAreas.filter((serviceArea) =>
      visibleAreaIds.includes(serviceArea.serviceArea.id)
    );
  },
  userId(state) {
    return state.id;
  },
  isNewEmployee(state) {
    return state.isNewEmployee;
  },
  serviceDetails(state) {
    return state.isNewEmployee ? state.serviceDetails : [];
  },
  showNewsletterSignUp(state) {
    return state.showNewsletterSignUp;
  },
  getUserInfoForNewsletter(state) {
    return {
      firstName: state.firstName,
      name: state.lastName,
      email: state.email,
    };
  },
  getFAQ(state) {
    return state.faq;
  },
  enableKommunalrabatt(state) {
    return state.enableKommunalrabatt;
  },
  enableKonzessionssachstaende(state) {
    return state.enableKonzessionssachstaende;
  },
  enableWaermeplanung(state) {
    return state.enableWaermeplanung;
  },
  enableWaermeMenu(state, getters) {
    // TODO: Add permissions for following Waermefeatures like Contracting/Quartiersentwicklung
    return getters.enableWaermeplanung;
  },
  enableVertragsuebersicht(state) {
    return state.enableVertragsuebersicht;
  },
  enableVerbrauchsdaten(state) {
    return state.enableVerbrauchsdaten;
  },
  enableMoments(state) {
    return state.enableMoments;
  },
  enableKommunenDashboard(state) {
    return state.enableKommunenDashboard;
  },
  userInitials(state) {
    return state.firstName[0] + state.lastName[0];
  },
  showRegisterForm(state) {
    return state.preferredUserData === null;
  },
  getCommuneQlickAppId(state) {
    return state.commune.QlickAppId;
  },
  getLadesaeulenUrl(state) {
    if(state.commune?.LadestellenBereichsId) {
      return `https://www.jetzt-mitmachen.de/viewer?p=lsk_new&bereich_id=${state.commune.LadestellenBereichsId}`;
    } else {
      return "https://www.jetzt-mitmachen.de/viewer?p=lsk_new";
    }
  },
  getDashboardCommunePartners(state) {
    return [state.communePartner, state.commune.weitererKommunalpartner].filter(i => !!i)
  }
};
