export default {
  SET_MESSAGE_SNACKBAR_DISPLAY_BOOLEAN(state) {
    state.messageSnackbarDisplayBoolean = !state.messageSnackbarDisplayBoolean;
  },

  SET_MESSAGE_SNACKBAR_TEXT(state, val) {
    state.messageSnackbarText = val;
  },

  SET_MESSAGE_SNACKBAR_COLOR(state, val) {
    state.messageSnackbarColor = val;
  },

  SET_MESSAGE_SNACKBAR_ICON(state, val) {
    state.messageSnackbarIcon = val;
  },
};
