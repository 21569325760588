<template>
  <v-row class="fill-height">
    <video autoplay muted loop id="bgVideo">
      <source :src="video" type="video/mp4" />
      <img
        :src="image"
        title="Your browser does not support the <video> tag"
        id="bgImg"
      />
    </video>
    <v-col cols="12" sm="6" md="6" lg="5">
      <v-card
        id="loginCard"
        height="100%"
        :class="
          $vuetify.breakpoint.width > 960 ? 'px-5 py-15' : 'px-4 py-4 ma-n3'
        "
      >
        <v-card-text
          id="loginCardText"
          :class="
            $vuetify.breakpoint.width > 960
              ? 'text-center px-15'
              : 'text-center px-8'
          "
        >
          <div :class="$vuetify.breakpoint.width < 960 ? 'my-3' : 'my-15'">
            <router-link :to="{ name: 'login' }">
              <img
                class="logo"
                alt="NEW logo"
                src="@/assets/logo/NEW-KoPo-Logo-Primary.svg"
              />
            </router-link>
          </div>
          <div
            :class="
              $vuetify.breakpoint.width < 960
                ? 'custom-header-text new-font-bold my-3'
                : 'custom-header-text new-font-bold my-15'
            "
          >
            LOGIN
          </div>
          <div class="text-left mb-5">
            Liebe Nutzerinnen und Nutzer,
          </div>
          <div class="text-left mb-5">
            herzlich Willkommen im NEW-Kommunalportal des Kommunalen
            Partnermanagements der NEW AG. Eine neue Schnittstelle für die
            Zusammenarbeit zwischen der NEW und den Kommunen – gemeinsam
            entwickelt und auch künftig laufend verbessert.
          </div>
          <div class="text-left mb-5">
            Zugang haben Mitarbeiter:innen aus den Kommunen im
            Versorgungsgebiet, die in beruflichem Kontakt zur NEW stehen.
          </div>
          <div class="text-left mb-5">
            Viel Spaß beim Erkunden unseres Portals.
          </div>
          <div class="text-left mb-5">
            Mit freundlichen Grüßen,<br />Ihre NEW AG
          </div>
          <v-btn
            :disabled="disableLoginButton"
            id="main_login"
            color="primary"
            :href="getSsoLoginUrl()"
            class="mx-auto px-10 mb-5"
            @click="startedLogin = true"
            :loading="startedLogin"
          >
            ANMELDEN
          </v-btn>
          <v-divider></v-divider>
          <div class="text-left my-5 text-h6">
            Fragen oder Anregungen?
          </div>
          <div class="text-left mb-5">
            Wenn Sie ebenfalls Zugang zum Kommunalportal wünschen, Fragen haben
            oder Unterstützung im Anmeldeprozess benötigen, kontaktieren Sie uns
            bitte. Auch Ihr Feedback nehmen wir gerne entgegen!
          </div>
          <div class="text-left mb-5">
            Sie erreichen uns schnell und einfach über das
            <v-icon class="mb-1" color="primary">
              mdi-open-in-new
            </v-icon>
            <a
              id="contact-link"
              target="_blank"
              href="https://www.new.de/kontakt"
              >Kontaktformular der NEW </a
            >, indem Sie den Themenbereich
            <b>"NEW-Kommunalportal"</b> auswählen.<br />
            <br />
            <a
              id="impressum-link"
              target="_blank"
              href="https://www.new.de/impressum"
              >Impressum</a
            >
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <dev-login-dialog></dev-login-dialog>
  </v-row>
</template>

<script>
import DevLoginDialog from "@/components/security/DevLoginDialog";
export default {
  components: { DevLoginDialog },
  data: () => ({
    startedLogin: false,
    baseUrl: process.env.VUE_APP_API_BASE_URL,
    image: require("@/assets/headerbild_image_1920x900.jpg"),
    video: require("@/assets/NEW_image_slideshow_small_3.mp4")
  }),
  computed: {
    disableLoginButton() {
      return this.$store.getters["user/showDevLoginDialog"];
    }
  },
  methods: {
    getSsoLoginUrl() {
      return !this.disableLoginButton
        ? this.$store.getters["user/ssoLoginUrl"]
        : "#";
    }
  }
};
</script>

<style scoped>
#logo {
  fill: #000;
}
.v-card__title {
  word-break: break-word !important;
}
#loginCard {
  background-color: #ffffffe3;
  max-width: none;
  border-radius: 0%;
}
#loginCardText {
  opacity: 1;
}
#main_login {
  opacity: 1;
}
#bgVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}
#bgImg {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}
</style>
