<script>
import {mapActions} from "vuex";

export default {
  name: "NotificationHintCard",
  methods: {
    ...mapActions("notifications", ["hideNotificationsList"]),
  },
}
</script>

<template>
<v-sheet elevation="0" class="notifications-hint-card pa-2" rounded>
  <div class="hint-font font-weight-bold primary-text">Fördermittel, Neuigkeiten und wichtige Termine direkt per Mail?</div>
  <div class="hint-font">Passen Sie an, über welche neuen Inhalte Sie informiert werden möchten.</div>
  <v-btn @click="hideNotificationsList" block to="/notification-config" color="primary" small>Zu den Einstellungen</v-btn>
</v-sheet>
</template>

<style scoped>
.notifications-hint-card {
  background: rgb(247,204,113);
}

.hint-font {
  font-size: 14px;
}
</style>
