<script>
import {mapActions, mapGetters} from "vuex";
import NotificationConfigRow from "@/components/list_rows/NotificationConfigRow.vue";

export default {
  name: "NotificationConfig",
  components: {NotificationConfigRow},
  async beforeMount() {
    await this.loadNotificationsConfig();
  },
  methods: {
    ...mapActions("notifications", ["loadNotificationsConfig", "updateNotificationsConfig"]),
    async enableMailService() {
      await this.updateNotificationsConfig({ key: "enableMailService", value: true });
      await this.loadNotificationsConfig();
    },
    async disableMailService() {
      await this.updateNotificationsConfig({ key: "enableMailService", value: false });
      await this.loadNotificationsConfig();
    }
  },
  computed: {
    ...mapGetters('notifications', ["getOptions", "getConfig"]),
    ...mapGetters('user', ['userEmail'])
  }
}
</script>

<template>
<div class="pa-10" style="max-width:  1200px">
  <v-row>
    <v-col cols="12">
      <div class="custom-header-text new-font-bold">Lassen Sie sich informieren</div>
      <span>Neuigkeiten finden Sie unter dem Briefsymbol oben rechts.</span>
    </v-col>
    <v-col v-for="(option, index) of getOptions" :key="index" cols="12" class="two-toned-row">
      <notification-config-row v-bind="option" />
    </v-col>
    <v-col cols="12">
      <div class="new-font-bold text-h6 mt-4">
        Noch schneller informiert: Erhalten Sie zusätzlich eine Benachrichtig per E-Mail.
      </div>
    </v-col>
    <v-col cols="12" class="px-0 pt-0" v-if="getConfig">
      <v-sheet elevation="1" rounded class="mail-sheet pa-3" :style="{background: !getConfig.enableMailService ? '#FFCA5F' : undefined}">
        <div class="mail-icon-container">
          <v-icon size="41px">$mailIcon</v-icon>
        </div>
        <div class="px-5">
          <template v-if="!getConfig.enableMailService">
          <b>E-Mail-Service</b>
          <p class="mb-0">
            Wir senden Ihnen alle oben ausgewählten Informationen direkt per E-Mail- so verpassen Sie nichts.
            <br>
            <br>
            E-Mail-Adresse
          </p>
          <div style="display: flex; align-items: center;">
            <v-text-field single-line outlined hide-details dense background-color="#f6e4ed" readonly :value="userEmail" />
            <v-btn class="ml-3" color="primary" @click="enableMailService">E-Mail-Service aktivieren</v-btn>
          </div>
          </template>
          <template v-else>
            <b>E-Mail-Service aktiv</b>
            <p class="mb-0">
              Wir senden Ihnen alle oben ausgewählten Informationen direkt per E-Mail- so verpassen Sie nichts.
              <br>
              <br>
              Sie möchten keine E-Mails mehr für die optionalen Themen von uns erhalten?
            </p>
            <v-btn class="px-0" text @click="disableMailService" color="primary">Vom E-Mail-Service abmelden</v-btn>
          </template>
        </div>
      </v-sheet>
    </v-col>
  </v-row>
</div>
</template>

<style scoped>
.two-toned-row:nth-child(even) {
  background: #EFE6EB;
}

.mail-sheet {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mail-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 115px;
  width: 115px;
  height: 115px;
  border-radius: 50%;
}
</style>
