<template>
    <v-sheet style="background-color: transparent">
        <v-row class="grey--text">
            <v-col cols="6">
                <span class="thema-header" @click="setSortByKeyOrToggleOrder('Vertrag')" style="margin-left: 20px">
                    Vertrag
                    <v-icon v-if="getSortBy.key === 'Vertrag'" color="grey" small>
                        {{ getSortBy.order === 'asc' ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                    <v-icon v-else color="grey" small>mdi-unfold-more-horizontal</v-icon>
                </span>
            </v-col>
            <v-col cols="2" class="d-flex">
                <span style="margin-left: 13px" class="thema-header" @click="setSortByKeyOrToggleOrder('Kommune')">
                    Kommune
                    <v-icon v-if="getSortBy.key === 'Kommune'" color="grey" small>
                        {{ getSortBy.order === 'asc' ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                    <v-icon v-else color="grey" small>mdi-unfold-more-horizontal</v-icon>
                </span>
            </v-col>
            <v-col cols="4" class="d-flex">
                <span class="thema-header" style="margin-left: 13px" @click="setSortByKeyOrToggleOrder('Änderungsdatum')">
                    Änderungsdatum
                    <v-icon v-if="getSortBy.key === 'Änderungsdatum'" color="grey" small>
                        {{ getSortBy.order === 'asc' ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                    <v-icon v-else color="grey" small>mdi-unfold-more-horizontal</v-icon>
                </span>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "KonzessionsThemaHeader",
    methods: {
        ...mapActions('konzessionsthemen', ['setSortByKeyOrToggleOrder']),
    },
    computed: {
        ...mapGetters('konzessionsthemen', ['getSortBy']),
    },
}
</script>

<style scoped>
.thema-header {
    font-size: 14px !important;
    font-weight: bold;
    cursor: pointer;
}
</style>
