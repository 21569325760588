<script>
import {mapActions} from "vuex";

export default {
  name: "NotificationConfigRow",
  methods: {
    ...mapActions('notifications', ['updateNotificationsConfig']),
    async onSwitchChanged(value) {
      await this.updateNotificationsConfig({ key: this.keyName, value });
    },
  },
  props: {
    keyName: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false
    },
    initialValue: {
      type: Boolean,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    }
  }
}
</script>

<template>
    <div class="options-row">
      <div style="max-width: 750px">
          <span class="font-weight-bold d-block">
            {{ name }}
          </span>
        <p class="mb-0">
          {{ subtitle }}
        </p>
      </div>
      <v-switch @change="onSwitchChanged" :input-value="initialValue" :disabled="readonly" :ripple="false" inset color="primary">Aktiviert</v-switch>
    </div>
</template>

<style scoped>
.options-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
