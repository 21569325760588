import mutations from "@/store/modules/userDetails/mutations";
import actions from "@/store/modules/userDetails/actions";
import getters from "@/store/modules/userDetails/getters";

export default {
    namespaced: true,
    state: {
        roles: [],
    },
    mutations,
    actions,
    getters,
}
