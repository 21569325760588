import httpClient from "@/store/httpClient";
export default {
    async loadFAQ({ commit }) {
        const response = await httpClient.get("/faq-waerme");

        if(response.status === 200) {
            commit("SET_FAQ", response.data);
        }
    },
    async checkTosStatus({ commit }) {
        try {
            const response = await httpClient.get("/waermeplanung/checkTosStatus");

            if(response.status === 200) {
                commit("SET_TOS_STATUS", response.data.accepted);
            }
        } catch (e) {
            commit("SET_TOS_STATUS", false);
        }
    },
    async acceptTos({ commit }) {
        const response = await httpClient.post("/waermeplanung/acceptTos");

        if(response.status === 200) {
            commit("SET_TOS_STATUS", true);
        }
    },
    async loadIframeUrl({ commit, rootGetters }) {
        const response = await httpClient.get("/waermeplanung/getUrl");



        if(response.status === 200) {
            const appId = rootGetters['user/getCommuneQlickAppId'];

            const origin = window.location.origin.includes('localhost') ? 'https://kommunity-dev.new.de' : window.location.origin.replaceAll('kommunalportal', 'kommunity');
            const url =`${origin}/single/?newagquery=${response.data.token}&appid=${appId}&sheet=36479394-f1b0-44f0-bb6c-c18403aa822a&lang=de-DE&theme=new_theme&opt=currsel%2Cctxmenu&select=clearall`;
            commit("SET_IFRAME_URL", url);
        }
    }
}
