<template>
  <div v-if="$vuetify.breakpoint.mdAndUp" class="new-wrapper">
    <v-row no-gutters>
      <v-col cols="8" style="display: flex;align-items: center" class="px-10">
        <span class="custom-header-text new-font-bold">Kommunalrabatt</span>
      </v-col>

      <v-col cols="4">
        <!-- contact person -->
        <contact-person-horizontal></contact-person-horizontal>
        <!-- contact person end -->
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row no-gutters>
      <v-col cols="12" class="px-10 py-6">
        <div style="display: flex">
          <span class="custom-header-text new-font-bold"
            >Lieferstellen Rabattermittlung</span
          >
          <div style="max-width: 250px" class="ml-5">
            <v-select
              background-color="#f6e4ed"
              :items="getYears"
              label="Jahr"
              dense
              outlined
              single-line
              hide-details
              v-model="selectedYear"
              :readonly="getYears.length <= 1"
            />
          </div>
        </div>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <div v-if="isNewEmployee">
      <v-row style="align-items: stretch" class="px-4 py-5">
        <v-col class="text-center">
          <span class="new-text-h6">
            <v-icon left color="warning">mdi-alert-circle</v-icon>
            Erstbearbeitung durch NEW</span
          >
        </v-col>
        <v-col class="text-center">
          <span class="new-text-h6">
            <v-icon left color="grey">mdi-play-circle</v-icon>
            Prüfung durch Gemeinde</span
          >
        </v-col>
        <v-col class="text-center">
          <span class="new-text-h6">
            <v-icon left color="grey">mdi-pause-circle</v-icon>
            Gegenprüfung durch NEW</span
          >
        </v-col>
        <v-col class="text-center">
          <span class="new-text-h6">
            <v-icon left color="primary">mdi-play-circle</v-icon>
            Freigabe durch Gemeinde</span
          >
        </v-col>
        <v-col class="text-center">
          <span class="new-text-h6">
            <v-icon left color="success">mdi-check-circle</v-icon>
            Abgeschlossen</span
          >
        </v-col>
      </v-row>

      <v-row style="align-items: stretch" class="px-4">
        <v-col class="card-column">
          <template v-if="getNeu.length > 0">
            <commune-card
              v-for="entry of getNeu"
              :key="entry.commune"
              :commune-name="entry.commune"
              color="warning"
              :lieferstellen-count="entry.lieferstellenCount"
              :selectedYear="selectedYear"
            />
          </template>
          <template v-else>
            <p class="pa-7">Aktuell gibt es keine neuen Lieferstellen.</p>
          </template>
        </v-col>
        <v-col class="card-column">
          <template v-if="getBeiKommune.length > 0">
            <commune-card
              v-for="entry of getBeiKommune"
              :key="entry.commune"
              :commune-name="entry.commune"
              color="grey"
              :lieferstellen-count="entry.lieferstellenCount"
              :selectedYear="selectedYear"
            />
          </template>
          <template v-else>
            <p class="pa-7">
              Aktuell gibt es keine Lieferstellen, die zur bearbeitung bei der
              Kommune liegen.
            </p>
          </template>
        </v-col>
        <v-col class="card-column">
          <template v-if="getGegenpruefungBeiNew.length > 0">
            <commune-card
              v-for="entry of getGegenpruefungBeiNew"
              :key="entry.commune"
              :commune-name="entry.commune"
              color="primary"
              :lieferstellen-count="entry.lieferstellenCount"
              :selectedYear="selectedYear"
            />
          </template>
          <template v-else>
            <p class="pa-7">
              Aktuell gibt es keine Lieferstellen, die zur gegenprüfung bei der
              NEW liegen.
            </p>
          </template>
        </v-col>
        <v-col class="card-column">
          <template v-if="getFreizugeben.length > 0">
            <commune-card
              v-for="entry of getFreizugeben"
              :key="entry.commune"
              :commune-name="entry.commune"
              color="primary"
              :lieferstellen-count="entry.lieferstellenCount"
              :selectedYear="selectedYear"
            />
          </template>
          <template v-else>
            <p class="pa-7">
              Aktuell gibt es keine Lieferstellen, die final freigegeben werden
              können.
            </p>
          </template>
        </v-col>
        <v-col class="card-column">
          <template v-if="getComplete.length > 0">
            <commune-card
              v-for="entry of getComplete"
              :key="entry.commune"
              :commune-name="entry.commune"
              color="success"
              :lieferstellen-count="entry.lieferstellenCount"
              :selectedYear="selectedYear"
            />
          </template>
          <template v-else>
            <p class="pa-7">
              Aktuell gibt es keine Lieferstellen, welche abgeschlossen wurden.
            </p>
          </template>
        </v-col>
      </v-row>
    </div>

    <div v-else>
      <v-row class="px-15 py-5">
        <v-col cols="12">
          <span
            :class="currentStatusIndex >= 0 ? 'font-weight-bold' : 'grey--text'"
          >
            <v-icon v-if="currentStatusIndex < 0" color="grey"
              >mdi-checkbox-blank-circle-outline</v-icon
            >
            <v-icon v-else-if="currentStatusIndex === 0" color="primary"
              >mdi-pause-circle</v-icon
            >
            <v-icon v-else color="green">mdi-check-circle</v-icon>
            Vorschlag wurde durch NEW erstellt...
          </span>
          <div class="commune-card-container" v-if="currentStatusIndex === 0">
            <commune-card
              :commune-name="getCommuneStatuses[0].commune"
              color="warning"
              :lieferstellen-count="
                getCommuneStatuses[0].data.find(d => d.year === getLatestYear)
                  .lieferstellenCount
              "
              :selectedYear="getLatestYear"
            />
          </div>
        </v-col>
        <v-col cols="12">
          <span
            :class="currentStatusIndex >= 1 ? 'font-weight-bold' : 'grey--text'"
          >
            <v-icon v-if="currentStatusIndex < 1" color="grey"
              >mdi-checkbox-blank-circle-outline</v-icon
            >
            <v-icon v-else-if="currentStatusIndex === 1" color="primary"
              >mdi-play-circle</v-icon
            >
            <v-icon v-else color="green">mdi-check-circle</v-icon>
            Vorschlag gegenprüfen</span
          >
          <div class="commune-card-container" v-if="currentStatusIndex === 1">
            <commune-card
              :commune-name="getCommuneStatuses[0].commune"
              color="warning"
              :lieferstellen-count="
                getCommuneStatuses[0].data.find(d => d.year === getLatestYear)
                  .lieferstellenCount
              "
              :selectedYear="getLatestYear"
            />
          </div>
        </v-col>
        <v-col cols="12">
          <span
            :class="currentStatusIndex >= 2 ? 'font-weight-bold' : 'grey--text'"
          >
            <v-icon v-if="currentStatusIndex < 2" color="grey"
              >mdi-checkbox-blank-circle-outline</v-icon
            >
            <v-icon v-else-if="currentStatusIndex === 2" color="primary"
              >mdi-pause-circle</v-icon
            >
            <v-icon v-else color="green">mdi-check-circle</v-icon>
            Finale Liste Liste wurde durch NEW erstellt...</span
          >
          <div class="commune-card-container" v-if="currentStatusIndex === 2">
            <commune-card
              :commune-name="getCommuneStatuses[0].commune"
              color="warning"
              :lieferstellen-count="
                getCommuneStatuses[0].data.find(d => d.year === getLatestYear)
                  .lieferstellenCount
              "
              :selectedYear="getLatestYear"
            />
          </div>
        </v-col>
        <v-col cols="12">
          <span
            :class="currentStatusIndex >= 3 ? 'font-weight-bold' : 'grey--text'"
          >
            <v-icon v-if="currentStatusIndex < 3" color="grey"
              >mdi-checkbox-blank-circle-outline</v-icon
            >
            <v-icon v-else-if="currentStatusIndex === 3" color="primary"
              >mdi-play-circle</v-icon
            >
            <v-icon v-else color="green">mdi-check-circle</v-icon>
            Finale Liste freigeben</span
          >
          <div class="commune-card-container" v-if="currentStatusIndex === 3">
            <commune-card
              :commune-name="getCommuneStatuses[0].commune"
              color="warning"
              :lieferstellen-count="
                getCommuneStatuses[0].data.find(d => d.year === getLatestYear)
                  .lieferstellenCount
              "
              :selectedYear="getLatestYear"
            />
          </div>
        </v-col>
        <v-col cols="12">
          <span
            :class="currentStatusIndex >= 4 ? 'font-weight-bold' : 'grey--text'"
          >
            <v-icon v-if="currentStatusIndex < 4" color="grey"
              >mdi-checkbox-blank-circle-outline</v-icon
            >
            <v-icon v-else color="green">mdi-check-circle</v-icon>
            Rabattermittlung abgeschlossen</span
          >
          <div class="commune-card-container" v-if="currentStatusIndex === 4">
            <commune-card
              :commune-name="getCommuneStatuses[0].commune"
              color="warning"
              :lieferstellen-count="
                getCommuneStatuses[0].data.find(d => d.year === getLatestYear)
                  .lieferstellenCount
              "
              :selectedYear="getLatestYear"
            />
          </div>
        </v-col>
      </v-row>
    </div>

    <v-divider class="mt-10"></v-divider>

    <v-row no-gutters>
      <v-col cols="12">
        <div class="px-10 py-8">
          <span class="custom-header-text new-font-bold"
            >Rabattermittlung des Jahres {{ getLatestYear }}</span
          >
        </div>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row no-gutters>
      <v-col
        cols="12"
        md="6"
        style="display: flex; justify-content: center"
        class="py-5 px-10"
      >
        <apexchart
          width="750"
          :series="getSeriesForYear"
          :options="chartOptions"
          ref="chart"
        />
      </v-col>

      <v-divider vertical />

      <v-col cols="12" md="6" class="pa-10">
        <v-select
          background-color="#f6e4ed"
          :items="getCommunes"
          :label="isNewEmployee ? 'Kommune' : 'Postleitzahl'"
          outlined
          dense
          hide-details
          v-model="selectedCommune"
          style="max-width: 250px"
          @change="onSelectedCommuneChange"
        />
        <br />
        <p style="line-height: 1.1" class="mb-2">Kommunalrabatt endgültig</p>
        <b>{{ getFinalKommunalrabatt }}</b>

        <br />
        <br />
        <p style="line-height: 1.1" class="mb-2">Bereits an Kommunen gezahlt</p>
        <b>{{ getFinalKommunalrabatt }}</b>
      </v-col>
    </v-row>
  </div>
  <mobile-view v-else />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CommuneCard from "@/components/ui/Kommunalrabatt/CommuneCard";
import MobileView from "@/components/ui/Kommunalrabatt/MobileView";
import ContactPersonHorizontal from "../components/layout/ContactPersonHorizontal.vue";

export default {
  name: "Kommunalrabatt",
  components: { MobileView, CommuneCard, ContactPersonHorizontal },
  data: () => ({
    selectedYear: undefined,
    selectedCommune: "Alle Kommunen",
    activeDataPoint: 0,
    availableStates: [
      "Neu",
      "Bei Kommune",
      "Gegenprüfung durch NEW",
      "Freizugeben",
      "Abgeschlossen"
    ]
  }),
  methods: {
    ...mapActions("kommunalrabatt", ["loadCommuneStatuses"]),
    createNewEmployeChartTooltip(series, seriesIndex, w) {
      const round = Math.round(series[seriesIndex] * 100) / 100 + "€";
      const commune = w.globals.seriesNames[seriesIndex];

      const { lieferstellenCount } = this.getCommuneStatuses
        .find(status => status.commune === commune)
        .data.find(data => data.year === this.selectedYear);

      return `<div class="arrow_box pa-4">
                <span><b>${commune}</b></span><br>
                <span>${lieferstellenCount} Lieferstellen</span><br>
                <span>${round} €</span>
            </div>`;
    },
    createCommuneEmployeChartTooltip(series, seriesIndex, w) {
      const round = Math.round(series[seriesIndex] * 100) / 100 + "€";
      const plz = w.globals.seriesNames[seriesIndex];

      const { count } = this.getCommuneStatuses[0].data
        .find(d => d.year === this.selectedYear)
        .plzData.find(plzData => plzData.plz === plz);

      return `<div class="arrow_box pa-4">
                <span><b>${plz}</b></span><br>
                <span>${count} Lieferstellen</span><br>
                <span>${round} €</span>
            </div>`;
    },
    onSelectedCommuneChange() {
      const dataPointIndex = this.getCommunes.indexOf(this.selectedCommune);
      if (dataPointIndex != 0) {
        this.$refs.chart.toggleDataPointSelection(dataPointIndex - 1);
        this.activeDataPoint = dataPointIndex - 1;
      } else {
        this.$refs.chart.toggleDataPointSelection(this.activeDataPoint);
      }
    },
  },
  async mounted() {
    await this.loadCommuneStatuses();
    this.selectedYear = this.getYears[this.getYears.length - 1];

    if (!this.isNewEmployee) {
      this.selectedCommune = "Alle Postleitzahlen";
    }
  },
  computed: {
    ...mapGetters("kommunalrabatt", ["getCommuneStatuses", "getChartColors"]),
    ...mapGetters("user", ["isNewEmployee", "userCommuneName"]),
    getYears() {
      return [
        ...new Set(
          this.getCommuneStatuses
            .map(status => status.data)
            .flat()
            .map(d => d.year)
        )
      ];
    },
    getDataForYear() {
      return this.getCommuneStatuses.map(status => {
        return {
          commune: status.commune,
          ...status.data.find(d => d.year === this.selectedYear)
        };
      }); //.filter(d => d.year === this.selectedYear);
    },
    /*getDataForCurrentYear() {
      return this.getCommuneStatuses.map(status => {
        return {
          commune: status.commune,
          ...status.data.find(d => d.year === this.getLatestYear)
        }
      })
    },*/
    getCommunes() {
      if (this.isNewEmployee) {
        const communes = ["Alle Kommunen"];
        communes.push(...this.getCommuneStatuses.map(status => status.commune));
        return communes;
      } else {
        const plzs = ["Alle Postleitzahlen"];

        if (!this.getCommuneStatuses[0]) return plzs;

        plzs.push(
          ...this.getCommuneStatuses[0]?.data
            .find(d => d.year === this.getLatestYear)
            ?.plzData?.map(d => d.plz)
        );
        return plzs;
      }
    },
    getSeriesForYear() {
      if (this.getDataForYear.length === 0) return [];

      if (this.isNewEmployee) {
        return this.getDataForYear.map(d => d.gesamtRabatt);
      } else {
        return this.getDataForYear[0].plzData?.map(d => d.rabatt);
      }
    },
    getLabelsForYear() {
      if (this.getDataForYear.length === 0) return [];

      if (this.isNewEmployee) return this.getDataForYear.map(d => d.commune);
      else return this.getDataForYear[0].plzData?.map(d => d.plz);
    },
    getFinalKommunalrabatt() {
      let total;

      if (this.isNewEmployee) {
        total =
          this.selectedCommune === "Alle Kommunen"
            ? this.getDataForYear
                .map(data => data.gesamtRabatt)
                .reduce((a, b) => a + b, 0)
            : this.getDataForYear.find(
                data => data.commune === this.selectedCommune
              ).gesamtRabatt;
      } else {
        total =
          this.selectedCommune === "Alle Postleitzahlen"
            ? this.getDataForYear
                .map(data => data.gesamtRabatt)
                .reduce((a, b) => a + b, 0)
            : this.getDataForYear[0]?.plzData?.find(
                d => d.plz === this.selectedCommune
              ).rabatt;
      }

      return Math.round(total * 100) / 100 + "€";
    },
    chartOptions() {
      return {
        dataLabels: { enabled: false },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: true,
                  label: [this.selectedYear],
                  fontSize: "22px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                  color: "#373d3f",
                  formatter: function(w) {
                    const num = w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                    return Math.round(num * 100) / 100 + "€";
                  }
                }
              }
            }
          }
        },
        fill: {
          colors: this.getChartColors
        },
        legend: {
          show: false
        },
        chart: {
          type: "donut",
          id: "kommunalrabatt-chart",
          // height: '450px',
          height: "550px",
          events: {
            dataPointSelection: (event, chartContext, config) => {
              if (config.selectedDataPoints[0].length === 0) {
                this.selectedCommune = this.getCommunes[0];
              } else {
                this.selectedCommune = this.getCommunes[
                  config.dataPointIndex + 1
                ];
                this.activeDataPoint = config.dataPointIndex;
              }
            }
          }
        },
        labels: this.getLabelsForYear,
        tooltip: {
          enabled: true,
          followCursor: false,
          marker: {
            show: true
          },
          fillSeriesColor: false,
          custom: ({ series, seriesIndex, w }) => {
            if (this.isNewEmployee)
              return this.createNewEmployeChartTooltip(series, seriesIndex, w);
            else
              return this.createCommuneEmployeChartTooltip(
                series,
                seriesIndex,
                w
              );
          }
        }
      };
    },
    getLatestYear() {
      const sortedYear = this.getYears;
      sortedYear.sort((a, b) => a - b);
      return sortedYear[sortedYear.length - 1];
    },
    getNeu() {
      const dataNeu = this.getDataForYear.filter(d => d.status === "Neu");
      return dataNeu;
    },
    getBeiKommune() {
      const dataBeiKommune = this.getDataForYear.filter(
        d => d.status === "Bei Kommune"
      );
      return dataBeiKommune;
    },
    getGegenpruefungBeiNew() {
      const dataBeiKommune = this.getDataForYear.filter(
        d => d.status === "Gegenprüfung durch NEW"
      );
      return dataBeiKommune;
    },
    getFreizugeben() {
      const data = this.getDataForYear.filter(d => d.status === "Freizugeben");
      return data;
    },
    getComplete() {
      const data = this.getDataForYear.filter(
        d => d.status === "Abgeschlossen"
      );
      return data;
    },
    currentStatusIndex() {
      if (this.isNewEmployee || !this.getCommuneStatuses[0]) return -1;

      this.getCommuneStatuses[0].data.find(d => d.year === this.selectedYear);

      return this.availableStates.indexOf(
        this.getCommuneStatuses[0].data.find(d => d.year === this.selectedYear)
          .status
      );
    }
  }
};
</script>

<style scoped>
.card-column {
  background-color: #ebebeb;
  border-left: solid 10px #f2f2f3;
  border-right: solid 10px #f2f2f3;
}

v-row .card-column:first-child {
  border-left: unset;
}

v-row .card-column:last-child {
  border-right: unset;
}

.commune-card-container {
  max-width: 300px;
  margin-top: 8px;
}

.default-padding {
  padding: 0px 40px;
}
</style>
