export default {
  getCommunes(state) {
    const excludedNames = [ 'Gremienmitglieder', 'NEW-Mitarbeiter']
    return state.details ? state.details.map((detail) => detail.name).filter(entry => !excludedNames.includes(entry)).sort() : [];
  },
  getDetailsForCommune: (state) => (communeName) => {
    const communeDetails = state.details.find(
      (detail) => detail.name === communeName
    );

    if (!communeDetails) return [];

    return communeDetails;
    /*
    const subServices = communeDetails.subServices;

    const serviceIds = [
      ...new Set(
        subServices.map((subService) => subService.sub_service.service)
      ),
    ];

    const services = communeDetails.services.filter(
      (service) => !serviceIds.includes(service.service.id)
    );

    const details = [];

    for (let service of services) {
      const myService = service.service;

      myService.routeName = "service";
      myService.title = service.Bezeichnung;
      details.push(myService);
    }

    for (let subService of communeDetails.subServices) {
      const mySubService = subService.sub_service;
      mySubService.routeName = "sub-service";
      mySubService.title = subService.Bezeichnung;
      details.push(mySubService);
    }

    return details;
    */
  },
  getCommunesForSubService: (state) => (subServiceName) => {
    const subServices = [];

    for (let details of state.details) {
      for (let subService of details.subServices) {
        if (subService.Bezeichnung !== subServiceName) continue;

        const mySubService = subService.sub_service;
        mySubService.commune = details.name;
        mySubService.iconMaterialDesign = "city";
        subServices.push(mySubService);
      }
    }

    return subServices;
  },
  getCommunesForService: (state) => (serviceName) => {
    const services = [];

    for (let details of state.details) {
      for (let service of details.services) {
        if (service.Bezeichnung !== serviceName) continue;

        const myService = service.service;

        myService.commune = details.name;
        myService.iconMaterialDesign = "city";
        services.push(myService);
      }
    }

    return services;
  },
};
