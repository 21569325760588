<template>
  <div id="sidebar-details" class="pa-1">
    <v-simple-table
      v-for="(item, index) of getDetails"
      :key="index"
      class="mb-10"
    >
      <template v-slot:default>
        <tbody>
          <tr>
            <td class="text-left">Kalenderjahr {{ item.year }}</td>
            <td class="text-right font-weight-bold">{{ item.rabattMenge }}€</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SidebarDetails",
  methods: {
    roundRabatt: (rabatt) => parseInt(rabatt * 10000) / 10000,
  },
  computed: {
    ...mapGetters("kommunalrabatt", ["getDetails", "selectedLieferstelle"]),
  },
};
</script>

<style scoped></style>
