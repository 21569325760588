import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    messageSnackbarDisplayBoolean: false,
    messageSnackbarText: '',
    messageSnackbarColor: '',
    messageSnackbarIcon: '',
  },
  mutations,
  actions,
  getters,
};
