<template>
  <v-row no-gutters class="new-wrapper min-height-100">
    <v-col :cols="colSize" class="pa-10">
      <!-- <WaermeFAQ v-if="enableWaermeplanung" hide-sidebar /> -->
      <v-row>
        <v-col align="left" cols="12">
          <p
            class="custom-header-text new-font-bold mb-1"
            v-if="$vuetify.breakpoint.smAndDown"
          >
            {{ shortHeader }}
          </p>
          <p v-else class="custom-header-text new-font-bold mb-1">
            {{ header }}
          </p>
          <p class="mb-0">{{ subheader }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-container v-if="FaqItems && FaqItems.length == 0">
            {{ noFAQFound }}
          </v-container>

          <v-card class="mx-auto" v-else elevation="0">
            <v-expansion-panels
              accordion
              :multiple="allowMulti"
              v-model="panel"
            >
              <v-expansion-panel v-for="(item, i) in FaqItems" :key="i">
                <v-expansion-panel-header class="expansion-panel-header-custom">
                  {{ item.question }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-img
                    v-if="item.image"
                    :src="`${baseUrl}${item.image.url}`"
                    class="mb-2"
                  ></v-img>
                  <vue-markdown-it
                    v-if="item.answer"
                    :source="item.answer"
                  ></vue-markdown-it>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>

          <RightColumnGroup
            v-if="$vuetify.breakpoint.xs"
            :communePartner="communePartner"
            :communeRegionalLeader="communeRegionalLeader"
            :contentObject="aboutUs"
          />
        </v-col>
      </v-row>
    </v-col>
    <!-- RIGHT SIDE CONTENT - ANSPRECHPARTNER, DOWNLOADS, INTERESSANTES -->
    <v-col
      v-if="$vuetify.breakpoint.smAndUp"
      cols="4"
      class="contact_container pl-4"
    >
      <RightColumnGroup
        :communePartner="communePartner"
        :communeRegionalLeader="communeRegionalLeader"
        :contentObject="aboutUs"
      />
    </v-col>
  </v-row>
</template>

<script>
import RightColumnGroup from "@/components/layout/RightColumnGroup.vue";
import VueMarkdownIt from "vue-markdown-it";
// import WaermeFAQ from "@/views/WaermeFAQ.vue";
import {mapGetters} from "vuex";
export default {
  name: "FAQ",
  components: {
    // WaermeFAQ,
    RightColumnGroup,
    VueMarkdownIt
  },
  data() {
    return {
      header: "FAQ - aktuelle Gassituation",
      shortHeader: "FAQs",
      subheader: "Antworten auf häufig gestellte Fragen",
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      noFAQFound: "Es wurde keine FAQ Elemente gefunden.",
      panel: [],
      allowMulti: false
    };
  },
  computed: {
    ...mapGetters('user', ['enableWaermeplanung']),
    FaqItems() {
      return this.$store.getters["user/getFAQ"];
    },
    colSize() {
      if (this.$vuetify.breakpoint.smAndUp) return 8;
      else return 12;
    },
    aboutUs() {
      return this.$store.getters["generalContent/getAboutUs"];
    },
    communePartner() {
      return this.$store.getters["user/userCommunePartner"];
    },
    communeRegionalLeader() {
      return this.$store.getters["user/userCommuneRegionalLeader"];
    }
  }
};
</script>

<style scoped></style>
