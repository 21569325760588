<template>
  <div class="callback text-center">
    Sie werden in wenigen Sekunden weitergeleitet
    <v-progress-circular
      :size="18"
      :width="2"
      class="ml-1"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SsoRedirect",

  mounted() {
    this.ssoLoginRedirect();
  },

  methods: {
    async ssoLoginRedirect() {
      let ssoLoginFailed = false;

      if (this.$route.query.id_token) {
        // Der ID-Token muss im Local Storage mit dem Namen new_openid_token gespeichert werden, damit die Login
        // NEW Bibliothek auf die Nutzerinformationen und den Anmeldestatus des Nutzer zugreifen kann.
        localStorage.setItem("new_openid_token", this.$route.query.id_token);

        // login to Strapi (requires 'access_token' and 'id_token' URL params)
        const strapiLoginUrl = this.$store.getters['user/ssoCallbackUrl'](
          this.$route.query
        );
        let resJwt = await axios.get(strapiLoginUrl);

        if (resJwt) {
          await this.$store.dispatch("user/verifyAuth", resJwt.data.jwt);
        } else {
          ssoLoginFailed = true;
        }
      } else {
        ssoLoginFailed = true;
      }

      if (ssoLoginFailed) {
        //todo: show message that SSO login failed
      }
    },
  },
};
</script>
