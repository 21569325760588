<script>
import {mapGetters} from "vuex";

export default {
  name: "OverviewCardContent",
  computed: {
    ...mapGetters('communeDashboard', ['communeOverview']),
    population() {
      return (this.communeOverview?.population ?? 0)
          .toLocaleString('de-DE', {maximumFractionDigits: 0});
    },
    einspeisung() {
      const value = this.communeOverview?.einspeisung ?? 0;
      return value.toLocaleString('de-DE', {maximumFractionDigits: 0});
    },
    verbrauch() {
      return (this.communeOverview?.verbrauch ?? 0)
          .toLocaleString('de-DE', {maximumFractionDigits: 0});
    }
  }
}
</script>

<template>
  <v-card width="100%" class="contrast-card white--text quadrat-card flex-card">
    <v-card-title class="pb-1">Auf einen Blick</v-card-title>
    <v-card-text class="pb-0 commune-dashboard-min-height" style="overflow: hidden">
      <v-simple-table class="white--text card-table" dark>
        <template v-slot:default>
        <tbody>
        <tr>
          <td class="text-end f-30">{{ population }}</td>
          <th class="f-14">Einwohner<br>gesamt</th>
        </tr>
        <tr>
          <td class="text-end f-30">{{ einspeisung }}</td>
          <th class="f-14">Regenerative<br>Einspeisung</th>
        </tr>
        <tr>
          <td class="text-end f-30">{{ verbrauch}}</td>
          <th class="f-14">MWh<br> TOTAL</th>
        </tr>
        </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
<v-spacer />
    <v-card-actions>
      <v-btn tile text color="white" block link to="/verbrauchsdaten" class="commune-dashboard-dotted-border commune-dashboard-dotted-border-white font-weight-bold commune-dashboard-card-btn">
        Verbrauchsdaten ansehen
        <v-icon right>mdi-arrow-right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped>
.card-table {
  background: unset !important;
  line-height: 1 !important;
}

tr:hover {
  background: unset !important;
}

.contrast-card {
  background: transparent linear-gradient(64deg, #281E78 0%, #8C0F78 100%) 0% 0% no-repeat padding-box;
}

.f-30 {
  font-size: 30px !important;
}

.f-14 {
  font-size: 14px !important;
}

th, td {
  padding-left: 0 !important;
}
</style>
