export default {
  // navigationListCount(state) {
  //     return (state.navigationList.length);
  // },

  getMainNavObject(state) {
    return state.mainNavObject;
  },

  serviceAreas: (state, getters) => {
    return getters.getMainNavObject.serviceAreaItems;
  },

  subServiceBySubServiceId: (state, getters) => id => {
    const serviceAreaItems = getters.getMainNavObject.serviceAreaItems;
    let foundSubService;
    serviceAreaItems.map(serviceArea =>
      serviceArea.services.map(service =>
        service.subServices.find(subService => {
          if (subService.id === id) {
            foundSubService = subService;
          }
        })
      )
    );
    return foundSubService || null;
  },

  serviceAreaByServiceAreaId: (state, getters) => serviceAreaId => {
    return getters.serviceAreas.find(el => el.id === serviceAreaId);
  },
  getServiceAreaByName: (state) => name => {
    for(const serviceArea of state.mainNavObject.serviceAreaItems) {
      if(serviceArea.title === name) {
        return serviceArea;
      }
    }
  },
  getServiceByName: (state) => name => {
    for(const serviceArea of state.mainNavObject.serviceAreaItems) {
      for(const service of serviceArea.services) {
        if(service.title === name) {
          return service;
        }
      }
    }

    return undefined;
  },
  getSubServiceByName: (state) => name => {
    for(const serviceArea of state.mainNavObject.serviceAreaItems) {
      for(const service of serviceArea.services) {
        for(const subService of service.subServices) {
          if(subService.title === name) {
            return subService;
          }
        }
      }
    }

    return undefined;
  },
  getGeneralNavItems(state, getters, rootState, rootGetters) {

    let generalNavItems = state.mainNavObject.generalNavItems;

    if (
      !rootGetters["user/enableKommunalrabatt"] ||
      rootGetters["user/isNewEmployee"]
    ) {
      generalNavItems = generalNavItems.filter(
        item => item.name !== "Kommunalrabatt"
      );
    }

    if(rootGetters["user/isNewEmployee"] || !rootGetters['user/enableVerbrauchsdaten']) {
      generalNavItems = generalNavItems.filter(
        item => item.name !== "Verbrauchsdaten"
      );
    }

    return generalNavItems;
  }
};
