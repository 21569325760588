// import { _sortBy } from '@/utils/sortings'

export default {
  SET_NAVIGATION_LISTS(state, serviceAreaItems) {
    // TODO: cache
    // create array list
    // state.navigationList = serviceAreaItems;

    // state.mainNavObject.serviceAreaItems = serviceAreaItems.navigationOptions
    state.mainNavObject.serviceAreaItems = serviceAreaItems;

    // create object list with key value pairs { serviceArea.id: serviceArea }
    // let navItemObj = {};
    // serviceAreaItems.forEach(serviceNavItem => {
    //     navItemObj[serviceNavItem.serviceArea.id] = serviceNavItem.serviceArea
    // });
    // state.mainNavObject = navItemObj;
  },

  // SET_SERVICES(state, services) {
  //     // default sorting is newest first
  //     _sortBy(services, 'published_at', true);

  //     let servicesByServiceMap = {};

  //     // append dynamic / computed attributes to each service
  //     services.forEach((service, index) => {
  //         // _enrichservice(service, state.serviceAreasIdList);

  //         const serviceIds = service.services;

  //         if (serviceIds && serviceIds.length > 0) {
  //             // add post to list of each service area it's assigned to
  //             serviceIds.forEach(serviceId => {
  //                 if (!servicesByServiceMap[serviceId]) {
  //                     // create post list if none exists yet
  //                     servicesByServiceMap[serviceId] = [];
  //                 }

  //                 servicesByServiceMap[serviceId].push(service)
  //             });

  //         } else {
  //             // remove services that are not assigned to any service
  //             services.splice(index, 1);
  //         }

  //     });

  //     state.services = services;
  //     state.servicesMap = servicesByServiceMap;
  // },
};
