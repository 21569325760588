<template>
  <v-container fluid class="pa-0" id="newsPosts">
    <NewsListRow
      :iconProps="iconProps"
      @click="showDetails(entry)"
      v-for="(entry, i) in allEntries"
      :entry="entry"
      :key="i"
    />
  </v-container>
</template>

<script>
import NewsListRow from "@/components/list_rows/NewsListRow";

export default {
  name: "NewsList",
  props: ["iconProps", "filterOption"],
  components: {
    NewsListRow
  },
  methods: {
    showDetails(entry) {
      this.checkReads(entry);
      if (entry.category == "Presse") {
        this.$router.push({
          name: "pressDetail",
          params: { pressId: entry.id }
        });
      } else {
        this.$router.push({ name: "newsDetail", params: { newsId: entry.id } });
      }
    },
    checkReads(entry) {
      if (entry.category == "Presse" && entry.wasRead == 0) {
        this.$store.dispatch("news/createPressRead", entry.id);
      } else if (entry.category == "News" && entry.wasRead == 0) {
        this.$store.dispatch("news/createNewsRead", entry.id);
      }
    }
  },
  computed: {
    allEntries() {
      if (this.filterOption == "News") {
        const news = this.$store.getters["news/orderdByDatePosts"];
        return news.filter(item => item.category == "News");
      } else if (this.filterOption == "Presse") {
        const presse = this.$store.getters["news/orderdByDatePosts"];
        return presse.filter(item => item.category == "Presse");
      } else {
        return this.$store.getters["news/orderdByDatePosts"];
      }
    }
  },
  created() {
    this.$store.dispatch("news/filterPosts", "Presse");
    // this.$store.dispatch("news/fetchNews");
  }
};
</script>

<style scoped>
#newsPosts {
  padding: 0px 12px 12px 12px;
}
</style>
