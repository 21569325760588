<template>
  <div class="compiled-md-content" v-html="compiledMdText" />
</template>

<script>
import remark from "remark";
import html from "remark-html";
import stripMD from "strip-markdown";

export default {
  name: "RichText",

  props: {
    markdownText: String,
    showPlainText: Boolean,
  },

  data: () => ({
    compiledMdText: String,
  }),

  created() {
    this.parseMarkdown();
  },

  updated() {
    this.parseMarkdown();
  },

  methods: {
    parseMarkdown() {
      const remarkPlugin = !this.showPlainText ? html : stripMD;

      // parse MD and covert to plain text or HTML
      remark()
        .use(remarkPlugin)
        .process(this.markdownText, (err, compiledMdData) => {
          if (err) this.compiledMdText = this.markdownText;

          let compiledTextStr = compiledMdData.contents;

          if (!this.showPlainText) {
            compiledTextStr = this.processHtml(compiledTextStr);
          }
          this.compiledMdText = compiledTextStr;
        });
    },

    processHtml(htmlStr) {
      // regular expressions
      const matchHrefRegEx = /(href="([^"]+)")/gi;
      const matchImgRegEx = /<img\s+src="([^"]+)"[^>]+>/gi;

      // make all links open in new tab
      htmlStr = htmlStr.replace(matchHrefRegEx, '$1 target="_blank"'); //todo: only add for external links

      // make <img> sources absolute URLs
      const baseUrl = this.$store.state.SERVER_BASE_URL;
      htmlStr = htmlStr.replace(
        matchImgRegEx,
        '<img class="richtext-img" src="' + baseUrl + '$1" />'
      );

      return htmlStr;
    },
  },
};
</script>

<style>
.compiled-md-content ul {
  margin-bottom: 1em;
}

img.richtext-img {
  width: 100%;
}
</style>