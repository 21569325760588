import RestMethods from "@/store/RestMethods";
const NEWS_MODEL = RestMethods.init("news-posts");
const PRESS_MODEL = RestMethods.init("press-posts");
const READ_MODEL = RestMethods.init("reads");
const NEWS_CACHE_SECS = 5 * 60; // 5 mins

export default {
  orderMonthYear({ commit }, monthYear) {
    commit("SET_MONTH_YEAR_ORDER", monthYear);
  },
  orderPosts({ commit }, order) {
    commit("SET_DATEORDER", order);
  },
  filterPosts({ commit }, filter) {
    commit("SET_CATEGORYFILTER", filter);
  },
  async fetchNews({ commit }, newsPosts) {
    if (!NEWS_MODEL.isCacheExpired("fetchNews", NEWS_CACHE_SECS)) {
      return;
    }

    const news = await Promise.all([
      READ_MODEL.find(), // read objects contain news and press

      // NEWS_MODEL.count(),
      // NEWS_MODEL.find(),

      PRESS_MODEL.count(),
      PRESS_MODEL.find(),
    ]).then((val) => val);
    //todo: handle failed request(s)
    //
    if (news[0] && news[1] && news[2]) {
      NEWS_MODEL.resetCacheTimeout("fetchNews");

      // filter reads according to news_post or presse_post
      let newsReads = news[0].data.filter((o) => o.news_post != null);
      let pressReads = news[0].data.filter((o) => o.press_post != null);
      // use reads in commit
      commit("SET_NEWS_READS", newsReads);
      commit("SET_PRESS_READS", pressReads);

      commit("SET_NEWS_COUNT", newsPosts.length); // news[1].data);
      commit("SET_NEWS", newsPosts); //news[2].data);

      commit("SET_PRESS_COUNT", news[1].data);
      commit("SET_PRESS", news[2].data);
    }
  },

  async createNewsRead({ commit }, newsPostId) {
    try {
      await READ_MODEL.create({ news_post: newsPostId });
      commit("MARK_NEWS_READ", newsPostId);
      // eslint-disable-next-line no-empty
    } catch (error) {
    }
  },

  async createPressRead({ commit }, pressPostId) {
    try {
      await READ_MODEL.create({ press_post: pressPostId });
      commit("MARK_PRESS_READ", pressPostId);
      // eslint-disable-next-line no-empty
    } catch (error) {
    }
  },
};
