<template>
  <div class="mt-2">
    <v-row class="pa-0 comments-box ma-4" no-gutters>
        <v-col v-for="(comment, i) of comments" :key="i" cols="12" class="mb-1 comment-col px-0" :class="comment.commune === 'NEW MA' ? ['new-col'] : ['commune-col']">
          <div class="comment pa-2" :class="comment.commune === 'NEW MA' ? ['new-ma-comment'] : ['commune-ma-comment']">
            <div>
              <span style="font-size: 14px"><b>{{ comment.author }}</b> - {{ comment.commune }}</span>
            </div>
            <p class="mt-1" style="white-space: pre-line">{{ comment.message }}</p>
            <div class="font-weight-light text-right text-caption" style="line-height: 1rem">{{ getTimestamp(comment.timestamp) }}</div>
          </div>
        </v-col>
    </v-row>
    <comment-input />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommentInput from "@/components/ui/Kommunalrabatt/CommentInput";
import moment from "moment";

export default {
  name: "SidebarComments",
  components: {CommentInput},
  data: () => ({}),
  methods: {
    getTimestamp(timestamp) {
      return new moment(timestamp).format('DD.MM.YYYY');
    },
  },
  computed: {
    ...mapGetters('kommunalrabatt', ['selectedLieferstelle', 'getComments']),
    comments() {
      return this.getComments ?? [];
    },
  },
}
</script>

<style scoped>
.comments-box {
  /* background-color: #eaecef; */
  border-radius: 10px;
}

.new-ma-comment {
  border-radius: 20px 20px 0 20px;
  background-color: #f5e6ed;
}

.commune-ma-comment {
  border-radius: 20px 20px 20px 0;
  background-color: #f9fafc;
}

.comment-col {
  display: flex;
  flex-direction: row;
}

.new-col {
  justify-content: flex-end;
}

.commune-col {
  justify-content: flex-start;
}

.comment {
  display: inline-block;
}

.comment > p {
  margin-bottom: 0;
}

.comment > div {
  line-height: 16px;
}
</style>
